import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"
import chroma from "chroma-js"

import AltFirmsProductivity from "./alt/firms-productivity.png"

const rawData = {
  gdp: {
    name: "การขยายตัวของ GDP (เฉลี่ยต่อปี)",
    data: [10.3, 8.6, 5.6, 3.0, 3.5],
  },
  exports: {
    name: "การขยายตัวของการส่งออก (เฉลี่ยต่อปี)",
    data: [19.8, 14.3, 10.2, 3.4, 2.0],
  },
  investment: {
    name: "การลงทุนต่อ GDP (เฉลี่ยต่อปี)",
    data: [32.3, 40.9, 25.8, 25.1, 24.1],
  },
}

const years = ["2528–33", "2533–38", "2545–50", "2553–58", "2558–62"]

function Graph({ facet, ...rest }) {
  return(
    <LazyHighcharts
      // altImage={}
      options={{
        series: [rawData[facet]],
        plotOptions: {
          column: {
            colorByPoint: true,
          },
        },
        colors: [
          chroma(defaultStyles.colors[1]).alpha(0.8).hex(),
          defaultStyles.colors[1],
          chroma(defaultStyles.colors[0]).alpha(0.64).hex(),
          chroma(defaultStyles.colors[0]).alpha(0.8).hex(),
          defaultStyles.colors[0],
        ],
        legend: {
          enabled: false,
        },
        title: {
          text: rawData[facet].name.replace(" (เฉลี่ยต่อปี)", "<br />(เฉลี่ยต่อปี)"),
          style: {
            fontSize: '1rem',
          },
        },
        chart: {
          type: "column",
          height: "300px",
        },
        xAxis: {
          categories: years
        },
        tooltip: {
          formatter: function(tooltip) {
            let tmp = tooltip.defaultFormatter.call(this, tooltip);
            tmp[1] = tmp[1].replace(" (เฉลี่ยต่อปี)", "").replace("</b>", "%</b>")
            return tmp
          }
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            format: "{value}%",
          },
          // reversedStacks: false,
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
      {...rest}
    />
  )
}

export default function FirmsProductivity() {

  return(
    <>
      <img className="printonly" src={AltFirmsProductivity} alt="Firms' productivity" />
      <div className="split-2 noprint">
        <Graph facet="gdp" />
        <Graph facet="exports" />
        <Graph facet="investment" />
      </div>
    </>
  )

}