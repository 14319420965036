import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"
 
export default function Blog2403F1() {
 
  return (
<LazyHighcharts
      options={{
        chart: {
          type: 'bar',
          height: 1600,
        },
        xAxis: {
          type: 'category',
          labels: {
            step: 1,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'VAT rate'
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          name: 'VAT rate',
          crisp: false,
          colorByPoint: true,
          groupPadding: 0,
          data: [
            {name: 'Hungary', y: 27, color: defaultStyles.colors[0]},
            {name: 'Croatia', y: 25, color: defaultStyles.colors[1]},
            {name: 'Denmark', y: 25, color: defaultStyles.colors[0]},
            {name: 'Norway', y: 25, color: defaultStyles.colors[0]},
            {name: 'Sweden', y: 25, color: defaultStyles.colors[0]},
            {name: 'Finland', y: 24, color: defaultStyles.colors[0]},
            {name: 'Greece', y: 24, color: defaultStyles.colors[0]},
            {name: 'Iceland', y: 24, color: defaultStyles.colors[0]},
            {name: 'Ireland', y: 23, color: defaultStyles.colors[0]},
            {name: 'Poland', y: 23, color: defaultStyles.colors[0]},
            {name: 'Portugal', y: 23, color: defaultStyles.colors[0]},
            {name: 'Italy', y: 22, color: defaultStyles.colors[0]},
            {name: 'Slovenia', y: 22, color: defaultStyles.colors[0]},
            {name: 'Argentina', y: 21, color: defaultStyles.colors[1]},
            {name: 'Belgium', y: 21, color: defaultStyles.colors[0]},
            {name: 'Czech Republic', y: 21, color: defaultStyles.colors[0]},
            {name: 'Latvia', y: 21, color: defaultStyles.colors[0]},
            {name: 'Lithuania', y: 21, color: defaultStyles.colors[0]},
            {name: 'Montenegro', y: 21, color: defaultStyles.colors[1]},
            {name: 'Netherlands', y: 21, color: defaultStyles.colors[0]},
            {name: 'Spain', y: 21, color: defaultStyles.colors[0]},
            {name: 'Albania', y: 20, color: defaultStyles.colors[1]},
            {name: 'Armenia', y: 20, color: defaultStyles.colors[1]},
            {name: 'Austria', y: 20, color: defaultStyles.colors[0]},
            {name: 'Bulgaria', y: 20, color: defaultStyles.colors[1]},
            {name: 'Estonia', y: 20, color: defaultStyles.colors[0]},
            {name: 'France', y: 20, color: defaultStyles.colors[0]},
            {name: 'Isle of Man', y: 20, color: defaultStyles.colors[1]},
            {name: 'Madagascar', y: 20, color: defaultStyles.colors[1]},
            {name: 'Moldova', y: 20, color: defaultStyles.colors[1]},
            {name: 'Morocco', y: 20, color: defaultStyles.colors[1]},
            {name: 'Serbia', y: 20, color: defaultStyles.colors[1]},
            {name: 'Slovak Republic', y: 20, color: defaultStyles.colors[0]},
            {name: 'Turkey', y: 20, color: defaultStyles.colors[0]},
            {name: 'Ukraine', y: 20, color: defaultStyles.colors[1]},
            {name: 'United Kingdom', y: 20, color: defaultStyles.colors[0]},
            {name: 'Cameroon, Republic of', y: 19.25, color: defaultStyles.colors[1]},
            {name: 'Algeria', y: 19, color: defaultStyles.colors[1]},
            {name: 'Chile', y: 19, color: defaultStyles.colors[1]},
            {name: 'Colombia', y: 19, color: defaultStyles.colors[0]},
            {name: 'Cyprus', y: 19, color: defaultStyles.colors[1]},
            {name: 'Germany', y: 19, color: defaultStyles.colors[0]},
            {name: 'Romania', y: 19, color: defaultStyles.colors[1]},
            {name: 'Tunisia', y: 19, color: defaultStyles.colors[1]},
            {name: 'Azerbaijan', y: 18, color: defaultStyles.colors[1]},
            {name: 'Chad', y: 18, color: defaultStyles.colors[0]},
            {name: 'Dominican Republic', y: 18, color: defaultStyles.colors[1]},
            {name: 'Gabon', y: 18, color: defaultStyles.colors[1]},
            {name: 'Georgia', y: 18, color: defaultStyles.colors[1]},
            {name: 'India', y: 18, color: defaultStyles.colors[1]},
            {name: 'Ivory Coast ', y: 18, color: defaultStyles.colors[1]},
            {name: 'Kosovo', y: 18, color: defaultStyles.colors[1]},
            {name: 'Malta', y: 18, color: defaultStyles.colors[1]},
            {name: 'North Macedonia', y: 18, color: defaultStyles.colors[1]},
            {name: 'Peru', y: 18, color: defaultStyles.colors[1]},
            {name: 'Rwanda', y: 18, color: defaultStyles.colors[1]},
            {name: 'Senegal', y: 18, color: defaultStyles.colors[1]},
            {name: 'Tanzania', y: 18, color: defaultStyles.colors[1]},
            {name: 'Uganda', y: 18, color: defaultStyles.colors[1]},
            {name: 'Barbados', y: 17.5, color: defaultStyles.colors[1]},
            {name: 'Bosnia and Herzegovina', y: 17, color: defaultStyles.colors[1]},
            {name: 'Israel', y: 17, color: defaultStyles.colors[0]},
            {name: 'Luxembourg', y: 17, color: defaultStyles.colors[0]},
            {name: 'Malawi', y: 16.5, color: defaultStyles.colors[1]},
            {name: 'Congo, Democratic Republic of the', y: 16, color: defaultStyles.colors[1]},
            {name: 'Jordan', y: 16, color: defaultStyles.colors[1]},
            {name: 'Kenya', y: 16, color: defaultStyles.colors[1]},
            {name: 'Mauritania', y: 16, color: defaultStyles.colors[1]},
            {name: 'Mexico', y: 16, color: defaultStyles.colors[0]},
            {name: 'Mozambique', y: 16, color: defaultStyles.colors[1]},
            {name: 'Venezuela', y: 16, color: defaultStyles.colors[1]},
            {name: 'Zambia', y: 16, color: defaultStyles.colors[1]},
            {name: 'Cabo Verde', y: 15, color: defaultStyles.colors[1]},
            {name: 'Equatorial Guinea', y: 15, color: defaultStyles.colors[1]},
            {name: 'Eswatini', y: 15, color: defaultStyles.colors[1]},
            {name: 'Ethiopia', y: 15, color: defaultStyles.colors[1]},
            {name: 'Fiji', y: 15, color: defaultStyles.colors[1]},
            {name: 'Jamaica', y: 15, color: defaultStyles.colors[1]},
            {name: 'Mauritius', y: 15, color: defaultStyles.colors[1]},
            {name: 'Namibia, Republic of', y: 15, color: defaultStyles.colors[1]},
            {name: 'New Zealand', y: 15, color: defaultStyles.colors[0]},
            {name: 'Nicaragua', y: 15, color: defaultStyles.colors[1]},
            {name: 'Saudi Arabia', y: 15, color: defaultStyles.colors[1]},
            {name: 'South Africa', y: 15, color: defaultStyles.colors[1]},
            {name: 'Sri Lanka', y: 15, color: defaultStyles.colors[1]},
            {name: 'Tajikistan', y: 15, color: defaultStyles.colors[1]},
            {name: 'Turkmenistan', y: 15, color: defaultStyles.colors[1]},
            {name: 'Zimbabwe', y: 15, color: defaultStyles.colors[1]},
            {name: 'Angola', y: 14, color: defaultStyles.colors[1]},
            {name: 'Botswana', y: 14, color: defaultStyles.colors[1]},
            {name: 'Egypt', y: 14, color: defaultStyles.colors[1]},
            {name: 'Guyana', y: 14, color: defaultStyles.colors[1]},
            {name: 'Bolivia', y: 13, color: defaultStyles.colors[1]},
            {name: 'Christian', y: 13, color: defaultStyles.colors[1]},
            {name: 'Costa Rica', y: 13, color: defaultStyles.colors[0]},
            {name: 'El Salvador', y: 13, color: defaultStyles.colors[1]},
            {name: 'Saint Lucia', y: 12.5, color: defaultStyles.colors[1]},
            {name: 'Trinidad and Tobago', y: 12.5, color: defaultStyles.colors[1]},
            {name: 'Ecuador', y: 12, color: defaultStyles.colors[1]},
            {name: 'Guatemala', y: 12, color: defaultStyles.colors[1]},
            {name: 'Kazakhstan', y: 12, color: defaultStyles.colors[1]},
            {name: 'Kyrgyzstan', y: 12, color: defaultStyles.colors[1]},
            {name: 'Philippines', y: 12, color: defaultStyles.colors[1]},
            {name: 'Uzbekistan, Republic of', y: 12, color: defaultStyles.colors[1]},
            {name: 'Indonesia', y: 11, color: defaultStyles.colors[1]},
            {name: 'Lebanon', y: 11, color: defaultStyles.colors[1]},
            {name: 'Australia', y: 10, color: defaultStyles.colors[0]},
            {name: 'Bahrain', y: 10, color: defaultStyles.colors[1]},
            {name: 'Cambodia', y: 10, color: defaultStyles.colors[1]},
            {name: 'Japan', y: 10, color: defaultStyles.colors[0]},
            {name: 'Korea, Republic of', y: 10, color: defaultStyles.colors[0]},
            {name: 'Malaysia', y: 10, color: defaultStyles.colors[1]},
            {name: 'Mongolia', y: 10, color: defaultStyles.colors[1]},
            {name: 'Vietnam', y: 10, color: defaultStyles.colors[1]},
            {name: 'Singapore', y: 	9, color: defaultStyles.colors[1]},
            {name: 'Switzerland', y: 8.1, color: defaultStyles.colors[0]},
            {name: 'Liechtenstein', y: 7.7, color: defaultStyles.colors[1]},
            {name: 'Nigeria', y: 7.5, color: defaultStyles.colors[1]},
            {name: 'Lao PDR', y: 	7, color: defaultStyles.colors[1]},
            {name: 'Thailand', y: 	7, color: defaultStyles.colors[2]},
            {name: 'Canada', y: 	5, color: defaultStyles.colors[0]},
            {name: 'Taiwan', y: 	5, color: defaultStyles.colors[1]},
            {name: 'United Arab Emirates', y: 	5, color: defaultStyles.colors[1]},
          ],
        }]
      }}
    />
  )
 
}