import React from "react"
import { LazyHighcharts } from "../../../../src/components/Plots"
import zIndex from "@mui/material/styles/zIndex"

export default function Ab0724Fig5() {
  return (
    <LazyHighcharts
      options={{
        chart: {
          type: "column"
        },
        plotOptions: {
          errorbar: {
            color: "black",
            whiskerLength: "20%",
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          plotLines: [{
            color: "black",
            width: 2,
            value: 0,
          }],
        },
        xAxis: {
          categories: [
            "Ball and Sheridan (2004)",
            "Stacked DiD Regression ",
            "Callaway and Sant'Anna (2021)",
            "Sun and Abraham (2021)",
          ],
        },
        series: [
          {
            name: "Inflation level",
            type: "errorbar",
            pointPlacement: -0.2,
            data: [
              [-3.74292, -0.01108],
              [-6.28672, -2.92728],
              [-4.86244, -0.49556],
              [-5.54688, -1.41912],
            ],
            zIndex: 12,
          },
          {
            name: "Inflation volatility",
            type: "errorbar",
            pointPlacement: 0,
            data: [
              [-1.9998, 0.1758],
              [-3.38868, -1.00532],
              [-2.03016, 0.69816],
              [-1.6702, 0.8582],
            ],
            zIndex: 10,
          },
          {
            name: "Growth volatility",
            type: "errorbar",
            pointPlacement: 0.2,
            data: [
              [-1.20304, 0.36104],
              [-0.58464, 0.43064],
              [-0.22264, 0.69464],
              [-0.24368, 1.15968],
            ],
            zIndex: 11,
          },
          {
            name: "Inflation Level",
            data: [-1.877, -4.607, -2.679, -3.483],
            zIndex: 1,
          },
          {
            name: "Inflation Volatility",
            data: [-0.912, -2.197, -0.666, -0.406],
            zIndex: 2,
          },
          {
            name: "Growth Volatility",
            data: [-0.421, -0.077, 0.236, 0.458],
            zIndex: 3,
          },
        ]
      }}
    />
  )
}