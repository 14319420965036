import React from "react"
import thMap from "@highcharts/map-collection/countries/th/th-all.topo.json";
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"

const data = {
  "2562": [['th-pt', 99.4595], ['th-lb', 99.2231], ['th-cc', 103.971], ['th-su', 99.3139], ['th-ac', 97.6757], ['th-kk', 101.001], ['th-un', 100.506], ['th-re', 98.8606], ['th-ln', 100.748], ['th-lg', 104.469], ['th-pr', 108.01], ['th-cr', 102.651], ['th-ss', 102.213], ['th-sg', 101.552], ['th-sa', 97.808], ['th-pl', 103.035], ['th-pi', 95.2752], ['th-yl', 97.588], ['th-nw', 95.4876]],
  "2564": [['th-at', 97.8515], ['th-sb', 94.707], ['th-sr', 96.4325], ['th-cb', 101.4], ['th-ry', 93.6497], ['th-ct', 95.7105], ['th-tt', 95.854], ['th-pb', 95.1289], ['th-nn', 94.22], ['th-sk', 97.1711], ['th-si', 96.1192], ['th-bk', 97.4289], ['th-le', 97.9125], ['th-nh', 99.3685], ['th-ms', 96.8796], ['th-sn', 98.0084], ['th-nf', 96.6665], ['th-ud', 99.967], ['th-ns', 99.6322], ['th-ut', 100.027], ['th-kp', 97.325], ['th-tk', 97.332], ['th-so', 107.697], ['th-pc', 97.2102], ['th-rt', 91.9481], ['th-sh', 99.1768], ['th-np', 97.7481], ['th-sm', 98.241], ['th-pe', 100.778], ['th-pk', 99.1015], ['th-rn', 96.5394], ['th-cp', 99.2473]],
}

export default function Map({ year, altImage }) {

  const options = {
    chart: {
      map: thMap,
      type: "map",
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    colorAxis: {
      // type: 'logarithmic',
      stops: [
        [0, defaultStyles.colors[0]],
        [0.2, defaultStyles.colors[0]],
        [0.5, '#ffffca'],
        [0.8, defaultStyles.colors[1]],
        [1, defaultStyles.colors[1]],
      ],
      min: 90,
      max: 110,
      reversed: false,
    },
    series: [{
      data: data[year],
      name: 'ระดับความพร้อม',
      states: {
        hover: {
          color: '#BADA55'
        }
      },
      dataLabels: {
        enabled: false,
        format: '{point.name}'
      }
    }]
  }

  return <LazyHighcharts altImage={altImage} constructorType={"mapChart"} options={options} />
}