import React from "react"
import { SimpleChart } from "../../../../src/components/Plots"

export default function Ab0724Fig4() {
  return (
    <>
      <SimpleChart
        type="spline"
        isDate={true}
        data={[
          {
            name: "Thailand",
            data: [61.4,61.4,59.9,59.3,62.9,62.5,61.8,63.6,67,69.2,72.4,77.9,80.4,80.2,81.5,84.5,87.3,86.5,85.4,86.9,87.3,87,86.9,86.4,88.8,87.3,86.8,91.1,92,92.6,93.2,96.1,98.1,100.1,101.2,105.5,109.3,113.9,120.2,124.2,126.5,128.6,132.6,135.7,137.2,139.7,142.1,145.1,148.2,151.8,152.9,156.5,159.3,164.8,171.9,180.7,182.8,188.3,192.8,199.9,204.7,207.9,207.4,210.9,213,213.7,227,242.4,224.8,228,224.7,220.4,217.8,209.8,198.3,187.2,183.6,174.4,161.1,155.9,154.4,144.5,143.9,139.1,146.1,143.2,143.7,142.2,140.7,140.6,138.2,133.6,135.5,135.7,139.9,136.7,135.8,136.4,136.1,135.3,133.1,131.8,130.9,129.5,126.6,126.3,125.5,124.2,124,124.8,125.4,128.3,128.8,131.5,133.4,132.4,128.3,127,125.8,129.7,131.1,133,134.9,140.6,142.8,144.7,146.9,145.8,145.4,148.1,148.2,152,152.4,153.3,153.7,156,155.7,157,158.2,159.4,161.1,160.5,158.5,156.9,154.6,154.7,152.9,153.3,152.3,152.9,152.8,154.4,154,153.6,153.4,155.1,158.5,165.7,170.7,176.2,179.2,176.9,179.3,179.8,179,177.8,175.5,174.2],
          },
          {
            name: "Indonesia",
            data: [19.2,19.9,21.2,22.3,23.2,27.6,26.8,26.9,26.5,25.2,25.1,24.9,25.1,25.4,26.2,26.5,26.7,27.2,28.4,29.4,29.8,31.4,37.4,37.2,38,37.8,36.7,37,38.4,39.1,40.2,41.8,41.7,43.2,45.2,46.7,48.5,53.8,56.8,59.3,58.5,58.4,56.6,58.8,58.3,58.8,59.6,58.4,55.7,56.4,56.1,55.2,56.7,57.3,58.2,59.5,59.3,59.6,61,62.1,62.4,65,66.1,67.1,67.6,69.9,78.3,83.5,105,137.8,98.2,74.6,59.4,38.5,42.4,34.5,32.7,33.7,32.1,31.8,32.1,32.3,28.5,28,26.1,25,25.7,26.2,25.8,24.9,25.4,26.3,25.5,28.3,28.2,28.9,29.3,30.1,30.8,29.1,26.7,26.9,26.6,27.1,26.3,25.3,26,28,27.8,28.7,29.1,29.2,28.1,27.2,26.9,27.4,25.8,27.4,27.4,28.7,28.2,29.5,31,32.1,33,34.6,35.3,36.8,36.7,38.4,40.3,41.2,40,41.1,41.5,42.1,41.6,42.5,42.9,43.2,42.2,42.6,42.3,43.1,41.9,42.1,41.5,42,41.4,42.4,42.9,43.1,42.7,42.8,42.9,42.9,44.7,43.6,44.9,44.2,44.5,43.6,42.4,41.8,41.2,41.7,40.6,40.1],
          },
          {
            name: "Philippines",
            data: [28.9,28.9,28.9,28.9,29.4,29.4,29.4,29.4,32.5,32.5,32.5,32.5,21.5,21.5,21.5,21.5,17.7,17.7,17.7,17.7,13.1,13.1,13.1,13.1,14,14,14,14,14.2,14.2,14.2,14.2,15.2,15.2,15.2,15.2,16.9,16.9,16.9,16.9,15.6,15.6,15.6,15.6,18.1,18.1,18.1,18.1,23.1,23.1,23.1,23.1,25.5,25.5,25.5,25.5,32.9,32.9,32.9,32.9,42.9,42.9,42.9,42.9,49.4,49.4,49.4,49.4,42,42,42,42,37.3,37.3,37.3,37.3,35.6,35.6,35.6,35.6,36.3,36.3,36.3,36.5,35.2,33.9,33.9,34.7,34.3,33.8,32.7,32.6,31.5,31.9,31.5,30.7,30.1,30.4,29.4,28.4,27.2,26.9,26,27.4,25.6,25.8,24.8,27.6,25.7,26.7,27.7,28.6,26.8,28.3,28.2,29.1,27.9,28.2,27.7,28.6,27.9,29.1,29.3,30.5,30,30.5,30.2,32.1,31.4,32.3,32.7,34.3,34.5,35.2,35.9,37.6,36.9,37,37.5,39.9,39.4,40.3,40.8,42.9,42.8,43.5,44.4,45.6,46,46.6,47,47.6,46,46.6,46.9,48,48.2,49.6,49.8,52,51.4,50.1,49.8,49.9,48.6,48.9,48.9,48.9],
          },
        ]}
        start={"1981-01"}
        interval={3}
        override={{
          title: {
            text: "(a) Private credit to GDP",
          },
          tooltip: {
            xDateFormat: "%YQ%q",
          },
        }}
      />

      <SimpleChart
        type="column"
        stacking="normal"
        override={{
          plotOptions: {
            scatter: {
              lineWidth: 0,
              marker: {
                radius: 8,
              }
            },
          },
          yAxis: {
            tickInterval: 5,
          },
          title: {
            text: "(b) Bank capital and loans default",
          },
        }}
        data={[
          {
            name: "Capital adequacy ratio (2011–2022)",
            data: [17.92,21.68563888,16.45770536],
          },
          {
            name: "%Non-performing loans (2001–2010)",
            data: [8.617,4.75625,10.74156442],
            type: 'scatter',
            color: "black",
          },
          {
            name: "%Non-performing loans (2011–2022)",
            data: [2.693333333,2.447279625,2.560086485],
            type: 'scatter',
            // just outline for the markers; no fill, black line
            marker: {
              fillColor: "white",
              lineWidth: 2,
              lineColor: "black",
            },
          },
        ]}
        categories={[
          "Thailand",
          "Indonesia",
          "Philippines",
        ]}
      />
    </>
  )
}