import Ab2323Fig1 from "../../../../content/abridged/2023/23/Ab2323Fig1";
import Ab2323Labor1 from "../../../../content/abridged/2023/23/Ab2323Labor1";
import Ab2323Labor2 from "../../../../content/abridged/2023/23/Ab2323Labor2";
import Ab2323Demo from "../../../../content/abridged/2023/23/Ab2323Demo";
import * as React from 'react';
export default {
  Ab2323Fig1,
  Ab2323Labor1,
  Ab2323Labor2,
  Ab2323Demo,
  React
};