import React from "react"
import { SimpleChart, defaultStyles } from "../../../../../src/components/Plots"

const rawData = {
  "Australia": {
    data: [12.0,12.1,12.1,12.1,12.1,12.2,12.2,12.2,12.2,12.2,12.2,12.3,12.3,12.3,12.3,12.4,12.4,12.4,12.4,12.5,12.5,12.5,12.6,12.6,12.6,12.7,12.7,12.7,12.8,12.8,12.8,12.8,12.8,12.8],
  },
  "Singapore": {
    data: [11.5,11.5,11.5,11.6,11.6,11.6,11.7,11.7,11.8,11.8,11.9,11.9,12.0,12.0,12.1,12.2,12.2,12.3,12.3,12.3,12.3,12.3,12.3,12.3,12.3,12.3,12.4,12.4,12.4,12.5,12.5,12.5,12.6,12.6],
  },
  "Japan": {
    data: [12.1,12.2,12.2,12.2,12.3,12.3,12.3,12.3,12.4,12.4,12.4,12.4,12.5,12.5,12.5,12.5,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6,12.6],
  },
  "Macao": {
    data: [null,null,null,null,null,11.6,11.7,11.8,11.9,12.0,11.9,11.9,11.9,11.9,11.9,11.9,11.9,11.9,11.9,11.9,12.0,12.1,12.2,12.2,12.2,12.2,12.2,12.2,12.3,12.3,12.4,12.4,12.5,12.5],
  },
  "Hong Kong": {
    data: [11.2,11.2,11.3,11.3,11.4,11.4,11.5,11.6,11.6,11.7,11.7,11.8,11.8,11.9,11.9,11.9,12.0,12.0,12.0,12.0,12.1,12.1,12.1,12.1,12.2,12.2,12.2,12.2,12.2,12.3,12.3,12.3,12.3,12.4],
  },
  "New Zealand": {
    data: [11.5,11.6,11.6,11.7,11.7,11.7,11.7,11.8,11.8,11.8,11.8,11.8,11.9,11.9,11.9,11.9,11.9,12.0,12.0,12.0,12.0,12.1,12.1,12.1,12.2,12.2,12.2,12.2,12.2,12.3,12.3,12.3,12.3,12.3],
  },
  "Korea": {
    data: [10.4,10.5,10.6,10.7,10.8,10.9,11.0,11.1,11.2,11.3,11.4,11.5,11.6,11.6,11.6,11.7,11.7,11.8,11.8,11.9,11.9,11.9,12.0,12.0,12.1,12.1,12.1,12.1,12.1,12.2,12.2,12.2,12.3,12.3],
  },
  "Malaysia": {
    data: [10.0,10.0,10.0,10.0,10.1,10.2,10.3,10.3,10.4,10.6,10.6,10.7,10.7,10.7,10.7,10.8,10.8,10.8,10.8,10.8,10.9,10.9,10.9,10.9,10.9,10.9,10.9,10.9,11.0,11.0,11.0,11.1,11.1,11.1],
  },
  "China": {
    data: [8.0,8.0,8.1,8.1,8.2,8.2,8.3,8.5,8.6,8.7,8.8,8.8,8.9,9.0,9.1,9.2,9.3,9.4,9.5,9.6,9.7,9.8,9.9,10.1,10.2,10.3,10.4,10.5,10.6,10.7,10.8,10.9,11.0,11.1],
  },
  "Thailand": {
    data: [9.3,9.3,9.4,9.4,9.5,9.6,9.7,9.8,9.9,10.0,10.1,10.1,10.2,10.2,10.2,10.1,10.1,10.1,10.1,10.1,10.2,10.2,10.2,10.2,10.3,10.2,10.3,10.3,10.4,10.4,10.4,10.5,10.5,10.5],
    color: defaultStyles.colors[0],
    lineWidth: 2,
  },
  "Indonesia": {
    data: [8.8,8.8,8.9,9.0,9.0,9.1,9.2,9.3,9.3,9.4,9.5,9.6,9.6,9.6,9.6,9.6,9.6,9.7,9.7,9.8,9.8,9.8,9.8,9.8,9.9,9.9,9.9,10.0,10.0,10.1,10.1,10.1,10.2,10.2],
  },
  "Philippines": {
    data: [null,null,null,null,null,null,null,null,null,null,null,null,null,9.1,9.2,9.2,9.2,9.2,9.2,9.2,9.3,9.3,9.3,9.3,9.4,9.4,9.4,9.5,9.5,9.6,9.6,9.7,9.8,9.9],
  },
  "Vietnam": {
    data: [7.0,7.1,7.1,7.2,7.2,7.2,7.3,7.4,7.5,7.6,7.7,7.8,7.9,8.0,8.1,8.1,8.2,8.3,8.4,8.4,8.5,8.6,8.6,8.7,8.7,8.8,8.8,8.9,8.9,9.0,9.0,9.1,9.2,9.2],
  },
}

export default function Ab2023KL() {
  // create line charts from the above data
  return(
    <SimpleChart
      data={Object.keys(rawData).map((country) => ({
        name: country,
        data: rawData[country].data,
        color: rawData[country].color,
        lineWidth: rawData[country].lineWidth || 1,
        marker: {
          enabled: country === "Thailand",
        },
      }))}
      start={1986}
      override={{
        chart: {
          height: '600px',
        },
      }}
    />
  )
}