import React from "react"
import { SimpleChart, defaultStyles } from "../../../../src/components/Plots"

export default function Ab1923Fig2() {
  return(
    <SimpleChart
      type="column"
      data={[
        {
          name: 'กลุ่มควบคุม',
          data: [0.1482, 0.1146, 0.1347],
          color: defaultStyles.colors[1],
        },
        {
          name: 'กลุ่มทดลอง',
          data: [0.206, 0.1941, 0.2011],
          color: defaultStyles.colors[0],
        },
      ]}
      categories={['ด้านคณิตศาสตร์', 'ด้านภาษา', 'ด้านวิชาการโดยรวม']}
      ytitle="ทักษะส่วนเพิ่มเฉลี่ยต่อวันที่ไปโรงเรียน"
    />
  )
}