import React from "react"
import { LazyHighcharts } from "../../../../src/components/Plots"

const date1 = ["1985Q1", "1985Q3", "1986Q3", "1987Q1", "1987Q3", "1988Q1", "1988Q3", "1989Q1", "1989Q3", "1990Q3", "1991Q1", "1991Q3", "1992Q1", "1992Q3", "1993Q1", "1993Q3", "1994Q1", "1994Q3", "1995Q1", "1995Q3", "1996Q1", "1996Q3", "1997Q1", "1997Q3", "1998Q1", "1998Q3", "1999Q1", "1999Q2", "1999Q3", "1999Q4", "2000Q1", "2000Q2", "2000Q3", "2000Q4", "2001Q1", "2001Q2", "2001Q3", "2001Q4", "2002Q1", "2002Q2", "2002Q3", "2002Q4", "2003Q1", "2003Q2", "2003Q3", "2003Q4", "2004Q1", "2004Q2", "2004Q3", "2004Q4", "2005Q1", "2005Q2", "2005Q3", "2005Q4", "2006Q1", "2006Q2", "2006Q3", "2006Q4", "2007Q1", "2007Q2", "2007Q3", "2007Q4", "2008Q1", "2008Q2", "2008Q3", "2008Q4", "2009Q1", "2009Q2", "2009Q3", "2009Q4", "2010Q1", "2010Q2", "2010Q3", "2010Q4", "2011Q1", "2011Q2", "2011Q3", "2011Q4", "2012Q1", "2012Q2", "2012Q3", "2012Q4", "2013Q1", "2013Q2", "2013Q3", "2013Q4", "2014Q1", "2014Q2", "2014Q3", "2014Q4", "2015Q1", "2015Q2", "2015Q3", "2015Q4", "2016Q1", "2016Q2", "2016Q3", "2016Q4", "2017Q1", "2017Q2", "2017Q3", "2017Q4", "2018Q1", "2018Q2", "2018Q3", "2018Q4", "2019Q1", "2019Q2", "2019Q3", "2019Q4", "2020Q1", "2020Q2", "2020Q3", "2020Q4", "2021Q1", "2021Q2", "2021Q3", "2021Q4", "2022Q1", "2022Q2", "2022Q3", "2022Q4"]
const rawData1 = [
  {name: "Primary", data: [30.8200,29.8449,27.5177,26.5744,27.9065,26.7174,27.5065,26.1774,27.7948,28.7558,27.8652,29.8936,29.1872,32.1498,32.5740,34.2456,31.5988,33.4843,32.1822,35.7636,33.0000,36.6212,34.3257,39.0463,32.8725,32.5024,31.3868,31.8893,31.9191,31.9813,30.9046,31.6802,31.0601,31.9684,30.0501,29.5408,29.3601,29.0346,29.8787,29.4508,29.8388,29.4644,29.4340,29.3108,29.4886,29.4432,28.8262,29.6163,29.0704,28.8825,28.7936,28.7966,28.3758,28.6631,28.7586,29.0497,28.7071,28.9550,28.6146,28.6367,29.3119,28.9589,29.6564,29.4364,30.0567,29.7141,30.1886,30.2447,30.0486,30.0265,29.6629,30.0377,31.5058,31.5555,30.6317,31.3527,31.5250,32.0120,32.5889,34.4827,35.3903,36.4522,40.7622,40.5974,42.8786,42.8450,43.4043,44.3309,45.3683,43.9898,45.1268,45.7263,44.9776,45.5969,45.7690,45.9052,45.6393,46.3218,46.1508,45.8745,46.6349,47.2081,46.4900,46.8798,47.9092,47.6918,48.5015,48.5653,48.0101,48.3623,49.1246,48.7865,49.3677,49.2042,50.3537,50.1506,50.2662,50.2164,48.4353,49.6538,48.9080,49.4001],},
  {name: "Lower secondary", data: [45.2220,43.8926,41.0379,38.7003,40.6175,38.2885,39.7274,37.5103,39.7173,41.2176,39.8667,42.9796,41.3824,46.8266,47.7530,50.1691,46.3771,48.1293,46.0120,51.2243,46.2810,51.4942,46.8144,53.5342,45.0561,45.1466,42.8909,43.3763,43.2741,43.3696,41.4633,43.1371,41.6259,43.5469,41.4308,41.0584,40.7598,40.4353,40.8062,40.2939,40.5348,39.8552,39.7437,39.4045,39.5864,39.4995,38.5803,38.7173,37.9503,37.8975,37.6930,37.8310,37.7025,37.3153,37.4788,37.5802,36.9831,37.1406,36.6372,36.6503,37.1336,37.2827,37.9549,37.3888,37.8799,37.3493,37.5689,37.7883,37.5858,37.0568,36.5837,36.9176,38.5704,38.4990,37.6328,38.0517,38.0417,38.7289,39.3540,41.3712,42.3078,43.3845,48.1832,48.3048,50.5999,50.1153,50.5589,51.6234,52.8283,50.7652,52.8019,52.7656,52.2577,52.5126,53.0250,53.1785,52.6520,53.3054,53.1374,52.8880,53.6141,53.9317,53.4291,53.9511,54.8914,54.4393,55.8627,55.8699,55.2710,55.1316,55.9998,56.0016,56.5184,56.0884,57.5163,56.7622,57.0498,57.1276,55.8764,56.4072,55.6310,56.0162],},
  {name: "Upper secondary", data: [66.3538,64.5525,61.2009,56.3592,59.1182,54.8708,57.3780,53.7495,56.7541,59.0799,57.0371,61.7941,58.6731,68.2037,70.0052,73.4967,68.0669,69.1796,65.7850,73.3687,64.9070,72.4077,63.8469,73.3976,61.7553,62.7097,59.7789,59.0708,58.5200,57.8693,56.6359,58.8070,55.6268,58.5861,57.8722,57.1979,56.3821,55.9175,56.1011,55.3095,54.9008,53.7226,53.7806,53.1408,53.0897,52.8317,51.6881,50.6863,49.6291,49.5174,49.4355,49.6895,50.2420,48.3672,48.9306,48.5951,47.7872,47.4296,47.0063,46.9309,47.0597,47.8636,48.6805,47.6032,47.5409,46.9092,46.9541,47.3079,46.6371,45.8275,45.3774,45.3786,46.7818,47.1597,46.5752,46.0149,45.4832,47.1823,47.8784,49.3139,50.1150,52.1182,57.3590,57.0302,59.1621,59.2495,59.2221,59.6447,61.1204,59.0954,61.9819,60.5441,60.4955,60.8148,61.5325,61.3687,60.6924,61.4819,61.2257,60.8859,61.5654,61.7241,61.4470,61.9864,62.8135,62.3746,64.2233,64.2245,63.5379,63.1984,63.5772,64.2514,64.6890,64.3251,65.2583,64.3135,64.8057,65.3022,64.0220,64.1360,63.3870,63.8191],},
  {name: "Some college", data: [85.4466,86.7492,95.4033,73.1174,74.8189,82.0866,79.5764,84.3834,83.8186,83.8284,86.8146,87.0825,86.9858,101.7886,111.1297,107.4799,99.9634,101.3974,110.0922,105.6260,100.5422,107.1195,103.3354,116.8303,93.8300,89.0606,86.0916,90.9459,89.1796,89.4510,90.0385,90.0021,84.7180,90.1377,86.2800,81.9548,87.2227,80.7866,83.0172,82.0371,78.0792,79.6372,80.6367,78.8661,78.9471,75.5688,74.3067,81.0391,79.4305,79.3659,76.9799,77.9130,77.5992,75.2127,73.9178,71.9267,74.1453,74.5262,71.6918,71.8459,70.1188,70.1984,74.1567,69.4113,67.2289,67.2239,67.7751,66.7091,65.0930,65.3889,64.9958,65.2920,67.5322,65.6656,62.0949,63.5509,65.8743,67.8579,69.4794,70.1868,69.6574,72.2886,75.6987,73.5614,74.3798,75.3129,78.0062,78.5153,77.2552,76.0712,77.3179,78.0372,78.2456,78.0277,76.9763,78.7228,78.9550,78.7187,79.6387,77.6518,79.8468,79.4872,78.1965,77.2177,79.2008,79.9577,79.0570,79.9685,78.3847,78.6045,82.0058,83.1024,80.8837,80.0210,81.8056,78.2482,79.9005,80.6762,79.7568,79.2435,80.2287,80.0054],},
  {name: "College", data: [119.5976,113.9712,115.9539,114.8308,115.7770,119.9031,118.3249,114.6971,120.0997,119.6800,125.3244,123.8188,124.1470,145.0076,158.1525,153.7734,152.7360,147.7373,149.2401,155.1901,153.4696,150.8942,151.0125,180.2536,140.9554,139.3568,128.5475,135.7201,133.7039,135.6873,136.6157,134.6053,138.2477,144.5198,142.0914,142.9956,142.8005,140.0425,138.3571,135.7363,141.6023,135.2904,138.6989,138.1009,135.2346,135.6596,134.6005,136.2107,137.0764,137.9930,143.6352,139.9001,134.6269,131.6166,128.2419,127.6810,127.5866,127.3580,125.2157,125.0551,125.1628,123.9338,126.4629,126.2568,124.3868,123.3705,123.6327,120.7540,119.6851,117.6589,116.5123,115.7782,123.2229,118.5770,112.3166,114.0215,117.3776,119.1986,124.7146,123.6096,124.0539,126.9710,130.1335,132.2290,133.8872,136.5829,129.2358,134.2573,135.0714,128.2676,136.9763,138.2874,137.3171,137.8367,141.7099,138.6569,136.7953,137.2526,132.8721,132.2800,132.5973,137.3160,133.2473,133.0880,135.7907,134.9921,138.9910,137.9100,135.8202,134.1132,136.2991,141.6419,142.7986,142.1988,141.4093,137.4138,137.7515,135.9162,132.1840,131.5762,128.4375,128.8407],},
]

const rawData2 = [
  {name: "Non-STEM", data: [138.1540,135.4460,131.2354,127.7613,123.4680,123.9550,122.8365,123.2404,121.9297,121.2553,120.7159,120.0736,122.8938,121.7804,121.2031,120.6557,119.9698,117.6534,116.2327,114.5104,113.1102,113.1578,120.3362,114.2738,109.7245,111.5035,114.6408,117.5720,121.0331,119.2543,119.1991,121.8562,125.6233,127.6909,130.3465,132.0550,125.1547,130.7176,131.5690,124.7305,132.9368,133.6618,132.4454,134.1998,138.2401,135.8162,134.0748,133.2723,129.3027,128.3590,128.9527,134.3559,130.6841,130.8757,132.2892,131.9403,135.0530,134.0834,131.4449,130.4917,131.2211,137.2308,139.2782,137.5591,137.7702,134.1833,135.0829,134.3220,130.2894,129.4032,126.5273,126.8217]},
  {name: "STEM", data: [194.2357,176.7248,157.3987,164.9576,163.7269,161.2591,165.7209,163.4822,155.6186,158.8041,156.4333,157.1571,150.8394,159.8351,147.8384,146.0356,151.5227,148.0973,146.5456,139.5239,145.5943,136.0024,142.8562,150.0769,132.3344,132.5366,135.0184,131.5092,147.6129,150.8725,152.9395,156.9294,154.8325,157.7554,151.7466,161.5682,150.6075,151.2037,152.2930,148.6188,155.3588,159.4326,162.2579,155.6689,153.6533,151.0724,150.7770,153.9056,148.6491,150.1668,147.9111,149.1829,145.5046,142.0894,150.5592,150.1169,157.2059,154.7834,154.6289,152.2664,158.7063,159.9531,156.2258,161.9748,156.9500,152.4077,148.3075,141.5646,138.7608,139.8459,134.4995,136.6902]},
]

function quarterToDate(strQ) {
  const [year, quarter] = strQ.split("Q")
  return Date.UTC(year, quarter * 3 - 1)
}

const commonOptions = {
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "ค่าจ้างเฉลี่ย (บาท/ชม.)"
    }
  },
  tooltip: {
    xDateFormat: "%YQ%q",
  }
}

export default function Ab2323Labor2() {
  return(
    <LazyHighcharts
      options={{
        series: rawData1.map(series => ({
          name: series.name,
          data: series.data.map((value, i) => [quarterToDate(date1[i]), value]),
          lineWidth: 2,
        })),
        ...commonOptions,
      }}
    />
  )
}

