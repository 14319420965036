import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'

export default function Inner() {

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "300px"}}
      data={{
        labels: ["ความรัก/ผูกพันในครอบครัว", "ความเชื่อใจในครอบครัว", "ความเชื่อใจเพื่อนสนิท"],
        datasets: [
          {
            label: "ค่าเฉลี่ยของกลุ่มตัวอย่าง",
            data: [85.06737, 86.33416, 77.0625],
            backgroundColor: getAreaColor(defaultStyles.colors[0]),
            borderColor: `none`,
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            max: 100,
            title: {
              display: true,
              text: "ดัชนี"
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        }
      }}
    />
  )


}