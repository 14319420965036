import React from "react"
import { LazyHighcharts } from "../../../../src/components/Plots"
import { SimplePie } from "../../../../src/components/Plots"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const rawData = {
  'เพศ': {
    categories: ['หญิง', 'ชาย'],
    data: [55.3, 44.7],
  },
  'อายุ': {
    categories: ['วัยต้น', 'วัยกลาง', 'วัยปลาย' ],
    data: [57.2 , 29.5 , 13.3 ],
  },
  'สถานภาพสมรส': {
    categories: ['โสด', 'หม้าย หย่า แยก', 'สมรส' ],
    data: [32.9  , 5.0  , 62.1 ],
  },
  'การศึกษา': {
    categories: ['ไม่ได้รับการศึกษา', 'ต่ำกว่าประถมศึกษา', 'ประถมศึกษา','มัธยมศึกษาตอนต้น', 'มัธยมศึกษาตอนปลาย/ปวช.', ' ปวส./ปวท./อนุปริญญา', 'ปริญญาตรี', 'สูงกว่าปริญญาตรี', 'อื่น/ไม่ทราบ'],
    data: [7.3  , 64.5  , 9.9, 4.1, 4.9 ,1.6, 6.4, 1.1, 0.1 ],
  },
  'การอ่านออกเขียนได้': {
    categories: ['อ่านออกเขียนได้', 'อ่านออกเขียนไม่ได้' ],
    data: [88.3  , 11.7 ],
  },
  'การอยู่อาศัย': {
    categories: ['อยู่ลำพังคนเดียว ', 'อยู่ลำพังกับคู่สมรส', 'อยู่กับบุคคลอื่น' ],
    data: [12.0  , 21.1  , 66.9 ],
  },
  'สภาพที่อยู่อาศัย': {
    categories: ['อยู่ในบ้านที่มีสภาพแวดล้อมเหมาะสม', 'อยู่ในบ้านที่มีสภาพแวดล้อมไม่เหมาะสม' ],
    data: [6.1 , 93.9],
  },
  'ระดับรายได้ (รวมจากทุกแหล่งที่ได้รับ)': {
    categories: ['ต่ำกว่า 10,000 บาท', '10,000–29,999 บาท','30,000–49,999', '50,000–69,999','70,000–99,999', '100,000–299,999', '300,000 บาทขึ้นไป ' ],
    data: [6.0 , 15.7, 8.8, 15.2, 20.3 , 25.0, 9.0],
  },
  'ประเมินสุขภาพตนเอง': {
    categories: ['สุขภาพดีมาก', 'สุขภาพดี','สุขภาพปานกลาง', 'สุขภาพไม่ดี','สุขภาพไม่ดีมาก ๆ'],
    data: [3.3 , 42.7, 41.6, 10.7, 1.7],
  },
  'ภาวะพึ่งพิง': {
    categories: ['ติดเตียง', 'ติดบ้าน','ติดสังคม'],
    data: [1.3 , 1.8, 96.9],
  },
  'แหล่งรายได้หลัก': {
    categories: ['การทำงาน', 'บุตร','เบี้ยยังชีพ', 'บำเหน็จ/บำนาญ','คู่สมรส', 'ดอกเบี้ย/เงินออม', 'อื่นๆ' ],
    data: [32.4 , 32.2, 19.2, 7.5, 4.5, 1.5, 2.7],
  },
  'การมีส่วนร่วมทางสังคม': {
    categories: ['เป็นสมาชิกและร่วมกิจกรรมชมรมผู้สูงอายุ','ไม่เป็นสมาชิกแต่เข้าร่วมกิจกรรมชมรมผู้สูงอายุ','เป็นสมาชิกแต่ไม่ได้ร่วมกิจกรรมชมรมผู้สูงอายุ','ไม่เป็นสมาชิกและไม่เข้าร่วมกิจกรรมชมรมผู้สูงอายุ','อื่นๆ'],
    data: [17.4, 7.0, 7.8, 56.9, 10.9],
  },
  'เงินออม': {
    bigCat: ['ไม่มีเงินออม', 'มีเงินออม'],
    categories: ['ไม่มีเงินออม', 'ต่ำกว่า 50,000 บาท', '50,000–99,999 บาท', '100,000–399,999 บาท', '400,000 บาทขึ้นไป'],
    data: [45.7, 41.4*0.543, 21.7*0.543, 25.0*0.543, 11.9*0.543].map(x => Math.round(x * 10) / 10),
  },
  'สถานภาพทางเศรษฐกิจ': {
    bigCat: ['ไม่ได้ทำงาน', 'ทำงาน'],
    categories: ["ไม่ได้ทำงาน", "ทำงานส่วนตัวไม่มีลูกจ้าง", "ช่วยธุรกิจครัวเรือน (ไม่รับค่าจ้าง)", "ทำงานเป็นลูกจ้าง", "ทำงานเป็นนายจ้าง"],
    data: [65.3, 64.8*0.347, 18.8*0.347, 13.0*0.347, 3.4*0.347].map(x => Math.round(x * 10) / 10),
  }
}

export default function Ab2123Demo() {
  
  const ref = React.useRef(null)
  const [facet, setFacet] = React.useState('อายุ')

  return(
    <div style={{marginTop: '2rem'}}>
      <div style={{textAlign: "center"}}>
        <FormControl sx={{ m: 1, minWidth: 300, textAlign: "left" }} size="small" style={{margin: '0 auto'}}>
          <InputLabel id="demo-simple-select-label">มิิติ</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={facet}
            label="มิติ"
            onChange={e => setFacet(e.target.value)}
          >
            {Object.keys(rawData).map(facet => <MenuItem key={facet} value={facet}>{facet}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      <div className="table-wrapper">
        <div style={{minWidth: '500px'}} ref={ref}>
          <LazyHighcharts
            options={{
              chart: {
                type: 'pie',
              },
              series: [{
                name: 'Value',
                data: rawData[facet].data.map((v, i) => ({ name: rawData[facet].categories[i], y: v })),
                size: '80%',
                innerSize: '60%',
                dataLabels: {
                  formatter: function() {
                    return this.point.name + '<br />' + this.y.toFixed(1) + '%';
                  },
                  distance: 20,
                  style: {
                    fontSize: '14px',
                  },
                }
              }].concat(rawData[facet].bigCat ? [{
                name: 'ภาพรวม',
                data: [
                  { name: rawData[facet].bigCat[0], y: rawData[facet].data[0] },
                  { name: rawData[facet].bigCat[1], y: rawData[facet].data.slice(1).reduce((a, b) => a + b, 0) }
                ],
                size: '40%',
                innerSize: '20%',
                dataLabels: {
                  formatter: function() {
                    return this.point.name + '<br />' + this.y.toFixed(1) + '%';
                  },
                  distance: -30,
                  style: {
                    fontSize: '14px',
                  },
                }
              }] : []),
            }}
          />
        </div>
      </div>
    </div>
  )

}