import FirmsProductivity from "../../../../content/abridged/2023/09/graphs/FirmsProductivity";
import Regulations from "../../../../content/abridged/2023/09/graphs/Regulations";
import Health from "../../../../content/abridged/2023/09/graphs/Health";
import GovernmentQuality from "../../../../content/abridged/2023/09/graphs/GovernmentQuality";
import Corruption from "../../../../content/abridged/2023/09/graphs/Corruption";
import Pisa from "../../../../content/abridged/2023/09/graphs/Pisa";
import Tax from "../../../../content/abridged/2023/09/graphs/Tax";
import Finance from "../../../../content/abridged/2023/09/graphs/Finance";
import Inequality from "../../../../content/abridged/2023/09/graphs/Inequality";
import Pollution from "../../../../content/abridged/2023/09/graphs/Pollution";
import Labor from "../../../../content/abridged/2023/09/graphs/Labor";
import Agriculture from "../../../../content/abridged/2023/09/graphs/Agriculture";
import Overview from "../../../../content/abridged/2023/09/Overview";
import * as React from 'react';
export default {
  FirmsProductivity,
  Regulations,
  Health,
  GovernmentQuality,
  Corruption,
  Pisa,
  Tax,
  Finance,
  Inequality,
  Pollution,
  Labor,
  Agriculture,
  Overview,
  React
};