import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"
import chroma from "chroma-js"

import Alt from "./alt/regulations.png"

const rawData = [
  {name: "ธุรกิจขนาดเล็ก", count: 230},
  {name: "ธุรกิจบริการและนวัตกรรม", count: 128},
  {name: "ธุรกิจอสังหาริมทรัพย์", count: 127},
  {name: "การท่องเที่ยว", count: 123},
  {name: "การส่งออกและการนำเข้าสินค้า", count: 97},
  {name: "การจ้างแรงงานต่างด้าว", count: 86},
  {name: "การอนุญาตที่ล้าสมัย", count: 84},
  {name: "ธุรกิจค้าปลีก", count: 59},
  {name: "สวัสดิการและส่งเสริมอาชีพผู้ด้อยโอกาส", count: 56},
  {name: "ธุรกิจการศึกษา", count: 32},
  {name: "การอนุญาตอื่น ๆ", count: 72},
]

const colors = chroma.scale([defaultStyles.colors[0], defaultStyles.colors[1]]).colors(rawData.length)

export default function Regulations() {

  const ref = React.useRef(null)

  React.useEffect(() => {
    let scrollElement = ref.current
    if (scrollElement)
      scrollElement.scrollLeft = (scrollElement.scrollWidth - scrollElement.clientWidth ) / 2;
  }, [])

  return(
    <>
      <img src={Alt} alt="Regulations" className="printonly" />    
      <div className="table-wrapper noprint" ref={ref}>
        <div style={{minWidth: '650px'}}>
          <LazyHighcharts
            options={{
              series: [
                // {
                //   name: "รวม",
                //   data: [{name: "รวม", y: total, color: 'black'}],
                //   size: '60%',
                //   innerSize: '55%',
                //   // dataLabels: {
                //   //   enabled: true,
                //   // },
                //   tooltip: {
                //     pointFormatter: function() {
                //       return `${this.name}: <b>${this.y.toLocaleString()}</b>`
                //     }
                //   }
                // },
                {
                  name: "แยก",
                  data: rawData.map((d, i) => ({
                    name: d.name,
                    y: d.count,
                    color: colors[i],
                  })),
                  size: '80%',
                  innerSize: '60%',
                  dataLabels: {
                    formatter: function() {
                      return `${this.key}: <b>${this.y.toLocaleString()}</b>`
                    },
                    style: {
                      fontFamily: "Dindan",
                      fontWeight: "normal",
                      fontSize: '14px',
                    },
                  },
                  tooltip: {
                    headerFormat: "",
                    pointFormatter: function() {
                      return `${this.name}: <b>${this.y.toLocaleString()}</b>`
                    }
                  }
                },
              ],
              plotOptions: {
                column: {
                  colorByPoint: true,
                },
              },
              // legend: {
              //   enabled: false,
              // },
              title: {
                text: "รวม<br />1,094<br />กระบวนงาน",
                align: 'center',
                verticalAlign: 'middle',
                // style: {
                //   fontSize: '1rem',
                // },
              },
              chart: {
                type: "pie",
                height: "300px",
              },
              // tooltip: {
              //   valueSuffix: "%",
              //   // formatter: function(tooltip) {
              //   //   let tmp = tooltip.defaultFormatter.call(this, tooltip);
              //   //   if (showPercent)
              //   //     tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.percentage.toFixed(2)}%</b>`)
              //   //   return tmp
              //   // }
              // },
              // yAxis: {
              //   title: {
              //     text: "",
              //   },
              //   labels: {
              //     format: "{value}%",
              //   },
              //   // reversedStacks: false,
              // },
              responsive: {
                rules: [{
                  condition: {
                    minWidth: 700,
                  },
                  chartOptions: {
                    legend: {
                      maxHeight: undefined,
                    },
                    chart: {
                      height: "50%",
                    },
                  },
                }]
              }
            }}
          />
        </div>
      </div>
    </>
  )
}
