import React from "react"

export default function Sankey() {

  return(
    <>
    <iframe
      src='https://flo.uri.sh/visualisation/10803835/embed'
      title='Interactive or visual content'
      className='flourish-embed-iframe'
      frameborder='0'
      scrolling='no'
      style={{
        width: '100%',
        height: 'auto',
        aspectRatio: '1',
        sandbox: 'allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'
      }}
    />
    <div
      style={{
        width: '100%!important',
        marginTop: '4px!important',
        textAlign: 'right!important'
      }}
    >
      <a
        className='flourish-credit'
        href='https://public.flourish.studio/visualisation/10803835/?utm_source=embed&utm_campaign=visualisation/10803835'
        target='_top'
        style={{textDecoration: 'none!important'}}
      >
        <img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style={{width: '105px!important', height: '16px!important', border: 'none!important', margin: '0!important'}} />
      </a>
    </div>
    </>
  )

}