import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"
import { cloneDeep, merge } from 'lodash'

let rawData = [
  {
    name: "บุคคลธรรมดา",
    tx: [509819548,527325760,515011245,545111451,546963222,590925016,582476201,589197848,672591741,637243261,717810782,732744371,793749805,827361591,844047637,880207551,867034153,931042909],
    avgAmount: [3978.03,3878.47,3897.72,3845.24,3940.70,4090.54,3924.02,3919.10,3864.70,3794.91,3691.32,3598.00,3369.82,3280.38,3285.28,3299.44,3470.55,3536.65]
  },
  {
    name: "e-wallet",
    tx: [2830716,3393892,3857090,15878583,80574013,49750823,73382143,43863304,30194800,6020855,6362916,8164219,146311458,49360853,25768580,161049600,189550639,117937513],
    avgAmount: [791.35,1481.84,1031.68,359.86,221.58,241.58,223.40,248.57,287.98,727.25,697.34,660.11,268.73,259.87,377.14,233.72,311.24,258.00]
  },
  {
    name: "อื่น ๆ",
    tx: [185978786,192177730,192418786,194316038,192584920,209170682,205428220,205187133,237990940,228842560,243392907,245941430,265793909,283930440,293431129,306360529,308994366,344230639],
    avgAmount: [5108.87,5210.26,5092.68,5481.14,5882.24,5917.62,6044.50,5859.43,5924.15,5635.80,5529.61,5445.70,4982.81,4930.83,4872.95,4769.97,5195.35,5086.24],
  },
]


export default function Destinations({ dataSeries }) {

  const defaultPlotBand = {
    color: '#99999933',
    label: {
      style: {
        fontFamily: defaultStyles.font.family,
      },
    },
  }

  return(
    <LazyHighcharts
      options={{
        series: rawData.map(series => ({
          name: series.name,
          data: series[dataSeries],
        })),
        plotOptions: {
          series: {
            pointStart: Date.UTC(2020, 6, 1),
            pointIntervalUnit: 'month',
            marker: {
              enabled: false,
            },
          },
        },
        legend: {
          // enabled: false,
        },
        chart: {
          height: "300px",
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          plotBands: [
            cloneDeep(merge(defaultPlotBand, {
              from: Date.UTC(2020, 9, 23),
              to: Date.UTC(2020, 11, 31),
              label: {
                text: "คนละครึ่ง ระยะที่ 1",
              },
            })),
            cloneDeep(merge(defaultPlotBand, {
              from: Date.UTC(2021, 0, 1),
              to: Date.UTC(2021, 2, 31),
              label: {
                text: "คนละครึ่ง ระยะที่ 2",
              },
            })),
            cloneDeep(merge(defaultPlotBand, {
              from: Date.UTC(2021, 6, 1),
              to: Date.UTC(2021, 11, 31),
              label: {
                text: "คนละครึ่ง ระยะที่ 3",
              },
            })),
          ],
        },
        tooltip: {
          xDateFormat: '%Y-%m',
          shared: true,
          valueDecimals: dataSeries === "tx" ? 0 : 2,
        },
        yAxis: {
          title: {
            text: dataSeries === "tx" ? "ปริมาณธุรกรรม" : "มูลค่าเฉลี่ยต่อธุรกรรม",
          },
          margin: 50,
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}