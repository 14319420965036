import React from "react"
import worldMap from "@highcharts/map-collection/custom/world.topo.json";
import { LazyHighcharts } from "../../../../src/components/Plots"
import chroma from "chroma-js"

const colors = chroma.scale('Spectral').colors(6).reverse()

const options = {
  chart: {
    map: worldMap,
    spacingBottom: 20,
    type: "map",
  },

  dataLabels: {
    enabled: false,
  },

  title: {
    text: "",
  },

  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },

  mapView: {
    projection: {
      name: "Miller",
    },
  },

  legend: {
    enabled: true,
  },

  plotOptions: {
    map: {
      allAreas: false,
      joinBy: ["iso-a3", "code"],
      tooltip: {
        headerFormat: "",
        pointFormat: "{point.name}: <b>{series.name}</b>",
      },
    },
  },

  series: [
    {
      name: "No subsidy",
      color: colors[0],
      data: [
        "MUS",
        "DEU",
        "HUN",
        "IRL",
        "JAM",
        "MWI",
        "NZL",
        "ECU",
        "DNK",
        "NIC",
        "PAN",
        "PRY",
        "GHA",
        "ROU",
        "KHM",
        "BGR",
        "LKA",
        "SWE",
        "BOL",
        "BEL",
        "TZA",
        "AUS",
        "VEN",
        "ARG",
        "ZAF",
        "HND",
      ].map(code => ({ code })),
      allAreas: true,
    },
    {
      name: "0–20%",
      color: colors[1],
      data: ["CHE", "ZMB"].map(code => ({ code })),
    },
    {
      name: "20–40%",
      color: colors[2],
      data: ["MAR", "MNG", "ISR", "HRV"].map(code => ({ code })),
    },
    {
      name: "40–60%",
      color: colors[3],
      data: [
        "KOR",
        "CZE",
        "NGA",
        "CYP",
        "HTI",
        "CAN",
        "PHL",
        "POL",
        "PRT",
        "MKD",
        "CHL",
        "RUS",
        "RWA",
        "SEN",
        "SLV",
        "NLD",
        "SVN",
        "SRB",
        "IND",
        "ARM",
        "IRN",
        "URY",
        "UKR",
        "NPL",
        "JPN",
        "AUT",
        "BIH",
        "SDN",
        "BRA",
        "ESP",
        "MNE",
        "TUR",
      ].map(code => ({ code })),
    },
    {
      name: "60–80%",
      color: colors[4],
      data: ["USA", "FRA", "CHN", "MDA", "ETH", "ITA", "IDN"].map(code => ({
        code,
      })),
    },
    {
      name: "80–100%",
      color: colors[5],
      data: ["MOZ", "MEX", "BGD", "KEN", "THA", "PER"].map(code => ({ code })),
    },
  ],
}

const HighchartsMap = () => {
  return <LazyHighcharts constructorType={"mapChart"} options={options} />
}

export default HighchartsMap