import OddRatios from "../../../../content/abridged/2023/17/OddRatios";
import Table from "../../../../content/abridged/2023/17/Table";
import Alt1 from "../../../../content/abridged/2023/17/alt/01.png";
import Alt2a from "../../../../content/abridged/2023/17/alt/02a.png";
import Alt2b from "../../../../content/abridged/2023/17/alt/02b.png";
import Alt3a from "../../../../content/abridged/2023/17/alt/03a.png";
import Alt3b from "../../../../content/abridged/2023/17/alt/03b.png";
import Alt4 from "../../../../content/abridged/2023/17/alt/04.png";
import Alt5a from "../../../../content/abridged/2023/17/alt/05a.png";
import Alt5b from "../../../../content/abridged/2023/17/alt/05b.png";
import Alt5c from "../../../../content/abridged/2023/17/alt/05c.png";
import Alt5d from "../../../../content/abridged/2023/17/alt/05d.png";
import * as React from 'react';
export default {
  OddRatios,
  Table,
  Alt1,
  Alt2a,
  Alt2b,
  Alt3a,
  Alt3b,
  Alt4,
  Alt5a,
  Alt5b,
  Alt5c,
  Alt5d,
  React
};