import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = [
  {name: "> 20 ปี", data: [193913,342974,409247]},
  {name: "16–20 ปี", data: [168264,109879,187608]},
  {name: "11–15 ปี", data: [115412,202030,147319]},
  {name: "6–10 ปี", data: [212960,153510,235301]},
  {name: "≤ 5 ปี", data: [207665,280704,245679]},
]

const years = [2555, 2560, 2565]

export default function Trucks() {

  const [showPercent, setShowPercent] = React.useState(true)
  const colorDefs = [
    "#43a047",
    "#EBDF15",
    "#FFB801",
    "#9F3C49",
    "#882D85",
  ].reverse()

  return(
    <div>
      <svg style={{height: 0, width: 0, display: 'block'}}>
        <defs>
          {[...Array(4)].map((_, i) => (
            <linearGradient key={i} id={`grad${i}`} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{stopColor: colorDefs[i + 1], stopOpacity: 0.3}} />
              <stop offset="100%" style={{stopColor: colorDefs[i], stopOpacity: 0.3}} />
            </linearGradient>
          ))}
        </defs>
      </svg>
			<div>
        <LazyHighcharts
          options={{
            series: rawData,
            colors: colorDefs,
            plotOptions: {
              column: {
                stacking: showPercent ? "percent" : "normal",
                // dataLabels: {
                //   enabled: showPercent,
                //   formatter: function() {
                //     return `${this.percentage.toFixed(0)}%`
                //   },
                // },
              },
            },
            legend: {
              // enabled: false,
            },
            chart: {
              type: "column",
              height: "400px",
              // defs: {
              //   gradient: {
              //     id: 'gradx',
              //     tagName: 'linearGradient',
              //     x1: 0,
              //     y1: 0,
              //     x2: "100%",
              //     y2: "100%",
              //     children: [
              //       {
              //         tagName: 'stop',
              //         offset: 0,
              //         style: 'stop-color: rgb(255, 255, 0); stop-opacity: 1',
              //       },
              //       {
              //         tagName: 'stop',
              //         offset: "100%",
              //         style: 'stop-color: rgb(255, 0, 0); stop-opacity: 1',
              //       },
              //     ],
              //   }
              // },
              events: {
                render: function() {
                  
                  const width = 0.24
                  function getPath(obj, x, stackLeft, stackRight=stackLeft) {
                    const xAxis = obj.xAxis[0]
                    const yAxis = obj.yAxis[0]
                    const left = xAxis.toPixels(x + width)
                    const right = xAxis.toPixels(x + 1 - width)
                    const topLeft = yAxis.toPixels(obj.series[stackLeft].data[x].stackY)
                    const topRight = yAxis.toPixels(obj.series[stackRight].data[x + 1].stackY)
                    let bottomLeft = yAxis.toPixels(stackLeft + 1 < obj.series.length ? (obj.series[stackLeft + 1].data[x].stackY) : 0)
                    let bottomRight = yAxis.toPixels(stackRight + 1 < obj.series.length ? (obj.series[stackRight + 1].data[x + 1].stackY) : 0)
                    if (stackRight === stackLeft) {
                      bottomLeft = yAxis.toPixels(obj.series[stackLeft + 2].data[x].stackY)
                    }
                    return [
                      'M', left, topLeft,
                      'C', xAxis.toPixels(x + 0.5), topLeft, xAxis.toPixels(x + 1 - 0.5), topRight, right, topRight,
                      'V', bottomRight,
                      'C', xAxis.toPixels(x + 1 - 0.5), bottomRight, xAxis.toPixels(x + 0.5), bottomLeft, left, bottomLeft,
                      'Z',
                    ]
                  }

                  // const styles = {
                  //   stroke: 'black',
                  //   "stroke-width": 2,
                  //   "stroke-dasharray": "2",
                  //   fill: 'url(#grad3)',
                  //   opacity: 0.3,
                  // }
                  if (this.lines) {
                    this.lines.map(x => x.element.remove())
                  }
                  this.lines = []
                  // mid line
                  this.lines.push(this.renderer.path(getPath(this, 0, 0)).attr({fill: colorDefs[0], opacity: 0.3}).add())
                  this.lines.push(this.renderer.path(getPath(this, 1, 0)).attr({fill: colorDefs[0], opacity: 0.3}).add())
                  for (let x = 0; x < 2; x ++) {
                    for (let i = 2; i <= 4; i ++) {
                      this.lines.push(this.renderer.path(getPath(this, x, i, i - 1)).attr({fill: `url(#grad${i - 1})`}).add())
                    }
                  }
                },
              },
            },
            title: {
              text: "ปริมาณรถบรรทุกเก่าในระบบเพิ่มขึ้น",
              style: {
                fontSize: '14px',
              },
            },
            xAxis: {
              categories: years
            },
            tooltip: {
              formatter: function(tooltip) {
                let tmp = tooltip.defaultFormatter.call(this, tooltip);
                if (showPercent)
                  tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.percentage.toFixed(2)}%</b>`)
                else
                  tmp[1] = tmp[1].replace("</b>", ` คัน</b>`)
                return tmp
              }
            },
            yAxis: {
              title: {
                text: showPercent ? "สัดส่วนของรถบรรทุก (%)" : "ปริมาณรถบรรทุก"
              },
              reversedStacks: true,
              labels: {
                formatter: !showPercent && function() {
                  return `${(this.value / 1e6).toFixed(1)}M`
                },
              }
            },
            responsive: {
              rules: [{
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    maxHeight: undefined,
                  },
                  chart: {
                    height: "50%",
                  },
                },
              }]
            }
          }}
        />
			</div>
			<div style={{textAlign: `center`, fontSize: `0.8rem`}}>
				<label>
					<input type="checkbox" onChange={() => setShowPercent(!showPercent)} defaultChecked={showPercent} /> แสดงสัดส่วนของกลุ่ม
				</label>
			</div>
		</div>
  )

}