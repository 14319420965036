import Geopolitics from "../../../../content/abridged/2021/16/Geopolitics";
import TrustGini from "../../../../content/abridged/2021/16/TrustGini";
import Trust from "../../../../content/abridged/2021/16/Trust";
import PoliticalShift from "../../../../content/abridged/2021/16/PoliticalShift";
import ValueAdded from "../../../../content/abridged/2021/16/ValueAdded";
import Platforms from "../../../../content/abridged/2021/16/Platforms";
import Aging from "../../../../content/abridged/2021/16/Aging";
import Debt from "../../../../content/abridged/2021/16/Debt";
import ComparePop from "../../../../content/abridged/2021/16/ComparePop";
import Extremity from "../../../../content/abridged/2021/16/Extremity";
import AffectiveScores from "../../../../content/abridged/2021/16/AffectiveScores";
import Cohesion from "../../../../content/abridged/2021/16/Cohesion";
import Coefficients from "../../../../content/abridged/2021/16/Coefficients";
import Inner from "../../../../content/abridged/2021/16/Inner";
import Perception from "../../../../content/abridged/2021/16/Perception";
import ExtremityBreakdown from "../../../../content/abridged/2021/16/ExtremityBreakdown";
import MediaScale from "../../../../content/abridged/2021/16/MediaScale";
import * as React from 'react';
export default {
  Geopolitics,
  TrustGini,
  Trust,
  PoliticalShift,
  ValueAdded,
  Platforms,
  Aging,
  Debt,
  ComparePop,
  Extremity,
  AffectiveScores,
  Cohesion,
  Coefficients,
  Inner,
  Perception,
  ExtremityBreakdown,
  MediaScale,
  React
};