import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"
import Alt from "./alt/corruption.png"

const rawData = [
  {name: "Corruption Index", data: [32,32,33,36,38,36,33,35,34,35,34,37,35,38,38,35,37,36,36,36,35,36]},
  {name: "Corruption Rank", data: [61,64,70,64,59,63,84,80,84,78,80,88,102,85,76,101,96,99,101,104,110,101]},
]

export default function Corruption() {

  return(
    <LazyHighcharts
      altImage={Alt}
      options={{
        series: [
          {
            name: rawData[0].name,
            data: rawData[0].data,
            type: "column",
          },
          {
            name: rawData[1].name + " (แกนขวา)",
            data: rawData[1].data,
            type: "spline",
            yAxis: 1,
          }
        ],
        plotOptions: {
          series: {
            pointStart: 2544,
          },
        },
        chart: {
          type: "column",
          height: "300px",
        },
        tooltip: {
          // valueSuffix: " ",
          // formatter: function(tooltip) {
          //   let tmp = tooltip.defaultFormatter.call(this, tooltip);
          //   if (showPercent)
          //     tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.percentage.toFixed(2)}%</b>`)
          //   return tmp
          // }
        },
        yAxis: [
          {
            title: {
              text: ""
            },
            max: 100,
          },
          {
            title: {
              text: ""
            },
            opposite: true,
            reversed: true,
            min: 0,
            max: 180,
          },
        ],
        legend: {
          verticalAlign: 'top',
          floating: true,
          align: 'right',
          x: -40,
          y: 10,
          layout: 'vertical',
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "60%",
              },
            },
          }]
        }
      }}
    />
  )

}