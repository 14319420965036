// https://janosh.dev/blog/gatsby-interactive-plots

import React from 'react'
import Loadable from 'react-loadable'
import { cloneDeep, merge } from 'lodash'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from "classnames/bind"
import styles from "./styles.module.scss"

const cx = classNames.bind(styles);

const LoadPlotly = Loadable.Map({
	loader: {
    Plot: () => import(`react-plotly.js`)
  },
  render(loaded, props) {
    let Plot = loaded.Plot.default
    return <Plot {...props} />
  },
	loading: ({ timedOut }) =>
		<div className={cx("loading")}>
		{timedOut
		? <blockquote>Error: Timed out.</blockquote>
		: <CircularProgress />
		}
		</div>,
	timeout: 30000,
})

export const LazyPlotly = ( {defaultStyles, layout, style, config, ...rest} ) => {

  const axisDefaults = {
		gridcolor: defaultStyles.gridlineColor,
		zerolinecolor: defaultStyles.zerolineColor,
		title: {
			font: {
				family: defaultStyles.axisTitle?.font?.family || defaultStyles.font.family,
				size: defaultStyles.axisTitle?.font?.size || defaultStyles.font.size,
				color: defaultStyles.axisTitle?.font?.color || defaultStyles.font.color,
			},
		},
		tickfont: {
			family: defaultStyles.tick?.font?.family || defaultStyles.font.family,
			size: defaultStyles.tick?.font?.size || defaultStyles.font.size,
			color: defaultStyles.tick?.font?.color || defaultStyles.font.color,
		},
    automargin: true,
	}

	const defLayout = {
		colorway: defaultStyles.colors,
    margin: {
      l: 50,
      r: 50,
      t: 20,
      b: 50,
    },
		paper_bgcolor: defaultStyles.paperColor,
		plot_bgcolor: defaultStyles.plotColor,
		autosize: true,
		title: {
			font: {
				family: defaultStyles.title?.font?.family || defaultStyles.font.family,
				size: defaultStyles.title?.font?.size || defaultStyles.font.size,
				color: defaultStyles.title?.font?.color || defaultStyles.font.color,
			},
			xref: 'paper',
			x: 0.5,
			xanchor: 'center',
		},
		font: {
			family: defaultStyles.font?.family,
			color: defaultStyles.font?.color,
		},
		xaxis: cloneDeep(axisDefaults),
		yaxis: cloneDeep(axisDefaults),
		xaxis2: cloneDeep(axisDefaults),
		xaxis3: cloneDeep(axisDefaults),
		xaxis4: cloneDeep(axisDefaults),
		yaxis2: cloneDeep(axisDefaults),
		yaxis3: cloneDeep(axisDefaults),
		yaxis4: cloneDeep(axisDefaults),
		hoverlabel: {
			font: {
				family: defaultStyles.tooltip?.font?.family || defaultStyles.font.family,
				size: defaultStyles.tooltip?.font?.size || defaultStyles.font.size,
				color: defaultStyles.tooltip?.font?.color || defaultStyles.font.color,
			},
			bordercolor: 'transparent',
		},
	}

	const defStyle = {
		width: `100%`,
    height: '500px',
	}
	const defConfig =  {
		displayModeBar: false,
		showTips: false,
		responsive: true,
	}

  // const isPrinting = React.useRef(0)
  const ref = React.useRef(null)

  const [finalLayout, setFinalLayout] = React.useState(merge(defLayout, layout))
  const [x, setX] = React.useState('100%')

	// let finalLayout = merge(layout, defLayout)

  async function handlePrint() {
    // isPrinting.current = isPrinting.current ++
    setX('1px')
	}

	function handlePrintDone() {
    // isPrinting.current = isPrinting.current ++
    setX('100%')
    window.dispatchEvent(new Event('resize'))
	}

  React.useEffect(() => {
		window.addEventListener("beforeprint", handlePrint);
		window.addEventListener("afterprint", handlePrintDone);
    return () => {
      window.removeEventListener("beforeprint", handlePrint);
			window.removeEventListener("afterprint", handlePrintDone);
		}
  }, [])


	return (
		<LoadPlotly
			layout={{
        ...finalLayout,
        width: x,
      }} ///processColors(cloneDeep(layout), colors))}
			style={{...merge(defStyle, style)}}
			config={{...merge(defConfig, config)}}
      // revision={isPrinting.current}
      // // onAutoSize={function () {
      // //   this.chartRelayout()
      // // }}
      // onAutoSize={function () {
      //   console.log("xxx")
      //   // ref.current.props.layout.width = isPrinting.current % 2 === 0 ? '100%' : '30px'
      //   // console.log(ref.current.props.layout.width)
      // }}
      ref={ref}
			{...rest}
			useResizeHandler={true}
		/>
	)

}