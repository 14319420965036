import React from "react"
import clsx from "clsx"

import styles from "./styles.module.scss"

const r = 200
const rGroup = 120
const rIcon = 160
const iconSize = 40
const rText = 100

function TextBox({ textArray, x, y, fontSize=1, textAnchor="middle" }) {
	const lineHeight = 1.1
	
	return(
		<g>
			{textArray.map((text, i) =>
				<text
					key={i}
					className={clsx(styles.fillText)}
					style={{fontSize: `${fontSize*16}px`}}
					x={x}
					y={`${y - lineHeight*fontSize*16*(textArray.length-1)/2 + i*lineHeight*fontSize*16}`}
					dominantBaseline="central"
					textAnchor={textAnchor}
				>
					{text}
				</text>
			)}
		</g>
	)
}

function Label({ textArray, rotate }) {
	const rotateRad = rotate * Math.PI / 180
	const length = 1.1*r
	return(
		<TextBox
			textArray={textArray}
			x={length*Math.sin(rotateRad)}
			y={-length*Math.cos(rotateRad)}
			textAnchor={Math.sin(rotateRad) > 0 ? "start" : "end"}
		/>
	)
}

function Piece({ span, rotate, node, group, labelRotate, ...rest }) {
	// span from degree to radian
	const spanRad = span * Math.PI / 180
	const rotateRad = rotate * Math.PI / 180
	return(
		<a href={`#${node.id}`}>
			<g className={clsx(styles.piece, styles.zoom)}>
				<g transform={`rotate(${rotate})`}>
					<path
						className={styles[group || node?.group]}
						d={`M 0 0 l ${r * Math.sin(spanRad/2)} ${-r * Math.cos(spanRad/2)} a ${r} ${r} 0 0 0 ${-2 * r * Math.sin(spanRad/2)} 0 z`}
						{...rest}
					/>
				</g>
				{node &&
					<>
						<g className={styles.fillBkg} transform={`translate(${-iconSize/2 + rIcon*Math.sin(rotateRad)} ${-iconSize/2 - rIcon*Math.cos(rotateRad)})`}>
							<svg viewBox={`0 0 ${node.width} 512`} width={iconSize} height={iconSize}>
								<path d={node.path}/>
							</svg>
						</g>
						<Label textArray={node.name.split("|")} rotate={labelRotate} />
					</>
				}
			</g>
		</a>
	)
}

function Group({ group, outer, rotate=0, span, label, width }) {
	const factor = 0.7
	const dy = outer ? -4 : 6
	const spanRad = span * Math.PI / 180
	return(
		<g transform={`rotate(${rotate})`}>
			<path
				className={clsx(styles[group], styles.group)}
				d={`M 0 0 l ${rGroup * Math.sin(spanRad/2)} ${-rGroup * Math.cos(spanRad/2)} a ${rGroup} ${rGroup} 0 0 0 ${-2 * rGroup * Math.sin(spanRad/2)} 0 z`}
			/>
			<text dy={dy} className={styles.textGroup} textLength={width * factor} transform={`rotate(${90 + (outer ? 1 : -1) * width/2 * factor / (2*Math.PI*(rGroup-dy)) * 360})`}>
				<textPath href={"#textcircle" + (outer ? "outer" : "")}>
					{label}
				</textPath>
			</text>
		</g>
	)
}

export default function Overview() {
	
	const nodes = [
		{ group: 'production', id: 'business', name: 'ธุรกิจ', width: 532, path: "M256 0q-27 1 -45 19v0q-18 18 -19 45v64h-128q-27 1 -45 19t-19 45v256q1 27 19 45t45 19h192h192q27 -1 45 -19t19 -45v-256v-128q-1 -27 -19 -45t-45 -19h-192v0zM64 304q1 -15 16 -16h32q15 1 16 16v32q-1 15 -16 16h-32q-15 -1 -16 -16v-32v0zM272 320 q-15 -1 -16 -16v-32q1 -15 16 -16h32q15 1 16 16v32q-1 15 -16 16h-32v0zM384 304v-32q1 -15 16 -16h32q15 1 16 16v32q-1 15 -16 16h-32q-15 -1 -16 -16v0zM80 192h32q15 1 16 16v32q-1 15 -16 16h-32q-15 -1 -16 -16v-32q1 -15 16 -16v0zM256 80q1 -15 16 -16h32 q15 1 16 16v32q-1 15 -16 16h-32q-15 -1 -16 -16v-32v0zM400 64h32q15 1 16 16v32q-1 15 -16 16h-32q-15 -1 -16 -16v-32q1 -15 16 -16v0zM256 208v-32q1 -15 16 -16h32q15 1 16 16v32q-1 15 -16 16h-32q-15 -1 -16 -16v0zM400 224q-15 -1 -16 -16v-32q1 -15 16 -16h32 q15 1 16 16v32q-1 15 -16 16h-32v0z" },
		{ group: 'production', id: 'agriculture', name: 'การเกษตร', width: 596, path: "M0 96q1 -41 28 -68v0q27 -27 68 -28q41 1 68 28t28 68h-192v0zM181 128l-48 118q-5 12 -5 24v210q0 18 9 32h-89q-20 -1 -34 -14q-13 -14 -14 -34v-336h181v0zM160 480v-210q0 -6 2 -12l49 -119q5 -11 16 -16l127 -60q14 -7 28 0l127 60q11 5 16 16l49 119q2 6 2 12v210 q0 14 -9 23t-23 9h-112v-64q-1 -20 -14 -34q-14 -13 -34 -14h-32q-20 1 -34 14q-13 14 -14 34v64h-112q-14 0 -23 -9t-9 -23v0zM320 248v48q2 22 24 24h48q22 -2 24 -24v-48q-2 -22 -24 -24h-48q-22 2 -24 24v0z" },
		{ group: 'production', id: 'labor', name: 'แรงงาน', width: 660, path: "M72 88q1 -32 28 -48q28 -16 56 0q27 16 28 48q-1 32 -28 48q-28 16 -56 0q-27 -16 -28 -48v0zM64 246q-15 17 -16 42q1 25 16 42v-84v0zM208 196q-46 42 -48 108q1 52 32 91v21q0 14 -9 23t-23 9h-64q-14 0 -23 -9t-9 -23v-27q-29 -14 -46 -40q-18 -27 -18 -61 q1 -48 33 -79q31 -32 79 -33h32q37 1 64 20v0zM448 416v-21q31 -39 32 -91q-2 -66 -48 -108q27 -19 64 -20h32q48 1 79 33q32 31 33 79q0 34 -18 61q-17 26 -46 40v27q0 14 -9 23t-23 9h-64q-14 0 -23 -9t-9 -23v0zM456 88q1 -32 28 -48q28 -16 56 0q27 16 28 48 q-1 32 -28 48q-28 16 -56 0q-27 -16 -28 -48v0zM576 246v84q16 -17 16 -42t-16 -42v0zM320 32q36 1 55 32q18 32 0 64q-19 31 -55 32q-36 -1 -55 -32q-18 -32 0 -64q19 -31 55 -32v0zM240 304q1 25 16 42v-84q-15 17 -16 42v0zM384 262v84q16 -17 16 -42t-16 -42v0zM448 304 q0 34 -18 61v0q-17 26 -46 40v43q0 14 -9 23t-23 9h-64q-14 0 -23 -9t-9 -23v-43q-29 -14 -46 -40q-18 -27 -18 -61q1 -48 33 -79q31 -32 79 -33h32q48 1 79 33q32 31 33 79v0z" },
		{ group: 'production', id: 'finance', name: 'การเงิน', width: 596, path: "M400 96v1q-8 -1 -16 -1h-128q-25 0 -48 6v-3v-3q1 -41 28 -68t68 -28q41 1 68 28t28 68v0zM384 128h10q7 1 13 2q28 -33 73 -34h12q17 2 15 20l-14 55q24 23 38 53h13q14 0 23 9t9 23v96q0 14 -9 23t-23 9h-32q-14 18 -32 32v64q0 14 -9 23t-23 9h-32q-14 0 -23 -9 t-9 -23v-32h-128v32q0 14 -9 23t-23 9h-32q-14 0 -23 -9t-9 -23v-64q-54 -41 -63 -112h-29q-29 -1 -48 -20t-20 -48q1 -29 20 -48t48 -20h4q22 2 24 24q-2 22 -24 24h-4q-18 2 -20 20q2 18 20 20h31q10 -45 41 -78t76 -45q19 -5 40 -5h128v0zM448 264q-2 -22 -24 -24 q-22 2 -24 24q2 22 24 24q22 -2 24 -24v0z" },
		{ group: 'human', id: 'education', name: 'การศึกษา', width: 469, path: "M219 1q5 -1 10 0l200 40q18 4 19 23q-1 19 -19 24l-77 15v57q-1 54 -37 91q-37 36 -91 37q-54 -1 -91 -37q-36 -37 -37 -91v-57l-48 -10v65l16 79q1 7 -4 13q-4 6 -12 6h-32q-7 0 -12 -6t-4 -13l16 -79v-71q-15 -6 -16 -23q1 -19 19 -23l200 -40v0zM112 328q17 -5 29 8 l71 76q12 10 24 0l71 -76q12 -13 29 -8q50 16 80 57q31 41 32 96q0 13 -9 22t-22 9h-386q-13 0 -22 -9t-9 -22q1 -55 32 -96q30 -41 80 -57v0z" },
		{ group: 'human', id: 'healthcare', name: 'สาธารณสุข', width: 468, path: "M224 256q35 0 64 -17v0q29 -17 47 -47q17 -30 17 -64t-17 -64q-18 -30 -47 -47t-64 -17t-64 17t-47 47q-17 30 -17 64t17 64q18 30 47 47t64 17v0zM128 311q-57 18 -92 64v0q-35 45 -36 107q0 13 9 21q8 9 21 9h388q13 0 21 -9q9 -8 9 -21q-1 -62 -36 -107 q-35 -46 -92 -64v51q21 6 34 22q14 17 14 40v40q-1 15 -16 16h-16q-15 -1 -16 -16q1 -15 16 -16v-24q0 -14 -9 -23t-23 -9t-23 9t-9 23v24q15 1 16 16q-1 15 -16 16h-16q-15 -1 -16 -16v-40q0 -23 14 -40q13 -16 34 -22v-57q-9 -1 -18 -1h-92q-9 0 -18 1v65q18 6 29 20 q11 15 11 34q-1 24 -16 40q-16 15 -40 16q-24 -1 -40 -16q-15 -16 -16 -40q0 -19 11 -34q11 -14 29 -20v-59v0zM144 448q22 -2 24 -24q-2 -22 -24 -24q-22 2 -24 24q2 22 24 24v0z" },
		{ group: 'human', id: 'pollution', name: 'มลพิษ', width: 586, path: "M411 282l131 -60q-15 -96 -85 -158q-69 -62 -169 -64q-100 2 -169 64q-70 62 -85 158l132 60q13 -25 42 -26h160q29 1 43 26v0zM416 314v70h94q33 -57 34 -128l-128 58v0zM413 416q-12 30 -45 32h-160q-33 -2 -45 -32h-75q36 44 87 70q52 25 113 26q61 -1 113 -26 q51 -26 87 -70h-75v0zM160 384v-70l-128 -58q1 71 34 128h94v0zM155 203q-11 10 -22 0q-10 -11 0 -22q10 -12 38 -24q27 -12 69 -13q15 1 16 16q-1 15 -16 16q-35 1 -57 11t-28 16v0zM336 176q-15 -1 -16 -16q1 -15 16 -16q42 1 69 13q28 12 38 24q10 11 0 22q-11 10 -22 0 q-6 -6 -28 -16t-57 -11v0zM208 304q-15 1 -16 16q1 15 16 16h160q15 -1 16 -16q-1 -15 -16 -16h-160v0zM208 368q-15 1 -16 16q1 15 16 16h160q15 -1 16 -16q-1 -15 -16 -16h-160v0z" },
		{ group: 'human', id: 'inequality', name: 'ความเหลื่อมล้ำ', width: 659, path: "M522 62q13 -4 19 -16v0q5 -11 1 -24t-16 -19q-11 -5 -24 -1l-113 37q-23 -37 -69 -39q-34 1 -57 23q-22 23 -23 57q0 4 1 9l-123 41q-13 4 -19 16q-5 11 -1 24t16 19q11 5 24 1l136 -45q6 5 14 8v327q0 14 9 23t23 9h192q14 0 23 -9t9 -23t-9 -23t-23 -9h-160v-295 q33 -15 44 -49l126 -42v0zM440 288l72 -124l72 124h-144v0zM512 384q48 -1 83 -23v0q34 -22 43 -56q3 -17 -7 -32l-95 -163q-8 -14 -24 -14q-16 1 -24 14l-95 163q-10 15 -7 32q9 35 43 56q35 22 83 23v0zM129 292l73 124h-145l72 -124v0zM3 433q9 34 44 56v0q34 22 82 23 q48 -1 83 -23q34 -22 43 -56q3 -17 -6 -32l-96 -163q-8 -14 -24 -14q-16 1 -24 14l-95 163q-10 15 -7 32v0z" },
		{ group: 'human', id: 'welfare', name: 'สวัสดิการ', width: 650, path: "M544 248v3l70 -69q16 -18 16 -40t-16 -40l-78 -78q-18 -16 -40 -16t-40 16l-40 41q-4 -1 -8 -1h-112q-28 1 -48 19q-20 17 -24 45v0v120q0 17 12 28q11 12 28 12t28 -12q12 -11 12 -28v-72v0v0v-16h16h136v0v0h8q34 1 57 23q22 23 23 57v8v0zM336 192v56q-1 31 -21 51 t-51 21q-31 -1 -51 -21t-21 -51v-119q-27 5 -48 23q-20 18 -28 46l-16 57l-74 73q-16 18 -16 40t16 40l78 78q18 16 40 16t40 -16l37 -38h2h1h160q20 0 34 -14q13 -14 14 -34q0 -8 -3 -16h3q20 0 34 -14q13 -14 14 -34q0 -20 -13 -33q19 -4 32 -19t13 -36v0q-1 -24 -16 -40 q-16 -15 -40 -16v0v0h-120v0z" },
		{ group: 'trends', id: 'geopolitics', name: 'ภูมิรัฐศาสตร์', width: 532, path: "M52 295l31 6q13 3 22 -6l16 -16q9 -8 20 -6q12 2 18 13l9 18q8 15 26 16q11 -1 18 -9q6 -9 3 -20l-6 -20q-3 -12 4 -22q7 -9 19 -9h3q21 -1 33 -18l11 -16q8 -14 -1 -28l-16 -21q-8 -11 -4 -23q4 -11 17 -15l17 -4q12 -4 17 -15l16 -41q-33 -11 -69 -11q-88 2 -147 61 t-61 147q0 20 4 39v0zM459 300q-2 -1 -4 0v0q-2 0 -5 1l-15 4q-11 2 -18 -7l-2 -3q-10 -15 -27 -15q-18 0 -28 15l-6 10q-2 3 -6 5l-36 20q-14 8 -18 22q-5 15 1 29l6 13q6 14 21 20q14 6 29 1l3 -1q16 -6 30 4l1 1q57 -46 74 -119v0zM0 256q1 -70 34 -128v0q34 -58 94 -94 q61 -34 128 -34t128 34q60 36 94 94q33 58 34 128q-1 70 -34 128q-34 58 -94 94q-61 34 -128 34t-128 -34q-60 -36 -94 -94q-33 -58 -34 -128v0zM145 348q-3 15 11 20l32 8q15 2 20 -12q2 -15 -12 -19l-32 -8q-15 -3 -19 11v0zM237 328q-3 15 11 20q15 2 20 -12l8 -32 q2 -15 -12 -19q-15 -3 -19 11l-8 32v0zM343 114q-13 -6 -21 7l-16 32q-6 14 7 21q14 6 21 -7l16 -32q6 -13 -7 -21v0z" },
		{ group: 'trends', id: 'green', name: 'เศรษฐกิจสีเขียว', width: 586, path: "M32 64q0 -14 9 -23v0q9 -9 23 -9h64q14 0 23 9t9 23v151l157 -84q12 -6 23 1q11 6 12 20v63l157 -84q12 -6 23 1q11 6 12 20v152v128q-1 20 -14 34q-14 13 -34 14h-416q-20 -1 -34 -14q-13 -14 -14 -34v-80v-48v-240v0zM264 312q-15 1 -16 16v48q1 15 16 16h48 q15 -1 16 -16v-48q-1 -15 -16 -16h-48v0zM120 328v48q1 15 16 16h48q15 -1 16 -16v-48q-1 -15 -16 -16h-48q-15 1 -16 16v0zM392 312q-15 1 -16 16v48q1 15 16 16h48q15 -1 16 -16v-48q-1 -15 -16 -16h-48v0z" },
		{ group: 'trends', id: 'aging', name: 'สังคมสูงวัย', width: 458, path: "M272 48q-1 -27 -24 -42q-24 -12 -48 0q-23 15 -24 42q1 27 24 42q24 12 48 0q23 -15 24 -42v0zM264 235l47 57q9 11 22 12t24 -7q10 -9 11 -22t-7 -23l-74 -89q-29 -34 -74 -35h-36q-26 0 -47 12q-22 13 -35 35l-58 97q-7 11 -4 24q3 12 14 19q12 7 25 4q12 -3 19 -14 l29 -48v223q0 14 9 23t23 9t23 -9t9 -23v-128h16v128q0 14 9 23t23 9t23 -9t9 -23v-245v0zM352 376q1 -7 8 -8q7 1 8 8v112q2 22 24 24q22 -2 24 -24v-112q-1 -24 -16 -40q-16 -15 -40 -16q-24 1 -40 16q-15 16 -16 40v8q2 22 24 24q22 -2 24 -24v-8v0z" },
		{ group: 'government', id: 'tax', name: 'ภาษี', width: 404, path: "M64 0q-27 1 -45 19v0q-18 18 -19 45v384q1 27 19 45t45 19h256q27 -1 45 -19t19 -45v-288h-128q-14 0 -23 -9t-9 -23v-128h-160v0zM256 0v128h128l-128 -128v0zM64 80q1 -15 16 -16h64q15 1 16 16q-1 15 -16 16h-64q-15 -1 -16 -16v0zM64 144q1 -15 16 -16h64q15 1 16 16 q-1 15 -16 16h-64q-15 -1 -16 -16v0zM192 216q15 1 16 16v17q13 2 24 5q14 5 11 20q-5 14 -19 11q-17 -5 -32 -5q-14 0 -24 6q-8 4 -8 12q-1 5 7 10q11 6 30 11v0v0v0v0q18 5 36 15q21 11 23 38q-1 29 -23 42q-12 7 -25 9v17q-1 15 -16 16q-15 -1 -16 -16v-18q-17 -3 -31 -9 v0l-6 -2q-14 -6 -10 -20q6 -13 20 -10q4 1 7 2v0v0v0v0q1 0 1 1q19 7 35 8q15 0 24 -5q8 -5 8 -14q0 -7 -8 -12q-10 -6 -29 -11l-1 -1v0q-18 -4 -35 -13q-20 -11 -23 -38q2 -29 24 -40q11 -7 24 -9v-17q1 -15 16 -16v0z" },
		{ group: 'government', id: 'regulations', name: 'กฎหมาย', width: 532, path: "M319 9q-10 -9 -23 -9v0q-13 0 -23 9l-120 120q-9 10 -9 23t9 23l16 16q10 9 23 9t23 -9l4 -4l106 106l-4 4q-9 10 -9 23t9 23l16 16q10 9 23 9t23 -9l120 -120q9 -10 9 -23t-9 -23l-16 -16q-10 -9 -23 -9t-23 9l-4 4l-106 -106l4 -4q9 -10 9 -23t-9 -23l-16 -16v0z M167 297q-10 -9 -23 -9v0q-13 0 -23 9l-112 112q-9 10 -9 23t9 23l48 48q10 9 23 9t23 -9l112 -112q9 -10 9 -23t-9 -23l-2 -1l59 -59l-45 -45l-59 59l-1 -2v0z" },
		{ group: 'government', id: 'corruption', name: 'คอร์รัปชัน', width: 532, path: "M215 96h-71h-36h-12v9v39v40v89l-96 -70q3 -29 26 -46l22 -17v-44q1 -20 14 -34q14 -13 34 -14h77l50 -37q14 -11 33 -11t34 11l49 37h77q20 1 34 14q13 14 14 34v44l22 17q23 17 26 46l-96 70v-89v-40v-39v-9h-12h-36h-71h-82v0zM0 448v-206l218 161q17 13 38 13t38 -13 l218 -161v206v0q-1 27 -19 45t-45 19h-384q-27 -1 -45 -19t-19 -45v0v0zM276 136v14q11 2 21 5q17 6 14 24q-6 17 -24 14q-17 -4 -31 -5q-13 0 -22 5q-7 4 -6 9q-1 3 5 7q9 5 29 11v0h1q18 5 36 15q22 11 25 41q-2 32 -25 45q-11 7 -23 9v14q-2 18 -20 20q-18 -2 -20 -20 v-15q-16 -3 -28 -8v0v0l-6 -2q-17 -7 -13 -25q7 -17 25 -13q2 1 4 1q2 1 4 1v0q19 8 35 9q13 0 21 -5q6 -3 6 -10q1 -4 -6 -9q-9 -5 -28 -10l-2 -1v0q-18 -4 -35 -14q-22 -11 -25 -40q2 -32 26 -44q10 -6 22 -9v-14q2 -18 20 -20q18 2 20 20v0z" },
		{ group: 'government', id: 'capability', name: 'ความสามารถ', width: 534, path: "M243 3l-224 96q-22 11 -18 35q6 24 31 26v8q2 22 24 24h400q22 -2 24 -24v-8q25 -2 31 -26q4 -24 -18 -35l-224 -96q-13 -6 -26 0v0zM128 224h-64v196q-1 1 -2 1l-48 32q-18 14 -13 36q8 22 31 23h448q23 -1 31 -23q5 -22 -13 -36l-48 -32h-1q-1 0 -1 -1v-196h-64v192 h-40v-192h-64v192h-48v-192h-64v192h-40v-192v0zM256 64q14 0 23 9v0q9 9 9 23t-9 23t-23 9t-23 -9t-9 -23t9 -23t23 -9v0z" },
	]

	const offset = -90

	return (
		<div className={styles.overview}>
			<svg width="650" viewBox="-325 -250 650 500" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<path id="textcircle" d=" M 0, 0 m -120, 0 a 120,120 0 0,1 240,0 a 120,120 0 0,1 -240,0 " transform={`scale(${rText/120})`}/>
					<path id="textcircleouter" d=" M 0 0 M 120 0 a 120 120 1 0 0 -240 0 a 120 120 0 0 0 240 0 " transform={`scale(${(rText+8)/120}) rotate(180)`}/>
				</defs>
        
				{nodes.map((node, i) => {
					return <Piece key={i} node={node} span={22.5} rotate={(i + 0.5) * 22.5 + offset} labelRotate={(i + 0.5) * 22.5 + offset} />
				})}

        {[...Array(16).keys()].map(i =>
          <line key={i} className={styles.seperator} x1="0" y1="0" x2="0" y2={-1.1*r} transform={`rotate(${i * 22.5})`}/>
        )}

				<Group group={"production"} span={90} rotate={-45} label="การผลิต" width={94.63} />
				<Group group={"human"} span={5*22.5} rotate={45 + 22.5/2} label="คน" width={33.41} />
				<Group group={"trends"} span={3*22.5} rotate={45 + 5*22.5 - 22.5/2} label="ทิศทางโลก" width={117.92} outer={true} />
				<Group group={"government"} span={90} rotate={-45-90} label="ภาครัฐ" width={74.88} outer={true} />

				<line className={styles.seperator} x1="0" y1="0" x2="0" y2={-1.1*r} transform={`rotate(${-90})`}/>
				<line className={styles.seperator} x1="0" y1="0" x2="0" y2={-1.1*r} transform={`rotate(${0})`}/>
				<line className={styles.seperator} x1="0" y1="0" x2="0" y2={-1.1*r} transform={`rotate(${90 + 22.5})`}/>
				<line className={styles.seperator} x1="0" y1="0" x2="0" y2={-1.1*r} transform={`rotate(${180})`}/>

        <circle className={styles.fillBkg} cx="0" cy="0" r={0.4*r} />
        {/* <TextBox textArray={labels["framework"][lang]} x={0} y={0} fontSize={1.8}/> */}

      </svg>
		</div>
	);
}
