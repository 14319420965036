import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'

function ComparePop({ pop, groups, sample, title, ...rest }) {

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "400px"}}
      data={{
        labels: groups,
        datasets: [
          {
            label: "ประชากรไทย",
            data: pop.map(x => x * 100),
            backgroundColor: getAreaColor(defaultStyles.colors[1]),
            borderColor: 'none',
          },
          {
            label: "กลุ่มตัวอย่าง",
            data: sample.map(x => x * 100),
            backgroundColor: getAreaColor(defaultStyles.colors[0]),
            borderColor: 'none',
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        scales: {
          y: {
            title: {
              display: true,
              text: "สัดส่วน (%)",
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: title,
          }
        }
      }}
      {...rest}
    />
  )

}

export default function ComparePopAll() {
  return(
    <div>
      <ComparePop
        groups={["< 30", "30–39", "40–59", "≥ 60"]}
        pop={[0.166234731, 0.161541394, 0.406425137, 0.265798739]}
        sample={[0.1408308, 0.259371834, 0.495440729, 0.104356636]}
        title="อายุ"
      />
      <ComparePop
        groups={["นอกเขตเทศบาล", "ในเขตเทศบาล", "กรุงเทพฯ"]}
        pop={[0.563505023, 0.313015343, 0.123479635]}
        sample={[0.159067882, 0.242654509, 0.598277609]}
        title="พื้นที่"
      />
      <ComparePop
        groups={["ธุรกิจส่วนตัว/รับจ้าง", "ข้าราชการ", "นักเรียน", "ทำงานที่บ้าน", "พนักงานเอกชน ", "เกษียณ/ว่างงาน"]}
        pop={[0.214418738, 0.055638929, 0.05916952, 0.165197247, 0.243307944, 0.262267623]}
        sample={[0.240894901, 0.277835588, 0.041623309, 0.036940687, 0.294484912, 0.108220604]}
        title="อาชีพ"
      />
      <ComparePop
        groups={["< 10,000", "10,000–25,000", "25,001–50,000", "50,001–80,000", "80,001–120,000", "120,001–200,000", "200,001–500,000", "> 500,000"]}
        pop={[0.154819513, 0.405666645, 0.251476368, 0.103813561, 0.044534863, 0.024262061, 0.011692634, 0.003734356]}
        sample={[0.032928065, 0.095744681, 0.166666667, 0.151975684, 0.151469098, 0.138804458, 0.168693009, 0.093718338]}
        title="รายได้"
      />
    </div>
  )
}