import React from "react"
import Education from "./Education"
import Wage from "./Wage"
import Alt from "./alt/labor.png"

export const educationLabels = [
  "ต่ำกว่าประถมศึกษา",
  "ประถมศึกษา",
  "มัธยมศึกษา",
  "ปวช. ปวส.",
  "ปริญญาตรีขึ้นไป",
]

export default function Labor() {
  return (
    <>
      <img className="printonly" src={Alt} alt="Agriculture" />
      <div className="split-2 noprint">
        <Education />
        <Wage />
      </div>
    </>
  )
}