import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"

export default function TaxWorkforce() {

  const [width, setWidth] = React.useState(0)

  return(
    <LazyHighcharts
      options={{
        series: [{
          name: "ประชากร",
          type: 'sankey',
          curveFactor: 0.2,
          data: [
            {
              from: 'กำลังแรงงาน',
              to: 'ผู้ไม่ยื่นแบบ',
              weight: 28,
              color: defaultStyles.colors[0],
            },
            {
              from: 'กำลังแรงงาน',
              to: 'ผู้ยื่นแบบ',
              weight: 11,
              color: defaultStyles.colors[2],
            },
            {
              from: 'ผู้ยื่นแบบ',
              to: 'ไม่เสียภาษี',
              weight: 7,
              color: defaultStyles.colors[1],
            },
            {
              from: 'ผู้ยื่นแบบ',
              to: 'เสียภาษี',
              weight: 4,
              color: defaultStyles.colors[2],
            },
          ],
          nodes: [
            {
              id: 'กำลังแรงงาน',
              color: defaultStyles.colors[3],
            },
            {
              id: 'ผู้ไม่ยื่นแบบ',
              color: defaultStyles.colors[0],
            },
            {
              id: 'ไม่เสียภาษี',
              offsetHorizontal: -1 * width / 4,
              offsetVertical: 80,
              color: defaultStyles.colors[1],
            },
            {
              id: 'เสียภาษี',
              offsetVertical: 80,
              color: defaultStyles.colors[2],
            },
          ],
        }],
        plotOptions: {
          series: {
            dataLabels: {
              align: 'center',
              style: {
                fontSize: '14px',
              },
              // nodeFormatter: function() {
              //   console.log(this)
              //   return(`${this.key}<br />${this.point.sum} ล้านคน`)
              // }
            },
          },
        },
        legend: {
          // enabled: false,
        },
        chart: {
          type: "column",
          height: "400px",
          events: {
            render: function() {
              setWidth(this.xAxis[0].width)
            }
          },
        },
        title: {
          text: "ผู้เสียภาษีมีประมาณ 10% ของกำลังแรงงาน",
        },
        subtitle: {
          text: "จำนวนผู้เสียภาษีและกำลังแรงงาน",
        },
        tooltip: {
          formatter: function(tooltip) {
            let tmp = tooltip.defaultFormatter.call(this, tooltip)
            tmp[1] = tmp[1].replace("</b>", ` ล้านคน</b>`)
            return tmp
          }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}