import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"
import { cohortFormatter } from "./util"

let rawData = [
  21806990,
  21070602,
  20850885,
  20691026,
  20627302,
  20595433,
  20486151,
  20455165,
  20502966,
  20397737,
  20398705,
  20396845,
  20536693,
  20548945,
  20564438,
  20576278,
  20546164,
  20553614,
  853025,
  650544,
  636700,
  632074,
  631474,
  628313,
  623893,
  629251,
  617075,
  621419,
  623235,
  651045,
  658568,
  657231,
  664886,
  662079,
  663989,
  491922,
  378333,
  366216,
  365036,
  359385,
  355948,
  361503,
  351873,
  353017,
  355588,
  369619,
  371443,
  378187,
  378345,
  376286,
  378698,
  518827,
  421063,
  403702,
  397654,
  383666,
  385564,
  376107,
  376568,
  376026,
  402369,
  398698,
  397636,
  410639,
  406010,
  406810,
  494953,
  396903,
  386970,
  370464,
  369529,
  356518,
  358886,
  359730,
  388941,
  384233,
  381952,
  393485,
  395302,
  390159,
  394244,
  320909,
  309004,
  306341,
  293136,
  293467,
  294672,
  312513,
  309406,
  308330,
  315153,
  314361,
  316894,
  431354,
  348736,
  335850,
  311626,
  311933,
  312547,
  342447,
  335483,
  332274,
  344098,
  341354,
  339572,
  391010,
  313855,
  296061,
  293777,
  292349,
  307003,
  303700,
  301950,
  307167,
  305295,
  304514,
  394390,
  285173,
  279401,
  278418,
  290930,
  289007,
  288564,
  293859,
  292319,
  292575,
  266368,
  216325,
  210302,
  215575,
  213574,
  212788,
  214276,
  212254,
  211969,
  298618,
  245774,
  247655,
  243933,
  242386,
  242601,
  239978,
  238939,
  317100,
  273490,
  263769,
  260065,
  263622,
  260344,
  258712,
  516310,
  418373,
  401064,
  424831,
  416423,
  409484,
  599759,
  514885,
  467599,
  445207,
  438973,
  440276,
  346417,
  325349,
  319619,
  323346,
  261302,
  253191,
  258017,
  213406,
  243026,
]

const cohortSize = [
  21806990,
  853025,
  491922,
  518827,
  494953,
  394244,
  431354,
  391010,
  394390,
  266368,
  298618,
  317100,
  516310,
  599759,
  440276,
  323346,
  258017,
  243026,
]

const tmpData = []
for (let i = 7; i <= 24; i ++) {
  tmpData.push(rawData.splice(0, 24 - i + 1))
}

export default function Age() {

  const [showMonthsSinceOrigin, setShowMonthsSinceOrigin] = React.useState(false)
  const [showRatio, setShowRatio] = React.useState(true)
  const [finalData, setFinalData] = React.useState([])

  React.useEffect(() => {

    const tmp = tmpData.map((d, i) => ({
      name: cohortFormatter(i + 7),
      data: d.map((p, j) => [(showMonthsSinceOrigin ? j : Date.UTC(2020, i + 7 - 1 + j, 1)), showRatio ? (p / cohortSize[i] * 100) : p]),
      color: defaultStyles.colors[0],
      dashStyle: i === 0 ? "dash" : "solid",
    })).slice(showRatio ? 0 : 1)

    const secondMonth = [{
      type: "scatter",
      data: tmp.slice(0, -1).map(x => x.data[1]),
      pointStart: Date.UTC(2020, 7, 1),
      marker: {
        enabled: true,
        symbol: "circle",
        fillColor: defaultStyles.colors[1],
      },
      lineWidth: 0,
      enableMouseTracking: false,
    }]

    setFinalData(tmp.concat(showMonthsSinceOrigin || !showRatio ? [] : secondMonth))

  }, [showMonthsSinceOrigin, showRatio])
  
  return(
    <div>
			<div>
        <LazyHighcharts
          options={{
            series: finalData,
            plotOptions: {
              series: {
                pointIntervalUnit: showMonthsSinceOrigin ? undefined : 'month',
                marker: {
                  enabled: false,
                },
              },
            },
            legend: {
              enabled: false,
            },
            chart: {
              height: "300px",
            },
            xAxis: {
              type: showMonthsSinceOrigin ? 'linear' : 'datetime',
              title: {
                text: showMonthsSinceOrigin ? 'เดือนนับจากวันที่เริ่มใช้' : 'เดือน',
              },
            },
            tooltip: {
              xDateFormat: '%Y-%m',
              valueDecimals: showRatio ? 2 : 0,
              valueSuffix: showRatio ? "%" : "",
            },
            yAxis: {
              title: {
                text: showRatio ? "สัดส่วนของ cohort (%)" : "จำนวนผู้ใช้บริการ",
              },
              max: showRatio ? 100 : null,
            },
            responsive: {
              rules: [{
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    maxHeight: undefined,
                  },
                  chart: {
                    height: "50%",
                  },
                },
              }]
            }
          }}
        />
			</div>
			<div style={{textAlign: `center`, fontSize: `0.8rem`}}>
				<label>
					<input type="checkbox" onChange={() => setShowMonthsSinceOrigin(!showMonthsSinceOrigin)} defaultChecked={showMonthsSinceOrigin} /> แสดงเดือนนับจากวันที่เริ่มใช้บนแกนนอน
				</label>
      </div>
			<div style={{textAlign: `center`, fontSize: `0.8rem`}}>
        <label>
					<input type="checkbox" onChange={() => setShowRatio(!showRatio)} defaultChecked={showRatio} /> แสดงสัดส่วนของ cohort
				</label>
			</div>
		</div>
  )

}