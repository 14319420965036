import React from "react"

const x = {
  textAlign: "left",
  paddingLeft: "2rem",
}
const sectionStyle = {
  textAlign: "left",
  fontStyle: "italic",
}
const data = {
  'ลักษณะเกษตรกรและการทำการเกษตร': [
    ['อายุ (ปี)', '53.0', '54.3', '–1.3***'],
    ['การศึกษาระดับประถมหรือต่ำกว่า (% ผู้กู้)', '45.8', '45.4', '0.4**'],
    ['พื้นที่เพาะปลูก (ไร่)', '20.5', '21.2', '–0.7***'],
    ['มีที่ดินเป็นของตัวเอง (% ผู้กู้)', '81.1', '82.1', '–1.0***'],
    ['อยู่ในเขตชลประทาน (% ผู้กู้)', '13.4', '19.0', '–5.6***'],
    ['มี diversification สูง (% ผู้กู้)', '39.1', '34.0', '5.2***'],
    ['เข้าร่วมโครงการด้านการเกษตรของภาครัฐ (% ผู้กู้)', '13.2', '14.0', '–0.9***'],
    ['ได้รับเงินช่วยเหลือภัยพิบัติจากภาครัฐ (% ผู้กู้)', '6.0', '4.8', '1.2***'],
  ],
  'ลักษณะการกู้ยืม': [
    ['ยอดเงินต้นคงค้าง (บาท)', '227,338', '145,427', '81,961***'],
    ['ยอดเงินฝาก (บาท)', '23,040', '43,640', '–20,600***'],
    ['ผิดนัดชำระหนี้ (% ผู้กู้)', '9.8', '19.1', '–9.3***'],
    ['มีหลักประกัน (% ผู้กู้)', '61.3', '46.4', '14.9***'],
  ],
}
  
export default function Ab1823Table() {
  return(
    <div className="table-wrapper">
    <table style={{minWidth: '450px'}}>
      <thead>
        <tr>
          <th />
          <th>เข้ามาตรการพักหนี้</th>
          <th>ไม่ได้เข้าเข้ามาตรการพักหนี้</th>
          <th>ความแตกต่าง</th>
        </tr>
      </thead>
      <tbody>
        {
          Object.keys(data).map(section => (
            <React.Fragment key={section}>
              <tr>
                <td colSpan={4} style={sectionStyle}>{section}</td>
              </tr>
              {data[section].map((row, j) => (
                <tr key={j} className={j === data[section].length - 1 ? "hr" : ""}>
                  <>
                    <td style={x} dangerouslySetInnerHTML={{__html: row[0].replace(/^(.*?) \(/, '<strong>$1</strong> (')}} />
                    {[1, 2, 3].map(i => <td key={i}>{row[i]}</td>)}
                  </>
                </tr>  
              ))}
            </React.Fragment>
          ))
        }
      </tbody>
    </table>
    </div>
  )
}