import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = 
[
	{name: "ไทย", data: [100,105.558416,105.6433676,110.39556,119.0543859,117.5622798,120.4976316,128.0100658,123.9616928,125.352272,122.0648346,122.912667,125.0542,123.1681841,129.1560118,123.9317878,129.951077,131.8380027,131.2297815,128.9283027,121.469267]},
	{name: "โลก", data: [100,100.3954998,101.0045236,102.5693468,106.1559525,107.7599148,109.8391511,112.300819,116.4054059,116.6971866,118.0641388,121.1597955,121.7784527,125.2344468,127.2121419,127.8212951,128.6640622,131.4587464,132.50856,132.650841,133.4495708]},
	{name: "กัมพูชา", data: [100,98.66736512,92.54660479,105.9250035,102.3104159,122.9724578,131.1369696,134.091285,142.9732754,137.8628419,135.7029687,145.1508766,150.4400513,156.3208685,161.7404583,163.896943,174.5591915,178.5351819,178.11331,180.5639192,182.5396193]},
	{name: "อินโดนีเซีย", data: [100,101.4494983,104.4208554,104.2444965,110.6493366,113.9909884,120.5629155,117.3399046,122.5982814,122.9603674,136.3548293,140.3534516,146.6000026,153.8792218,152.3982569,156.1159076,154.8717528,170.8912076,177.6112556,173.4834547,172.2302005]},
	{name: "ลาว", data: [100,100.2807331,107.3907344,112.387517,105.8685304,106.1527142,103.3623614,106.9507466,105.1338327,114.1407865,117.2375672,126.8389111,132.5056808,134.7575225,153.3220246,172.8729532,172.9753139,166.7484046,167.5728343,175.7835632,186.9014953]},
	{name: "พม่า", data: [100,103.9399689,105.6149663,106.1383755,119.5130767,129.0509034,138.7659894,141.6354636,147.8488048,152.5633821,148.804068,141.9600532,139.1081635,141.9213949,141.7562215,143.2691987,141.5357995,141.1487954,142.3102052,143.0723072,147.4120811]},
	{name: "ฟิลิปปินส์", data: [100,102.583468,104.1347492,113.1774088,107.8276557,110.7223769,113.191163,117.8684351,120.1312289,117.6504741,118.2838519,119.5294283,121.6132645,120.8351292,120.8729348,121.5442532,119.9133363,125.4521803,125.2713311,125.645645,122.7620411]},
	{name: "เวียดนาม", data: [100,101.7886021,105.512207,100.1761146,117.3184286,119.7951079,124.2435595,129.525633,134.4592159,134.2889414,129.5217541,133.6395695,139.3079737,140.0581755,142.9276227,137.9148989,138.1004488,143.4073494,150.0994934,157.1355274,163.279926]},
	{name: "จีน", data: [100,100.4564561,101.7035702,107.4153178,107.944498,111.6852702,114.2592445,118.0307845,124.3447648,125.5169946,127.9617291,131.1430531,133.6556094,136.8046879,140.5032337,142.6217981,144.1113999,147.3332823,148.8857979,148.4586054,151.8482341]},
	{name: "เกาหลีใต้", data: [100,101.9607484,98.85728456,103.2105793,103.3538421,105.312356,111.6317056,111.9993761,116.7422842,120.8904496,115.8974016,113.7274431,116.7743264,117.3917535,122.736586,122.5138669,124.4723132,126.5987327,128.1331212,128.6512981,125.7004578]},
	{name: "อินเดีย", data: [100,101.0401416,92.76029091,102.5598559,98.39931189,101.9377749,107.1106642,115.0315326,115.5064018,111.8156172,119.6105429,128.5875909,132.7323641,137.1619678,139.4294716,138.1480016,143.1763948,151.6556645,158.8260131,160.5331407,162.0291802]},
	{name: "บราซิล", data: [100,104.2965342,108.1168302,106.825136,107.5252514,112.7155112,118.922929,123.5771167,134.1266628,134.9221705,141.1938208,145.4218244,142.1298349,149.2712823,152.2588271,157.9946088,151.8680811,164.5952141,165.4077482,167.9030772,169.535111]},
]



export default function AgriProductivity() {

  return(
    <LazyHighcharts
      // altImage={}
      options={{
        series: rawData.map((series, i) => ({
          ...series,
          opacity: (series.name === "ไทย" || series.name === "โลก") ? 1 : 0.8,
          lineWidth: series.name === "ไทย" ? 3 : (series.name === "โลก" ? 2 : 0.5),
          zIndex: series.name === "ไทย" ? 9 : (series.name === "โลก" ? 5 : 0),
          label: {
            enabled: series.name === "ไทย" || series.name === "โลก",
          },
        })),
        chart: {
          type: "spline",
          height: "500px",
        },
        plotOptions: {
          series: {
            pointStart: 2543,
            marker: {
              enabled: false,
            },
          },
        },
        xAxis: {
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        title: {
          text: "ผลิตภาพรวมของภาคเกษตรไทยโตต่ำต่อเนื่อง<br />เมื่อเทียบกับเพื่อนบ้านและค่าเฉลี่ยโลก",
        },
        subtitle: {
          text: "ดัชนีผลิตภาพรวมของภาคเกษตร (2543 = 100)",
        },
        tooltip: {
          formatter: function(tooltip) {
            let tmp = tooltip.defaultFormatter.call(this, tooltip);
            tmp[1] = tmp[1].replace(/<b>[0-9,.]+<\/b>/, `<b>${this.y.toFixed(2)}</b>`)
            return tmp
          }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}