import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = [
  {name: "การจ้างงาน", data: [79, 49]},
  {name: "อื่น ๆ", data: [21, 51]},
]

export default function TaxContribution() {

  return(
    <LazyHighcharts
      options={{
        series: rawData,
        plotOptions: {
          series: {
            dataLabels: {
              style: {
                fontSize: '16px',
              },
            },
          },
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
              formatter: function() {
                return `${this.y.toFixed(0)}%`
              },
            },
          },
        },
        legend: {
          // enabled: false,
        },
        chart: {
          type: "column",
          height: "400px",
          events: {
            render: function() {
              const styles = {
                stroke: 'black',
                "stroke-width": 2,
                "stroke-dasharray": "2",
                opacity: 0.3,
              }
              if (this.lines) {
                this.lines.map(x => x.element.remove())
              }
              this.lines = []
              const left = this.xAxis[0].toPixels(0.24)
              const right = this.xAxis[0].toPixels(1 - 0.24)
              // mid line
              this.lines.push(this.renderer.path([
                'M', left, this.yAxis[0].toPixels(21),
                'C', this.xAxis[0].toPixels(0.5), this.yAxis[0].toPixels(21), this.xAxis[0].toPixels(0.5), this.yAxis[0].toPixels(51), right, this.yAxis[0].toPixels(51),
              ]).attr(styles).add())
              this.lines.push(this.renderer.path([
                'M', left, this.yAxis[0].toPixels(100),
                'H', right,
              ]).attr(styles).add())
            },
          },
        },
        title: {
          text: "และ 80% ของภาษีที่เก็บได้มาจากมนุษย์เงินเดือน",
        },
        subtitle: {
          text: "การกระจายตัวของปริมาณภาษีและรายได้ประชาชาติ",
        },
        xAxis: {
          categories: ["ภาษีที่เก็บได้", "รายได้ครัวเรือนประชาชาติ"]
        },
        tooltip: {
          valueSuffix: "%",
        //   formatter: function(tooltip) {
        //     let tmp = tooltip.defaultFormatter.call(this, tooltip);
        //     if (showPercent)
        //       tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.percentage.toFixed(2)}%</b>`)
        //     return tmp
        //   }
        },
        yAxis: {
          title: {
            text: "",
          },
          max: 100,
          // reversedStacks: true,
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}