import React from "react";
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const data = {
  "5 ปี": {
    name: "รายรับรายจ่ายกองทุนฯ",
    data: [
      {
        name: "สมทบ 5 ปี",
        data: [
          [51, 8400],
          [52, 8400],
          [53, 8400],
          [54, 8400],
          [55, 8400],
          [56, -36000],
        ],
      },
    ],
  },
  "10 ปี": {
    name: "รายรับรายจ่ายกองทุนฯ",
    data: [
      {
        name: "สมทบ 10 ปี",
        data: [
          [46, 8400],
          [47, 8400],
          [48, 8400],
          [49, 8400],
          [50, 8400],
          [51, 8400],
          [52, 8400],
          [53, 8400],
          [54, 8400],
          [55, 8400],
          [56, -72000],
        ],
      },
    ],
  },
  "15 ปี": {
    name: "รายรับรายจ่ายกองทุนฯ",
    data: [
      {
        name: "สมทบ 15 ปี",
        data: [
          [41, 8400],
          [42, 8400],
          [43, 8400],
          [44, 8400],
          [45, 8400],
          [46, 8400],
          [47, 8400],
          [48, 8400],
          [49, 8400],
          [50, 8400],
          [51, 8400],
          [52, 8400],
          [53, 8400],
          [54, 8400],
          [55, 8400],
          [56, -24000],
          [57, -24000],
          [58, -24000],
          [59, -24000],
          [60, -24000],
          [61, -24000],
          [62, -24000],
          [63, -24000],
          [64, -24000],
          [65, -24000],
          [66, -24000],
          [67, -24000],
          [68, -24000],
          [69, -24000],
          [70, -24000],
          [71, -24000],
          [72, -24000],
          [73, -24000],
          [74, -24000],
          [75, -24000],
        ],
      },
    ],
  },
  "20 ปี": {
    name: "รายรับรายจ่ายกองทุนฯ",
    data: [
      {
        name: "สมทบ 20 ปี",
        data: [
          [36, 8400],
          [37, 8400],
          [38, 8400],
          [39, 8400],
          [40, 8400],
          [41, 8400],
          [42, 8400],
          [43, 8400],
          [44, 8400],
          [45, 8400],
          [46, 8400],
          [47, 8400],
          [48, 8400],
          [49, 8400],
          [50, 8400],
          [51, 8400],
          [52, 8400],
          [53, 8400],
          [54, 8400],
          [55, 8400],
          [56, -33000],
          [57, -33000],
          [58, -33000],
          [59, -33000],
          [60, -33000],
          [61, -33000],
          [62, -33000],
          [63, -33000],
          [64, -33000],
          [65, -33000],
          [66, -33000],
          [67, -33000],
          [68, -33000],
          [69, -33000],
          [70, -33000],
          [71, -33000],
          [72, -33000],
          [73, -33000],
          [74, -33000],
          [75, -33000],
        ],
      },
    ],
  },
  "25 ปี": {
    name: "รายรับรายจ่ายกองทุนฯ",
    data: [
      {
        name: "สมทบ 25 ปี",
        data: [
          [31, 8400],
          [32, 8400],
          [33, 8400],
          [34, 8400],
          [35, 8400],
          [36, 8400],
          [37, 8400],
          [38, 8400],
          [39, 8400],
          [40, 8400],
          [41, 8400],
          [42, 8400],
          [43, 8400],
          [44, 8400],
          [45, 8400],
          [46, 8400],
          [47, 8400],
          [48, 8400],
          [49, 8400],
          [50, 8400],
          [51, 8400],
          [52, 8400],
          [53, 8400],
          [54, 8400],
          [55, 8400],
          [56, -42000],
          [57, -42000],
          [58, -42000],
          [59, -42000],
          [60, -42000],
          [61, -42000],
          [62, -42000],
          [63, -42000],
          [64, -42000],
          [65, -42000],
          [66, -42000],
          [67, -42000],
          [68, -42000],
          [69, -42000],
          [70, -42000],
          [71, -42000],
          [72, -42000],
          [73, -42000],
          [74, -42000],
          [75, -42000],
        ],
      },
    ],
  },
  "30 ปี": {
    name: "รายรับรายจ่ายกองทุนฯ",
    data: [
      {
        name: "สมทบ 30 ปี",
        data: [
          [26, 8400],
          [27, 8400],
          [28, 8400],
          [29, 8400],
          [30, 8400],
          [31, 8400],
          [32, 8400],
          [33, 8400],
          [34, 8400],
          [35, 8400],
          [36, 8400],
          [37, 8400],
          [38, 8400],
          [39, 8400],
          [40, 8400],
          [41, 8400],
          [42, 8400],
          [43, 8400],
          [44, 8400],
          [45, 8400],
          [46, 8400],
          [47, 8400],
          [48, 8400],
          [49, 8400],
          [50, 8400],
          [51, 8400],
          [52, 8400],
          [53, 8400],
          [54, 8400],
          [55, 8400],
          [56, -51000],
          [57, -51000],
          [58, -51000],
          [59, -51000],
          [60, -51000],
          [61, -51000],
          [62, -51000],
          [63, -51000],
          [64, -51000],
          [65, -51000],
          [66, -51000],
          [67, -51000],
          [68, -51000],
          [69, -51000],
          [70, -51000],
          [71, -51000],
          [72, -51000],
          [73, -51000],
          [74, -51000],
          [75, -51000],
        ],
      },
    ],
  },
};

export default function Fig1() {
  const [toShow, setToShow] = React.useState("5 ปี"); //"5 ปี","10 ปี", "15 ปี","20 ปี", "25 ปี","30 ปี"

  return (
    <div>
      <LazyHighcharts
        options={{
          chart: {
            type: "column", // Set the chart type to 'bar'
          },
          title: {
            text: data[toShow].name,
            align: "center",
          },
          yAxis: {
            title: {
              text: "รายรับ(+), รายจ่าย(-)",
            },
            min: -70000,
            max: 20000,
            plotLines: [
              {
                value: 0,
                color: defaultStyles.zerolineColor,
                zIndex: 9,
              },
            ],
          },
          xAxis: {
            accessibility: {
              rangeDescription: "อายุ",
            },
            min: 25,
            max: 80,
          },
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            },
          },
          series: JSON.parse(JSON.stringify(data[toShow].data)),
          responsive: {
            rules: [
              {
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    align: "right",
                    verticalAlign: "middle",
                    layout: "vertical",
                    maxHeight: undefined,
                  },
                  chart: {
                    // height: "70%",
                  },
                },
              },
            ],
          },
        }}
      />

      <div style={{ textAlign: "center" }}>
        <ToggleButtonGroup
          value={toShow}
          size="small"
          exclusive
          onChange={(e, v) => {
            if (v) {
              setToShow(v);
            }
          }}
        >
          {Object.keys(data).map((key) => (
            <ToggleButton value={key} key={key}>
              {key}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
}
