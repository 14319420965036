import React from "react"
import Corn from "./Corn"
import Sugarcane from "./Sugarcane"
import Trucks from "./Trucks"

import Alt from "./alt/pollution.png"

export default function Pollution() {
  return(
    <>
      <img className="printonly" src={Alt} alt="Pollution" />
      <div className="split-2 noprint">
        <Trucks />
        <Sugarcane />
        <Corn />
      </div>
    </>
  )
}