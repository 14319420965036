import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"
import chroma from "chroma-js"

import Alt from "./alt/finance.png"

const rawData = [
  {name: "ประเทศไทย", data: [0.7551597726, 0.4161493609, -0.8122986573]},
  {name: "ประเทศรายได้สูง", data: [0.487910542, 0.4285053224, 0.1438594172]},
  {name: "ประเทศรายได้ปานกลางค่อนไปทางสูง", data: [0.2779226573, 0.2129252103, 0.3782929305]},
  {name: "ประเทศรายได้ปานกลางค่อนไปทางต่ำ", data: [-0.1141143541, -0.4406880889, -0.2371522857]},
  {name: "ประเทศรายได้ต่ำ", data: [-1.42161991, -0.3792932333, -0.6864645354]},
]

export default function Finance() {

  return(
    <LazyHighcharts
      altImage={Alt}
      options={{
        series: rawData,
        colors: [defaultStyles.colors[0]]
          .concat([...Array(4).keys()].map(i => chroma(defaultStyles.colors[1]).brighten(i*0.7).hex())),
        plotOptions: {
          // column: {
          //   stacking: showPercent ? "percent" : "normal",
          //   dataLabels: {
          //     enabled: showPercent,
          //     formatter: function() {
          //       return `${this.percentage.toFixed(0)}%`
          //     },
          //   },
          // },
        },
        legend: {
          // enabled: false,
        },
        chart: {
          type: "column",
          height: "400px",
        },
        xAxis: {
          categories: ["Efficiency (2559–63)", "Stability (2563)", "Inclusion (2559–64)"]
        },
        yAxis: {
          title: {
            text: "คะแนน",
          },
          plotLines: [{
            value: 0,
            color: defaultStyles.zerolineColor,
            zIndex: 9,
          }],
        },
        tooltip: {
          formatter: function(tooltip) {
            let tmp = tooltip.defaultFormatter.call(this, tooltip);
            console.log(tmp)
            tmp[1] = tmp[1].replace(/<b>[-0-9,\.]+<\/b>/, `<b>${this.y.toFixed(2)}</b> คะแนน`)
            return tmp
          }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}