import React from "react";
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"
import deepmerge from "deepmerge"


// TODO: make this better! Export defAxisOptions
const defAxisOptions = {
  gridLineColor: defaultStyles.gridlineColor,
  lineColor: defaultStyles.zerolineColor,
  tickColor: defaultStyles.zerolineColor,
  labels: {
    style: {
      fontFamily: defaultStyles.tick?.font?.family || defaultStyles.font.family,
      fontSize: `${defaultStyles.tick?.font?.size || defaultStyles.font.size}px`,
      color: defaultStyles.tick?.font?.color || defaultStyles.font.color,
    },
  },
  title: {
    style: {
      fontFamily: defaultStyles.axisTitle?.font?.family || defaultStyles.font.family,
      fontSize: `${defaultStyles.axisTitle?.font?.size || defaultStyles.font.size}px`,
      color: defaultStyles.axisTitle?.font?.color || defaultStyles.font.color,
    },
  },
}

// Data for the left chart
const leftChartSeries = [
  {
    name: "จำนวนประชากรอายุเกิน 60 ปี",
    data: [
      0.2, 0.4, 0.4, 0.4, 0.4, 0.5, 0.5, 0.6, 0.6, 0.7, 0.8, 0.8, 0.9, 1.0, 1.1,
      1.2, 1.3, 1.4, 1.4,
    ],
    lineWidth: 2,
  },
  {
    name: "ค่าใช้จ่ายเฉลี่ยต่อหัว (แกนขวา)",
    data: [
      65257, 88919, 94703, 96527, 101131, 103124, 104076, 107518, 111857,
      113323, 115448, 120996, 137072, 141477, 146461, 150895, 155879, 161063,
      149761,
    ],
    dashStyle: "ShortDash",
    yAxis: 1,
    lineWidth: 2,
  },
];

// Data for the right chart
const rightChartSeries = [
  {
    name: "จำนวนผู้ได้รับเบี้ยยังชีพ",
    data: [
      6.785434, 7.309041, 7.664599, 7.774463, 8.021853, 8.158313, 8.380749,
      9.093916, 9.663169, 10.488013, 10.913245,
    ],
    lineWidth: 2,
  },
  {
    name: "เบี้ยยังชีพเฉลี่ยต่อหัว (แกนขวา)",
    data: [7742, 7983, 7959, 7991, 7881, 7941, 7924, 7908, 7894, 7561, 7545],
    dashStyle: "ShortDash",
    yAxis: 1,
    lineWidth: 2,
  },
];

const commonOptions = {
  yAxis: [
    {
      title: {
        text: "ล้านคน",
      },
      min: 0,
      labels: {
        format: "{value}",
      },
    },
    {
      title: {
        text: "บาท",
      },
      min: 0,
      opposite: true,
      labels: {
        format: "{value}",
      },
    },
  ].map(axis => deepmerge(defAxisOptions, axis)),
  tooltip: {
    shared: true,
    valueSuffix: "",
  },
  legend: {
    align: "center",
    verticalAlign: "bottom",
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
};

function LeftChart() {
  return (
    <LazyHighcharts
      options={{
        series: leftChartSeries,
        title: {
          text: "บำเหน็จบำนาญข้าราชการ",
        },
        xAxis: {
          categories: [
            "2004",
            "2005",
            "2006",
            "2007",
            "2008",
            "2009",
            "2010",
            "2011",
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
            "2017",
            "2018",
            "2019",
            "2020",
            "2021",
            "2022",
          ],
        },
        ...commonOptions,
      }}
    />
  );
}

function RightChart() {
  return (
    <LazyHighcharts
      options={{
        series: rightChartSeries,
        title: {
          text: "เบี้ยยังชีพ",
          // style: {
          //   fontSize: "16px",
          // },
        },
        xAxis: {
          categories: [
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
            "2017",
            "2018",
            "2019",
            "2020",
            "2021",
            "2022",
          ],
        },
        ...commonOptions,
      }}
    />
  );
}

export default function ComparisonCharts() {
  return (
    <div className="split-2">
      <LeftChart />
      <RightChart />
    </div>
  );
}
