import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

import Alt from "./alt/healthcare.png"

const rawData = [
  {name: "ค่าใช้จ่ายในการรักษาพยาบาล", amount: 139.3},
  {name: "การออกจากตลาดแรงงาน", amount: 1406.8},
  {name: "การขาดงาน", amount: 34.6},
  {name: "ประสิทธิภาพการทำงานที่ลดลง", amount: 56.9},
]

export default function Health() {
  return(
    <LazyHighcharts
      altImage={Alt}
      options={{
        series: [
          {
            name: "ต้นทุน",
            data: rawData.map((d, i) => ({
              name: d.name,
              y: d.amount,
            })),
            size: '80%',
            innerSize: '60%',
            dataLabels: {
              formatter: function() {
                return `${this.key}: <b>${this.y.toLocaleString()}</b> พลบ.`
              },
              style: {
                fontFamily: "Dindan",
                fontWeight: "normal",
                fontSize: "14px",
              },
            },
            tooltip: {
              headerFormat: "",
              pointFormatter: function() {
                return `${this.name}: <b>${this.y.toLocaleString()} พันล้านบาท</b> (${this.percentage.toFixed(1)}%)`
              }
            }
          },
        ],
        plotOptions: {
          column: {
            colorByPoint: true,
          },
        },
        chart: {
          type: "pie",
          height: "300px",
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )
}
