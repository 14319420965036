import React from "react"
import { SimpleChart, defaultStyles } from "../../../../src/components/Plots"
import chroma from "chroma-js"

export default function Blog100123Fig1({ data, type, categories, xtitle, ytitle, colorIndex, minmax }) {
  return (
    <SimpleChart
      type={type || "spline"}
      data={
        data.map((d, i) => ({
          name: `ปี ${2016 + i * 2}`,
          data: d,
          // color: defaultStyles.colors[colorIndex || 0],
          // opacity: [0.3, 0.5, 1][i],
          color:  chroma(defaultStyles.colors[colorIndex || 0]).alpha([0.35, 0.6, 1][i]).hex(),
        }))
      }
      categories={categories || ['10–19', '20–29', '30–39', '40–49', '50–59', '60–69', '70–79', '80–89', '90–99']}
      xtitle={xtitle}
      ytitle={ytitle}
      override={{
        yAxis: {
          min: minmax && minmax[0],
          max: minmax && minmax[1],
        }
      }}
    />
  )
}