import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"

const rawData = {
  // sd of inflation, inflation, sd of gdp growth
  "Thailand": {
    inflationSd: [2.913268192, 2.105977352],
    inflation: [4.599084708, 2.075115851],
    gdpSd: [4.876858521, 3.59809377]
  },
  "Indonesia": {
    inflationSd: [12.1215618, 3.456612436],
    inflation: [11.04952147, 5.491678113],
    gdpSd: [5.115841377, 2.147800258]
  },
  "Philippines": {
    inflationSd: [10.54475502, 2.029801779],
    inflation: [11.49356835, 3.853976627],
    gdpSd: [4.095535223, 3.985765999]
  },
}

const labels = {
  "inflationSd": "S.D. of headline inflation (%)",
  "inflation": "Headline inflation (%)",
  "gdpSd": "S.D. of real GDP growth (%)",
}

export default function Ab0724Fig2({ fig }) {

  let x, y

  if (fig === 'a') {
    x = "inflationSd"
    y = "inflation"
  }
  else if (fig === 'b') {
    x = "gdpSd"
    y = "inflationSd"
  }

  return (
    <LazyHighcharts
      options={{
        series: Object.entries(rawData).map(([country, data]) => ({
          name: country,
          type: 'scatter',
          data: [
            { x: data[x][0], y: data[y][0] },
            { x: data[x][1], y: data[y][1] },
          ],
          lineWidth: 0,
          marker: {
            radius: 8,
          }
        })),
        title: {
          text: fig === "a"
            ? `(a) Inflation level and volatility`
            : `(b) Inflation and GDP growth volatility`
        },
        xAxis: {
          title: {
            text: labels[x],
          },
          tickInterval: 2,
          min: 0,
        },
        yAxis: {
          title: {
            text: labels[y],
          },
          tickInterval: 2,
          min: 0,
        },
        chart: {
          events: {
            render: function() {
              function addArrow(obj, pre, post, index) {
                const xAxis = obj.xAxis[0]
                const yAxis = obj.yAxis[0]
                const preCoords = {x: xAxis.toPixels(pre.x), y: yAxis.toPixels(pre.y)}
                const postCoords = {x: xAxis.toPixels(post.x), y: yAxis.toPixels(post.y)}
                const distance = Math.sqrt((postCoords.x - preCoords.x) ** 2 + (postCoords.y - preCoords.y) ** 2)
                const angle = Math.atan2(postCoords.y - preCoords.y, postCoords.x - preCoords.x)
                const arrowLength = 8
                const offset = 8
                const calculatedPath = [
                  'M', preCoords.x + offset, preCoords.y,
                  'L', preCoords.x + distance - offset, preCoords.y,
                  'm', -arrowLength, -arrowLength,
                  'L', preCoords.x + distance - offset, preCoords.y,
                  'l', -arrowLength, arrowLength,
                ]
                const arrow = obj.renderer.path(calculatedPath).attr({
                  "stroke-width": 2,
                  transform: `rotate(${angle * 180 / Math.PI}, ${preCoords.x}, ${preCoords.y})`,
                  stroke: defaultStyles.colors[index],
                  zIndex: 20,
                }).add()
                obj.lines.push(arrow)
              }
              if (this.lines) {
                this.lines.map(x => x.element.remove())
              }
              this.lines = []
              // add arrow from pre to post
              Object.entries(rawData).forEach(([country, data], index) => {
                addArrow(this, { x: data[x][0], y: data[y][0] }, { x: data[x][1], y: data[y][1] }, index)
              })
            }
          },
        }
      }}
    />
  )
}