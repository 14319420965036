import CompareAeEm from "../../../../content/abridged/2023/05/graphs/CompareAeEm";
import Overall from "../../../../content/abridged/2023/05/graphs/Overall";
import Age from "../../../../content/abridged/2023/05/graphs/Age";
import Cohorts from "../../../../content/abridged/2023/05/graphs/Cohorts";
import AgeUsage from "../../../../content/abridged/2023/05/graphs/AgeUsage";
import Quintile from "../../../../content/abridged/2023/05/graphs/Quintile";
import Destinations from "../../../../content/abridged/2023/05/graphs/Destinations";
import Heatmap from "../../../../content/abridged/2023/05/graphs/Heatmap";
import Box from "../../../../content/abridged/2023/05/graphs/Box";
import WalletComposition from "../../../../content/abridged/2023/05/graphs/WalletComposition";
import * as React from 'react';
export default {
  CompareAeEm,
  Overall,
  Age,
  Cohorts,
  AgeUsage,
  Quintile,
  Destinations,
  Heatmap,
  Box,
  WalletComposition,
  React
};