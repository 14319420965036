import React from "react"
import { LazyHighcharts } from "../../../../src/components/Plots"

const rawData = [
  {
    name: "การผลิต",
    data: [440.61, 428.74, 428.08, 457.8, 475.04, 484.5, 460.2, 476.3],
  },
  {
    name: "ข้อมูลข่าวสารและการสื่อสาร",
    data: [264.86, 232.72, 384.41, 390.62, 417.58, 438.5, 450.3, 477.3],
  },
  {
    name: "การขนส่ง",
    data: [49.69, 64.13, 83.93, 108.28, 118.52, 136.3, 130.8, 139.3],
  },
  {
    name: "ศิลปะ ความบันเทิง และนันทนาการ",
    data: [9.35, 9.51, 15.46, 23.41, 12.71, 14.2, 16.4, 17.9],
  },
  {
    name: "ค้าปลีกและค้าส่ง",
    data: [252.63, 536.73, 713.69, 660.63, 1256.98, 1319.8, 1434.7, 1628.5],
  },
  {
    name: "การประกันภัย",
    data: [2.45, 2.09, 2.4, 3.02, 0.44, 1, 3.6, 7.5],
  },
  {
    name: "การให้บริการที่พัก",
    data: [630.16, 559.7, 607.9, 614.34, 905.68, 1010.7, 463.8, 324.6],
  },
  {
    name: "การบริการอื่น ๆ",
    data: [3.68, 19.1, 9.62, 8.92, 24.77, 26.3, 48.1, 52.2],
  },
]

export default function Stats() {

  const [showPercent, setShowPercent] = React.useState(false)
  const [showForecast, setShowForecast] = React.useState(false)

  return(
    <div>
			<div>
        <LazyHighcharts
          options={{
            series: rawData,
            plotOptions: {
              areaspline: {
                stacking: showPercent ? "percent" : "normal",
                marker: {
                  enabled: false,
                },
                pointStart: 2557,
              },
            },
            legend: {
              // enabled: false,
            },
            chart: {
              type: "areaspline",
              height: "600px",
            },
            xAxis: {
              crosshair: true,
              crossing: 0,
              max: showForecast ? 2564 : 2563,
            },
            tooltip: {
              formatter: function(tooltip) {
                let tmp = tooltip.defaultFormatter.call(this, tooltip);
                tmp = [tmp[0]].concat(tmp.slice(1).map((x, i) => x.replace(/<\/b>/, ` พันล้านบาท (${this.points[i]?.percentage?.toFixed(2)}%)</b>`)))
                return tmp
              },
              shared: true,
            },
            yAxis: {
              title: {
                text: showPercent ? "สัดส่วน (%)" : "มูลค่า (พันล้านบาท)"
              },
              reversedStacks: false,
            },
            responsive: {
              rules: [{
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    maxHeight: undefined,
                  },
                  chart: {
                    height: "70%",
                  },
                },
              }]
            }
          }}
        />
			</div>
			<div style={{textAlign: `center`, fontSize: `0.8rem`}}>
				<label>
					<input type="checkbox" onChange={() => setShowPercent(!showPercent)} defaultChecked={showPercent} /> แสดงสัดส่วน
				</label>
        <div style={{display: `inline-block`, width: `1rem`}} />
				<label>
          <input type="checkbox" onChange={() => setShowForecast(!showForecast)} defaultChecked={showForecast} /> แสดงประมาณการปี 2564
				</label>
			</div>
		</div>
  )

}