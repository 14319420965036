import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"

const x = {
  textAlign: "left",
  paddingLeft: "2rem",
}
const sectionStyle = {
  textAlign: "left",
  fontStyle: "italic",
}

const data = {
  'Primary': [
    [89.6, 9.2, 1.3],
    [85.5, 12.6, 2.0],
    [83.6, 14.5, 1.8],
    [82.0, 16.5, 1.5],
  ],
  'Lower secondary': [
    [63.3, 26.9, 9.8],
    [68.3, 25.1, 6.6],
    [72.7, 23.4, 3.9],
    [71.7, 25.5, 2.8],
  ],
  'Upper secondary': [
    [41.9, 41.4, 16.7],
    [50.6, 36.3, 13.0],
    [59.1, 32.0, 8.9],
    [58.4, 34.1, 7.5],
  ],
  'Some college': [
    [22.6, 40.3, 37.1],
    [27.0, 41.7, 31.3],
    [38.6, 38.1, 23.4],
    [46.7, 36.7, 16.6],
  ],
  'College': [
    [4.1, 13.7, 82.2],
    [5.1, 18.4, 76.5],
    [9.3, 29.3, 61.4],
    [11.7, 32.7, 55.5],
  ],
  'Total': [
    [80.7, 13.0, 6.3],
    [72.5, 17.7, 9.8],
    [67.1, 21.4, 11.5],
    [61.6, 25.3, 13.1],
  ],
}

const skillLabels = ['Low skill', 'Medium skill', 'High skill']
const yearLabels = ['1985–1994', '1995–2004', '2005–2014', '2015–2022']

const processedData = []
// add fake data
for (let skillIndex = 0; skillIndex < 3; skillIndex ++) {
  processedData.push({
    name: skillLabels[skillIndex],
    data: [0, 0, 0],
    color: defaultStyles.colors[skillIndex],
    id: skillLabels[skillIndex],
    xAxis: 0,
  })
}
Object.keys(data).forEach((educationGroup, i) => {
  for (let skillIndex = 0; skillIndex < 3; skillIndex ++) {
    processedData.push({
      name: skillLabels[skillIndex],
      data: data[educationGroup].map(row => row[skillIndex]),
      color: defaultStyles.colors[skillIndex],
      xAxis: i,
      // id: i === 0 ? skillLabels[skillIndex] : undefined,
      // linkedTo: i > 0 ? skillLabels[skillIndex] : undefined,
      linkedTo: skillLabels[skillIndex],
    })
  }
})

const numEducationGroups = Object.keys(data).length

const titleMargin = 6

export default function Ab2023Table() {
  return(
    <LazyHighcharts
      options={{
        chart: {
          type: 'bar',
          height: '680px',
        },
        plotOptions: {
          bar: {
            stacking: 'percent',
            grouping: false,
          },
        },
        xAxis: Array.from(Array(numEducationGroups).keys()).map(i => ({
          offset: 0,
          top: `${i * 100 / numEducationGroups + titleMargin}%`,
          height: `${100 / numEducationGroups - titleMargin}%`,
          categories: yearLabels,
          labels: {
            style: {
              fontFamily: "Dindan",
            },
          },
        })),
        yAxis: {
          reversedStacks: false,
          title: {
            text: "สัดส่วน",
          },
        },
        series: processedData,
        annotations: [{
          draggable: '',
          labels: Array.from(Array(numEducationGroups).keys()).map(i => ({
            point: {
              xAxis: i,
              x: 0,
              y: 0,
            },
            text: Object.keys(data)[i],
            y: -7,
            crop: false,
          }))
        }],
      }}
    />
  )
  
// //   // table
//   return(
//     <div className="table-wrapper">
//     <table style={{minWidth: '450px'}}>
//       <thead>
//         <tr>
//           <th>% Share</th>
//           <th>Low skill</th>
//           <th>Middle skill</th>
//           <th>High skill</th>
//         </tr>
//       </thead>
//       <tbody>
//         {
//           Object.keys(data).map(section => (
//             <React.Fragment key={section}>
//               <tr>
//                 <td colSpan={4} style={sectionStyle}>{section}</td>
//               </tr>
//               {data[section].map((row, j) => (
//                 <tr key={j} className={j === data[section].length - 1 ? "hr" : ""}>
//                   <>
//                     <td style={x} dangerouslySetInnerHTML={{__html: yearLabels[j].replace(/^(.*?) \(/, '<strong>$1</strong> (')}} />
//                     {[0, 1, 2].map(i => <td key={i}>{row[i]}</td>)}
//                   </>
//                 </tr>  
//               ))}
//             </React.Fragment>
//           ))
//         }
//       </tbody>
//     </table>
//     </div>
//   )
}