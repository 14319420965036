import React from "react"
import { defaultStyles, LazyHighcharts } from "../../../../../src/components/Plots"
import chroma from 'chroma-js'

const rawData = {
  AE: [
    {
      name: "Credit transfers",
      data: [61.5961070426,65.6968538209,68.745552083,70.0471507084,73.8433624378,77.1492897197,81.2636896851,85.6428759979,90.3057261084],
    },
    {
      name: "Direct debits",
      data: [42.1956833446,43.3457853209,44.323486748,45.7789960452,46.4297732837,49.4512622694,50.6516083692,52.2903550148,53.9546893116],
    },
    {
      name: "Cheques",
      data: [13.4359956543,12.5012004657,11.7269649635,10.8801604458,9.6463041564,8.8621152413,7.9229475278,7.0148771919,5.7444342062],
    },
    {
      name: "Card payments",
      data: [139.861865449,150.5213321668,162.8086126813,175.5354852101,192.7958725599,210.1920732717,231.8566611021,251.2671559619,250.1168164604],
    },
  ],
  EM: [
    {
      name: "Credit transfers",
      data: [14.1435446706,15.8281031534,16.9837627374,18.5821309917,19.7913206105,21.7542343829,24.1532860943,26.836866122,32.0433349519],
    },
    {
      name: "Direct debits",
      data: [7.4082162576,7.910609162,8.203836544,8.0814913951,8.1427160066,8.0741187147,8.557249653,8.4271065805,9.5182961127],
    },
    {
      name: "Cheques",
      data: [3.5061580027,3.1439449839,2.8633487041,2.5707611276,2.2662742773,2.0173374633,1.789252982,1.5493110874,1.083577001],
    },
    {
      name: "Card payments",
      data: [87.1934211868,97.8521095356,105.8700869768,115.8393377016,128.9807603588,143.3189239022,164.2652241129,190.1824524604,182.6355208212],
    },
  ],
}

export default function CompareAeEm({ type }) {

  return(
    <LazyHighcharts
      options={{
        series: rawData[type].map((series, i) => ({
          ...series,
          color: chroma(defaultStyles.colors[type === "AE" ? 1 : 0]).brighten(i * 0.5).hex(),
        })),
        plotOptions: {
          column: {
            stacking: "normal",
          },
          series: {
            pointStart: 2012,
          },
        },
        legend: {
          // enabled: false,
        },
        title: {
          text: type === "AE" ? "กลุ่มประเทศรายได้สูง" : "กลุ่มประเทศกำลังพัฒนา",
        },
        chart: {
          type: "column",
          height: "300px",
        },
        xAxis: {
        },
        tooltip: {
          valueDecimals: 2,
          // formatter: function(tooltip) {
          //   let tmp = tooltip.defaultFormatter.call(this, tooltip);
          //   return tmp
          // }
        },
        yAxis: {
          title: {
            text: "ปริมาณธุรกรรมต่อประชากร 1,000 คน"
          },
          reversedStacks: false,
          max: 500,
          alignTicks: false,
          tickInterval: 100,
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 320,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "350px",
              },
            },
          }]
        }
      }}
      split={2}
    />
  )

}