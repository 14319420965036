import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = [
  {
    name: "ใข้ทุกเดือน",
    "2020m7": [283567,4851692,5102309,4097822,2300674,1248017],
    "2020m8": [28222,94879,61382,62219,48220,34930],
    "2020m9": [22140,58448,39357,38688,26575,13354],
    "2020m10": [20730,53397,42748,49147,38248,27637],
    "2020m11": [26600,53152,42113,50446,39918,19705],
    "2020m12": [24151,46414,36572,41575,32102,16047],
    "2021m1": [25571,51917,40145,43035,33289,17279],
    "2021m2": [26062,48958,40385,42584,31194,14680],
    "2021m3": [29815,46349,37721,39672,28314,13419],
    "2020q4": [71481,152963,121433,141168,110268,63389],
  },
  {
    name: "ใช้มากกว่าครึ่งหนึ่งของเดือนที่เห็น",
    "2020m7": [138922,1032585,713512,568256,391482,257215],
    "2020m8": [29079,92851,63468,65204,57218,42956],
    "2020m9": [20565,52208,34516,35193,30691,22272],
    "2020m10": [17185,40048,30041,36055,34828,28466],
    "2020m11": [18771,35415,25835,32817,34547,28471],
    "2020m12": [15668,26725,19077,23288,23423,19404],
    "2021m1": [14255,29294,21195,27076,29741,26601],
    "2021m2": [12598,25160,18846,21750,20765,15921],
    "2021m3": [14233,22708,17932,22052,23075,15318],
    "2020q4": [51624,102188,74953,92160,92798,76341],
  },
  {
    name: "ใช้น้อยกว่าครึ่งหนึ่งของเดือนที่เห็น",
    "2020m7": [19241,194752,173304,141887,110811,82519],
    "2020m8": [6254,28833,26147,26999,27944,25228],
    "2020m9": [4036,15216,13509,14734,15367,15074],
    "2020m10": [3765,12862,12702,15593,18024,18032],
    "2020m11": [3162,9452,9463,12132,15594,16482],
    "2020m12": [3330,8249,7770,9955,11840,12867],
    "2021m1": [2492,7363,6894,9131,12120,12926],
    "2021m2": [2640,8166,7221,9004,10638,11127],
    "2021m3": [2385,5881,5522,7443,9605,8291],
    "2020q4": [10257,30563,29935,37680,45458,47381],
  },
  {
    name: "ใช้เดือนเดียว",
    "2020m7": [1803,21612,21739,19376,17596,14416],
    "2020m8": [841,4862,5146,5999,6900,7061],
    "2020m9": [729,2968,3356,3924,4543,4343],
    "2020m10": [740,2394,2740,3695,4639,4934],
    "2020m11": [582,2153,2617,3933,5386,6126],
    "2020m12": [634,1839,2089,2832,3843,4474],
    "2021m1": [625,1886,2271,3689,5514,6951],
    "2021m2": [644,2153,2568,3853,5974,8048],
    "2021m3": [817,2122,3058,7996,19337,11242],
    "2020q4": [1956,6386,7446,10460,13868,15534],
  },
]


export default function AgeUsage({ cohort }) {

  const [showPercent, setShowPercent] = React.useState(true)

  return(
    <div>
			<div>
        <LazyHighcharts
          options={{
            series: rawData.map(freq => ({
              name: freq.name,
              data: freq[cohort],
            })),
            plotOptions: {
              column: {
                stacking: showPercent ? "percent" : "normal",
                dataLabels: {
                  enabled: showPercent,
                  formatter: function() {
                    return `${this.percentage.toFixed(0)}%`
                  },
                },
              },
            },
            legend: {
              // enabled: false,
            },
            chart: {
              type: "column",
              height: "300px",
            },
            xAxis: {
              categories: ["15–19", "20–29", "30–39", "40–49", "50–59", "≥ 60"]
            },
            tooltip: {
              formatter: function(tooltip) {
                let tmp = tooltip.defaultFormatter.call(this, tooltip);
                if (showPercent)
                  tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.percentage.toFixed(2)}%</b>`)
                return tmp
              }
            },
            yAxis: {
              title: {
                text: showPercent ? "สัดส่วนของกลุ่มอายุ (%)" : "จำนวนผู้ใช้บริการ"
              },
              reversedStacks: false,
            },
            responsive: {
              rules: [{
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    maxHeight: undefined,
                  },
                  chart: {
                    height: "50%",
                  },
                },
              }]
            }
          }}
        />
			</div>
			<div style={{textAlign: `center`, fontSize: `0.8rem`}}>
				<label>
					<input type="checkbox" onChange={() => setShowPercent(!showPercent)} defaultChecked={showPercent} /> แสดงสัดส่วนของกลุ่ม
				</label>
			</div>
		</div>
  )

}