import React from 'react'
import { LazyChartjs, defaultStyles } from '../../../../src/components/Plots'
import chroma from 'chroma-js'

import styles from "./styles.module.scss"

const areaAlpha = defaultStyles.areaAlpha
const usColor = '#0A3161'
const cnColor = '#df2407'

function Pyramid({ title, data, ...rest }) {

  function alpharize(color, alpha=areaAlpha) {
    return [
      ...Array(6).fill(chroma(color).alpha(0.3*alpha).hex()),
      ...Array(3).fill(chroma(color).alpha(alpha).hex()),
    ]
  }

  return(
    <LazyChartjs
      type="bar"
      style={{minWidth: "0", width: "100%", minHeight: "300px"}}
      data={{
        labels: [
          "≤ 25",
          ...[...Array(7).keys()].map(x => `${5*x+26}–${5*x+30}`),
          "> 60"
        ],
        datasets: [
          {
            label: "หญิง",
            data: data.female,
            backgroundColor: alpharize(cnColor),
          },
          {
            label: "ชาย",
            data: data.male?.map(x => -1*x),
            backgroundColor: alpharize(usColor),
          },
          {
            label: "หญิง (ต่างชาติ)",
            data: data.femaleForeign,
            backgroundColor: alpharize(defaultStyles.colors[0]),
          },
          {
            label: "ชาย (ต่างชาติ)",
            data: data.maleForeign?.map(x => -1*x),
            backgroundColor: alpharize(defaultStyles.colors[0]),
          }
        ]
      }}
      options={{
        // aspectRatio: 0.6,
        maintainAspectRatio: false,
        layout: {
          padding: 0
        },
        indexAxis: 'y',
        scales: {
          y: {
            stacked: true,
            reverse: true,
            ticks: {
              color: alpharize(defaultStyles.font.color, 1),
            }
          },
          x: {
            title: {
              display: true,
              text: "จำนวน (ราย)",
            },
            stacked: true,
            min: -1e6,
            max: 1e6,
            ticks: {
              callback: (value, index) => {
                if (value % 5e5 === 0)
                  return Math.abs(value)
                return null
              }
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              highlight: {
                type: 'box',
                yMin: 5.5,
                yMax: 8.5,
                backgroundColor: chroma('black').alpha(0.2).hex(),
                borderColor: 'rgba(255, 255, 255, 0)',
                drawTime: 'beforeDraw'
              }
            }
          }
        }
      }}
      {...rest}
    />
  )


}

export default function Aging() {
  return(
    <div className={styles.aging}>
      <Pyramid
        title="2002 (3.42%)"
        data={{
          male: [721554, 566617, 446071, 305765, 191807, 117316, 66743, 29584, 13721],
          female: [868050, 605420, 456874, 298911, 174206, 87170, 38207, 13422, 4504],
          maleForeign: [813, 2336, 4140, 4783, 4155, 3426, 3117, 1741, 672],
          femaleForeign: [364, 664, 649, 460, 344, 203, 121, 57, 31],
        }}
      />
      <Pyramid
        title="2009 (5.84%)"
        data={{
          male: [689001, 803854, 671193, 506708, 374290, 244438, 142516, 69234, 24534],
          female: [741137, 813156, 646882, 498837, 362407, 221357, 109873, 42109, 12807],
          maleForeign: [5169, 6161, 6802, 7733, 7866, 6313, 4532, 3314, 1501],
          femaleForeign: [3612, 2923, 2037, 1728, 1072, 630, 354, 144, 59],
        }}
      />
      <Pyramid
        title="2019 (9.91%)"
        data={{
          male: [715941, 736427, 653627, 664370, 539958, 412479, 294215, 144834, 51606],
          female: [780933, 781834, 674107, 659776, 514249, 390667, 262010, 114599, 35804],
          maleForeign: [146626, 131895, 115796, 84238, 48089, 28214, 14476, 5103, 2417],
          femaleForeign: [110226, 96483, 80600, 55819, 27738, 14753, 5430, 715, 178],
        }}
      />
    </div>
  )
}