import React from 'react'
import { LazyChartjs, defaultStyles } from '../../../../src/components/Plots'
import chroma from 'chroma-js'

export default function Cohesion() {

  const areaAlpha = defaultStyles.areaAlpha

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "400px"}}
      data={{
        labels: [
          "ความรู้สึกมั่นคงทางเศรษฐกิจ",
          "ความรู้สึกมั่นคงทางสังคม",
          "การเข้าถึงตลาดแรงงาน",
          "การเข้าถึงการศึกษา",
          "ความเชื่อใจทางสังคม",
          "ความเชื่อมั่นในองค์กร",
          "ความสามัคคี",
          "ความอิสระในการกำหนดเส้นทางชีวิต",
          "การมีส่วนร่วมในกิจกรรมการเมือง",
          "ความสามารถในการเปลี่ยนแปลงสังคม"
        ],
        datasets: [
          {
            label: "ค่าเฉลี่ยของกลุ่มตัวอย่าง",
            data: [78.01819, 72.15211, 51.61855, 48.9426, 43.25326, 33.90756, 30.442, 72.68779, 64.38076, 60.83162],
            backgroundColor: [
              ...Array(2).fill(chroma(defaultStyles.colors[1]).alpha(defaultStyles.dimFactor*areaAlpha).hex()),
              ...Array(2).fill(chroma(defaultStyles.colors[2]).alpha(defaultStyles.dimFactor*areaAlpha).hex()),
              ...Array(3).fill(chroma(defaultStyles.colors[0]).alpha(areaAlpha).hex()),
              ...Array(3).fill(chroma(defaultStyles.colors[3]).alpha(defaultStyles.dimFactor*areaAlpha).hex()),
            ],
            borderColor: `none`,
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            max: 100,
            title: {
              display: true,
              text: "ดัชนี"
            }
          },
          y: {
            ticks: {
              color: [
                ...Array(4).fill(chroma(defaultStyles.font.color).alpha(defaultStyles.dimFactor).hex()),
                ...Array(3).fill(defaultStyles.font.color),
                ...Array(3).fill(chroma(defaultStyles.font.color).alpha(defaultStyles.dimFactor).hex()),
              ]
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              highlight: {
                type: 'box',
                yMin: 3.5,
                yMax: 6.5,
                backgroundColor: chroma('black').alpha(0.2).hex(),
                borderColor: 'rgba(255, 255, 255, 0)',
                drawTime: 'beforeDraw'
              }
            }
          }
        }
      }}
    />
  )


}