import React from "react"
import { LazyHighcharts } from "../../../../src/components/Plots"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useMediaQuery from '@mui/material/useMediaQuery';

const regionNames = {
  bkk: 'กรุงเทพฯ',
  c: 'ภาคกลาง',
  e: 'ภาคตะวันออก',
  n: 'ภาคเหนือ',
  ne: 'ภาคตะวันออกเฉียงเหนือ',
  s: 'ภาคใต้',
}

const rawData = [
  {
    name: '≤ 7,800',
    data: {
      bkk: [94486,112284,65323,45608,20343],
      c: [69484,122047,76998,45592,17427],
      e: [39957,61484,27218,13299,4338],
      n: [17668,45129,34808,25147,11482],
      ne: [20501,42797,30501,25547,9121],
      s: [15430,41335,33279,22258,7377],
    },
  },
  {
    name: '7,801–10,400',
    data: {
      bkk: [129812,257227,184077,142849,57693],
      c: [161189,375131,257263,160526,53620],
      e: [44282,92731,58795,36081,11913],
      n: [29648,98643,71401,52395,22692],
      ne: [44103,123310,95391,79904,27105],
      s: [28648,89263,67589,45516,15290],
    },
  },
  {
    name: '10,401–13,000',
    data: {
      bkk: [124756,325547,207244,139710,50195],
      c: [58692,200497,145186,95685,29392],
      e: [38054,123101,64865,36777,10799],
      n: [9648,51563,34961,19882,7151],
      ne: [12367,57644,43027,27639,8184],
      s: [12937,60834,43169,25016,6902],
    },
  },
  {
    name: '13,001–14,999',
    data: {
      bkk: [27600,125984,80332,49131,15141],
      c: [11423,79080,68179,41107,11281],
      e: [8008,56094,33967,16193,3855],
      n: [1928,20167,19792,11429,4007],
      ne: [2657,26389,27066,16105,5295],
      s: [2031,18905,19039,10986,3241],
    },
  },
  {
    name: '≥ 15,000',
    data: {
      bkk: [77207,868514,820602,539471,201455],
      c: [21318,266686,331022,248428,75243],
      e: [11566,160412,210974,115377,27026],
      n: [4368,60167,71551,49514,17921],
      ne: [4385,66821,85224,55281,19023],
      s: [4031,58495,79298,54466,17511],
    },
  },
]



function Region() {

  const [showPercent, setShowPercent] = React.useState(false)

  function handleClick() {
    setShowPercent(!showPercent)
  }

  return(
    <div>
      <LazyHighcharts
        options={{
          chart: {
            type: 'column',
            height: 400,
          },
          xAxis: {
            categories: Object.values(regionNames),
          },
          yAxis: {
            min: 0,
            title: {
              text: showPercent ? 'สัดส่วน' : 'จำนวน (คน)'
            },
            stackLabels: {
              enabled: !showPercent,
            },
          },
          legend: {
            reversed: true,
          },
          tooltip: {
            formatter: function(tooltip) {
              let tmp = tooltip.defaultFormatter.call(this, tooltip);
              tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.y.toLocaleString()} (${this.percentage.toFixed(1)}%)</b>`)
              return tmp
            }
          },
          plotOptions: {
            column: {
              stacking: showPercent ? 'percent' : 'normal',
            }
          },
          series: rawData.map((wageBin, i) => ({
            name: wageBin.name,
            data: Object.keys(wageBin.data).map(region => wageBin.data[region].reduce((a, b) => a + b, 0)),
          })).reverse(),
          responsive: {
            rules: [{
              condition: {
                minWidth: 700,
              },
              chartOptions: {
                chart: {
                  height: "50%",
                },
              },
            }]
          }
        }}
      />
      <div style={{textAlign: `center`, fontSize: `0.8rem`}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showPercent}
              onChange={handleClick}
            />
          }
          label="แสดงสัดส่วนของกลุ่ม"
        />
			</div>
		</div>
  )
}

function Age() {

  const [showPercent, setShowPercent] = React.useState(false)
  const [region, setRegion] = React.useState('bkk')
  const smallScreen = useMediaQuery('(max-width:600px)');

  function handleClick() {
    setShowPercent(!showPercent)
  }
  function handleRegion(e) {
    setRegion(e.target.value)
  }

  return(
    <div>
      <LazyHighcharts
        options={{
          chart: {
            type: 'areaspline',
            height: 400,
          },
          xAxis: {
            categories: ["< 25", "25–34", "35–44", "45–54", "≥ 55"],
          },
          yAxis: {
            min: 0,
            title: {
              text: showPercent ? 'สัดส่วน' : 'จำนวน (คน)'
            },
            stackLabels: {
              enabled: !showPercent,
            },
          },
          legend: {
            reversed: true,
          },
          tooltip: {
            formatter: function(tooltip) {
              let tmp = tooltip.defaultFormatter.call(this, tooltip);
              tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.y.toLocaleString()} (${this.percentage.toFixed(1)}%)</b>`)
              return tmp
            }
          },
          plotOptions: {
            areaspline: {
              stacking: showPercent ? 'percent' : 'normal',
            }
          },
          series: rawData.map((wageBin, i) => ({
            name: wageBin.name,
            data: wageBin.data[region],
          })).reverse(),
          responsive: {
            rules: [{
              condition: {
                minWidth: 700,
              },
              chartOptions: {
                chart: {
                  height: "50%",
                },
              },
            }]
          },
        }}
      />
      <div style={{textAlign: `center`, fontSize: `0.8rem`}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showPercent}
              onChange={handleClick}
            />
          }
          label="แสดงสัดส่วนของกลุ่ม"
        />
        <ToggleButtonGroup
          value={region}
          exclusive
          onChange={handleRegion}
          size="small"
        >
          {Object.keys(rawData[0].data).map(region => (
            <ToggleButton key={region} value={region} aria-label={region}>{smallScreen ? region.toUpperCase() : regionNames[region]}</ToggleButton>
          ))}
        </ToggleButtonGroup>
			</div>
		</div>
  )
}

export default { Region, Age }