import React from "react"
import { LazyPlotly } from '../../../../src/components/Plots'


var gddebt = {
  x: ['2015'],
  y: [25.82],
  name: 'Good borrowers',
  marker: {color: 'rgb(44, 123, 191)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var drghsp = {
  x: ['2016', '2017'],
  y: [34.58, 15.12],
  name: 'Drought 2015 BAAC',
  marker: {color: 'rgb(181, 62, 62)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var drgh22 = {
  x: ['2016', '2017'],
  y: [5.235, 1.329],
  name: 'Drought 2015 22 provinces',
  marker: {color: 'rgb(250, 188, 2)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var rice = {
  x: ['2017', '2018'],
  y: [19.79, 22.95],
  name: 'Rice farmers',
  marker: {color: 'rgb(64, 115, 245)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var covid = {
  x: ['2020', '2021'],
  y: [0.001, 59.07],
  name: 'Covid-19',
  marker: {color: 'rgb(49, 158, 95)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var oth = {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021'],
  y: [0.038, 2.585, 10.68, 9.234, 0.532, 3.678, 7.191],
  name: 'Other DM or other mixes',
  marker: {color: 'rgb(82, 81, 81)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}'+'%'
};

var notuse = {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021'],
  y: [4.831, 16.47, 6.367, 1.196, 7.230, 5.801, 8.896],
  name: 'DM eligible, but not exploit',
  marker: {color: 'rgb(179, 179, 179)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var nodm= {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021'],
  y: [69.29, 41.11, 30.95, 52.20, 11.27, 29.85, 13.92],
  name: 'Not receive DM',
  marker: {color: 'rgb(235, 235, 235)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var drghrice= {
  x: ['2017'],
  y: [15.75],
  name: 'Drought 2015 + Rice farmers',
  marker: {color: 'rgb(134, 71, 186)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var pracharat= {
  x: ['2019', '2020', '2021'],
  y: [59.88, 16.92, 0.367],
  name: 'Pracharat',
  marker: {color: 'rgb(95, 103, 212)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var lowincpracharat= {
  x: ['2019', '2020', '2021'],
  y: [8.796, 2.122, 0.006],
  name: 'Pracharat + Low income',
  marker: {color: 'rgb(34, 42, 153)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var lowinc= {
  x: ['2018', '2019', '2020', '2021'],
  y: [14.41, 12.28, 5.453, 0.009],
  name: 'Low income',
  marker: {color: 'rgb(117, 192, 217)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var flood62= {
  x: ['2020', '2021'],
  y: [16.84, 10.52],
  name: 'Flood 2019',
  marker: {color: 'rgb(222, 99, 42)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var drgh62= {
  x: ['2020', '2021'],
  y: [ 19.31, 0.000],
  name: 'Drought 2019',
  marker: {color: 'rgb(235, 151, 42)'},
  type: 'bar',
  hovertemplate: '%{y:.1f}%'
};

var data = [gddebt, drghsp, drgh22, rice, drghrice, lowinc, pracharat, lowincpracharat, drgh62, flood62, covid, oth, notuse, nodm];

var layout = {
  xaxis: {
    type: 'category'
  },
  yaxis: {
    title: '% Borrowers'
  },
  barmode: 'stack',
};

export default function Graph() {
  return <LazyPlotly data={data} layout={layout} />
}

