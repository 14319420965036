import React from "react"
import { LazyHighcharts } from "../../../../src/components/Plots"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const data = {
  increasing: {
    name: "Increasing pensionable age",
    data: [{
      name: 'Australia',
      data: [[1949, 65.0], [1958, 65.0], [1971, 65.0], [1983, 65.0], [1989, 65.0], [1993, 65.0], [1999, 65.0], [2002, 65.0], [2010, 65.0], [2020, 66.0]]
    }, {
      name: 'Czech Republic',
      data: [[1958, 60], [1971, 60], [1983, 60], [1989, 60], [1993, 60], [1999, 60], [2002, 60.5], [2010, 61], [2020, 63.6666666]
      ]
    }, {
      name: 'Germany',
      data: [[1949, 63], [1958, 63], [1971, 63], [1983, 63], [1989, 63], [1993, 63], [1999, 63], [2002, 63.5], [2010, 65], [2020, 65.666666]
      ]
    }, {
      name: 'Greece',
      data: [[1949, 55], [1958, 57], [1971, 57], [1983, 57], [1989, 57], [1993, 57], [1999, 57], [2002, 57], [2010, 57], [2020, 62]
      ]
    }, {
      name: 'Hungary',
      data: [[1949, 60], [1958, 60], [1971, 60], [1983, 60], [1989, 60], [1993, 60], [1999, 60], [2002, 60], [2010, 60], [2020, 64.5]
      ]
    }, {
      name: 'Japan',
      data: [[1958, 60], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]
      ]
    }, {
      name: 'Korea',
      data: [[1993, 60], [1999, 60], [2002, 60], [2010, 60], [2020, 62]
      ]
    }, {
      name: 'Poland',
      data: [[1949, 60], [1958, 60], [1971, 60], [1983, 60], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]
      ]
    }, {
      name: 'Slovak Republic',
      data: [[1958, 60], [1971, 60], [1983, 60], [1989, 60], [1993, 60], [1999, 60], [2002, 60], [2010, 62], [2020, 62.8333333333333]
      ]
    }, {
      name: 'United Kingdom',
      data: [[1949, 65], [1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 66]
      ]
    }, {
      name: 'United States',
      data: [[1949, 65], [1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 66], [2020, 66]]
    }, {
      name: 'Belgium',
      data: [[1949, 60], [1958, 60], [1971, 60], [1983, 60], [1989, 60], [1993, 60], [1999, 60], [2002, 60], [2010, 60], [2020, 65]]
    }, {
      name: 'Netherlands',
      data: [[1949, 65], [1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 66.33333]]
    }, {
      name: 'Portugal',
      data: [[1949, 65], [1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65.33333]]
    }]
  },
  level: {
    name: "Level pensionable age",
    data: [{
      name: 'Austria',
      data: [[1949, 65], [1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]]
    }, {
      name: 'Finland',
      data: [[1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]]
    }, {
      name: 'Iceland',
      data: [[1958, 67], [1971, 67], [1983, 67], [1989, 67], [1993, 67], [1999, 67], [2002, 67], [2010, 67], [2020, 67]]
    }, {
      name: 'Mexico',
      data: [[1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]]
    }, {
      name: 'Spain',
      data: [[1949, 65], [1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]]
    }, {
      name: 'Switzerland',
      data: [[1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]]
    }]
  },
  falling: {
    name: "Falling pensionable age",
    data: [{
      name: 'Canada',
      data: [[1949, 70], [1958, 69], [1971, 68], [1983, 67], [1989, 66], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]]
    }, {
      name: 'Norway',
      data: [[1949, 70], [1958, 70], [1971, 70], [1983, 70], [1989, 67], [1993, 67], [1999, 67], [2002, 67], [2010, 67], [2020, 67]]
    }, {
      name: 'Sweden',
      data: [[1949, 67], [1958, 67], [1971, 67], [1983, 67], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 65]]
    }]
  },
  other: {
    name: 'Other patterns of pensionable age',
    data: [{
      name: 'Denmark',
      data: [[1949, 65], [1958, 65], [1971, 67], [1983, 67], [1989, 67], [1993, 67], [1999, 67], [2002, 67], [2010, 65], [2020, 65.5]]
    }, {
      name: 'Ireland',
      data: [[1949, 70], [1958, 70], [1971, 70], [1983, 70], [1989, 65], [1993, 65], [1999, 65], [2002, 65], [2010, 65], [2020, 66]]
    }, {
      name: 'Luxembourg',
      data: [[1949, 65], [1958, 65], [1971, 65], [1983, 65], [1989, 65], [1993, 60], [1999, 60], [2002, 60], [2010, 60], [2020, 62]]
    }, {
      name: 'France',
      data: [[1958, 65], [1971, 65], [1983, 65], [1989, 60], [1993, 60], [1999, 60], [2002, 60], [2010, 60.5], [2020, 64.5]]
    }, {
      name: 'Italy',
      data: [[1949, 60], [1958, 60], [1971, 60], [1983, 55], [1989, 55], [1993, 55], [1999, 55], [2002, 57], [2010, 59], [2020, 62]]
    }, {
      name: 'New Zealand',
      data: [[1949, 65], [1958, 60], [1971, 60], [1983, 60], [1989, 60], [1993, 60], [1999, 61.1], [2002, 64.1], [2010, 65], [2020, 65]]
    }, {
      name: 'Turkey',
      data: [[1971, 60], [1983, 45], [1989, 45], [1993, 45], [1999, 45], [2002, 44], [2010, 44.9], [2020, 52]]
    }]
  }
}

export default function F1() {

  const [toShow, setToShow] = React.useState("increasing") //"increasing","level", "falling","other"

  return (

    <div>

      <LazyHighcharts
        options={{
          title: {
            text: data[toShow].name,
            align: 'center'
          },
          yAxis: {
            title: {
              text: 'Pensionable age'
            }
          },
          xAxis: {
            accessibility: {
              rangeDescription: 'Year'
            }
          },
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false
              },
            }
          },
          series: JSON.parse(JSON.stringify(data[toShow].data)),
          responsive: {
            rules: [{
              condition: {
                minWidth: 700,
              },
              chartOptions: {
                legend: {
                  align: "right",
                  verticalAlign: "middle",
                  layout: "vertical",
                  maxHeight: undefined,
                },
                chart: {
                  // height: "70%",
                },
              },
            }]
          }
        }}
      />

      <div style={{textAlign: "center"}}>
        <ToggleButtonGroup
          value={toShow}
          size="small"
          exclusive
          onChange={(e, v) => {
            if (v) {
              setToShow(v)
            }
          }}
        >
          {Object.keys(data).map((key) => <ToggleButton value={key} key={key}>{key}</ToggleButton>)}
        </ToggleButtonGroup>
      </div>

    </div>

  )

}