import React from "react"
import thMap from "@highcharts/map-collection/countries/th/th-all.topo.json";
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"
import chroma from "chroma-js";

const data = [
  ["th-bm",20.4,10.2],
  ["th-sp",31.6,10.2],
  ["th-no",30.2,10.2],
  ["th-pt",33.7,10.2],
  ["th-pa",35.5,14.3],
  ["th-at",56.9,17.3],
  ["th-lb",38.6,14.6],
  ["th-sb",52.9,17.3],
  ["th-cn",56.2,17.3],
  ["th-sr",39.3,14.3],
  ["th-cb",29.4,10.8],
  ["th-ry",24.8,10.8],
  ["th-ct",43.5,15.9],
  ["th-tt",46.9,15.3],
  ["th-cc",36,14.3],
  ["th-pb",39.4,14.3],
  ["th-nn",60.3,14.9],
  ["th-sk",42.1,15.9],
  ["th-nr",49.8,13.6],
  ["th-br",50,15.9],
  ["th-su",55,16.3],
  ["th-si",48.9,17.6],
  ["th-ur",48.4,15.9],
  ["th-ys",57.7,16.6],
  ["th-cy",52,17.3],
  ["th-ac",54.8,17.6],
  ["th-bk",49.8,17],
  ["th-nb",52.2,17.6],
  ["th-kk",46.7,14.3],
  ["th-un",45.3,17.6],
  ["th-le",44.9,17.6],
  ["th-nk",49.9,14.9],
  ["th-ms",52.4,17.6],
  ["th-re",50.2,17],
  ["th-kl",47.3,17],
  ["th-sn",51.2,15.9],
  ["th-nf",51,15.9],
  ["th-md",53.3,15.9],
  ["th-cm",41.8,14.3],
  ["th-ln",50.1,16.6],
  ["th-lg",53.4,17.6],
  ["th-ud",51.5,17.6],
  ["th-pr",57.7,18.3],
  ["th-na",54.9,18.3],
  ["th-py",48.3,18.3],
  ["th-cr",53.4,15.9],
  ["th-mh",48.9,17.6],
  ["th-ns",53.8,16.6],
  ["th-ut",49.4,17.6],
  ["th-kp",54.8,17.6],
  ["th-tk",59.3,15.9],
  ["th-so",50.3,17.6],
  ["th-ps",43.7,15.9],
  ["th-pc",58.1,17.6],
  ["th-ph",60.4,17],
  ["th-rt",54.1,17.6],
  ["th-kn",58.4,15.9],
  ["th-sh",55.8,14.9],
  ["th-np",46.8,10.2],
  ["th-ss",56.9,10.2],
  ["th-sm",55.4,14],
  ["th-pe",62.3,16.3],
  ["th-pk",51.5,15.9],
  ["th-nt",50,17],
  ["th-kr",44.5,15.3],
  ["th-pg",40.4,15.9],
  ["th-pu",20.1,8.1],
  ["th-st",42,15.9],
  ["th-rn",74.7,17.6],
  ["th-cp",52.1,16.3],
  ["th-sg",49.4,15.9],
  ["th-sa",53,17.6],
  ["th-tg",54.5,18.3],
  ["th-pl",50.6,17.3],
  ["th-pi",56.5,21.2],
  ["th-yl",60.4,21.2],
  ["th-nw",55.6,21.2],
]

export default function Ab0324Map({column}) {
  return (
    <LazyHighcharts
        constructorType={"mapChart"}
        options={
          {
            chart: {
              map: thMap,
              height: 600,
              spacingBottom: 20,
              type: "map",
            },
          
            dataLabels: {
              enabled: false,
            },
          
            title: {
              text: column === 1 ? "สัดส่วนลูกจ้างที่รายได้ต่ำกว่า 400 บาท/วัน" : "อัตราการปรับค่าจ้างขั้นต่ำ",
            },
          
            xAxis: {
              visible: false,
            },
            yAxis: {
              visible: false,
            },
          
            mapView: {
              projection: {
                name: "Miller",
              },
            },
          
            legend: {
              enabled: true,
            },
          
            plotOptions: {
              map: {
                allAreas: false,
                tooltip: {
                  headerFormat: "",
                  // format to two decimals
                  pointFormat: "{point.name}: <b>{point.value:.2f}</b>",
                },
              },
            },
          
            colorAxis: {
              stops: [
                [0, 'white'],
                [0.5, defaultStyles.colors[column - 1]],
                [1, chroma(defaultStyles.colors[column - 1]).darken(2).hex()],
              ],
            },
          
            series: [
              {
                name: "No subsidy",
                data: data.map(e => [e[0], e[column]]),
                allAreas: true,
              },
            ],
          }
        }
      />
  )
}