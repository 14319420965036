import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

let rawData = [
  {
    name: "Quintile 1",
    "2020m7": [2.65534,5.34645,6.37816,7.62268,9.60543,10.1404,11.0063,11.0188,12.4326,11.5519,13.0571,13.6666,18.5069,17.4999,17.6174,21.6696,22.4119,22.8806],
    "2020m10": [1,6.0921,7.08523,8.43461,8.71988,9.89662,9.33465,10.997,11.3719,15.6805,14.8353,15.0175,18.5543,19.8987,20.005],
  },
  {
    name: "Quintile 2",
    "2020m7": [8.56622,11.0874,12.0312,13.6787,16.2517,17.1041,17.8161,17.494,19.5065,17.8722,19.9574,20.713,27.4987,26.0695,26.0539,31.597,32.412,33.4029],
    "2020m10": [1.21027,6.8743,8.05262,9.41308,9.38484,10.9543,10.3158,12.0121,12.5788,17.1692,16.5681,16.7489,20.4159,21.4758,21.3226],
  },
  {
    name: "Quintile 3",
    "2020m7": [16.2824,18.3317,19.1299,21.0589,24.0724,25.2823,25.7694,25.0826,27.8042,25.509,28.3656,29.2309,37.5495,35.8108,35.7211,42.2073,43.0209,44.3593],
    "2020m10": [2.41298,9.3747,10.759,12.4379,12.7794,14.7981,14.4963,16.1468,16.9738,22.1252,20.4152,20.1412,25.051,26.5352,25.713],
  },
  {
    name: "Quintile 4",
    "2020m7": [28.9717,30.3984,30.8508,33.2244,36.772,38.3992,38.6478,37.4856,41.5697,38.2898,42.4245,43.4177,53.4472,51.2236,51.1262,59.1355,59.859,61.5175],
    "2020m10": [5.14588,14.11,15.424,17.4734,17.7408,20.1432,19.0912,21.3637,21.834,28.5151,26.5754,26.3111,32.3869,33.2847,33.5895],
  },
  {
    name: "Quintile 5",
    "2020m7": [103.344,100.308,94.043,96.2238,98.4668,100.007,98.9585,94.3928,104.513,96.8993,105.692,105.11,118.425,113.444,111.501,123.381,122.767,123.662],
    "2020m10": [31.4504,56.8671,53.0714,56.4235,52.4182,58.314,53.596,57.5136,58.9567,70.665,62.9927,62.6191,74.275,74.8892,70.4707],
  },
]



export default function Quintile({ cohort }) {

  return(
    <LazyHighcharts
      options={{
        series: rawData.map(quintile => ({
          name: quintile.name,
          data: quintile[cohort].slice(1),
        })),
        plotOptions: {
          series: {
            pointStart: Date.UTC(2020, Number(cohort.slice(5)), 1),
            pointIntervalUnit: 'month',
            marker: {
              enabled: false,
            },
          },
        },
        legend: {
          // enabled: false,
        },
        chart: {
          height: "300px",
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
        },
        tooltip: {
          xDateFormat: '%Y-%m',
          valueDecimals: 2,
          shared: true,
        },
        yAxis: {
          title: {
            text: "ปริมาณธุรกรรม",
          }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}