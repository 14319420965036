import React from "react"
import { SimpleChart } from "../../../../../src/components/Plots"

const rawData1 = [
  {name: "Low-skill", data: [], showInLegend: false},
  {name: "Middle-skill", data: [126.0895,117.0438,106.7499,117.0457,135.0629,129.6434,149.1985,110.7896,114.2037,108.0160,102.8042,101.5379,99.5893,99.7385,113.1077,114.0672,111.2376,112.9973,106.9283]},
  {name: "High-skill", data: [116.3313,117.3043,120.9380,136.4117,152.1501,153.1938,158.6519,133.1919,143.5472,139.8898,135.9006,132.0206,131.6547,132.8746,145.2263,148.3687,146.4106,154.1744,147.7930]},
]

const rawData2 = [
  {name: "Low-skill", data: [82.1818,66.4919,67.8474,78.4747,90.5661,93.4144,90.0333,72.9552,67.3561,67.4470,63.6718,59.5830,57.8808,63.7183,70.7604,72.9382,73.0069,72.6597,74.2119]},
  {name: "Middle-skill", data: [100.9991,78.6467,78.1543,88.9779,101.4092,99.4748,99.4822,79.1929,73.6730,71.4897,68.4068,62.0636,60.2699,61.5016,69.4613,73.7673,74.0810,73.1963,74.0775]},
  {name: "High-skill", data: [93.1827,78.8217,88.5417,103.6998,114.2388,117.5448,105.7855,95.2062,92.6026,92.5851,90.4294,80.6956,79.6754,81.9341,89.1859,95.9501,97.5052,99.8697,102.3876]},
]

const override = {
  yAxis: {
    min: 50,
    max: 175,
  }
}

export default function Ab2023Fig3() {
  return(
    <div className="split-2">
      <SimpleChart
        data={rawData1}
        start={1985}
        ytitle="ค่าจ้างเฉลี่ย (บาท/ชม.)"
        override={override}
      />
      <SimpleChart
        data={rawData2}
        start={1985}
        ytitle="ค่าจ้างเฉลี่ย (บาท/ชม.)"
        override={override}
      />
    </div>
  )
}

