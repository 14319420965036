import React from "react";
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots";

const categories = ["15 ปี", "20 ปี", "25 ปี", "30 ปี"];
const rawData1 = [
  { name: "อายุขัย 70", data: [7.2, 6.2, 5.4, 4.8] },
  { name: "อายุขัย 80", data: [8.7, 7.6, 6.6, 5.9] },
  { name: "อายุขัย 90", data: [9.2, 8.0, 7.1, 6.4] },
  { name: "อัตราผลตอบแทนย้อนหลัง 5 ปี", data: [3.27, 3.27, 3.27, 3.27] },
];

const rawData2 = [
  { name: "อายุขัย 70", data: [3.4, 3.8, 4.2, 4.6] },
  { name: "อายุขัย 80", data: [4.5, 4.9, 5.3, 5.7] },
  { name: "อายุขัย 90", data: [5.0, 5.4, 5.8, 6.2] },
  { name: "อัตราผลตอบแทนย้อนหลัง 5 ปี", data: [3.27, 3.27, 3.27, 3.27] },
];

const commonOptions = {
  xAxis: {
    categories: categories,
    labels: {
      style: {
        fontSize: "14px",
      },
    },
  },
  yAxis: {
    title: {
      text: "อัตราผลตอบแทนต่อปี",
    },
    min: 0,
    max: 10,
    labels: {
      format: "{value:.1f}%",
    },
  },
  tooltip: {
    shared: true,
    valueSuffix: "%",
  },
  legend: {
    align: "center",
    verticalAlign: "bottom",
    itemStyle: {
      fontSize: "14px",
    },
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
};

function Fig31() {
  return (
    <LazyHighcharts
      options={{
        series: rawData1.map((series) => ({
          name: series.name,
          data: series.data,
          lineWidth: 2,
        })),
        title: {
          text: "อัตราผลตอบแทนต่อปีที่ประกันสังคมต้องลงทุนได้ <br> (กรณีสมทบถึงอายุ 55)",
          style: {
            fontSize: "16px",
          },
        },
        ...commonOptions,
      }}
    />
  );
}

function Fig32() {
  return (
    <LazyHighcharts
      options={{
        series: rawData2.map((series) => ({
          name: series.name,
          data: series.data,
          lineWidth: 2,
        })),
        title: {
          text: "อัตราผลตอบแทนต่อปีที่ประกันสังคมต้องลงทุนได้ <br> (กรณีเริ่มสมทบอายุ 25)",
          style: {
            fontSize: "16px",
          },
        },
        ...commonOptions,
      }}
    />
  );
}

export default function Fig3() {
  return (
    <div className="split-2">
      <Fig31 />
      <Fig32 />
    </div>
  );
}
