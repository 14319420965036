import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = 
[
	{name: "ทั้งประเทศ", data: [353142,370641,392057,445793,425850,440875,464811]},
  {name: "กลางตอนล่าง", data: [548455,564598,596889,664203,656760,669992,683735]},
  {name: "กลางตอนบน", data: [397206,412180,431645,509058,506289,518142,522316]},
  {name: "เหนือ", data: [340539,352154,362934,412293,407682,412625,426818]},
  {name: "อีสาน", data: [267183,281154,302664,350594,364131,380445,408988]},
  {name: "ใต้", data: [479746,511176,541270,579576,609600,623867,641579]},
]


export default function AgriDebt() {

  return(
    <LazyHighcharts
      // altImage={}
      options={{
        series: rawData.map((series, i) => ({
          ...series,
        })),
        chart: {
          type: "spline",
          height: "500px",
        },
        plotOptions: {
          series: {
            pointStart: 2559,
            marker: {
              enabled: false,
            },
          },
        },
        xAxis: {
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        title: {
          text: "หนี้สินครัวเรือนเกษตรไทยมีปริมาณมาก<br />และโตต่อเนื่องในทุกพื้นที่",
        },
        subtitle: {
          text: "หนี้เฉลี่ยต่อครัวเรือน (บาท)",
        },
        tooltip: {
          valueSuffix: " บาท",
          // formatter: function(tooltip) {
          //   let tmp = tooltip.defaultFormatter.call(this, tooltip);
          //   tmp[1] = tmp[1].replace(/<b>[0-9,\.]+<\/b>/, `<b>${this.y.toFixed(2)}</b> บาท/ชม.`)
          //   return tmp
          // }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}