import React from "react"
import { SimpleChart, defaultStyles } from "../../../../../src/components/Plots"
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';

const colors = [
  defaultStyles.colors[3],
  defaultStyles.colors[1],
  defaultStyles.colors[2],
  defaultStyles.colors[0],
]

const rawData = {
  "Knowledge Economy Infrastructure Index": {
    data: [
      [-0.16238,-0.17357,-0.02872,0.029342,0.088553,0.101333,0.086239,0.101263,0.13711,0.197867,0.240989,0.279793,0.328841,0.326849,0.28536,0.424727,0.426449],
      [-2.55643,-2.56812,-2.43086,-2.36651,-2.27292,-2.24737,-2.2011,-2.15568,-2.10051,-2.0426,-1.97813,-1.87446,-1.71788,-1.60539,-1.618,-1.5936,-1.59363],
      [-0.17869,-0.09838,0.082445,0.139179,0.221073,0.188454,0.022623,0.04257,0.046398,0.112585,0.158236,0.179088,0.224044,0.229466,0.242836,0.366415,0.361976],
      [-2.85654,-2.85961,-2.92855,-2.84515,-2.78165,-2.65779,-2.63498,-2.57184,-2.52701,-2.4331,-2.40352,-2.34599,-2.18385,-2.0685,-2.00323,-1.59771,-1.56698],
    ],
    start: 2004,
  },
  "ICT Infrastructure Index": {
    data: [
      [-0.5594,-0.34446,-0.24868,-0.16989,-0.11647,-0.03042,0.069099,0.155676,0.206209,0.284931,0.364796,0.444362,0.520678,0.592789,0.672894,0.783174,0.922274,1.056715,1.229726,1.33112],
      [-2.32647,-2.2299,-2.18283,-2.05641,-1.98294,-1.87773,-1.74651,-1.60272,-1.43576,-1.26436,-1.05961,-0.85298,-0.7005,-0.59457,-0.41904,-0.24802,-0.05111,0.110703,0.254544,0.413556],
      [-0.77643,-0.60026,-0.50583,-0.41165,-0.3623,-0.05942,0.040338,0.071723,0.127164,0.114101,0.227425,0.319893,0.423778,0.50639,0.591172,0.720775,0.874077,1.056805,1.193634,1.294562],
      [-2.32385,-2.25026,-2.18446,-2.13379,-1.96331,-1.88123,-1.76912,-1.84523,-1.77102,-1.68115,-1.63012,-1.51796,-1.4183,-1.17925,-1.00136,-0.67193,-0.4524,-0.29199,0.106308,0.559475],
    ],
    start: 2001,
  },
  "Innovation Infrastructure Index": {
    data: [
      [-0.30542,-0.27035,-0.25528,-0.23526,-0.1932,-0.0875,-0.02617,-0.02327,0.045125,0.101121,0.137379,0.227749,0.27992,0.300499,0.349363,0.3417,0.319113,0.333491,0.318721,0.467511,0.495911],
      [-1.62259,-1.63212,-1.62042,-1.62933,-1.62412,-1.61791,-1.60662,-1.5848,-1.53937,-1.52326,-1.5121,-1.51248,-1.48288,-1.46694,-1.44071,-1.41999,-1.40458,-1.39254,-1.38211,-1.3623,-1.34324],
      [-0.30154,-0.37863,-0.38796,-0.36778,-0.31818,-0.22181,-0.17283,-0.159,-0.09025,-0.04797,-0.01221,0.072316,0.078225,0.098956,0.130613,0.138466,0.096852,0.114397,0.108237,0.220876,0.232905],
      [-1.75922,-1.75293,-1.76196,-1.75016,-1.74683,-1.73966,-1.72339,-1.72865,-1.71878,-1.70308,-1.67968,-1.63659,-1.60257,-1.58148,-1.55391,-1.48671,-1.40088,-1.28049,-1.21059,-1.19947,-1.10723],
    ],
    start: 2000,
  },
  "Financial Infrastructure Index": {
    data: [
      [-0.08449,-0.10552,-0.09025,-0.06717,-0.05135,-0.05131,-0.06331,-0.05657,-0.07201,-0.09665,-0.09938,-0.11922,-0.14855,-0.16361,-0.16022,-0.18015,-0.21522],
      [-0.53556,-0.53284,-0.51905,-0.4841,-0.4341,-0.42324,-0.40661,-0.39857,-0.38393,-0.36678,-0.34695,-0.33587,-0.33101,-0.33281,-0.32245,-0.3225,-0.337],
      [-0.15163,-0.14602,-0.12982,-0.1149,-0.04749,-0.05226,-0.06646,-0.05778,-0.06308,-0.07577,-0.07614,-0.0858,-0.10258,-0.10899,-0.10096,-0.10189,-0.11992],
      [-0.60496,-0.56227,-0.51395,-0.48166,-0.41438,-0.38195,-0.34498,-0.31888,-0.29138,-0.25912,-0.20938,-0.19637,-0.19588,-0.19728,-0.19875,-0.21089,-0.22934],
    ],
    start: 2004,
  },
  "Quality of Institutions Index": {
    data: [
      [-0.4294,null,-0.21192,-0.15437,-0.00718,0.041641,-0.00056,0.012295,0.030514,-0.04943,-0.08161,-0.10816,-0.10803,-0.08458,-0.06504,-0.01371,0.018525,0.002397,-0.05335,-0.02645,-0.03128],
      [-3.50237,null,-3.61684,-3.64195,-3.61281,-3.54856,-3.07569,-3.04934,-3.03919,-3.06486,-3.00152,-3.02229,-3.02338,-2.99003,-2.91639,-2.84327,-2.7241,-2.65444,-2.64337,-2.62745,-2.70184],
      [-0.65937,null,-0.43329,-0.3887,-0.32547,-0.29418,-0.26032,-0.23687,-0.21999,-0.30358,-0.316,-0.35283,-0.36988,-0.34844,-0.32653,-0.26609,-0.20987,-0.21514,-0.26525,-0.25913,-0.28479],
      [-1.82817,null,-2.51869,-3.36104,-3.55247,-3.67311,-3.87814,-3.7824,-3.62805,-3.439,-3.46632,-3.39719,-3.39719,-3.28599,-3.39397,-3.42152,-3.33741,-3.29771,-3.29771,-2.72822,-2.98075],
    ],
    start: 2000,
  },
  "Average Years of Schooling age 25 plus": {
    data: [
      [10.00972,10.13183,10.25394,10.37606,10.49817,10.62028,10.71767,10.81506,10.91244,11.00983,11.10722,11.23022,11.35322,11.47622,11.59922,11.72222,11.78056,11.83889,11.89722,11.95556,12.01389],
      [7.4188,7.55176,7.68472,7.81768,7.95064,8.0836,8.19416,8.30472,8.41528,8.52584,8.919677,9.009931,9.121241,9.232552,9.343862,9.455172,9.537242,9.61931,9.701379,9.783448,9.865517],
      [9.794348,9.904261,10.01417,10.12409,10.234,10.34391,10.45739,10.57087,10.68435,10.79783,10.9113,11.08,11.20111,11.32222,11.44333,11.56444,11.62622,11.688,11.74978,11.81156,11.87333],
      [4.81,5.136,5.462,5.788,6.114,6.44,6.612,6.784,6.956,7.128,7.3,7.46,7.62,7.78,7.940001,8.1,8.200001,8.3,8.400001,8.5,8.6],
    ],
    start: 2000,
  },
  "LAYS age 25 plus": {
    data: [
      [8.348961,8.444005,8.539052,8.611943,8.728919,8.846119,8.848457,8.88465,9.002752,9.12084,9.192731,9.284342,9.402046,9.495981,9.588587,9.680423,9.574004,9.466,9.356411,9.401721,9.447031],
      [4.982082,5.062605,5.140954,5.31958,5.434728,5.550711,5.457215,5.725203,5.75235,5.822566,6.170682,6.321047,6.348562,6.477778,6.832473,6.906464,6.857871,6.745127,6.630034,6.685983,6.741932],
      [8.303275,8.411521,8.519973,8.434606,8.536917,8.63941,8.662741,8.708362,8.817662,8.88863,8.994182,9.103979,9.213886,9.324761,9.500801,9.614082,9.615759,9.505296,9.393114,9.441307,9.4895],
      [3.548398,3.761037,3.97014,4.175706,4.399743,4.622591,4.73399,4.949627,5.041815,5.132355,5.26712,5.393723,5.630273,5.673103,5.712832,5.74946,5.726648,5.701547,5.67416,5.741709,5.809258],
    ],
    start: 2000,
  },
}

const groupsLabel = ["OECD", "UMC", "HIC", "THA"]

function Chart({ dimension, override }) {
  return (
    <SimpleChart
      data={rawData[dimension].data.map((series, i) => ({
        name: groupsLabel[i],
        data: series,
        color: colors[i],
      }))}
      start={rawData[dimension].start}
      override={{
        ...override,
        title: {
          text: dimension,
        },
        plotOptions: {
          series: {
            label: {
              enabled: true,
            },
          },
        },
        legend: {
          enabled: false,
        },
      }}
    />
  )
}


const dimensions = [
  "Knowledge Economy Infrastructure Index",
  "ICT Infrastructure Index",
  "Innovation Infrastructure Index",
  "Financial Infrastructure Index",
  "Quality of Institutions Index",
  "LAYS age 25 plus",
]

function Pair({ d1, d2, override }) {
  return (
    <div className="split-2">
      <Chart dimension={d1} override={override} />
      <Chart dimension={d2} override={override} />
    </div>
  )
}

export default function Ab2023Fig89({ fig }) {
  // const [dimension, setDimension] = React.useState("Knowledge Economy Infrastructure Index")
  if (fig === 8) {
    return(
      <>
        <Pair
          d1={dimensions[0]}
          d2={dimensions[1]}
        />
        <Pair
          d1={dimensions[2]}
          d2={dimensions[3]}
        />
        <Pair
          d1={dimensions[4]}
          d2={dimensions[5]}
        />
      </>
    )
    // return(
    //   <div style={{marginTop: '2rem'}}>
    //     <div style={{textAlign: "center"}}>
    //       <FormControl sx={{ minWidth: 340, textAlign: "left" }} size="small" style={{margin: '1rem auto'}}>
    //         <InputLabel id="demo-simple-select-label">มิิติ</InputLabel>
    //         <Select
    //           labelId="demo-simple-select-label"
    //           id="demo-simple-select"
    //           value={dimension}
    //           label="มิติ"
    //           onChange={e => setDimension(e.target.value)}
    //         >
    //           {dimensions.map(facet => <MenuItem value={facet}>{facet}</MenuItem>)}
    //         </Select>
    //       </FormControl>
    //     </div>
    //     <Chart dimension={dimension} />
    //   </div>
    // )
  }
  else {
    return(
      <Pair
        d1="Average Years of Schooling age 25 plus"
        d2="LAYS age 25 plus"
        override={{
          yAxis: {
            min: 2,
            max: 14,
          }
        }}
      />
    )
  }
}

