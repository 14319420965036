import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"
import { educationLabels } from "./Labor"

const rawData = [
  [10.382059,8.5562229,11.548411,12.835323,12.600806,18.049238,11.779604,16.244314,18.321692,18.896446,14.852114,18.115942,15.850372,19.675364,18.276802,17.209974,19.409336,15.890908,22.660963,23.758707,22.648924,22.41357,22.177721,24.719189,30.5966,36.693134,37.449902,36.337208,36.1236,37.2241895,36.79068,39.577,40.65141],
  [16.14987,16.096395,18.477457,18.640186,21.421371,22.561548,21.534588,21.929825,22.956461,24.529043,22.609819,22.644928,21.65274,23.884569,24.596457,25.599598,24.883764,24.719189,25.178848,25.986084,25.620811,26.079809,26.729345,27.304405,29.996666,35.22541,37.449902,37.790699,37.574331,39.392835,42.04649,41.75156,42.98487],
  [33.802055,35.039772,38.673748,39.064407,41.025883,42.49966,40.449581,42.49966,41.137981,43.186325,37.911823,36.853004,34.557499,33.479225,33.245758,31.857279,30.966463,30.898987,30.686722,30.798325,31.314325,30.937037,30.887245,30.898989,35.551601,39.139339,40.742207,43.604649,44.2187585,44.6772265,47.53317,47.19976,50.52915],
  [55.210026,61.604805,71.61805,69.927635,80.377235,77.066055,73.672173,76.659668,72.312851,84.021347,65.672569,63.195145,65.118179,63.371395,62.251816,61.120464,64.233292,61.503113,59.012924,59.396767,54.745319,53.792568,50.587196,48.923397,52.438454,55.0397,57.615234,58.139538,60.102371,61.353979,68.38258,66.07967,68.5473],
  [76.411964,82.13974,93.332642,95.419189,115.20737,114.49813,110.94743,116.88524,116.80247,136.90628,104.68979,104.57893,101.11801,102.07081,100.64082,98.757568,103.78189,103.37572,99.141716,98.169655,94.559486,92.583817,87.977737,86.481857,98.583847,100.64402,99.098206,111.43411,111.62186,110.97245,118.8329,116.516,124.9084],
]

export default function Wage() {

  return(
    <LazyHighcharts
      // altImage={}
      options={{
        series: rawData.map((data, i) => ({
          name: educationLabels[i],
          data,
        })),
        chart: {
          type: "spline",
          height: "400px",
        },
        plotOptions: {
          series: {
            pointStart: 1988 + 543,
            marker: {
              enabled: false,
            },
          },
        },
        xAxis: {
          plotLines: [
            {
              color: "red",
              dashStyle: "5",
              width: 1,
              value: 2554.5,
              label: {
                text: "เพิ่มค่าแรงขั้นต่ำ",
                style: {
                  fontFamily: "Dindan",
                },
              },
            },
            {
              color: "red",
              dashStyle: "5",
              width: 1,
              value: 2556.5,
              label: {
                text: "ปรับเงินเดือนข้าราชการ",
                style: {
                  fontFamily: "Dindan",
                },
              },
            },
          ],
        },
        yAxis: {
          title: {
            text: "ค่าจ้างรายชั่วโมง (บาท/ชม.)",
          },
        },
        tooltip: {
          formatter: function(tooltip) {
            let tmp = tooltip.defaultFormatter.call(this, tooltip);
            tmp[1] = tmp[1].replace(/<b>[0-9,.]+<\/b>/, `<b>${this.y.toFixed(2)}</b> บาท/ชม.`)
            return tmp
          }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}