import Ab2023Table from "../../../../content/abridged/2023/20/graphs/Ab2023Table.js";
import Ab2023KL from "../../../../content/abridged/2023/20/graphs/Ab2023KL.js";
import Ab2023Education from "../../../../content/abridged/2023/20/graphs/Ab2023Education.js";
import Ab2023Fig2 from "../../../../content/abridged/2023/20/graphs/Ab2023Fig2.js";
import Ab2023Fig3 from "../../../../content/abridged/2023/20/graphs/Ab2023Fig3.js";
import Ab2023Fig4 from "../../../../content/abridged/2023/20/graphs/Ab2023Fig4.js";
import Ab2023Fig7 from "../../../../content/abridged/2023/20/graphs/Ab2023Fig7.js";
import Ab2023Fig89 from "../../../../content/abridged/2023/20/graphs/Ab2023Fig89.js";
import * as React from 'react';
export default {
  Ab2023Table,
  Ab2023KL,
  Ab2023Education,
  Ab2023Fig2,
  Ab2023Fig3,
  Ab2023Fig4,
  Ab2023Fig7,
  Ab2023Fig89,
  React
};