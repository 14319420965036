import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

import Alt from "./alt/government.png"

const rawData = [
  {name: "ไทย", data: [58,58,58,66,68,68,68,70,74,74,74,73,66,56,58,57,54,53,41,39,39,39,39,40,42,42,42,42,42,42,42,42,42,42,42,42,42]},
  {name: "มาเลเซีย", data: [81,77,74,61,56,56,56,56,60,67,67,75,75,73,60,58,56,56,56,59,61,60,63,61,61,61,61,61,61,61,61,61,61,61,61,61,61]},
  {name: "สิงคโปร์", data: [90,90,90,85,79,79,79,79,79,83,85,85,86,89,89,89,89,92,87,86,86,86,86,86,86,86,86,86,86,86,86,86,88,89,89,89,89]},
  {name: "เวียดนาม", data: [36,36,38,42,42,42,42,41,45,52,61,61,58,56,56,56,49,47,47,47,47,53,55,58,58,54,53,53,53,53,53,53,51,52,53,53,53]},
]

export default function GovernmentQuality() {

  return(
    <LazyHighcharts
      altImage={Alt}
      options={{
        series: rawData,
        chart: {
          type: "spline",
          height: "300px",
        },
        plotOptions: {
          series: {
            pointStart: 1985 + 543,
            marker: {
              enabled: false,
            },
            label: {
              enabled: true,
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          min: 0,
        },
        tooltip: {
          valueSuffix: " คะแนน",
        },
        legend: {
          enabled: false,
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "40%",
              },
            },
          }]
        }
      }}
    />
  )

}