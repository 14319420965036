import React from 'react'
import chroma from 'chroma-js'

import picVoiceTV from './media/voice-tv.png'
import picPrachathai from './media/prachathai.png'
import picTheStandard from './media/the-standard.png'
import picMatichon from './media/matichon.png'
import picTheReporters from './media/the-reporters.jpg'
import picBBCThai from './media/bbc-thai.png'
import picKhaosod from './media/khaosod.png'
import picTheMatter from './media/the-matter.png'
import picBangkokPost from './media/bangkok-post.png'
import picWorkpoint from './media/workpoint.png'
import picTheMomentum from './media/the-momentum.png'
import picAmarinTv from './media/amarin-tv.jpg'
import picNew18 from './media/new-18.png'
import picSpringNews from './media/spring-news.jpg'
import pic101World from './media/101-world.png'
import picKom from './media/kom.jpg'
import picPPTV36 from './media/pptv-36.jpg'
import picDailyNews from './media/daily-news.jpg'
import picThaipbs from './media/thaipbs.png'
import picThairath from './media/thairath.jpg'
import picPrachachat from './media/prachachat.jpg'
import picBluesky from './media/bluesky.png'
import picNaewna from './media/naewna.png'
import picThaipublica from './media/thaipublica.jpg'
import picCh3 from './media/ch-3.png'
import picTNN16 from './media/tnn-16.png'
import picIsraNews from './media/isranews.png'
import picThaiPost from './media/thaipost.jpg'
import picMCOT from './media/mcot.png'
import picNation from './media/nation.png'
import picManager from './media/manager.png'
import picTopNews from './media/top-news.png'

import styles from "./styles.module.scss"

const mediaData = [-5.68, -5.41, -4.94, -4.34, -4.25, -4.14, -2.29, -1.95, -1.02, -0.95, -0.85, -0.62, -0.47, -0.06, 0.09, 0.26, 0.53, 0.62, 0.71, 0.81, 0.94, 1.06, 1.07, 1.17, 1.38, 1.44, 1.53, 1.58, 1.97, 2.58, 4.74, 7.77]
const mediaLabel = ["VoiceTV", "ประชาไท", "The Standard", "มติชน", "The Reporter", "BBC Thai", "ข่าวสด", "The Matter", "Bangkok Post", "Workpoint", "The Momentum", "AmarinTV", "New18", "Spring News", "The 101.World", "คมชัดลึก", "PPTV", "เดลินิวส์", "ThaiPBS", "ไทยรัฐ", "ประชาชาติ", "BLUESKY", "แนวหน้า", "ThaiPublica", "ช่อง 3", "TNN", "สำนักข่าวอิศรา", "ไทยโพสต์", "อสมท.", "Nation", "ผู้จัดการ", "Top News"]
const mediaLogo = [picVoiceTV, picPrachathai, picTheStandard, picMatichon, picTheReporters, picBBCThai, picKhaosod, picTheMatter, picBangkokPost, picWorkpoint, picTheMomentum, picAmarinTv, picNew18, picSpringNews, pic101World, picKom, picPPTV36, picDailyNews, picThaipbs, picThairath, picPrachachat, picBluesky, picNaewna, picThaipublica, picCh3, picTNN16, picIsraNews, picThaiPost, picMCOT, picNation, picManager, picTopNews]
const politicalColors = chroma.scale(['orange', 'white', 'yellow'])

export default function MediaScale() {

  return(
    <div className={styles.mediaScale}>
      {
        mediaData.map((data, i) => (
          <div className="media-box" key={`box${i}`}>
            <div style={{
              height: `${Math.abs(data)*30}px`,
              backgroundColor: politicalColors((data + 8) / 16).hex()
            }} />
            <img className="media-logo" src={mediaLogo[i]} alt={`Logo of ${mediaLabel[i]}`} />
            <div className="tooltip">
              <div><img className="media-tooltip" src={mediaLogo[i]} alt={`Logo of ${mediaLabel[i]}`} /></div>
              {mediaLabel[i]}<br />{mediaData[i]}
            </div>
          </div>
        ))
      }
    </div>
  )

}
