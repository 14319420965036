import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'

export default function Trust() {

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "300px"}}
      data={{
        labels: ["2008", "2013", "2018"],
        datasets: [
          {
            data: [41.26466751, 32.16666794, 29.79999924],
            backgroundColor: getAreaColor(defaultStyles.colors[0]),
            borderColor: 'none',
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        scales: {
          y: {
            title: {
              display: true,
              text: "คะแนน"
            }
            // max: 100,
          },
        },
        plugins: {
          // title: {
          //   display: true,
          //   text: "คะแนนความเชื่อใจจาก World Values Survey (0–100)",
          // },
          legend: {
            display: false,
          }
        }
      }}
    />
  )


}