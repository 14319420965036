import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'

export default function AffectiveScores() {

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "400px"}}
      data={{
        labels: ["ความเห็นอกเห็นใจ (ช่วยว่าความให้หากเป็นทนาย)", "ความชอบ/ไม่ชอบ", "ความเต็มใจแลกเปลี่ยนความคิดเห็น", "ความไว้วางใจ", "ความเห็นอกเห็นใจ (ช่วยเหลือหากประสบอุบัติเหตุ)"],
        datasets: [
          {
            label: "ค่าเฉลี่ยของกลุ่มตัวอย่าง",
            data: [36.6, 57.2, 61.6, 65.2, 86.1],
            backgroundColor: getAreaColor(defaultStyles.colors[0]),
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
          }
        },
        scales: {
          x: {
            max: 100,
            title: {
              display: true,
              text: "คะแนน"
            }
          },
          y: {
            // reverse: true,
          }
        },
      }}
    />
  )


}