import Ab0724Fig1 from "../../../../content/abridged/2024/07/Ab0724Fig1.js";
import Ab0724Fig2 from "../../../../content/abridged/2024/07/Ab0724Fig2.js";
import Ab0724Fig3 from "../../../../content/abridged/2024/07/Ab0724Fig3.js";
import Ab0724Fig4 from "../../../../content/abridged/2024/07/Ab0724Fig4.js";
import Ab0724Fig5 from "../../../../content/abridged/2024/07/Ab0724Fig5.js";
import Ab0724Fig6 from "../../../../content/abridged/2024/07/Ab0724Fig6.js";
import * as React from 'react';
export default {
  Ab0724Fig1,
  Ab0724Fig2,
  Ab0724Fig3,
  Ab0724Fig4,
  Ab0724Fig5,
  Ab0724Fig6,
  React
};