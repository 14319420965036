import React from "react"
import clsx from "clsx"
import styles from "./styles.module.scss"

export default function Table({ cols, rows, data, shadeByValue=false }) {
  return(
    <div className="table-wrapper">
      <div
        className={styles.grid}
        style={{
          gridTemplateColumns: `auto repeat(${cols.length}, 80px)`,
        }}
      >
        <div className={clsx(styles.firstRow, styles.firstCol, styles.heading)} />
        {cols.map((x, i) => (
          <div key={i} className={clsx(styles.firstRow, styles.otherCols, styles.heading)}>
            <div dangerouslySetInnerHTML={{__html: x}}></div>
          </div>
        ))}
        {rows.map((x, i) => (
          <>
            <div key={i} className={clsx(styles.otherRows, styles.firstCol, styles.heading)} dangerouslySetInnerHTML={{__html: x}}></div>
            {data[i].map((y, j) => {
              const shade = y && y < 0.6 ? 0.1 : 0.3
              const sig = y && y < 1
              return(
                <div
                  key={j}
                  className={clsx(styles.otherRows, styles.otherCols)}
                  style={{
                    backgroundColor: shadeByValue && y !== 1 && y !== null && `rgba(0, 0, 0, ${shade})`,
                  }}
                >
                  {y && y.toFixed(2) + (sig ? '*' : '')}
                </div>
              )
            })}
          </>
        ))}
      </div>
    </div>
  )
}