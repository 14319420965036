import React from "react"
import AgriProductivity from "./AgriProductivity"
import AgriDebt from "./AgriDebt"

import Alt from "./alt/agriculture.png"

export default function Tax() {
  return(
    <>
      <img className="printonly" src={Alt} alt="Agriculture" />
      <div className="split-2 noprint">
        <AgriProductivity />
        <AgriDebt />
      </div>
    </>
  )
}