import React from "react"
import { LazyPlotly } from "../../../../../src/components/Plots";

const notuse = {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  y: [4.83, 16.52, 6.47, 1.22, 7.26, 5.83, 8.98, 7.33, 2.66],
  legendgroup: 'group',
  legendgrouptitle: {
    text: "Not in DM",
  },
  name: 'Eligible for DM, but opt out',
  marker: { color: 'rgb(200, 200, 200)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const gddebt = {
  x: ['2015'],
  y: [25.83],
  legendgroup: 'group2',
  legendgrouptitle: {
    text: "In 1 DM",
  },
  name: 'Non-NPL borrowers',
  marker: { color: 'rgb(19, 149, 186)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const drgh15 = {
  x: ['2016', '2017'],
  y: [42.31, 16.97],
  legendgroup: 'group2',
  name: 'Drought 2015',
  marker: { color: 'rgb(217, 78, 31)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const othdm = {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  y: [0.04, 0.05, 2.33, 2.05, 0.03, 0.03, 0, 0.03, 0.03],
  legendgroup: 'group2',
  name: 'Other DM programs',
  marker: { color: 'rgb(239, 139, 44)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const rice = {
  x: ['2017', '2018'],
  y: [23.43, 23.28],
  legendgroup: 'group2',
  name: 'Rice farmers',
  marker: { color: 'rgb(17, 120, 153)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const covid = {
  x: ['2020', '2021', '2022', '2023'],
  y: [0.001, 59.33, 52.35, 16.99],
  legendgroup: 'group2',
  name: 'COVID-19',
  marker: { color: 'rgb(192, 46, 29)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const othmix = {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  y: [0, 0, 2.25, 6.78, 0.01, 3.34, 6.32, 0.16, 0],
  legendgroup: 'group3',
  name: 'Participating > 1 DM program',
  marker: { color: 'rgb(13, 60, 85)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};



const nodm = {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  y: [69.30, 41.12, 30.96, 52.20, 11.28, 29.85, 13.92, 40.08, 80.32],
  legendgroup: 'group',
  name: 'Not eligible for DM',
  marker: { color: 'rgb(240, 240, 240)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const drghrice = {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  y: [0, 0, 17.59, 0, 0, 0, 0, 0, 0],
  legendgroup: 'group3',
  legendgrouptitle: {
    text: "In > 1 DM",
  },
  name: 'Drought 2015 + Rice farmers',
  marker: { color: 'rgb(90, 170, 124)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const pracharat = {
  x: ['2019', '2020', '2021'],
  y: [60.15, 17.05, 0.42],
  legendgroup: 'group2',
  name: 'Pracharat',
  marker: { color: 'rgb(16, 91, 120)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const lowincpracharat = {
  x: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
  y: [0, 0, 0, 0, 8.80, 2.13, 0.01, 0, 0],
  legendgroup: 'group3',
  name: 'Pracharat + Low income',
  marker: { color: 'rgb(162, 184, 108)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const lowinc = {
  x: ['2018', '2019', '2020', '2021', '2022'],
  y: [14.47, 12.48, 5.53, 0.01, 0.04],
  legendgroup: 'group2',
  name: 'Low income',
  marker: { color: 'rgb(109, 181, 183)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const flood62 = {
  x: ['2020', '2021'],
  y: [16.88, 11.01],
  legendgroup: 'group2',
  name: 'Flood 2019',
  marker: { color: 'rgb(235, 200, 68)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const drgh62 = {
  x: ['2020'],
  y: [19.31],
  legendgroup: 'group2',
  name: 'Drought 2019',
  marker: { color: 'rgb(241, 108, 32)' },
  type: 'bar',
  hovertemplate: '%{y:.1f}' + '%'
};

const data = [othdm, gddebt, drgh15, rice, lowinc, pracharat, drgh62, flood62, covid, drghrice, lowincpracharat, othmix, notuse, nodm];

const layout = {
  xaxis: { type: 'category' },
  yaxis: { title: '% borrowers' },
  barmode: 'stack',
  // width: 900,
  // height: 600
  shapes: [
    {
      type: 'line',
      x0: -0.5,
      y0: 52.2,
      x1: 8.5,
      y1: 52.2,
      line: {
        color: 'red',
        width: 1,
        dash: 'dot'
      }
    }
  ],
  annotations: [
    {
      x: 8,
      y: 52.2 + 2.5,
      xref: 'x',
      yref: 'y',
      text: 'Average',
      showarrow: false,
    }
  ]
};

export default function Ab1823Fig3() {
  return (
    <LazyPlotly data={data} layout={layout} />
  )
}