import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"

let rawData = [
  12634,
  2855,
  1065,
  566,
  285,
  212,
  146,
  104,
  89,
  77,
  55,
  43,
  40,
  42,
  32,
  30,
  29,
  29,
  33,
  32,
  22,
  37,
  33,
  35,
  38,
  36,
  24,
  22,
  25,
  23,
  16,
  11,
  11,
  11,
  24,
  9,
  11,
  11,
  7,
  10,
  12,
  6,
  5,
  9,
  4,
  8,
  9,
  7,
  5,
  8,
  8,
  3,
  4,
  2,
  7,
  6,
  5,
  2,
  7,
  2,
  6,
  7,
  1,
  7,
  5,
  5,
  5,
  8,
  8,
  6,
  9,
  3,
  5,
  8,
  7,
  2,
  7,
  6,
  6,
  3,
  4,
  5,
  7,
  3,
  4,
  3,
  6,
  2,
  3,
  2,
  2,
  3,
  4,
  3,
  4,
  0,
  7,
  3,
  2,
  0,
  2,
  2,
  2,
  1,
  1,
  1,
  0,
  1,
  1,
  2,
  1,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  2,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9063,
  2494,
  1108,
  694,
  379,
  305,
  227,
  169,
  156,
  134,
  95,
  64,
  69,
  77,
  53,
  48,
  33,
  36,
  31,
  31,
  17,
  24,
  27,
  20,
  9,
  23,
  21,
  17,
  10,
  14,
  13,
  15,
  12,
  15,
  13,
  11,
  14,
  13,
  14,
  15,
  14,
  8,
  10,
  9,
  7,
  10,
  7,
  7,
  7,
  4,
  11,
  5,
  3,
  9,
  5,
  5,
  2,
  5,
  4,
  2,
  4,
  1,
  3,
  2,
  5,
  2,
  0,
  2,
  3,
  3,
  2,
  2,
  2,
  0,
  1,
  0,
  1,
  0,
  0,
  7,
  1,
  1,
  2,
  5,
  2,
  2,
  2,
  1,
  4,
  1,
  1,
  2,
  2,
  2,
  0,
  1,
  1,
  1,
  0,
  1,
  1,
  1,
  0,
  1,
  1,
  3,
  2,
  0,
  1,
  2,
  2,
  0,
  3,
  0,
  0,
  0,
  1,
  0,
  1,
  1,
  1,
  1,
  1,
  1,
  0,
  2,
  0,
  1,
  2,
  1,
  2,
  0,
  2,
  1,
  2,
  1,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  2,
  0,
  0,
  0,
  0,
  0,
  2,
  1,
  1,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  16,
  6240,
  2155,
  1138,
  696,
  512,
  340,
  281,
  207,
  148,
  129,
  116,
  102,
  77,
  64,
  47,
  58,
  54,
  37,
  48,
  30,
  35,
  25,
  19,
  21,
  18,
  22,
  18,
  14,
  28,
  8,
  12,
  27,
  12,
  14,
  11,
  17,
  11,
  10,
  11,
  5,
  18,
  8,
  4,
  10,
  7,
  5,
  6,
  5,
  7,
  7,
  10,
  4,
  5,
  5,
  4,
  7,
  3,
  5,
  4,
  3,
  3,
  2,
  4,
  4,
  6,
  5,
  3,
  3,
  4,
  4,
  1,
  6,
  1,
  4,
  4,
  1,
  3,
  2,
  5,
  2,
  4,
  2,
  2,
  0,
  7,
  3,
  5,
  3,
  4,
  3,
  2,
  2,
  3,
  1,
  1,
  4,
  1,
  1,
  2,
  1,
  1,
  2,
  1,
  1,
  0,
  1,
  3,
  0,
  1,
  3,
  2,
  0,
  0,
  2,
  1,
  2,
  2,
  0,
  2,
  5,
  1,
  2,
  0,
  1,
  1,
  0,
  3,
  0,
  2,
  1,
  0,
  0,
  1,
  3,
  0,
  0,
  3,
  1,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  2,
  1,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  1,
  0,
  2,
  0,
  1,
  0,
  0,
  1,
  1,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  2,
  0,
  2,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  1,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  19,
  5133,
  2139,
  1126,
  697,
  547,
  400,
  295,
  198,
  160,
  141,
  117,
  99,
  70,
  70,
  61,
  50,
  48,
  45,
  38,
  43,
  37,
  31,
  33,
  25,
  27,
  18,
  24,
  21,
  29,
  13,
  19,
  13,
  11,
  15,
  11,
  11,
  10,
  16,
  10,
  11,
  11,
  13,
  10,
  9,
  6,
  7,
  10,
  9,
  10,
  6,
  11,
  3,
  4,
  4,
  3,
  5,
  4,
  4,
  4,
  10,
  3,
  3,
  2,
  5,
  6,
  3,
  3,
  3,
  3,
  3,
  5,
  3,
  3,
  1,
  3,
  2,
  3,
  4,
  1,
  3,
  2,
  2,
  2,
  3,
  2,
  4,
  2,
  1,
  0,
  2,
  3,
  2,
  3,
  4,
  5,
  2,
  0,
  2,
  1,
  1,
  0,
  1,
  1,
  3,
  1,
  1,
  1,
  1,
  3,
  2,
  0,
  4,
  0,
  3,
  1,
  1,
  1,
  1,
  1,
  2,
  2,
  2,
  1,
  1,
  1,
  3,
  1,
  0,
  1,
  2,
  0,
  0,
  1,
  0,
  1,
  0,
  2,
  3,
  3,
  1,
  1,
  0,
  0,
  3,
  2,
  0,
  2,
  1,
  1,
  0,
  0,
  2,
  0,
  1,
  0,
  0,
  0,
  3,
  1,
  0,
  0,
  2,
  2,
  0,
  0,
  0,
  3,
  0,
  0,
  0,
  1,
  1,
  0,
  2,
  1,
  0,
  0,
  0,
  2,
  1,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  1,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  1,
  1,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  2,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  1,
  1,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  2,
  2,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  41,
  4565,
  2176,
  1197,
  760,
  607,
  381,
  309,
  249,
  178,
  164,
  132,
  125,
  88,
  92,
  75,
  74,
  57,
  52,
  61,
  45,
  32,
  28,
  46,
  24,
  24,
  23,
  24,
  26,
  22,
  15,
  20,
  15,
  20,
  14,
  19,
  14,
  7,
  19,
  18,
  10,
  14,
  6,
  10,
  11,
  14,
  11,
  11,
  13,
  4,
  14,
  9,
  7,
  7,
  8,
  6,
  9,
  9,
  5,
  7,
  6,
  9,
  10,
  2,
  3,
  6,
  3,
  3,
  4,
  8,
  0,
  5,
  1,
  6,
  0,
  3,
  5,
  6,
  4,
  7,
  2,
  1,
  4,
  5,
  3,
  2,
  3,
  3,
  5,
  4,
  3,
  3,
  1,
  0,
  5,
  2,
  1,
  3,
  2,
  0,
  2,
  0,
  3,
  3,
  1,
  4,
  2,
  3,
  1,
  1,
  2,
  1,
  1,
  0,
  1,
  1,
  3,
  2,
  2,
  0,
  3,
  0,
  1,
  2,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  2,
  2,
  1,
  0,
  3,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  1,
  1,
  1,
  0,
  1,
  1,
  0,
  0,
  2,
  2,
  0,
  1,
  1,
  1,
  1,
  0,
  2,
  2,
  0,
  2,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  2,
  1,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  1,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  1,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  1,
  1,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  1,
  0,
  1,
  1,
  1,
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  2,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  2,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  49,
  4113,
  2406,
  1382,
  877,
  649,
  471,
  393,
  291,
  230,
  173,
  162,
  140,
  113,
  98,
  109,
  93,
  64,
  72,
  67,
  43,
  45,
  41,
  37,
  48,
  33,
  37,
  31,
  29,
  26,
  23,
  17,
  18,
  19,
  15,
  25,
  11,
  16,
  23,
  17,
  11,
  15,
  21,
  8,
  14,
  12,
  11,
  13,
  9,
  9,
  15,
  9,
  7,
  9,
  8,
  8,
  11,
  4,
  9,
  6,
  5,
  7,
  6,
  9,
  3,
  5,
  3,
  13,
  10,
  6,
  2,
  1,
  11,
  5,
  5,
  4,
  3,
  8,
  4,
  6,
  5,
  5,
  4,
  3,
  5,
  4,
  8,
  4,
  1,
  8,
  4,
  5,
  4,
  2,
  4,
  3,
  3,
  4,
  1,
  2,
  2,
  4,
  4,
  2,
  1,
  3,
  3,
  3,
  5,
  1,
  2,
  2,
  1,
  1,
  1,
  2,
  2,
  1,
  2,
  4,
  3,
  2,
  2,
  2,
  0,
  3,
  1,
  2,
  2,
  1,
  0,
  1,
  0,
  0,
  5,
  2,
  1,
  3,
  1,
  1,
  0,
  2,
  1,
  3,
  4,
  0,
  3,
  0,
  2,
  3,
  1,
  1,
  1,
  2,
  3,
  1,
  0,
  1,
  2,
  3,
  1,
  0,
  0,
  2,
  1,
  3,
  0,
  1,
  3,
  1,
  0,
  1,
  0,
  3,
  0,
  0,
  0,
  2,
  1,
  0,
  1,
  2,
  2,
  0,
  1,
  1,
  1,
  2,
  3,
  3,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  2,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  2,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  1,
  2,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  1,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  2,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  60,
  3731,
  2694,
  1564,
  1045,
  783,
  592,
  458,
  366,
  307,
  210,
  203,
  141,
  140,
  144,
  118,
  98,
  88,
  78,
  83,
  63,
  72,
  51,
  63,
  42,
  63,
  34,
  34,
  36,
  26,
  34,
  28,
  24,
  33,
  22,
  25,
  31,
  22,
  21,
  25,
  17,
  16,
  13,
  14,
  10,
  23,
  14,
  7,
  11,
  14,
  9,
  9,
  7,
  12,
  10,
  9,
  11,
  10,
  11,
  8,
  8,
  8,
  9,
  7,
  4,
  8,
  9,
  8,
  8,
  6,
  6,
  12,
  6,
  9,
  5,
  4,
  9,
  3,
  1,
  10,
  8,
  1,
  5,
  4,
  7,
  4,
  5,
  3,
  2,
  4,
  7,
  4,
  3,
  5,
  5,
  1,
  1,
  4,
  5,
  4,
  5,
  3,
  4,
  2,
  1,
  1,
  0,
  0,
  5,
  2,
  1,
  1,
  4,
  4,
  1,
  2,
  2,
  1,
  0,
  2,
  2,
  1,
  3,
  5,
  2,
  1,
  3,
  4,
  2,
  2,
  0,
  3,
  1,
  1,
  3,
  3,
  4,
  3,
  0,
  4,
  1,
  2,
  1,
  1,
  1,
  1,
  4,
  3,
  2,
  1,
  0,
  3,
  1,
  1,
  0,
  0,
  1,
  2,
  2,
  1,
  1,
  1,
  0,
  1,
  1,
  0,
  2,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  0,
  1,
  1,
  0,
  1,
  0,
  1,
  2,
  2,
  0,
  3,
  0,
  0,
  2,
  0,
  1,
  0,
  1,
  1,
  0,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  2,
  0,
  1,
  0,
  0,
  1,
  2,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  2,
  0,
  1,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  3,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  60,
  3639,
  2865,
  1712,
  1193,
  873,
  665,
  543,
  414,
  329,
  307,
  222,
  200,
  168,
  150,
  132,
  97,
  93,
  94,
  87,
  78,
  73,
  51,
  68,
  50,
  35,
  38,
  30,
  30,
  39,
  39,
  30,
  27,
  38,
  26,
  20,
  21,
  25,
  12,
  17,
  25,
  18,
  11,
  15,
  19,
  17,
  18,
  20,
  14,
  8,
  15,
  12,
  11,
  14,
  11,
  9,
  14,
  6,
  15,
  8,
  12,
  18,
  7,
  10,
  8,
  5,
  7,
  9,
  9,
  8,
  10,
  5,
  10,
  4,
  7,
  10,
  7,
  4,
  4,
  6,
  6,
  1,
  6,
  6,
  2,
  8,
  6,
  4,
  2,
  5,
  6,
  2,
  5,
  3,
  3,
  10,
  4,
  3,
  4,
  2,
  5,
  3,
  3,
  2,
  5,
  0,
  5,
  3,
  2,
  2,
  0,
  2,
  4,
  1,
  3,
  3,
  1,
  2,
  4,
  3,
  3,
  0,
  3,
  4,
  1,
  2,
  1,
  3,
  3,
  3,
  1,
  3,
  1,
  1,
  2,
  1,
  1,
  2,
  1,
  2,
  2,
  2,
  1,
  5,
  2,
  3,
  1,
  1,
  1,
  2,
  0,
  2,
  1,
  1,
  1,
  0,
  1,
  1,
  3,
  3,
  2,
  3,
  0,
  0,
  1,
  1,
  0,
  3,
  1,
  0,
  3,
  1,
  2,
  1,
  1,
  0,
  1,
  1,
  0,
  1,
  2,
  1,
  1,
  0,
  0,
  1,
  1,
  1,
  1,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  2,
  0,
  0,
  1,
  2,
  0,
  3,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  1,
  0,
  0,
  1,
  0,
  1,
  1,
  1,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  2,
  0,
  1,
  1,
  1,
  0,
  2,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  3,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  2,
  0,
  2,
  2,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  1,
  1,
  1,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  44,
  3057,
  3105,
  2056,
  1400,
  1099,
  744,
  610,
  533,
  403,
  2096,
  301,
  254,
  184,
  171,
  170,
  137,
  124,
  116,
  103,
  86,
  79,
  88,
  75,
  61,
  67,
  58,
  54,
  53,
  54,
  44,
  37,
  36,
  43,
  36,
  123,
  40,
  37,
  29,
  24,
  22,
  15,
  32,
  21,
  21,
  18,
  13,
  18,
  17,
  18,
  16,
  11,
  11,
  10,
  10,
  12,
  13,
  12,
  9,
  14,
  8,
  9,
  10,
  6,
  10,
  9,
  10,
  10,
  10,
  8,
  9,
  3,
  9,
  12,
  6,
  6,
  7,
  6,
  7,
  6,
  7,
  7,
  9,
  5,
  5,
  6,
  5,
  3,
  6,
  10,
  8,
  8,
  5,
  5,
  2,
  4,
  6,
  2,
  3,
  7,
  4,
  4,
  1,
  3,
  3,
  6,
  9,
  3,
  2,
  2,
  6,
  4,
  3,
  3,
  1,
  8,
  2,
  4,
  2,
  3,
  2,
  0,
  3,
  2,
  2,
  3,
  2,
  3,
  4,
  4,
  0,
  3,
  1,
  4,
  3,
  2,
  2,
  1,
  1,
  2,
  3,
  2,
  3,
  2,
  3,
  3,
  3,
  2,
  0,
  1,
  1,
  2,
  0,
  0,
  1,
  1,
  1,
  2,
  1,
  3,
  1,
  2,
  1,
  2,
  0,
  2,
  0,
  0,
  1,
  0,
  2,
  0,
  0,
  2,
  3,
  2,
  0,
  0,
  1,
  0,
  0,
  0,
  2,
  2,
  0,
  3,
  1,
  0,
  0,
  4,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  2,
  0,
  1,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  1,
  1,
  0,
  2,
  1,
  0,
  4,
  0,
  1,
  1,
  0,
  2,
  1,
  1,
  0,
  0,
  1,
  1,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  2,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  1,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  56,
  2673,
  3289,
  2316,
  1784,
  1288,
  1054,
  739,
  616,
  616,
  391,
  341,
  302,
  230,
  211,
  185,
  154,
  163,
  140,
  126,
  121,
  110,
  89,
  88,
  65,
  74,
  70,
  67,
  58,
  54,
  66,
  67,
  49,
  49,
  38,
  28,
  51,
  41,
  28,
  29,
  27,
  30,
  27,
  32,
  14,
  19,
  23,
  24,
  21,
  22,
  22,
  17,
  5,
  16,
  21,
  15,
  13,
  10,
  25,
  14,
  11,
  9,
  19,
  18,
  8,
  15,
  8,
  10,
  9,
  11,
  11,
  11,
  15,
  6,
  15,
  12,
  9,
  4,
  7,
  8,
  6,
  6,
  8,
  10,
  1,
  8,
  7,
  8,
  11,
  5,
  6,
  5,
  3,
  14,
  7,
  6,
  4,
  9,
  7,
  5,
  5,
  5,
  7,
  2,
  5,
  2,
  4,
  4,
  2,
  5,
  3,
  3,
  5,
  6,
  3,
  7,
  3,
  4,
  3,
  3,
  4,
  2,
  2,
  3,
  2,
  3,
  2,
  3,
  1,
  2,
  3,
  3,
  4,
  1,
  1,
  1,
  2,
  4,
  0,
  1,
  1,
  2,
  2,
  0,
  0,
  2,
  2,
  2,
  5,
  2,
  3,
  1,
  0,
  4,
  0,
  5,
  3,
  0,
  2,
  5,
  2,
  1,
  4,
  3,
  0,
  3,
  4,
  2,
  1,
  2,
  2,
  0,
  0,
  2,
  2,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  3,
  3,
  0,
  0,
  0,
  2,
  0,
  1,
  2,
  3,
  0,
  2,
  0,
  0,
  1,
  1,
  1,
  1,
  0,
  2,
  1,
  1,
  1,
  1,
  4,
  0,
  0,
  2,
  0,
  1,
  1,
  0,
  2,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  1,
  1,
  0,
  0,
  1,
  2,
  2,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  2,
  1,
  0,
  0,
  1,
  2,
  0,
  2,
  0,
  0,
  1,
  1,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  2,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  49,
  2401,
  3311,
  2705,
  2084,
  1573,
  1260,
  1007,
  814,
  704,
  505,
  433,
  335,
  321,
  301,
  262,
  230,
  178,
  157,
  143,
  147,
  124,
  91,
  125,
  95,
  94,
  71,
  82,
  79,
  76,
  55,
  60,
  61,
  52,
  58,
  49,
  42,
  48,
  37,
  40,
  30,
  37,
  28,
  21,
  37,
  34,
  28,
  34,
  27,
  26,
  21,
  20,
  19,
  14,
  18,
  19,
  19,
  19,
  13,
  19,
  16,
  16,
  18,
  23,
  13,
  14,
  10,
  17,
  13,
  14,
  11,
  7,
  9,
  9,
  6,
  8,
  9,
  11,
  15,
  9,
  15,
  10,
  5,
  9,
  12,
  10,
  11,
  10,
  7,
  6,
  6,
  8,
  7,
  6,
  3,
  5,
  8,
  11,
  4,
  5,
  7,
  5,
  3,
  3,
  5,
  6,
  4,
  5,
  5,
  3,
  4,
  3,
  9,
  7,
  7,
  4,
  5,
  8,
  2,
  2,
  3,
  4,
  8,
  6,
  6,
  3,
  5,
  3,
  6,
  6,
  7,
  2,
  4,
  2,
  1,
  3,
  0,
  2,
  0,
  2,
  4,
  3,
  4,
  1,
  1,
  4,
  1,
  2,
  1,
  3,
  2,
  1,
  3,
  3,
  4,
  3,
  5,
  3,
  3,
  0,
  4,
  4,
  2,
  0,
  2,
  1,
  1,
  4,
  0,
  0,
  3,
  3,
  1,
  2,
  0,
  2,
  4,
  0,
  0,
  0,
  3,
  4,
  0,
  0,
  4,
  0,
  4,
  2,
  1,
  0,
  3,
  2,
  0,
  1,
  0,
  0,
  2,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  4,
  0,
  1,
  0,
  1,
  0,
  2,
  2,
  1,
  1,
  1,
  0,
  1,
  0,
  2,
  0,
  0,
  0,
  2,
  0,
  0,
  1,
  0,
  1,
  1,
  0,
  1,
  1,
  0,
  0,
  2,
  1,
  2,
  0,
  3,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  1,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  1,
  1,
  0,
  0,
  1,
  0,
  1,
  0,
  0,
  2,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  3,
  0,
  0,
  0,
  0,
  2,
  0,
  1,
  0,
  0,
  3,
  0,
  0,
  0,
  1,
  0,
  0,
  57,
  2371,
  3257,
  3169,
  2619,
  2155,
  1684,
  1350,
  1100,
  830,
  702,
  551,
  532,
  434,
  361,
  318,
  307,
  255,
  227,
  214,
  195,
  141,
  167,
  149,
  118,
  125,
  99,
  98,
  108,
  105,
  89,
  77,
  81,
  70,
  71,
  64,
  66,
  56,
  69,
  50,
  54,
  42,
  36,
  48,
  40,
  36,
  33,
  42,
  26,
  32,
  37,
  18,
  31,
  27,
  32,
  31,
  26,
  19,
  27,
  27,
  23,
  30,
  16,
  26,
  14,
  19,
  13,
  17,
  14,
  10,
  15,
  20,
  17,
  12,
  13,
  13,
  8,
  18,
  10,
  15,
  11,
  19,
  8,
  6,
  10,
  13,
  10,
  10,
  8,
  11,
  6,
  12,
  7,
  6,
  9,
  18,
  9,
  6,
  10,
  7,
  11,
  6,
  9,
  3,
  9,
  6,
  6,
  6,
  7,
  8,
  9,
  9,
  5,
  3,
  11,
  5,
  3,
  6,
  6,
  5,
  7,
  2,
  3,
  7,
  3,
  2,
  4,
  3,
  4,
  4,
  7,
  1,
  4,
  5,
  6,
  2,
  2,
  2,
  2,
  2,
  4,
  3,
  3,
  5,
  5,
  5,
  2,
  2,
  2,
  0,
  8,
  4,
  2,
  2,
  1,
  2,
  1,
  1,
  0,
  5,
  3,
  1,
  1,
  0,
  1,
  4,
  4,
  1,
  2,
  2,
  4,
  0,
  1,
  2,
  3,
  2,
  2,
  0,
  3,
  2,
  4,
  1,
  3,
  1,
  1,
  1,
  1,
  1,
  1,
  3,
  1,
  1,
  1,
  3,
  0,
  1,
  4,
  1,
  1,
  1,
  2,
  1,
  0,
  0,
  0,
  1,
  0,
  5,
  2,
  1,
  1,
  1,
  0,
  2,
  0,
  1,
  0,
  1,
  4,
  1,
  0,
  0,
  2,
  0,
  1,
  0,
  1,
  1,
  0,
  1,
  3,
  0,
  0,
  1,
  2,
  1,
  1,
  0,
  1,
  1,
  1,
  1,
  2,
  2,
  0,
  1,
  0,
  1,
  2,
  0,
  1,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  2,
  0,
  0,
  0,
  0,
  0,
  3,
  2,
  2,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  0,
  3,
  0,
  1,
  1,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  1,
  1,
  0,
  1,
  0,
  91,
  2580,
  3056,
  3766,
  3217,
  2785,
  2235,
  1831,
  1579,
  1383,
  1072,
  932,
  775,
  694,
  608,
  513,
  499,
  399,
  330,
  309,
  289,
  250,
  258,
  194,
  236,
  160,
  157,
  146,
  139,
  137,
  110,
  111,
  102,
  119,
  91,
  101,
  95,
  80,
  63,
  63,
  71,
  89,
  56,
  64,
  63,
  63,
  60,
  62,
  49,
  53,
  52,
  37,
  46,
  51,
  31,
  28,
  29,
  38,
  29,
  27,
  31,
  27,
  36,
  27,
  20,
  32,
  26,
  22,
  31,
  24,
  27,
  16,
  19,
  20,
  26,
  14,
  10,
  21,
  12,
  18,
  10,
  25,
  14,
  13,
  14,
  12,
  17,
  16,
  15,
  11,
  18,
  16,
  12,
  9,
  5,
  10,
  15,
  15,
  11,
  5,
  18,
  10,
  8,
  16,
  4,
  7,
  8,
  13,
  10,
  9,
  7,
  9,
  8,
  13,
  11,
  8,
  7,
  8,
  7,
  6,
  7,
  6,
  5,
  4,
  6,
  6,
  8,
  13,
  5,
  4,
  7,
  8,
  6,
  3,
  6,
  4,
  6,
  5,
  2,
  5,
  3,
  5,
  7,
  5,
  4,
  8,
  9,
  6,
  3,
  3,
  6,
  5,
  3,
  6,
  8,
  4,
  3,
  3,
  1,
  3,
  1,
  4,
  4,
  2,
  4,
  5,
  2,
  3,
  7,
  2,
  2,
  4,
  4,
  3,
  2,
  1,
  2,
  1,
  2,
  2,
  2,
  2,
  1,
  2,
  4,
  2,
  4,
  3,
  0,
  2,
  2,
  2,
  1,
  4,
  3,
  1,
  0,
  2,
  1,
  2,
  1,
  5,
  4,
  2,
  0,
  1,
  1,
  3,
  2,
  2,
  0,
  3,
  1,
  1,
  2,
  2,
  2,
  5,
  0,
  2,
  1,
  3,
  3,
  2,
  3,
  1,
  1,
  1,
  1,
  2,
  3,
  1,
  3,
  1,
  0,
  0,
  1,
  1,
  0,
  3,
  1,
  0,
  4,
  1,
  1,
  1,
  0,
  1,
  0,
  4,
  2,
  3,
  2,
  1,
  1,
  0,
  0,
  1,
  0,
  3,
  2,
  1,
  2,
  0,
  1,
  0,
  3,
  0,
  0,
  2,
  0,
  0,
  1,
  1,
  2,
  0,
  0,
  0,
  2,
  0,
  0,
  1,
  0,
  0,
  0,
  2,
  1,
  1,
  0,
  1,
  1,
  0,
  2,
  0,
  0,
  1,
  0,
  1,
  2,
  0,
  0,
  115,
  3536,
  2858,
  4006,
  4350,
  4194,
  3748,
  3168,
  2784,
  2357,
  1996,
  1689,
  1513,
  1305,
  1102,
  975,
  867,
  772,
  656,
  587,
  536,
  511,
  427,
  398,
  428,
  344,
  320,
  328,
  263,
  239,
  229,
  230,
  219,
  216,
  173,
  159,
  151,
  132,
  126,
  140,
  136,
  132,
  95,
  117,
  116,
  117,
  94,
  94,
  95,
  79,
  79,
  93,
  93,
  65,
  76,
  74,
  69,
  50,
  60,
  48,
  61,
  43,
  60,
  48,
  53,
  46,
  44,
  53,
  37,
  44,
  49,
  41,
  37,
  32,
  26,
  32,
  34,
  27,
  32,
  35,
  37,
  30,
  31,
  32,
  24,
  28,
  24,
  29,
  24,
  31,
  23,
  30,
  27,
  22,
  24,
  16,
  18,
  11,
  15,
  14,
  16,
  20,
  18,
  14,
  15,
  13,
  12,
  18,
  24,
  17,
  9,
  11,
  16,
  16,
  17,
  7,
  14,
  10,
  9,
  5,
  13,
  9,
  12,
  12,
  16,
  12,
  13,
  8,
  12,
  9,
  11,
  6,
  16,
  12,
  6,
  6,
  5,
  6,
  13,
  2,
  5,
  10,
  7,
  9,
  12,
  6,
  8,
  9,
  7,
  9,
  10,
  4,
  5,
  8,
  7,
  7,
  7,
  7,
  5,
  8,
  6,
  6,
  6,
  0,
  8,
  6,
  4,
  7,
  5,
  7,
  2,
  9,
  3,
  6,
  5,
  2,
  6,
  3,
  3,
  9,
  1,
  2,
  3,
  3,
  3,
  5,
  2,
  3,
  2,
  3,
  3,
  3,
  8,
  3,
  4,
  3,
  4,
  5,
  3,
  2,
  2,
  2,
  0,
  2,
  3,
  3,
  2,
  1,
  2,
  0,
  4,
  4,
  5,
  1,
  3,
  4,
  3,
  0,
  8,
  1,
  1,
  1,
  1,
  5,
  2,
  1,
  3,
  2,
  2,
  3,
  4,
  2,
  1,
  2,
  2,
  2,
  3,
  2,
  2,
  2,
  1,
  4,
  2,
  3,
  0,
  3,
  2,
  2,
  1,
  0,
  1,
  2,
  0,
  0,
  0,
  2,
  0,
  1,
  0,
  1,
  2,
  1,
  1,
  0,
  2,
  3,
  0,
  1,
  1,
  5,
  1,
  0,
  0,
  0,
  0,
  1,
  2,
  0,
  0,
  1,
  1,
  2,
  1,
  3,
  1,
  1,
  0,
  1,
  2,
  3,
  1,
  0,
  2,
  0,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  145,
  16346,
  5561,
  5626,
  6889,
  8179,
  8830,
  8917,
  8796,
  8609,
  8558,
  7965,
  7712,
  7138,
  6662,
  6271,
  5705,
  5257,
  5003,
  4722,
  4298,
  3942,
  3730,
  3403,
  3110,
  3017,
  2841,
  2582,
  2495,
  2324,
  2201,
  2068,
  1866,
  1782,
  1689,
  1574,
  1525,
  1359,
  1351,
  1273,
  1264,
  1203,
  1180,
  1075,
  993,
  970,
  925,
  873,
  842,
  844,
  773,
  758,
  735,
  726,
  681,
  608,
  620,
  609,
  583,
  528,
  494,
  490,
  479,
  478,
  476,
  444,
  436,
  399,
  386,
  370,
  337,
  365,
  357,
  336,
  304,
  343,
  299,
  296,
  284,
  305,
  316,
  252,
  249,
  278,
  254,
  258,
  260,
  245,
  231,
  250,
  220,
  206,
  230,
  215,
  177,
  196,
  179,
  189,
  170,
  171,
  184,
  194,
  177,
  153,
  150,
  138,
  128,
  135,
  136,
  127,
  140,
  145,
  149,
  120,
  139,
  131,
  126,
  133,
  136,
  129,
  104,
  103,
  145,
  125,
  101,
  95,
  123,
  106,
  110,
  98,
  105,
  111,
  94,
  85,
  86,
  80,
  84,
  92,
  92,
  86,
  59,
  88,
  104,
  80,
  72,
  63,
  60,
  80,
  79,
  76,
  63,
  72,
  70,
  65,
  66,
  45,
  53,
  67,
  57,
  59,
  65,
  57,
  64,
  69,
  57,
  63,
  48,
  52,
  52,
  38,
  45,
  55,
  42,
  46,
  39,
  42,
  42,
  56,
  47,
  30,
  37,
  43,
  41,
  43,
  42,
  31,
  41,
  40,
  30,
  35,
  35,
  34,
  44,
  49,
  39,
  36,
  49,
  29,
  38,
  32,
  37,
  35,
  33,
  35,
  33,
  29,
  34,
  31,
  27,
  35,
  40,
  27,
  30,
  30,
  34,
  28,
  17,
  29,
  23,
  23,
  23,
  26,
  25,
  26,
  23,
  17,
  23,
  21,
  25,
  19,
  32,
  26,
  23,
  24,
  11,
  27,
  15,
  15,
  23,
  18,
  22,
  26,
  15,
  18,
  24,
  18,
  15,
  27,
  15,
  23,
  13,
  17,
  18,
  22,
  17,
  19,
  16,
  24,
  17,
  11,
  16,
  13,
  14,
  12,
  15,
  15,
  17,
  20,
  21,
  12,
  24,
  12,
  16,
  16,
  12,
  12,
  12,
  15,
  16,
  14,
  25,
  11,
  12,
  13,
  17,
  13,
  11,
  14,
  16,
  10,
  7,
  13,
  12,
  12,
  15,
  11,
  10,
  7,
  14,
  19,
  1,
  1756
]

let medians = [
  1,
  1.5,
  2,
  2.25,
  2.6,
  3,
  3.285714286,
  3.5,
  4.666666667,
  4.6,
  5.090909091,
  5.666666667,
  6.615384615,
  8.071428571,
  15,
]

const cutoff = 100

let tmpData = []
for (let i = 1; i <= 15; i ++) {
  let tmp = rawData.splice(0, cutoff - 1)
  tmp.push(rawData.splice(0, 301 - cutoff + 1).reduce((a, b) => a + b, 0))
  // const sum = tmp.reduce((a, b) => a + b, 0)
  tmpData = tmpData.concat(tmp.map((e, tx) => [i, tx + 1, e === 0 ? 1e-8 : e]))
}


export default function Heatmap({ cohort }) {
  // console.log(tmpData)

  return(
    <LazyHighcharts
      options={{
        series: [
          {
            name: "จำนวนผู้ใช้บริการ",
            data: tmpData,
            tooltip: {
              pointFormat: "{point.value} คน",
              // formatter: function() {
              //   console.log(this)
              //   return `${this.point.value.toLocaleString()} คน`
              // },
            },
          },
          {
            name: "มัธยฐาน",
            data: medians,
            type: "spline",
            pointStart: 1,
            marker: {
              // enabled: false,
              fillColor: "white",
              lineColor: defaultStyles.colors[1],
              lineWidth: 2,
            },
            color: defaultStyles.colors[1],
            tooltip: {
              valueDecimals: 2,
            },
          }
        ],
        plotOptions: {
          // series: {
          //   pointStart: Date.UTC(2020, 6, 1),
          //   pointIntervalUnit: 'month',
          //   marker: {
          //     enabled: false,
          //   },
          // },
        },
        chart: {
          height: "600px",
          type: "heatmap",
        },
        colorAxis: {
          type: 'logarithmic',
          stops: [
              [0, "#333333"],
              [0.4, defaultStyles.colors[1]],
              [0.7, '#ffffca'],
              [1, defaultStyles.colors[0]],
          ],
          min: 1,
          max: 10000,
          layout: "vertical",
          reversed: false,
        },
        xAxis: {
          title: {
            text: "จำนวนเดือนที่ใช้งาน",
          },
          tickInterval: 1,
        },
        yAxis: {
          title: {
            text: "ปริมาณธุรกรรมเฉลี่ยต่อเดือนที่ใช้งาน",
          },
          max: cutoff,
          labels: {
            formatter: function () {
              return `${this.value === cutoff ? "≥" : ""} ${this.value}`
            },
          },
        },
        legend: {
          align: "right",
          verticalAlign: "middle",
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "70%",
              },
            },
          }]
        }
      }}
    />
  )

}