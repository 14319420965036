import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'

export default function TrustGini() {

  const countries = ["UKR", "KAZ", "KGZ", "KOR", "EGY", "DEU", "PAK", "BGD", "CYP", "TUN", "CAN", "TJK", "MMR", "PRT", "GRC", "ETH", "NGA", "VNM", "ROU", "THA", "RUS", "IDN", "SRB", "CHN", "IRN", "MYS", "USA", "ARG", "TUR", "PER", "PHL", "BOL", "CHL", "ECU", "MEX", "ZWE", "COL", "BRA", "AND", "AUS", "GTM", "HKG", "IRQ", "JOR", "JPN", "LBN", "MAC", "NIC", "NZL", "PRI", "SGP"]

  return(
    <LazyChartjs
      type="scatter"
      style={{maxWidth: '450px'}}
      data={{
        datasets: [
          {
            type: "scatter",
            data: [{x: 25.84000015, y: 0.300940037}, {x: 27.32500076, y: 0.228056431}, {x: 28.10000038, y: 0.128013209}, {x: 31.39999962, y: 0.329317272}, {x: 31.64999962, y: 0.07333333}, {x: 31.79999924, y: 0.445680618}, {x: 32.09999847, y: 0.233082712}, {x: 32.40000153, y: 0.129166663}, {x: 32.75, y: 0.065964758}, {x: 32.79999924, y: 0.138245031}, {x: 33.23333359, y: 0.466930151}, {x: 34, y: 0.205833331}, {x: 34.40000153, y: 0.150833338}, {x: 34.5, y: 0.169134736}, {x: 34.57500076, y: 0.084471963}, {x: 35, y: 0.118699186}, {x: 35.09999847, y: 0.130063519}, {x: 35.5, y: 0.276666671}, {x: 35.52500153, y: 0.114002943}, {x: 36.13999939, y: 0.288886756}, {x: 37.29999924, y: 0.229347616}, {x: 38.47999954, y: 0.046373185}, {x: 38.5, y: 0.163479924}, {x: 38.54999924, y: 0.634894729}, {x: 40.57500076, y: 0.148098737}, {x: 41.09999847, y: 0.195734963}, {x: 41.22499847, y: 0.370217174}, {x: 41.82500076, y: 0.191813305}, {x: 42, y: 0.13995859}, {x: 42.84000015, y: 0.041612707}, {x: 43.45000076, y: 0.053309843}, {x: 44.15999985, y: 0.08514756}, {x: 44.40000153, y: 0.129027218}, {x: 45.36000061, y: 0.057500001}, {x: 45.84999847, y: 0.104989827}, {x: 47.29999924, y: 0.021399178}, {x: 50.59999847, y: 0.045394737}, {x: 53.15999985, y: 0.06524758}],
            backgroundColor: getAreaColor(defaultStyles.colors[1]),
            borderWidth: 0,
            borderColor: 'none',
            // pointRadius: 8,
          },
          {
            type: "line",
            data: [{x: 25, y: -0.0078*25+0.4741}, {x: 55, y: -0.0078*55+0.4741}],
            borderColor: defaultStyles.colors[0],
            pointRadius: 0,
          }
        ]
      }}
      options={{
        aspectRatio: 1,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
               label: function(ctx) {
                  return countries[ctx.dataIndex] + ': (' + ctx.parsed.x + ', ' + ctx.parsed.y + ')';
               }
            }
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "ความเหลื่อมล้ำ (Gini Coefficient)",
            }
          },
          y: {
            title: {
              display: true,
              text: "ความสมานฉันท์ในสังคม (Trust)",
            }
          },
        },
      }}
    />
  )


}