import React from "react"
import { defaultStyles, LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = [
  {
    name: "Mobile/Internet",
    data: [88883,91184,106428,101049,114087,118074,127030,138333,145924,154153,161373,174995,183365,184589,212389,210536,240130,250821,270333,285158,295837,322743,325061,350741,351211,349865,399257,379043,419723,429151,462860,501050,514472,546532,555631,595510,586867,604773,675915,652507,775339,796234,839919,867938,854735,920378,991073,1044736,1062359,1039439,1185831,1095201,1207103,1224372,1445307,1416374,1439160,1628704,1645879,1704422],
  },
  {
    name: "e-Money",
    data: [103115,94642,105945,99104,102775,103518,105309,107941,106706,114475,115929,112777,114800,105563,116186,112216,116068,115145,118802,119960,119724,149669,154796,168081,154843,151635,166794,154272,159302,153427,156228,178525,176873,170111,166816,176927,171171,164316,165551,138349,158656,172988,184389,189447,187711,198846,198638,206086,189905,191493,225282,197413,206230,200163,203312,211691,218044,222186,229254,242508],
  },
  {
    name: "Credit/Debit Card",
    data: [48170,43290,47726,47127,49358,47790,50878,50828,49865,52516,53101,58381,57918,51980,58208,58544,59183,57171,61269,60502,59149,55731,55656,60794,64706,60536,65037,66591,66138,64693,70802,68299,67850,70757,70634,79579,75142,67759,67741,50093,58260,66235,69535,69919,69025,71256,73028,78496,69250,66593,78963,71904,72215,73910,72904,71897,78527,79415,79594,86837],
  },
  {
    name: "Bulk",
    data: [33921,33442,35435,32630,35814,33610,32626,34197,36019,34357,35646,38532,36119,36673,37923,36477,38729,36702,37096,39293,40841,39799,39836,41351,40692,40939,43389,41781,43477,39371,41987,41552,47999,46041,44566,48093,48810,48471,52552,60460,70827,71009,61133,50621,63124,49420,60851,77172,70660,89864,119721,89990,82401,81637,75283,78612,83265,77303,81889,95782],
  },
  {
    name: "BAHTNET",
    data: [323,325,386,310,353,334,304,351,340,325,341,336,364,333,365,326,362,365,344,384,338,376,365,336,383,335,385,366,388,364,396,386,371,372,355,355,406,375,429,360,330,374,381,362,372,371,366,378,368,330,433,367,351,395,381,387,406,357,391,397],
  },
  {
    name: "Counter",
    data: [776,753,830,635,785,750,708,767,696,697,712,674,690,618,628,540,592,546,536,569,518,547,528,490,492,456,494,478,480,435,452,424,409,406,387,383,381,357,364,274,257,288,284,284,288,274,265,274,244,224,272,219,222,227,212,214,232,198,211,221],
  },
  {
    name: "ATM",
    data: [32051,29552,34853,31253,34428,32088,32429,32917,31404,29833,28784,28456,27303,25453,26981,23425,24168,22449,22530,22238,21042,20417,18838,18450,16675,16044,16560,14233,14771,13317,13754,13613,13053,12630,12179,12772,10971,10977,10483,9032,9320,8895,9140,8826,9132,9049,9141,9506,8927,8025,8296,7738,8043,7329,8578,7713,7041,7240,6699,7388],
  },
]

export default function Overall() {

  const [showGrowth, setShowGrowth] = React.useState(false)

  function handleClick() {
    setShowGrowth(!showGrowth)
  }

  function getSeries(data) {
    if (!showGrowth) {
      return data.map(e => e/1000)
    }
    else {
      return data.map((e, i) => {
        if (i < 12)
          return null
        else
          return ((data[i] / data[i-12] - 1) * 100)
      })
    }
  }

  return(
    <div>
			<div>
        <LazyHighcharts
          options={{
            series: rawData.map(s => ({
              name: s.name,
              data: getSeries(s.data),
            })),
            plotOptions: {
              series: {
                pointStart: Date.UTC(2017, 0, 1),
                pointIntervalUnit: 'month',
                marker: {
                  enabled: false,
                },
              },
              areaspline: {
                stacking: "normal",
              }
            },
            legend: {
              maxHeight: 100,
            },
            chart: {
              type: showGrowth ? "spline" : "areaspline",
              zoomType: "x",
              height: "500px",
            },
            xAxis: {
              type: 'datetime',
            },
            tooltip: {
              xDateFormat: '%Y-%m',
              valueDecimals: 2,
              valueSuffix: showGrowth ? "%" : "",
            },
            yAxis: {
              title: {
                text: showGrowth ? "อัตราการขยายตัว (% YoY)" : "ปริมาณธุรกรรม (ล้านรายการ)"
              },
              plotLines: [{
                value: 0,
                color: defaultStyles.zerolineColor,
                zIndex: 9,
              }],
            },
            responsive: {
              rules: [{
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    maxHeight: undefined,
                  },
                  chart: {
                    height: "60%",
                  },
                },
              }]
            }
          }}
        />
			</div>
			<div style={{textAlign: `center`, fontSize: `0.8rem`}}>
				<label>
					<input type="checkbox" onChange={handleClick} defaultChecked={showGrowth} /> แสดงอัตราการขยายตัว
				</label>
			</div>
		</div>
  )

}