import React from "react"
import { LazyPlotly } from '../../../../src/components/Plots'

import j from './df_date.json'

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

const reg_date = j.data.map((x => x[4]))
const count = j.data.map((x => x[1]))
const score = j.data.map((x => x[2]))
const year = j.data.map((x => x[3]))

const boxPlotSeriesOptions = {
	type: 'box',
	boxpoints: 'all',
	jitter: 0.6,
	pointpos: -2,
	marker: {
		size: 3,
	},
	hoverinfo: 'text+y',
	hovertemplate: '%{text}<br />จำนวน %{y:.0f} ราย<extra></extra>'
}

const catname = ['วันกาลกิณี', 'วันโชคร้าย', 'วันโชคดีปานกลาง', 'วันโชคดี', 'วันมหาโชค']

const boxPlotDataAll = [0, 0.25, 0.5, 0.75, 1].map((s, j) => {
	return {
		name: catname[j],
		y: count.filter((x, i) => score[i] === s),
		text: reg_date.filter((x, i) => score[i] === s),
		...boxPlotSeriesOptions
	}
})

const boxPlotDataEx2020 = [0, 0.25, 0.5, 0.75, 1].map((s, j) => {
	return {
		name: catname[j],
		y: count.filter((x, i) => (score[i] === s) && (year[i] < 2020)),
		text: reg_date.filter((x, i) => (score[i] === s) && (year[i] < 2020)),
		...boxPlotSeriesOptions
	}
})

export default function YearFilter() {

	const cx = classNames.bind(styles);

	const [showAll, setShowAll] = React.useState(false)

	let boxPlot = {
		data: showAll ? boxPlotDataAll : boxPlotDataEx2020,
		layout: {
			showlegend: false,
			// title: {
			// 	text: 'จำนวนนิติบุคคลจดทะเบียนในวันต่าง ๆ',
			// },
			xaxis: {
				title: {
					text: 'ประเภทของวัน',
				},
				fixedrange: true,
			},
			yaxis: {
				title: {
					text: 'จำนวน (ราย)',
				},
				range: [100, 500],
				fixedrange: true,
			},
			hovermode: 'closest',
			margin: {
				l: 50,
				r: 10,
				t: 10,
			},
		},
	}

	function handle2020(e) {
		setShowAll(e.target.checked)
	}

	return(
		<div>
			<div className={cx("plotlyContainer")}>
				<LazyPlotly {...boxPlot} />
			</div>
			<div style={{textAlign: `center`, fontSize: `0.8rem`}}>
				<label>
					<input type="checkbox" onChange={handle2020} defaultChecked={showAll} /> รวมข้อมูลปี 2563
				</label>
			</div>
		</div>
	)

}