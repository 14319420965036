import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"
import { cohortFormatter } from "./util"

const rawData = [
  {
    name: "15–19",
    data: [443533,480176,510132,538075,575012,612994,642046,682460,732153,761935,806179,860480,920865,1033183,1137632,1180426,1223792,1283373],
  },
  {
    name: "20–29",
    data: [6100641,6107664,6120155,6163508,6211271,6270323,6319931,6390588,6477173,6467835,6524278,6577971,6704419,6784163,6865034,6908107,6920782,6964177],
  },
  {
    name: "30–39",
    data: [6010864,6008249,6012183,6039379,6080738,6116369,6143476,6187335,6249996,6247969,6291686,6327552,6448168,6528118,6584714,6612352,6617871,6645582],
  },
  {
    name: "40–49",
    data: [4827341,4849580,4863517,4911269,4977963,5026080,5064744,5109072,5180597,5172534,5218550,5261991,5426402,5533636,5592587,5636471,5646695,5672218],
  },
  {
    name: "50–59",
    data: [2820563,2845596,2854174,2900152,2972400,3011679,3058322,3083073,3149572,3118763,3156567,3196068,3376458,3471862,3512693,3566874,3574804,3590021],
  },
  {
    name: "≥ 60",
    data: [1602167,1630473,1631259,1670543,1722235,1747312,1780159,1783286,1807634,1780488,1802683,1836323,1976009,1985716,1987137,2060111,2071846,2076450],
  },
]

const transposedData = [...rawData[0].data.keys()].map(i => ({
  name: cohortFormatter(i + 7),
  data: rawData.map(ageObj => ageObj.data[i])
}))

const pop = [
  3983216,
  9102229,
  9332320,
  10195309,
  9815897,
  12071837,
]

export default function Age() {

  const [showRatio, setShowRatio] = React.useState(true)

  function handleClick() {
    setShowRatio(!showRatio)
  }

  const labels = rawData.map((age, i) => ({
    labels: [0, 1].map(j => ({
      text: (age.data[j === 0 ? age.data.length - 1 : 0] / pop[i] * 100).toFixed(0),
      point: {
        x: i + (j === 0 ? 0.4 : -0.4),
        y: age.data[j === 0 ? age.data.length - 1 : 0] / pop[i] * 100,
        xAxis: 0,
        yAxis: 0,
      },
    })),
  }))

  return(
    <div>
			<div>
        <LazyHighcharts
          options={{
            series: showRatio
              ? transposedData.map(g => ({
                  name: g.name,
                  data: g.data.map((d, i) => d / pop[i] * 100)
                }))
              : transposedData,
            annotations: showRatio ? labels : [],
            plotOptions: {
              column: {
                colors: [...rawData[0].data.keys()].map(e => defaultStyles.colors[1]),
                colorByPoint: true,
                groupPadding: 0.1,
                crisp: false,
              }
            },
            legend: {
              enabled: false,
            },
            chart: {
              type: "column",
              height: "300px",
            },
            xAxis: {
              categories: rawData.map(o => o.name)
            },
            tooltip: {
              valueDecimals: showRatio ? 2 : 0,
              valueSuffix: showRatio ? "%" : "",
            },
            yAxis: {
              title: {
                text: showRatio ? "สัดส่วนเทียบกับประชากรในกลุ่มอายุ (%)" : "จำนวนผู้ใช้บริการ"
              }
            },
            responsive: {
              rules: [{
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    maxHeight: undefined,
                  },
                  chart: {
                    height: "50%",
                  },
                },
              }]
            }
          }}
        />
			</div>
			<div style={{textAlign: `center`, fontSize: `0.8rem`}}>
				<label>
					<input type="checkbox" onChange={handleClick} defaultChecked={showRatio} /> แสดงสัดส่วนของประชากร
				</label>
			</div>
		</div>
  )

}