import React from "react"
import { LazyHighcharts } from "../../../../src/components/Plots"

const rawData = [
  {
    name: 'รายได้ต่อหัว',
    data: [5082.2563, 5091.9604, 5425.688, 5537.745, 5561.7, 5708.797, 5878.757, 6099.201, 6336.2427, 6456.242, 6042.0195, 6123.947],
  },
  {
    name: 'ดัชนีความสุข',
    data: [null, 5.80, null, null, 6.46, 6.47, 6.42, 6.07, 6.01, 6.00, 5.98, 5.89],
  },
  {
    name: 'HDI',
    data: [0.737, 0.743, 0.746, 0.747, 0.778, 0.781, 0.785, 0.790, 0.795, 0.804, 0.802, 0.800],
  },
  {
    name: 'ทุนธรรมชาติ',
    data: [null, 8048, 6756, 7192, 6808, 6888, 6492, 6044, 5916],
  },
]

export default function Ab2323Fig1() {
  return (
    <LazyHighcharts
      options={{
        series: rawData.map((d, i) => ({
          name: d.name,
          // normalize data to have the first non-null value = 100
          data: d.data.map((v, j) => v === null ? null : v / d.data.find(v => v !== null) * 100),
          // data: d.data,
          // yAxis: i,
        })),
        plotOptions: {
          series: {
            pointStart: 2010,
            connectNulls: true,
          },
        },
        yAxis: {
          title: {
            text: 'ดัชนี (ปีแรกที่มีข้อมูล = 100)'
          },
        },
      }}
    />
  )
}