import React from 'react'
import chroma from 'chroma-js'
import { LazyChartjs, defaultStyles } from '../../../../src/components/Plots'

import styles from "./styles.module.scss"

const countryList = ["ไทย", "อินโดนีเซีย", "มาเลเซีย", "ฟิลิปปินส์", "เวียดนาม"]
const colorList = [
  defaultStyles.colors[0],
  ...chroma.brewer.Pastel1.slice(0, 4).map(c => chroma(c).saturate(0.4).alpha(0.8).hex())
]

function ValueAddedLabel() {

  return(
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "10px",
    }}>
      {
        [...Array(5).keys()].map(i =>
          <div key={`x${i}`} style={{flexGrow: "0", flexBasis: "10px"}}>
            <div
              className="center"
              style={{
                height: "4px",
                width: "20px",
                borderRadius: "2px",
                backgroundColor: colorList[i],
                marginBottom: "4px",
              }}
            />
            <span style={{fontFamily: "Dindan"}}>{countryList[i]}</span>
          </div>
        )
      }
    </div>
  )

}

function ValueAdded({ title, data, showTicks=true, ...rest }) {

  return(
    <LazyChartjs
      type="line"
      style={{minWidth: 0, width: "100%", minHeight: "300px"}}
      data={{
        labels: ["2011", "2016"],
        datasets: countryList.map((c, i) => ({
          label: c,
          pointStyle: 'line',
          data: data[i],
          borderColor: colorList[i],
          backgroundColor: `rgba(255, 255, 255, 0)`,
          pointRadius: 0,
          borderWidth: i === 0 ? 8 : 2,
        }))
      }}
      options={{
        // maintainAspectRatio: true,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        maintainAspectRatio: false,
        // aspectRatio: 0.6,
        layout: {
          padding: 0,
        },
        scales: {
          y: {
            grid: {
              color: 'rgba(255, 255, 255, 0)',
            },
            title: {
              display: showTicks,
              text: "สัดส่วนมูลค่าเพิ่มที่สร้างได้ในประเทศ (%)"
            },
            min: 0,
            max: 100,
            ticks: {
              display: showTicks,
              // callback: () => "1",
              // color: 'rgba(255, 255, 255, 0)',
            },
          }
        },
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: false,
            labels: {
              pointStyle: 'line',
              usePointStyle: true,
            }
          },
          tooltip: {
            usePointStyle: true,
          }
        },
      }}
      {...rest}
    />
  )

}

export default function AllGraphs() {

  return(
    <div className={styles.valueAdded}>

      <ValueAdded
        title="Computers"
        data={[
          [43.49, 54.29],
          [61.07, 71.60],
          [39.08, 44.98],
          [64.22, 60.68],
          [45.76, 37.13],
        ]}
        showTicks={true}
      />
      <ValueAdded
        title="Chemicals"
        data={[
          [57.59, 67.78],
          [81.17, 86.29],
          [59.64, 64.85],
          [64.20, 71.54],
          [49.14, 53.58],
        ]}
      />
      <ValueAdded
        title="Motor vehicles"
        data={[
          [48.09, 54.23],
          [79.79, 83.78],
          [39.71, 44.43],
          [65.12, 59.06],
          [48.17, 48.46],
        ]}
      />
      <ValueAdded
        title="Rubbers"
        data={[
          [50.80, 58.59],
          [71.01, 74.38],
          [51.33, 56.20],
          [64.00, 68.08],
          [45.84, 48.21],
        ]}
      />
      <ValueAdded
        title="Food"
        data={[
          [80.40, 84.15],
          [90.83, 92.71],
          [67.05, 73.32],
          [88.52, 89.54],
          [62.12, 63.21],
        ]}
      />

      <ValueAddedLabel />

    </div>
  )

}