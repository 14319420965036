import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'

export default function ReplacementRates() {

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "400px"}}
      data={{
        labels: ["China", "Indonesia", "Philippines", "Argentina", "Brazil", "Chile", "Columbia", "Mexico", "Peru", "Canada", "France", "Germany", "Portugal", "Spain", "UK", "US"],
        datasets: [
          {
            label: "Low Income",
            data: [89.3, 59.95, 73.8, 85.85, 53.7, 55.45, 102.6, 57.3, 76.8, 77.3, 55.1, 43.2, 61.2, 73.9, 56.6, 50.3],
            backgroundColor: getAreaColor(defaultStyles.colors[0]),
            borderColor: 'none',
          },
          {
            label: "Average Income",
            data: [70.55, 59.95, 71.9, 73.9, 55.75, 39.15, 67.45, 29.05, 70.6, 44, 49, 43.2, 54.6, 73.9, 31.9, 38.7],
            backgroundColor: getAreaColor(defaultStyles.colors[1]),
            borderColor: 'none',
          },
          {
            label: "High Income",
            data: [61.15, 58.85, 70.1, 67.9, 59.85, 32.7, 67.45, 26.15, 35.3, 22, 37.1, 33.6, 53.6, 57.2, 16.8, 28.8],
            backgroundColor: getAreaColor(defaultStyles.colors[2]),
            borderColor: 'none',
          },
          {
            label: "Very High Income",
            data: [0, 0, 0, 65.9, 50.2, 32.4, 67.45, 25.5, 23.5, 14.7, 32.9, 22.4, 52.6, 38.1, 11.2, 20.6],
            backgroundColor: getAreaColor(defaultStyles.colors[3]),
            borderColor: 'none',
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        scales: {
          y: {
            title: {
              display: true,
              text: "Replacement Rate",
            }
          }
        },
        plugins: {
          // title: {
          //   display: true,
          //   text: title,
          // }
        }
      }}
    />
  )

}