import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"
import Alt from "./alt/pisa.png"

const rawData = [
	{name: "Math", data: [431, 420, 417, 421, 441, 409, 393]},
	{name: "Reading", data: [432, 417, 417, 419, 427, 415, 419]},
	{name: "Sciences", data: [436, 429, 421, 425, 444, 421, 426]},
]

export default function Pisa() {

  return(
    <div className="split-2">
      <LazyHighcharts
        altImage={Alt}
        options={{
          series: rawData,
          chart: {
            type: "spline",
            height: "300px",
          },
          plotOptions: {
            // series: {
            //   pointStart: 2543,
            //   pointInterval: 3,
            //   marker: {
            //     enabled: false,
            //   },
            // },
          },
          xAxis: {
            categories: ["2543", "2546", "2549", "2552", "2555", "2558", "2561"],
          },
          yAxis: {
            title: {
              text: "",
            },
            min: 300,
            max: 550,
            plotLines: [{
              color: "red",
              width: 2,
              value: 500,
              dashStyle: "Dash",
              label: {
                text: "ค่าเฉลี่ยของนักเรียนทั่วโลก",
                style: {
                  fontFamily: "Dindan",
                  fontSize: '14px',
                },
              },
            }],
          },
          legend: {
            verticalAlign: 'bottom',
            floating: true,
            align: 'right',
            y: -40,
            layout: 'vertical',
          },
          tooltip: {
            valueSuffix: " คะแนน",
          },
          responsive: {
            rules: [{
              condition: {
                minWidth: 700,
              },
              chartOptions: {
                legend: {
                  maxHeight: undefined,
                },
                chart: {
                  height: "50%",
                },
              },
            }]
          }
        }}
      />
    </div>
  )

}