import React from "react"
import useMediaQuery from '@mui/material/useMediaQuery';

export default function NextStrain() {

  return(
    <div style={{
      position: "relative",
      paddingBottom: "120%",
      minHeight: useMediaQuery("(min-width:600px)") ? "657px" : "unset",
      boxSizing: "border-box",
      background: 'url(https://www.pier.or.th/files/blog/2021/pier_blog_2021_1201.png) center center no-repeat',
      backgroundSize: 'contain',
    }}>
      <iframe
        title="NextStrain"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: "0",
          top: "0", 
        }}
        frameBorder="0"
        width="100%"
        height="100%" 
        src="https://nextstrain.org/groups/neherlab/ncov/21K.Omicron?d=tree&l=radial&m=div&p=full&sidebar=closed&onlyPanels"
      />
    </div>
  )

}