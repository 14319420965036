import React from "react";
import tambon from "./tambon.json";
import flood from "./flood.json";
import useMediaQuery from '@mui/material/useMediaQuery';
import { LazyPlotly } from "../../../../src/components/Plots"

export default function Flood() {
  const [heatmap, setHeatmap] = React.useState(false);
  const notSmall = useMediaQuery("(min-width:550px)");

  const frames = React.useMemo(
    () =>
      Object.entries(flood).map((obj, i) => {
        return {
          name: obj[0],
          data: [
            {
              lon: obj[1].map((point) => tambon[point.id].lon),
              lat: obj[1].map((point) => tambon[point.id].lat),
              hovertext: obj[1].map((point) => tambon[point.id].name),
              hovertemplate: `<b>%{hovertext}</b><br>${obj[0]}<br>%{${
                heatmap ? "z" : "marker.color"
              }} ไร่<br><extra></extra>`,
              mode: "markers",
              colorbar: {
                title: {
                  text: "พื้นที่น้ำท่วม (ไร่)"
                }
              },
              z: obj[1].map((point) => point.v),
              zmin: 0,
              zmax: 40000,
              colorscale: "Viridis",
              marker: {
                sizemode: "area",
                size: obj[1].map((point) => point.v / 50),
                color: obj[1].map((point) => point.v),
                colorscale: "Viridis",
                cmin: 0,
                cmax: 40000,
                showscale: notSmall,
                colorbar: {
                  title: {
                    text: "พื้นที่น้ำท่วม (ไร่)"
                  }
                }
              }
            }
          ]
        };
      }),
    [heatmap, notSmall]
  );

  const data = React.useMemo(
    () => [
      {
        type: heatmap ? "densitymapbox" : "scattermapbox",
        locationmode: "world",
        radius: 30,
        ...frames[0].data[0]
      }
    ],
    [frames, heatmap]
  );

  const sliderSteps = React.useMemo(
    () =>
      Object.keys(flood).map((key) => ({
        label: key,
        method: "animate",
        args: [
          [key],
          {
            mode: "immediate",
            transition: { duration: 300 },
            frame: { duration: 300 }
          }
        ]
      })),
    []
  );

  const layout = React.useMemo(
    () => ({
      height: notSmall ? 700 : 400,
      mapbox: {
        style: "stamen-terrain",
        center: {
          lat: 13,
          lon: 101.5
        },
        zoom: 4.5
      },
      margin: {
        t: 0,
        l: 10,
        r: notSmall ? 80 : 0
      },
      sliders: [
        {
          active: 0,
          steps: sliderSteps,
          x: 0,
          len: 1,
          xanchor: "left",
          y: 0,
          yanchor: "top",
          pad: { t: 10, b: 10 },
          currentvalue: {
            visible: false
          },
          transition: {
            duration: 300,
            easing: "cubic-in-out"
          }
        }
      ]
    }),
    [notSmall, sliderSteps]
  );

  return (
    <div>
      <LazyPlotly
        data={data}
        layout={layout}
        frames={frames}
        onUpdate={(figure) => {
          // setCur(figure.layout.sliders[0].active);
        }}
      />
      <div style={{textAlign: `center`, fontSize: `0.8rem`}}>
				<label>
					<input
            type="checkbox"
            onChange={() => {
              setHeatmap(!heatmap);
            }}
          /> Heatmap
				</label>
			</div>
    </div>
  );
}
