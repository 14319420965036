import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = [
  {name: "มูลค่านำเข้าข้าวโพด", data: [136965000,241760000,392138000,412408000]},
  {name: "มูลค่าส่งออกเมล็ดพันธุ์", data: [9153000,14883000,18838000,19213000]},
]

export default function Corn() {

  return(
    <LazyHighcharts
      options={{
        series: [
          {
            name: rawData[0].name,
            data: rawData[0].data,
            type: "column",
          },
          {
            name: rawData[1].name + "<br />(แกนขวา)",
            data: rawData[1].data,
            type: "spline",
            yAxis: 1,
          }
        ],
        plotOptions: {
          series: {
            pointStart: 2562,
          },
        },
        legend: {
          // enabled: false,
        },
        chart: {
          type: "column",
          height: "400px",
        },
        title: {
          text: "เมียนมาร์เป็นแหล่งนำเข้าข้าวโพด<br />และส่งออกเมล็ดพันธุ์ที่สำคัญขึ้นของไทย",
          style: {
            fontSize: '14px',
          },
        },
        tooltip: {
          // valueSuffix: " USD",
          formatter: function(tooltip) {
            let tmp = tooltip.defaultFormatter.call(this, tooltip);
            tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${(this.y / 1e6).toFixed(2)}</b> ล้านเหรียญสหรัฐฯ`)
            return tmp
          }
        },
        yAxis: [
          {
            title: {
              text: ""
            },
          },
          {
            title: {
              text: ""
            },
            opposite: true,
            min: 0,
          },
        ],
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}