import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"

export default function F2() {

  return (
    <LazyHighcharts
      options={{
        chart: {
          type: 'bar',
          height: "800px",
        },
        xAxis: {
          type: 'category',
          labels: {
            step: 1,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Pensionable age'
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          name: 'Pensionable age',
          crisp: false,
          colorByPoint: true,
          groupPadding: 0,
          data: [
            {name: 'Iceland', y: 67, color: defaultStyles.colors[0]},
            {name: 'Norway', y: 67, color: defaultStyles.colors[0]},
            {name: 'Netherlands', y: 66.3, color: defaultStyles.colors[0]},
            {name: 'Australia', y: 66, color: defaultStyles.colors[0]},
            {name: 'Ireland', y: 66, color: defaultStyles.colors[0]},
            {name: 'United Kingdom', y: 66, color: defaultStyles.colors[0]},
            {name: 'United States', y: 66, color: defaultStyles.colors[0]},
            {name: 'Germany', y: 65.7, color: defaultStyles.colors[0]},
            {name: 'Denmark', y: 65.5, color: defaultStyles.colors[0]},
            {name: 'Portugal', y: 65.3, color: defaultStyles.colors[0]},
            {name: 'Austria', y: 65, color: defaultStyles.colors[0]},
            {name: 'Belgium', y: 65, color: defaultStyles.colors[0]},
            {name: 'Canada', y: 65, color: defaultStyles.colors[0]},
            {name: 'Finland', y: 65, color: defaultStyles.colors[0]},
            {name: 'Japan', y: 65, color: defaultStyles.colors[0]},
            {name: 'Mexico', y: 65, color: defaultStyles.colors[0]},
            {name: 'New Zealand', y: 65, color: defaultStyles.colors[0]},
            {name: 'Poland', y: 65, color: defaultStyles.colors[0]},
            {name: 'Spain', y: 65, color: defaultStyles.colors[0]},
            {name: 'Sweden', y: 65, color: defaultStyles.colors[0]},
            {name: 'Switzerland', y: 65, color: defaultStyles.colors[0]},
            {name: 'Hong Kong, China', y: 65, color: defaultStyles.colors[1]},
            {name: 'Philippines', y: 65, color: defaultStyles.colors[1]},
            {name: 'Singapore', y: 65, color: defaultStyles.colors[1]},
            {name: 'France', y: 64.5, color: defaultStyles.colors[0]},
            {name: 'Hungary', y: 64.5, color: defaultStyles.colors[0]},
            {name: 'Czech Republic', y: 63.7, color: defaultStyles.colors[0]},
            {name: 'Slovak Republic', y: 62.8, color: defaultStyles.colors[0]},
            {name: 'Greece', y: 62, color: defaultStyles.colors[0]},
            {name: 'Italy', y: 62, color: defaultStyles.colors[0]},
            {name: 'Korea', y: 62, color: defaultStyles.colors[0]},
            {name: 'Luxembourg', y: 62, color: defaultStyles.colors[0]},
            {name: 'China', y: 60, color: defaultStyles.colors[1]},
            {name: 'Viet Nam', y: 60, color: defaultStyles.colors[1]},
            {name: 'Pakistan', y: 60, color: defaultStyles.colors[1]},
            {name: 'India', y: 58, color: defaultStyles.colors[1]},
            {name: 'Indonesia', y: 57, color: defaultStyles.colors[1]},
            {name: 'Malaysia', y: 55, color: defaultStyles.colors[1]},
            {name: 'Thailand', y: 55, color: defaultStyles.colors[1]},
            {name: 'Sri Lanka', y: 55, color: defaultStyles.colors[1]},
            {name: 'Turkey', y: 52, color: defaultStyles.colors[0]},
          ],
        }]
      }}
    />
  )

}