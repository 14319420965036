import React from "react"
import { SimpleChart } from "../../../../../src/components/Plots"

const rawData = [
  {name: "Low-skill", data: [18.9611,23.9764,28.7197,33.0952,36.6166,38.2966,38.0642,37.7897,39.3490,43.6879,49.6021]},
  {name: "Medium-skill", data: [27.3014,35.7986,42.3939,52.4957,57.6573,59.8276,63.1323,67.5909,73.3447,79.2621,83.7926]},
  {name: "High-skill", data: [58.2606,66.4519,82.1477,92.1860,104.1634,112.6084,126.0565,140.2517,158.2263,169.9351,185.8115]},
]

export default function Ab2023Fig4() {
  return(
    <SimpleChart
      data={rawData}
      xtitle={"ประสบการณ์ทำงาน (ปี)"}
      ytitle={"ค่าจ้าง (บาท/ชม.)"}
    />
  )
}

