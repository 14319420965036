import React from "react"
import { LazyHighcharts } from "../../../../src/components/Plots"

const data = `Year,Argentina,Australia,Bahrain,Bangladesh,Belgium,Brazil,Bulgaria,Canada,Cayman Islands,Chile,China,Colombia,Congo,Croatia,Cuba,Cyprus,Denmark,Ecuador,Egypt,El Salvador,Finland,France,Georgia,Germany,Ghana,Greece,Guatemala,Hong Kong,Iceland,India,Iran,Ireland,Isle of Man,Israel,Italy,Japan,North Korea,South Korea,Lithuania,Malaysia,Malta,Martinique,Mauritius,Mexico,Montenegro,Mozambique,Netherlands,New Zealand,Nigeria,Norway,Oman,Pakistan,Panama,Peru,Philippines,Poland,Portugal,Romania,Russia,Singapore,Slovenia,South Africa,Spain,Sudan,Sweden,Taiwan,Thailand,Trinidad and Tobago,Turkey,Ukraine,United Kingdom,United States,Uruguay,Venezuela,Viet Nam,Yemen
1816,,,,,,,,,,,,,,,,,,,,,,-26.308,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
1817,,,,,,,,,,,,,,,,,,,,,,-28.958,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
1818,,,,,,,,,,,,,,,,,,,,,,-19.183,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
1819,,,,,,,,,,,,,,,,,,,,,,-20.55,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
1820,,,,,,,,,,,,,,,,,,,,,,-24.283,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-0.308,,,,,,,,,,,,,,,,,,,,
1821,,,,,,,,,,,,,,,,,,,,,,-25.3,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,3.142,,,,,,,,,,,,,,,,,,,,
1822,,,,,,,,,,,,,,,,,,,,,,-28.083,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,18.594,,,,,,,,,,,,,,,,,,,,
1823,,,,,,,,,,,,,,,,,,,,,,-25.558,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,19.424,,,,,,,,,,,,,,,,,,,,
1824,,,,,,,,,,,,,,,,,,,,,,-26.667,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,26.465,,,,,,,,,,,,,,,,,,,,
1825,,,,,,,,,,,,,,,,,,,,,,-27.233,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,36.799,,,,,,,,,,,,,,,,,,,,
1826,,,,,,,,,,,,,,,,,,,,,,-32.6,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,30.457,,,,,,,,,,,,,,,,,,,,
1827,,,,,,,,,,,,,,,,,,,,,,-37.35,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,21.782,,,,,,,,,,,,,,,,,,,,
1828,,,,,,,,,,,,,,,,,,,,,,-36.333,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,25.765,,,,,,,,,,,,,,,,,,,,
1829,,,,,,,,,,,,,,,,,,,,,,-37.008,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,26.94,,,,,,,,,,,,,,,,,,,,
1830,,,,,,,,,,,,,,,,,,,,,,-35.85,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,26.299,,,,,,,,,,,,,,,,,,,,
1831,,,,,,,,,,,,,,,,,,,,,,-34.517,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,19.815,,,,,,,,,,,,,,,,,,,,
1832,,,,,,,,,,,,,,,,,,,,,,-39.433,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,2.787,,,,,,,,,,,,,,,,,,,,
1833,,,,,,,,,,,,,,,,,,,,,,-41.975,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-3.35,,,,,,,,,,,,,,,,,,,,
1834,,,,,,,,,,,,,,,,,,,,,,-44.583,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,2.567,,,,,,,,,,,,,,,,,,,,
1835,,,,,,,,,,,,,,,,,,,,,,-47.208,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-4.792,,,,,,,,,,,,,,,,,,,,
1836,,,,,,,,,,,,,,,,,,,,,,-46.18,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,4.725,,,,,,,,,,,,,,,,,,,,
1837,,,,,,,,,,,,,,,,,,,,,,-45.152,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,4.717,,,,,,,,,,,,,,,,,,,,
1838,,,,,,,,,,,,,,,,,,,,,,-44.123,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-0.833,,,,,,,,,,,,,,,,,,,,
1839,,,,,,,,,,,,,,,,,,,,,,-43.095,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-6.058,,,,,,,,,,,,,,,,,,,,
1840,,,,,,,,,,,,,,,,,,,,,,-42.067,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-7.042,,,,,,,,,,,,,,,,,,,,
1841,,,,,,,,,,,,,,,,,,,,,,-41.038,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-8.125,,,,,,,,,,,,,,,-18.109,,,,,
1842,,,,,,,,,,,,,,,,,,,,,,-40.01,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-9.45,,,,,,,,,,,,,,,-21.361,,,,,
1843,,,,,,,,,,,,,,,,,,,,,,-38.982,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-3.208,,,,,,,,,,,,,,,-21.515,,,,,
1844,,,,,,,,,,,,,,,,,,,,,,-37.953,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-7.367,,,,,,,,,,,,,,,-22.44,,,,,
1845,,,,,,,,,,,,,,,,,,,,,,-36.925,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-8.833,,,,,,,,,,,,,,,-21.265,,,,,
1846,,,,,,,,,,,,,,,,,,,,,,-35.897,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-6.742,,,,,,,,,,,,,,,-23.909,,,,,
1847,,,,,,,,,,,,,,,,,,,,,,-34.868,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-8.883,,,,,,,,,,,,,,,-22.339,,,,,
1848,,,,,,,,,,,,,,,,,,,,,,-33.84,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-12.308,,,,,,,,,,,,,,,-16.94,,,,,
1849,,,,,,,,,,,,,,,,,,,,,,-32.812,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-5.808,,,,,,,,,,,,,,,-12.468,,,,,
1850,,,,,,,,,,,,,,,,,,,,,,-31.783,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-5.7,,,,,,,,,,,,,,,-13.721,,,,,
1851,,,,,,,,,,,,,,,,,,,,,,-30.755,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,1.467,,,,,,,,,,,,,,,-14.171,,,,,
1852,,,,,,,,,,,,,,,,,,,,,,-29.727,,-19.538,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,4.458,,,,,,,,,,,,,,,-8.113,,,,,
1853,,,,,,,,,,,,,,,,,,,,,,-28.698,,-27.083,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-1.767,,,,,,,,,,,,,,,-7.118,,,,,
1854,,,,,,,,,,,,,,,,,,,,,,-27.67,,-24.421,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-2.225,,,,,,,,,,,,,,,-6.122,,,,,
1855,,,,,,,,,,,,,,,,,,,,,,-26.642,,-30.329,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,0.683,,,,,,,,,,,,,,,-5.127,,,,,
1856,,,,,,,,,,,,,,,,,,,,,,-26.133,,-33.738,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,-3.167,,,,,,,,,,,,,,,-4.131,,,,,
1857,,,,,,,,,,,,,,,,,,,,,,-27.285,,-35.16,,,,,,,,,,,,,,,,,,,,,,,3.208,,,,,,,,,-8.3,,,,,,,,,,,,,,,-3.136,,,,,
1858,,,,,,,,,,,,,,,,,,,,,,-26.126,,-35.913,,,,,,,,,,,,,,,,,,,,,,,-4.458,,,,,,,,,-3.85,,,,,,,,,,,,,,,-2.14,,,,,
1859,,,,,,,,,,,,,,,,,,,,,,-24.966,,-33.303,,,,,,,,,,,,,,,,,,,,,,,-0.958,,,,,,,,,-4.133,,,,,,,,,,,,,,,-1.145,,,,,
1860,,,,,,,,,,,,,,,,,,,,,,-23.807,,-36.608,,,,,,,,,,,,,,,,,,,,,,,3.25,,,,,,,,,-4.992,,,,,,,,,,,,,,,-0.15,,,,,
1861,,,,,,,,,,,,,,,,,,,,,,-22.647,,-36.525,,,,,,,,,,,,,,,,,,,,,,,5.5,,,,,,,,,-7.067,,,,,,,,,,,,,,,0.846,,,,,
1862,,,,,,,,,,,,,,,,,,,,,,-21.488,,-37.957,,,,,,,,,,,,,,,,,,,,,,,3.521,,,,,,,,,-10.225,,,,,,,,,,,,,,,1.841,,,,,
1863,,,,,,,,,,,,,,,,,,,,,,-20.328,,-29.912,,,,,,,,,,,,,,,,,,,,,,,6.208,,,,,,,,,-6.108,,,,,,,,,,,,,,,2.837,-44.254,,,,
1864,,,,,,,,,,,,,,,,,,,,,,-19.169,,-34.688,,,,,,,,,,,,,,,,,,,,,,,-0.458,,,,,,,,,-14.692,,,,,,,,,,,,,,,3.832,-47.187,,,,
1865,,,,,,,,,,,,,,,,,,,,,,-18.009,,-35.961,,,,,,,,,,,,,,,,,,,,,,,0.451,,,,,,,,,-21.975,,,,,,,,,,,,,,,4.827,-55.35,,,,
1866,,,,,,,,,,,,,,,,,,,,,,-16.85,,-36.119,,,,,,,,,,,,,,,,,,,,,,,10.104,,,,,,,,,-22.675,,,,,,,,,,,,,,,5.823,-53.446,,,,
1867,,,,,,,,,,,,,,,,,,,,,-51.561,-15.69,,-24.017,,,,,,,,,,,,,,,,,,,,,,,27.965,,,,,,,,,-6.792,,,,,,,,,,,,,,,6.818,-51.812,,,,
1868,,,,,,,,,,,,,,,,,,,,,-54.304,-14.531,,-15.33,,,,,,,,,,,,,,,,,,,,,,,43.639,,,,,,,,,1.3,,,,,,,,,,,,,,,7.814,-44.537,,,,
1869,,,,,,,,,,,,,,,,,,,,,-56.603,-30.681,,-12.797,,,,,,,,,,,,,,,,,,,,,,,49.993,,,,,,,,,3.092,,,,,,,,,,,,,,,8.809,-43.471,,,,
1870,,,,,,,,,,,,,,,,,,,,,-60.501,-42.108,,-11.174,,,,,,,,,,,,,,,,,,,,,,,43.34,,,,,,,,,3.75,,,,,,,,,,,,,,,9.805,-36.975,,,,
1871,,,,,,,,,,,,,,,,,,,,,-71.184,-44.775,,-13.988,,,,,,,,,,,,,,,,,,,,,,,42.049,,,,,,,,,-0.958,,,,,,,,,,,,,,,10.8,-24.333,,,,
1872,,,,,,,,,,,,,,,,,,,,,-76.032,-42.042,,-13.924,,,,,,,,,,,,,,,,,,,,,,,46.158,,,,,,,,,-2.017,,,,,,,,,,,,,,,23.688,-23.567,,,,
1873,,,,,,,,,,,,,,,,,,,,,-87.06,-39.7,,-15.628,,,,,,,,,,,,,,,,,,,,,,,51.416,,,,,,,,,-1.283,,,,,,,,,,,,,,,25.933,-19.987,,,,
1874,,,,,,,,,,,,,,,,,,,,,-75.899,-42.925,,-8.797,,,,,,,,,,,,,,,,,,,,,,,59.386,,,,,,,,,8.683,,,65.504,,,,,,,,,,,,24.838,-19.829,,,,
1875,,,,,,,,,,,,,,,,,,,,,-86.502,-45,,-8.892,,,,,,,,,,,,,,,,,,,,,,,59.076,,,,,,,,,7.742,,,65.025,,,,,,,,,,,,19.992,-14.933,,,,
1876,,,,,,,,,,,,,,,,,,,,,-102.607,-43.783,,-10.617,,,,,,,,,,,,,,,,,,,,,,,53.655,,,,,,,,,6.892,,,55.596,,,,,,,,,,,,15.175,-11.096,,,,
1877,,,,,,,,,,,,,,,,,,,,,-107.65,-38.775,,-14.028,,,,,,,,,,,,,,,,,,,,,,,57.299,,,,,,,,,0.867,,,57.596,,,,,,,,,,,,11.625,-7.417,,,,
1878,,,,,,,,,,,,,,,,,,,,,-109.793,-32.842,,-14.153,,,,,,,,,,,,,,,,,,,,,,,60.72,,,,,,,,,3.092,,,71.767,,,,,,-78.667,,,,,,8.479,-3.192,,,,
1879,,,,,,,,,,,,,,,,,,,,,-120.117,-25.292,,-13.503,,,,,,,,,,,,,,,,,,,,,,,56.395,,,,,,,,,6.117,,,79.737,,,,,,-89.167,,,,,,-1.367,-6.35,,,,
1880,,,,,,,,,,,,,,,,,,,,,-113.514,-18.442,,-6.715,,,,,,,,,,,,,,,,,,,,,,,63.797,,,,,,,,,13.467,,,105.646,,,,,,-84.333,,,,,,-7.417,-8.204,,,,
1881,,,,,,,,,,,,,,,,,,,,,-107.207,-10.842,,-0.724,,,,,,,,,,,15.89,,,,,,,,,,,,63.91,,,-35.673,,,,,,20.183,,,124.421,,,,,,-80.5,,,,,,-12.538,-4.196,,,,
1882,,,,,,,,,,,,,,,,,,,,,-103.275,-10.8,,3.037,,,,,,,,,,,18.264,,,,,,,,,,,,65.063,,,-38.973,,,,,,26,,,143.575,,,,,,-78.167,,,,,,-28.201,-6.367,,,,
1883,,,,,,,,,,,,,,,,,,,,,-111.936,-8.658,184.75,2.393,,,,,,,,,,,19.589,,,,,,,,,,,,63.532,,,-38.205,,,,,,24.583,,,148.492,,,,,,-88.333,,,,,,-28.247,-5.992,,,,
1884,,,,,,,,,,,,,,,,,,,,,-126.981,-3.808,199.083,1.695,,,,,,,,,,,27.861,,,,,,,,,,,,62.812,,,-37.437,,,,,,18.917,,,153.746,,,,,,-101.583,,,,,,-25.338,3.375,,,,
1885,,,,,,,,,,,,,,,,,,,,,-117.073,-0.262,197.042,5.214,,,,,,,,,,,36.919,,,,,,,,,,,,67.248,,,-36.669,,,,,,24.042,,,172.829,,,,,,-95.917,,,,,,-24.538,8.267,,,,
1886,,,,,,,,,,,,,,,,,,,,,-119.672,-0.054,193.917,2.476,,,,,,,,,,,35.519,,,,,,,,,,,,65.615,,,-35.901,,,,,,17.858,,,176.55,,,,,,-105.167,,,,,,-25.48,5.225,,,,
1887,,,,,,,,,,,,,,,,,,,,,-119.312,-5.374,181.667,1.158,,,,,,-48.524,,,,,30.186,,,,,,,,,,,,52.413,,,-35.133,,,,,,17.442,,,184.433,,,,,,-101.393,,,,,,-31.217,-2.85,,,,
1888,,,,,,,,,,,,,,,,,,,,,-132.465,-7.882,186.083,-5.926,,,,,,-54.449,,,,,26.896,,,,,,,,,,,,39.843,,,-34.365,,,,,,8.458,,,174.9,,,,,,-107.08,,,,,,-32.297,-12.475,,,,23.725
1889,,,,,,,,,,,,,,,,,,,,,-133.928,-13.653,189.333,-7.763,,,,,,-52.038,,,,,21.921,,,,,,,,,,,,38.011,,,-33.597,,,,,,4.317,,,174.675,,,,-44.272,,-100.867,,,,,,-29.246,-6.675,,,,26.55
1890,,,,,,,,,,,,,,,,,,,,,-132.674,-17.015,176,-9.622,,,,,,-44.351,,,,,20.902,,,,,,,,,,,,31.165,,,-32.829,,,,,,1.633,,,173.846,,,,-47.102,,-94.173,,,,,,-22.262,-2.417,,,,28.142
1891,,,,,,,,,,,,,,,,,,,,,-137.57,-22.064,155.25,-12.678,,,,,,-53.653,,,,,16.11,,,,,,,,,,,,30.832,,,-32.061,,,,,,-2.883,49.325,,171.8,,,,-47.093,,-89.608,,,,,,-17.258,-6.15,,,,28.367
1892,,,,,,,,,,,,,,,,,,,,,-136.196,-23.785,160.5,-11.232,,,,,,-50.282,,,,,16.998,,,,,,,,,,,,28.11,,,-31.293,,,,,,-2.075,55.692,,175.225,,,,-42.037,,-81.191,,,,,,-13.052,-9.292,,,,30.983
1893,,,,,,,,,,,,,,,,,,,,,-126.445,-24.155,161.042,-4.865,,,,,,-39.043,,,,,16.509,,,,,,,,,,,,29.701,,,-30.525,,,,,,2.183,64.9,,180.646,,,,-43.978,,-64.654,,,,,,-11.784,-5.075,,,,31.467
1894,,,,,,,,,,,,,,,,,,,,,-120.53,-25.101,155,-4.836,,,,,,-31.448,,,,,13.915,,,,,,,,,,,,31.957,,,-29.757,,,,,,0.717,66.133,,198.838,,,,-51.867,,-51.798,,,,,,-10.184,-9.521,,,,31.491
1895,,29.908,,,,,,,,,,,,,,,,,,,-117.927,-23.38,166.792,0.981,,,,,,-26.492,,,,,14.351,,,,,,,,,,,,31.711,,,-28.989,,,,,,4.15,73.817,,214.317,,,,-48.17,,-38.164,,,,,,-2.966,-7.671,,,,31.516
1896,,32.125,,,,,,,,,,,,,,,,,,,-111.405,-27.942,176.625,8.387,,,,,,-16.918,,,,,13.506,,,,,,,,,,,,31.471,,,-28.221,,,,,,10.542,71.45,,224.008,,,,-37.013,,-17.892,,,,,,-2.721,-0.975,,,,31.541
1897,,32.675,,,,,,,,,,,,,,,2.537,,,,-116.441,-25.446,192.542,7.833,,,,,,-16.947,,,,,10.076,,,,,,,,,,,,35.127,,,-27.453,,,,,,7.383,66.408,,221.271,,,,-28.621,,-19.519,,,,,,1.721,6.114,,,,31.566
1898,,38.166,,,,,,,,,,,,,,,7.746,,,,-103.81,-25.45,177.542,17.776,,,,,,-8.821,,,,,2.18,,,,,,,,,,,,44.164,,,-26.685,,,,,,14.6,64.65,,237.25,,,,-20.712,,-7.931,,,,,,7.423,7.163,,,,31.59
1899,,39.408,,,,,,,,,,,,,,,16.854,,,,-90.548,-20.655,160.375,30.726,,,,,,-10.466,,,,,-5.062,,,,,,,,,,,,48.598,,,-25.917,,,,,,28.058,73.7,,260.175,,,,-8.587,,5.572,,,,,,13.895,6.282,,,,31.615
1900,,40.751,,,,,,,,,,,,,,,16.38,,,,-98.799,-14.459,176.125,32.316,,,,,,-18.527,,,,,-1.336,,,,,,,,,,,,50.439,,,-25.149,,,,,,25.775,77.808,,258.213,,,,-11.223,,0.41,,,,,,14.038,3.31,,,,31.64
1901,,40.559,,,,,,,,,,,,,,,14.791,,,,-102.647,-11.305,187.083,34.087,,,,,,-10.247,,,,,2.814,,,,,,,,,,,,49.633,,,-24.381,,,,,,26.317,80.75,,261.358,,,,-5.752,,-3.268,,,,,,9.746,8.66,,,,31.665
1902,,35.892,,,,,,,,,,,,,,,15.579,,,,-105.165,-9.867,192.958,36.597,,,,,,-4.801,,,,,2.684,,,,,,,,,,,,44.575,,,-23.613,,,,,,29.579,84.775,,265.671,,,,-0.242,,-5.206,,,,,,9.705,20.319,,,,31.689
1903,,31.401,,,,,,,,,,,,,,,20.209,,,,-101.576,-7.275,198.542,43.101,,,,,,-5.15,,,,,4.271,17.242,,,,,,,,,,,48.088,,,-22.845,,,,,,39.425,81.3,,275.308,,,,5.641,,-1.596,,,,,,16.557,21.697,,,,31.714
1904,,33.851,,,,,,18.774,,,,,,,,,18.853,,,,-107.963,-3.9,204.542,44.081,,,,,,-6.45,,,,,10.217,18.642,,,,,,,,,,,47.631,,,-22.077,,,,,,41.208,79.025,,258.313,,,,14.354,,-7.299,,,,,,17.877,20.361,,,,31.739
1905,,34.767,,,,,,9.624,,,,,,,,,20.712,,,,-106.784,-7.284,197.333,47.706,,,,,,-10.358,,,,,6.266,19.408,,,,,,,,,,,48.144,,,-21.309,,,,,,47.55,69.167,,251.108,,,,9.179,,-8.478,,,,,,19.839,16.876,,,,31.764
1906,,37.121,,,,,,0.68,,,,,,,,,23.853,,,,-106.473,-5.434,199.875,52.553,,,,,,-13.333,,,,,7.702,21.033,,,,,,,,,,,52.767,,,-20.541,,,,,,54.258,69.95,,256.979,,,,10.304,,-8.169,,,,,,24.976,14.163,,,,31.788
1907,,38.521,,,,,,-6.785,,,,,,,,,26.192,,,,-107.092,-5.938,197.958,56.318,,,,,,-15.492,,,,,8.971,15.558,,,-70.667,,,,,,,,53.786,,,-19.773,,,,,,58.387,69.25,,258.146,,,,-0.17,,-7.966,,,,,,24.002,13.466,,,,31.813
1908,,33.589,,,,,,-12.599,,,,,,,,,19.392,,,,-122.163,-6.246,199.458,49.228,,,,,,-21.233,,,,,9.077,8.7,,,-85.833,,,,,,,,50.123,,,-19.005,,,,,,51.333,65.667,,245.654,,,,-7.03,,-23.073,,,,,,22.654,15.556,,,,31.838
1909,,35.122,,,,,,-11.593,,,,,,,,,13.9,,,,-136.354,-7.205,204.292,40.11,,,,,,-22.092,,,,,12.996,1.942,,,-103.583,,,,,,,,51.579,,,-18.237,,,,,,39.521,55.008,,229.708,,,,-24.238,,-37.274,,,,,,21.381,14.748,,,,31.863
1910,,38.913,,,,,,-23.994,,,,,,,,,16.604,,,,-136.727,-5.742,205.833,41.31,,,,,,-20.992,,,,,15.401,-0.517,,,-96.583,,,,,,,,58.79,,,-17.469,,,,,-0.627,40.633,44.312,,230.325,,,,-27.922,,-37.897,,,,,,23.4,13.729,,,,31.887
1911,,45.263,,,,,,-28.495,,,,,,,,,23.834,,,,-126.954,-3.917,197.167,46.914,,,,,,-22.367,,,,,13.974,-0.375,,,-88.583,,,,,,,,64.887,,,-16.701,,,,,-2.422,48.817,31.68,,238.646,,,,-38.838,,-31.688,,,,,,29.86,12.474,,,,31.912
1912,,51.775,,,,,,-44.517,,,,,,,,,29.275,,,,-123.319,-1.363,196.292,50.862,,,,,,-22.742,,,,,13.768,4.517,,,-83.5,,,,,,,,79.75,9.521,,-15.933,,,,,-3.764,51.038,13.267,,239.442,,,,-43.271,,-30.319,,,,,,35.133,6.44,,,,31.937
1913,,64.104,,,,,,-51.284,,,,,,,,,28.766,,,,-129.777,2.325,194.625,49.087,,,,,,-26.558,,,,,14.989,8.558,,,-86.417,,,,,,,,81.04,8.788,,-15.165,,,,,2.644,48.554,5.439,,238.396,,,,-48.671,,-36.527,,,,,,29.86,3.097,,,,31.961
1914,-70.5,58.223,,,,,,-47.996,,,,,,,,,35.361,,,,-125.295,8.154,185.917,54.837,,,,,,-24.783,,,,,15.837,13.908,,,-74.583,,,,,,,,88.062,5.638,,-14.397,,,,,-0.539,54.346,0.757,,262.308,,,,-43.213,,-34.031,,,,,,31.687,4.706,,,,31.986
1915,-77.75,59.735,,,,,,-45.258,,,,,,,,,33.609,,,,-134.165,16.7,193.215,51.497,,,,,,-18.975,,,,,22.272,16.758,,,-83.5,,,,,,,,93.399,8.838,,-13.629,,,,,-2.697,48.779,-1.881,,268.929,,,,-40.13,,-42.27,,,,,,29.059,7.39,,,,32.011
1916,-86.583,55.747,,,,,,-34.036,,,,,,,,,35.798,,,,-137.809,27.845,200.513,52.087,,,,,,-14.617,,,,,25.979,21.058,,,-84.667,,,,,,,,100.314,19.08,,-12.861,,,,,-3.189,48.079,-10.333,,283.842,,,,-33.638,,-45.711,,,,,,27.261,5.606,,,,32.036
1917,-96.167,59.915,,,,,,-18.905,,,,,,,,,35.375,,,,-136.935,29.786,207.811,53.616,,,,,,-12.725,,,,,30.182,23.458,,,-83,,,,,,,,104.295,20.36,,-12.093,,,,,3.153,48.9,-13.908,,276.492,,,,-14.952,,-49.363,,,,,,25.159,2.889,,,,32.06
1918,-93.417,61.683,,,,,,-4.078,,,,,,,,,38.033,,,,-130.703,34.816,215.109,58.866,,,,,,-10.283,,,,,32.883,24.817,,,-79.5,,,,,,,,108.731,17.491,,-11.325,,,,,2.053,52.112,-16.208,,241.517,,,,1.483,,-46.902,,,,,,25.753,4.401,,,,32.085
1919,-86.542,54.934,,,,,,6.031,,,,,,,,,35.061,,,,-132.314,37.47,222.407,59.928,,,,,,-9.285,,,,,37.949,31.133,,,-77.417,,,,,,,,106.66,13.278,,-10.557,,,,,-2.675,50.667,-15.124,,221.792,,,,26.901,,-52.296,,,,,-19.333,20.903,8.142,,,,32.11
1920,-73.792,48.08,,,,,,6.847,,,,,,,,,33.571,,,,-132.06,38.045,229.705,59.114,,,,,,-8.822,,,,,35.599,28.217,,,-84.667,,,,,,,,102.382,6.314,,-9.789,,,,,-1.142,48.954,-12.374,,193.758,,,,27.408,,-55.943,,,,,-25.667,18.703,11.415,,,,32.135
1921,-66.917,45.395,,,,,,7.973,,,,,,,,,33.797,,,,-125.656,36.416,237.003,61.789,,,,,,-21.414,,,,,34.656,25.208,,,-78,,,,,,,,101.568,-3.215,,-9.021,,,,,0.39,49.637,-8.067,,161.95,,,,27.915,,-54.091,,,,,-35.833,13.029,13.592,,,,32.159
1922,-61.167,43.225,,,,,,11.385,,,,,,,,,32.485,,,,-125.199,34.832,244.301,64.287,,,,,,-29.932,,,,,34.594,25.942,,,-74,,,,,,,,94.83,-3.768,,-8.253,,,,,1.923,52.337,0.1,,151.072,,,,28.423,,-57.367,,,,,-34.167,6.161,15.178,,,,32.184
1923,-59.167,31.119,,,,,,20.365,,,,,,,,,30.778,,,,-124.227,29.153,251.599,62.928,,,,,,-48.974,,,,,36.9,28.85,,,-73.833,,,,,,,,92.712,-2.039,,-7.485,,,,,3.456,49.592,1.253,,134.106,,,,28.93,,-60.977,,,,,-32.833,0.627,15.033,,,,32.209
1924,-59.792,35.599,,,,,,32.448,,,,,,,,,26.721,,,,-128.56,27.47,258.897,58.42,,,,,,-59.808,,,,,39.96,25.483,,,-79.417,,,,,,,,86.881,8.699,,-2.393,,,,,4.988,44.742,4.486,,101.142,,,,29.437,,-68.25,,,,,-33.167,-0.297,10.593,,,,32.234
1925,-57.625,30.827,,,,,,43.91,,,,,,,,,32.015,,,,-116.118,23.695,266.195,65.439,,,,,,-77.151,,,,,35.865,24.283,,,-65.917,,,,,,,,90.217,10.897,,10.603,,,,,6.521,52.6,4.912,,80.489,,,,29.944,,-61.614,,,,,-53.25,2.733,7.485,,,,32.258
1926,-44.958,33.129,,,,,,44.607,,,,,,,,,31.377,,,,-116.858,20.074,273.493,68.337,,,,,,-94.956,,,,,35.231,21.725,,,-62.25,,,,,,,,87.124,4.326,,11.658,,,,,8.054,54.525,12.211,,48.497,,,,30.452,,-65.64,,,,,-52.917,5.066,6.872,,,,30.908
1927,-31.583,28.561,,,,,,37.625,,,,,,,,,36.628,,,,-113.671,26.254,280.791,74.047,,,,,,-112.153,,,,,41.179,21.133,,,-55.667,,,,,,,,89.064,0.116,,15.684,,,,,9.586,61.033,13.967,,45.512,,,,30.959,,-63.656,,,,,-57.083,11.414,9.756,,,,32.283
1928,-27.75,27.504,,,,,,55.227,,,,,,,,,39.623,,,,-113.957,29.475,288.089,78.051,,,,,,-114.982,,,,,47.863,19.192,,,-50.25,,,,,,,,92.394,-4.781,,19.384,,,,,11.119,64.425,16.947,,67.566,,,,31.466,,-64.002,,,,,-53.917,14.744,7.473,,,,31.142
1929,-30.042,28.643,,,,,,58.202,,,,,,,,,42.618,,,,-114.342,27.962,295.387,80.337,,,,,,-126.915,,,,,45.281,19.625,,,-48.167,,,,,,,,92.035,-4.523,,23.974,,,,,12.652,68.308,15.986,,68.712,,,,31.973,,-63.469,,,,,-66.5,17.918,3.598,,,,29.408
1930,-31.167,26.071,,,,,,70.909,,,,,,,,,43.198,,,,-118.602,30.325,302.685,84.681,,,,,,-141.104,,,,,54.213,23.447,,,-44,,,,,,,,96.845,-6.909,,20.667,,,,,14.184,72.717,19.557,,77.616,,,,32.48,,-66.205,,,,,-69.583,20.332,1.69,,,,27.267
1931,-33.417,20.955,,,,,,66.666,,,,,,,,,42.522,,,,-134.238,34.783,309.983,86.433,,,,,,-138.779,,,,,64.217,23.68,,,-47.667,,,,,,,,101.916,-10.113,,22.19,,,,,15.717,69.95,21.662,,88.059,,,,32.988,,-76.491,,,,,-43.333,22.894,-0.143,,,,28.242
1932,-35.292,16.64,,,,,,70.353,,,,,,,,,44.303,,-5.95,,-136.234,37.804,295.583,89.399,,,,,,-130.603,,,,,68.803,21.077,,,-46.25,,,,,,,,105.17,-16.836,,29.35,,,,,17.25,71.369,25.983,,83.78,,,,33.495,,-77.938,,,,,-43.417,25.282,1.529,,,,28.133
1933,-33.292,15.488,,,,,,62.293,,,,,,,,,39.499,,-8.383,,-154.48,43.762,280.708,89.424,,,,,,-101.244,,,,,73.8,17.301,,,-56.75,,,,,,,,101.674,-20.332,,24.844,,,,,18.782,63.753,31.739,,68.877,,,,34.002,,-92.222,,,,,-46,26.161,4.939,,,,29.492
1934,-35.75,16.143,,,,,,46.932,,,,,,,,,39.403,,-14.475,,-156.155,43.325,256.542,93.164,,,,,,-86.439,,,,,77.21,12.469,,,-56.5,,,,,,,,101.699,-28.863,,25.097,,,,,20.315,64.936,33.078,,63.933,,,,34.509,,-93.124,,,,,-51.75,22.605,7.591,,,,31.853
1935,-30.375,17.21,,,,,,34.153,,,,,,,,,38.168,,-7.075,,-164.014,44.862,265.625,90.683,,,,,,-71.825,,,,,83.143,9.526,,,-60,,,,,,,,102.145,-33.329,,20.941,,,,,21.848,60.608,32.753,,58.012,,,,35.016,,-97.679,,,,,-48.167,21.084,9.963,,,,34.215
1936,-29.833,12.897,,,,,,39.619,,,,,,,,,36.733,,0.875,,-169.751,48.62,271.167,86.937,,,,,,-48.67,,,,,94.234,8.27,,,-63.833,,,,,,,,104.776,-33.962,,16.765,,,,,20.496,54.278,34.119,,53.072,,,,35.524,,-102.992,,,,,-44.417,21.957,12.261,,,,36.577
1937,-28.458,10.402,,,,,,44.833,,,,,,,,,31.248,,11.767,,-184.13,52.366,279.917,80.886,,,,,,-30.158,,,,,101.899,10.718,,,-73.25,,,,,,,,106.873,-23.397,,10.035,,,,,14.694,41.5,35.6,,39.966,,,,36.031,,-116.184,,,,,-37.583,23.071,14.247,,,,38.939
1938,-28.458,10.957,,,,,29.745,26.814,,,,,,,,,34.98,,18.608,,-175.577,47.698,296.75,81.666,11.717,,,,,-21.686,,,,,101.132,14.515,,,-66.583,,,,,,,,108.34,-18.482,,12.689,,,,,9.821,45.858,36.592,,45.088,,,,36.538,,-112.855,,,,,-25.917,21.225,18.221,,,,41.3
1939,-25.417,15.502,,,,,34.789,24.059,,,,,,,,,32.011,,28.699,,-185.718,53.467,312.208,77.555,14.55,,,,,-5.488,,,,,110.323,10.938,,,-78.25,,,,,,,,112.522,-11.745,,11.613,,,,,10.89,39.942,39.411,,37.734,,,,37.045,,-123.677,,,,,-15.75,22.683,20.817,,,,43.662
1940,-21.875,16.38,,,,,52.96,20.314,,,,,,,,,30.385,,37.466,,-187.107,54.851,333.667,74.479,21.1,,,,,7.549,,,,,113.502,9.921,,,-76.175,,,,,,,,112.603,-8.786,,4.409,,,,,11.959,38.808,37.469,,39.163,,,,37.552,,-129.226,,,,,1.917,20.148,25.013,,,,46.024
1941,-16.208,13.796,,,,,61.901,30.293,,,,,,,,,23.823,,45.274,,-201.695,49.07,345.958,70.065,32.075,,,,,20.215,,,,,119.021,12.723,,,-74.101,,,,,,,,107.985,-3.43,,-8.839,,,,,13.028,35.242,39.16,,38.532,,,,38.06,,-141.988,,,,,13.167,17.355,28.904,,,,48.386
1942,-14.333,16.691,,,,,67.789,32.305,,,,,,,,,18.744,,52.383,,-212.328,45.529,351.833,65.198,39.808,,,,,16.599,,,,,123.821,15.62,,,-72.026,,,,,,,,106.008,-0.794,,-20.775,,,,,14.097,29.839,40.946,,41.531,,,,38.567,,-152.293,,,,,9.257,16.073,31.744,,,,50.747
1943,-11.042,19.522,,,,,61.831,55.17,,,,,,,,,25.796,,59.033,,-195.961,43.132,344.5,70.273,42.142,,,,,11.025,,,,,122.142,14.243,,,-69.952,,,,,,,,114.146,1.285,,-20.35,,,,,15.166,40.376,42.555,,35.292,,,,39.074,,-141.73,,,,,5.347,19.909,32.325,,,,53.109
1944,-7.208,18.108,,,,,71.704,65.909,,,,,,,,,28.614,,67.903,,-194.754,37.331,350.5,76.665,48.575,,,,,10.496,,,,,123.316,17.33,,,-67.877,,,,,,,,121.793,3.329,,-29.016,,,,,16.235,46.659,46.457,,35.284,,,,39.581,,-141.758,,,,,1.437,20.355,35.642,,,,55.471
1945,-5.333,20.915,,,,,75.26,84.697,,,,,,,,,31.657,,63.758,,-200.574,32.887,356.417,81.561,53.392,,,,,17.875,,,,,124.781,13.826,,,-65.803,,,,,,,,123.49,7.918,,-33.002,,,,,17.304,48.239,53.01,,27.524,,,,40.088,,-144.316,,,,,-2.472,22.153,38.097,,,,57.833
1946,0.458,26.156,,,,,66.914,78.263,,,,,,,,,35.154,,56.608,,-201.029,30.175,351.875,84.348,58.667,,,,,16.127,,,,,120.812,15.848,,,-63.728,,,,,,,,123.466,13.923,,-29.594,,-6.992,,,18.373,49.819,55.141,,22.289,,,,40.596,,-144.22,,,,,-6.382,22.598,41.332,,,,60.194
1947,3.486,29.588,,,,,56.394,89.448,,,,,,,,,33.533,,39.824,,-211.292,28.588,348.875,81.845,63.342,,,,,22.14,,25.142,-5.161,,118.156,13.488,,,-61.654,,,,,,,,117.477,7.164,,-30.617,,-10.305,,,19.442,51.398,59.352,,22.026,,,,41.103,,-150.789,,,,,-10.292,21.33,42.259,,,,63.811
1948,2.319,30.38,,,,,56.41,85.385,,,,,,,,,33.998,,37.357,,-222.204,34.052,350.542,82.89,66.625,,,,,22.215,,31.233,-0.794,,122.353,13.739,,,-59.579,,,,,,,,118.668,10.883,,-33.02,,-20.389,,,20.511,52.978,64.446,,12.35,,,,41.61,,-156.511,,,,,-14.201,25.808,45.129,,,,67.093
1949,5.069,30.539,,,,,41.21,71.996,,,,,,,,,43.859,,34.89,,-209.664,33.233,337.042,93.248,72.633,,,,,23.599,,30.592,-3.177,,115.77,16.686,,,-57.504,,,,,,,,123.547,10.279,,-28.889,,-15.024,,,21.58,54.557,69.201,,12.207,,,,42.117,,-143.774,,11.22,,,-18.111,28.498,46.581,,,,73.226
1950,4.986,37.541,,,,,24.752,67.705,,,,,,,,,50.019,,32.422,,-209.645,32.117,325.792,99.236,73.758,,,,,30.825,,31.633,-0.225,,113.495,19.808,,,-55.43,,,,,,,,128.099,13.948,,-21.148,,-9.659,,-14.387,22.649,56.137,77.888,,9.685,,,,42.624,,-138.129,,17.243,,,-22.021,34.773,44.771,,,,71.06
1951,5.694,45.102,,,-9.013,,13.989,76.625,,,,,,,,,53.39,,29.955,,-209.332,54.573,319.25,95.693,74.025,,,,,31.365,,37.542,7.717,,112.286,20.131,,,-53.355,,,,,,,,134.386,19.307,,-10.866,,-4.294,,-14.392,23.718,57.717,80.806,,3.678,,,,39.916,,-136.825,,19.158,,,-25.931,43.196,44.105,,,,70.026
1952,7.986,48.3,,,-9.33,,4.197,80.512,,,,,,,,,59.288,,27.488,,-208.991,65.423,318.333,101.413,76.05,,,,,39.316,,40.092,15.54,,112.72,19.824,,,-51.281,,,,,,,,142.839,22.709,,-7.374,,1.071,,-16.083,24.788,59.296,79.665,,-14.76,,,,38.085,,-131.917,,17.751,,,-29.84,47.98,43.517,,,,66.835
1953,7.486,49.694,,,-10.343,,12.811,63.665,,13.125,,,,,,,57.324,,25.02,,-223.297,62.096,334.917,98.565,77.333,,,,,41.809,,40.725,17.515,,113.382,23.485,,,-49.206,,,,,,,,141.689,25.356,,-6.441,,6.436,,-11.675,25.857,60.876,80.239,,-8.492,,,,40.567,,-140.264,,20.951,,,-33.75,48.633,44.199,,,,70.51
1954,11.986,53.525,,,-25.218,,1.059,77.322,,12.81,,,,,,,54.004,,22.553,,-236.006,68.476,331.167,89.931,79.175,,,,,44.712,,44.783,23.548,,114.048,22.979,,,-47.132,,,,,,,,141.625,29.937,,-4.986,,11.801,,-16.017,26.926,62.456,78.826,37.408,-13.073,,,,39.937,,-148.847,,22.25,,,-35.667,53.281,43.239,,,,77.081
1955,5.778,61.847,,,-21.843,,11.983,69.661,,10.385,,,,,,,51.696,,20.086,,-240.461,76.689,344.167,89.391,81.375,,,,,47.771,,50.008,29.848,,118.996,25.89,,,-45.057,,,,,,,,143.814,34.448,,-5.553,,17.166,,-18.915,27.995,64.035,81.943,58.392,-15.351,,,,41.141,,-153.633,,24.414,,,-24.25,54.958,41.46,,,,79.025
1956,-0.764,69.548,,,-23.277,,20.93,70.842,,5.732,,,,,,,48.716,,17.619,,-245.941,73.619,353.667,90.271,81.675,,,,,47.188,,44.858,31.757,,115.437,22.741,,,-42.982,,,,,,,,140.477,36.458,,-13.876,,22.531,,-20.397,29.064,65.615,78.992,79.325,-20.812,,,,35.224,,-156.309,,21.96,,,-13.833,50.529,39.28,,,,77.042
1957,-1.963,71.329,,,-20.81,,25.964,54.265,,8.924,,-34.53,,,,,55.031,49.116,15.151,,-234.801,69.191,354.042,98.551,80.833,,,,,41.198,,41.633,34.865,,108.542,24.245,,,-40.908,,,,,,,,147.249,41.791,,-11.674,,27.896,,-11.281,24.796,67.194,77.462,85.042,-22.702,,,,26.932,,-146.41,,15.1,,,-16.083,49.993,39.602,,,,71.942
1958,7.667,75.666,,,-18.977,,34.531,56.992,,13.572,,-29.57,,,,,51.825,56.832,12.684,,-250.267,67.462,359.958,97.526,78.3,,,,,40.046,,39.321,34.707,,107.483,19.327,,,-38.833,,,,,,,,147.635,41.673,,-16.953,,33.261,,-6.589,23.002,68.774,74.363,86.042,-28.636,,,,22.558,,-155.128,,12.602,,,-11.833,47.195,38.975,,,,75.55
1959,12.766,75.072,,,-19.017,,48.048,61.633,,16.919,-39.574,-25.532,,,,,44.401,62.017,10.217,,-273.598,65.71,373.083,89.284,75.333,,,170.198,,45.071,,39.483,38.173,,109.899,14.816,,,-47.5,,,,,,,,142.173,40.168,,-23.227,,38.626,,-1.241,24.011,70.354,73.583,94.242,-25.112,,,,21.41,,-170.352,,6.708,,,-1.667,44.548,38.561,,,,81.235
1960,17.749,73.299,,,-16.533,,65.313,68.406,,18.422,-40.202,-21.084,,,,,38.137,66.842,12.917,,-296.368,66.138,384.167,83.479,76.133,,,174.19,,46.131,,44.245,41.34,,108.539,10.334,,,-64.083,,,,,,,,140.67,44.032,,-35.521,,43.991,-31.893,3.611,21.044,71.933,73.44,105.992,-29.85,,,,23.837,,-184.471,,-4.624,,,10.083,41.473,41.128,,,,87.168
1961,24.396,71.762,,,-14.212,,64.888,63.587,,16.909,-37.24,-20.472,,-3.361,,,46.639,63.275,16.842,,-283.682,49.218,382.967,94.074,77.158,,,166.228,,49.293,,43.5,44.14,,100.141,6.62,,,-43.583,,,,,4.388,,,146.966,43.607,,-34.36,,49.356,-36.718,2.397,20.77,86.933,76.703,101.092,-32.207,,,,25.211,,-171.627,,-15.308,,,10,38.613,42.229,,,,92.618
1962,21.894,74.018,,,-15.902,,74.94,52.071,,14.611,-42.916,-14.578,,-0.97,,,45.287,62.442,19.4,,-286.622,39.87,387.6,91.78,77.658,,,158.103,,49.164,,39.954,39.808,,92.748,1.43,,,-39,,,,29.223,3.727,,,145.002,48.181,,-36.533,,54.721,-34.487,3.872,22.004,88.6,82.719,104.325,-30.118,,,,24.37,,-172.547,,-17.31,,,15.417,32.069,42.06,,,,99.652
1963,27.198,75.047,,,-16.667,40.675,88.092,50.21,,13.388,-55.103,-13.086,,8.605,,,40.187,59.953,30.758,,-303.952,45.334,394.9,83.671,76.762,,,131.299,,45.678,,41.344,49.867,,96.123,-6.6,,,-50.833,,,,29.992,5.143,,,141.789,52.717,,-43.181,,60.085,-31.087,-4.505,17.495,80.717,81.771,106.958,-31.027,,,,26.382,,-183.113,,-20.381,,,21.833,28.429,40.615,-4.223,,,108.115
1964,23.546,78.776,,,-16.982,44.949,93.797,33.104,,12.92,-54.438,-12.847,,6.517,,,41.358,53.361,28.108,,-306.131,41.662,404.742,84.673,70.114,,,115.112,,44.035,,39.54,42.992,,90.476,-8.601,,,-48.417,,,,27.547,2.626,,,138.224,55.556,,-42.623,,65.45,-30.521,-5.611,13.195,86.35,75.161,107.492,-31.696,,,,29.311,,-181.585,,-20.223,,,25.167,21.864,39.514,-3.256,-29.897,,113.791
1965,33.95,71.005,,,-20.334,49.619,95.57,28.624,,21.077,-51.995,-9.584,,6.315,,,40.002,54.345,27.325,,-316.449,37.116,398.083,80.759,62.333,,,99.528,56.042,36.006,,31.894,33.817,,83.788,-9.619,,,-59.167,,,,27.847,6.289,,,139.213,53.652,,-44.351,,70.815,-22.944,1.352,8.694,80.866,73.804,99.65,-34.3,,,,29.041,,-185.968,2.767,-25.91,,,21.417,16.751,40.451,7.29,-31.389,,114.688
1966,40.193,63.827,,,-12.695,47.891,108.414,26.992,,23.04,-38.222,-7.831,,12.617,,,41.698,51.185,30.017,,-325.114,40.965,399.217,80.517,61.922,,,81.923,60.5,31.242,,39.248,32.75,,86.179,-10.753,,,-68,,,,,5.372,,,149.223,53.853,,-41.419,,76.18,-22.852,-1.07,6.079,74.957,75.805,103.575,-31.906,,,2.264,35.592,,-189.984,3.517,-26.935,,,29,20.991,40.64,11.377,-34.309,-0.667,125.505
1967,42.71,62.818,,,-10.714,50.183,118.113,32.545,,18.384,-27.429,-9.735,,13.584,,,45.951,40.318,31.733,,-322.663,40.59,408.933,86.495,59.853,,,75.698,63.417,29.197,,39.54,28.833,,82.804,-12.17,,,-59.25,,,,,1.829,,,158.186,52.055,,-34.987,,82.397,-31.836,-14.297,7.129,82.173,75.042,117.05,-21.051,,,-0.948,40.019,,-183.64,0.867,-16.89,,,43.167,21.121,39.471,11.993,-26.572,-1,133.396
1968,39.449,63.082,,,-13.574,46.955,114.255,32.111,,14.123,-26.632,-8.815,,12.965,,,43.521,38.868,35.475,,-328.711,43.618,407.942,85.177,63.942,,,65.068,66.667,26.965,,40.398,26.298,,79.225,-12.11,,,-66.083,,,,,-1.047,,,158.938,52.758,,-34.866,,77.88,-34.994,-21.072,9.366,81.107,75.052,120.492,-15.413,,,1.58,43.099,,-183.427,-5.142,-16.498,,,45.75,20.515,36.39,10.031,-23.209,-4.178,134.263
1969,34.18,60.764,,,-9.739,47.239,123.109,38.703,,13.401,-22.483,-5.204,,21.703,,,42.668,45.993,39.342,,-331.004,49.064,413.7,86.197,64.14,,,57.098,62.917,18.913,,41.319,23.871,,76.275,-10.39,,-19.259,-65.75,,,,,1.675,,,163.529,50.76,,-35.679,,69.234,,-22.3,8.949,82.44,76.974,131.683,-17.624,,,-1.437,48.58,,-181.863,-12.65,-7.411,,,53.917,21.258,38.314,3.223,,-3.136,133.636
1970,28.674,61.273,,,-8.766,37.915,129.4,35.088,,11.495,-24.357,0.776,,20.384,,,45.652,41.306,39.925,,-326.695,48.108,424.25,91.926,58.634,,,56.368,62.167,18.024,,34.015,14.862,,68.136,-8.875,,-19.865,-54.833,,,,,1.826,,,167.434,53.337,,-30.251,,71.084,,,11.293,92.973,74.639,143.158,-16.35,,,-4.045,48.7,,-175.67,-21.433,2.319,,,64.583,18.64,37.901,2.29,,-5.22,133.636
1971,22.746,62.118,,,-12.575,38.516,134.033,36.266,,4.229,-23.815,10.526,,19.64,,,40.353,35.109,35.883,16.607,-339.689,42.421,433.333,89.21,53.373,,,62.977,61.583,22.096,,27.99,3.887,,63.99,-6.154,-16.75,-21.733,-58.917,,,,,2.835,,,160.564,64.175,,-34.137,,71.442,,,21.272,91.09,69.985,148.008,-14.263,,28.27,-7.92,46.908,,-182.296,-28.392,14.645,,,68.333,15.985,37.854,-4.46,,-2.803,133.636
1972,25.758,55.247,,,-15.465,38.962,130.562,48.124,,8.336,-19.906,26.499,,16.899,,,34.066,41.562,30.808,27.542,-358.575,43.656,435.125,82.27,49.262,,36.482,67.07,73.083,12.453,,31.486,3.887,,63.43,1.163,-16.5,-19.629,-73.917,,,,,13.205,,,156.024,69.272,,-35.364,,69.576,,,21.172,80.923,64.73,145.417,-11.158,,30.512,-7.447,50.848,,-191.55,-28.617,18.505,,,63.25,17.259,40.68,-2.34,,0.614,133.636
1973,22.851,54.671,,,-10.385,48.109,117.112,55.57,,1.704,-7.62,41.138,,6.578,,,37.562,35.903,22.383,14.917,-349.97,39.667,425.625,91.984,54.314,,40.302,85.699,78.417,15.628,,27.075,3.887,,68.377,8.97,-15.417,-13.791,-58,,,,,16.208,24.45,,163.843,68.729,,-32.17,,67.751,,,29.397,95.04,66.991,132.625,-11.273,,23.42,-12.756,49.551,,-178.656,-26.929,26.987,,,49.167,16.779,43.673,2.341,,9.572,133.636
1974,22.371,52.718,,,-5.974,52.477,122.311,67.583,,2.136,-6.975,57.03,,8.372,,,37.821,40.809,24.233,15.775,-357.085,42.759,428.375,96.578,62.139,,51.922,90.12,75.25,15.963,,30.092,3.887,,82.734,12.952,-17.083,-12.577,-58.417,,,,,25.255,26.867,,170.327,71.735,,-37.007,,68.379,,,40.956,97.407,75.542,136.142,-16.434,,28.929,-13.375,48.201,,-177.19,-30.925,33.119,,,51.833,19.986,47.273,8.999,,18.405,133.636
1975,16.415,54.903,,,-5.546,57.85,122.063,72.313,,-3.995,-3.705,70.834,,2.864,32.219,,36.338,35.799,21.758,7.392,-355.954,40.949,437.083,97.061,55.61,,53.199,90.976,70,19.115,,27.35,3.887,,88.199,16.888,-15.167,-8.858,-53.75,,,,,28.643,24.183,,166.683,76.803,,-33.621,,64.012,,,54.837,101.157,72.487,136.75,-8.812,-19.912,24.429,-11.752,48.255,,-172.49,-32.283,46.385,,,52.333,19.492,49.07,-2.213,,26.672,133.636
1976,17.084,56.489,,,-11.967,70.3,104.609,77.63,,-1.594,-9.426,84.749,,-3.036,29.412,,29.59,42.837,23.2,12.492,-364.96,37.288,435.208,92.284,42.677,,60.058,90.964,78.689,23.81,,18.704,3.887,,90.867,20.233,-13,-8.227,-57.5,,,,,36.279,20.033,,157.79,78.891,,-37.528,,58.37,,,63.442,99.783,64.209,118.6,-5.156,-23.97,17.514,-9.947,48.217,,-173.557,-36.267,57.329,,,35.833,14.245,48.624,-4.429,,32.505,133.636
1977,15.862,54.199,,,-12.344,79.683,102.943,73.404,,-0.613,-13.411,101.482,,-2.888,27.517,,21.463,38.698,25.758,13.517,-384.89,40.097,439.5,83.091,29.011,,65.295,90.302,79.548,34.448,,17.946,3.887,,104.136,21.922,-14.417,-8.749,-67.083,,,,,41.314,20.642,,152.409,77.908,,-50.179,,56.07,,,76.373,90.15,57.646,114.117,-17.906,-28.841,19.08,-4.978,49.677,,-181.428,-36.988,57.794,,,30.833,14.351,48.99,-7.383,,31.255,133.636
1978,14.69,50.968,,,-10.672,91.1,105.835,70.408,,-1.842,-12.83,118.218,,-1.64,28.719,,21.236,36.039,29.167,15.55,-392.15,37.253,445.25,81.162,13.89,-35.505,59.27,97.297,91.126,43.401,,12.658,,,116.599,25.591,-16.667,-7.773,-58.333,,,,,44.855,21.217,,152.853,77.715,,-55.245,,48.47,,,89.659,92.6,50.535,111.067,-23.844,-27.849,20.605,-6.675,48.905,,-180.388,-35.583,62.241,,,28.917,13.462,51.831,-3.822,,35.864,133.636
1979,18.473,51.511,,,-10.255,104.825,113.672,64.105,,-4.005,-15.172,132.475,,-2.867,26.712,,21.561,29.454,32.55,11.483,-394.648,33.354,455.625,80.801,3.044,-35.452,47.126,101.501,100.334,49.358,,8.508,,,132.064,25.152,-16.083,-9.433,-53.917,,,,,43.363,20.9,,155.408,85.094,,-57.778,,53.142,,,113.985,96.283,41.472,111.7,-28.897,-35.616,23.93,-3.665,46.696,,-177.341,-35.838,63.649,,,32.667,12.928,51.025,-4.124,,37.197,133.636
1980,22.61,49.867,,,-9.044,124.675,105.122,61.245,,0.024,-16.558,143.139,,-3.79,27.727,,19.38,34.644,34.058,16.225,-403.69,32.387,453.667,77.022,6.594,-33.86,43.877,93.369,103.442,53.24,,9.15,,,147.715,23.83,-17.5,-11.744,-51.083,,,,,49.58,21.158,,156.523,86.564,,-61.306,,52.008,,,117.762,94.85,36.74,89.233,-29.012,-39.165,24.085,-1.007,46.425,,-178.051,-34.488,71.499,,,27.917,13.789,50.58,-0.16,,40.69,133.636
1981,24.583,52.001,,,0.692,131.5,116.036,64.008,,4.419,-17.791,153.047,,-3.159,23.812,,23.475,42.092,37.533,1.833,-405.236,38.156,459.625,82.504,-2.798,-26.556,43.877,87.293,104.092,57.76,,5.475,,,164.131,24.256,-15,-11.651,-40.417,,,,,54.568,24.283,,165.979,82.763,,-64.145,,46.342,,,120.924,103.067,32.13,86.858,-28.017,-42.452,25.379,6.303,47.699,,-171.36,-29.796,74.399,,,38.833,15.686,50.107,8.148,,43.64,133.636
1982,27.529,52.574,,,10.447,144.333,128.897,56.161,,7.733,-20.192,155.064,,-2.032,12.764,,31.534,34.462,40.458,-27.714,-400.293,39.355,469.792,89.075,-13.352,-23.409,43.877,75.623,108.867,63.742,,4.717,,,176.767,17.086,-15.417,-14.032,-29.417,,,,,55.47,28.825,,176.523,82.208,,-58.605,,45.65,,,127.692,110.417,26.506,85.758,-28.275,-48.138,26.921,4.731,46.005,,-162.007,-34.113,82.438,,,50.75,19.839,47.525,15.504,,37.59,133.636
1983,38.078,49.686,,,18.317,151.35,133.72,59.013,,23.237,-23.098,157.102,,1.331,14.392,,37.266,47.546,47.392,-28.164,-398.6,44.924,479.167,94.034,-46.428,-20.462,43.877,63.252,119.534,64.483,,5.178,,,177.602,13.749,-12.083,-11.561,-20.667,,,,,68.219,32.458,,183.538,80.777,,-53.978,,32.058,-33.761,,133.112,117.95,19.219,77.667,-25.795,-57.566,30.196,5.683,48.116,,-157.003,-36.371,94.842,,,59.333,25.243,49.873,22.454,,33.851,133.636
1984,47.313,48.078,,,20.392,154.908,136.718,56.374,,26.857,-25.836,160.301,,5.359,12.52,,36.04,24.867,52.392,-33.873,-407.848,47.201,478.458,89.698,-76.947,-16.661,,52.881,126.537,73.466,,-0.209,,,177.382,7.398,-9,-10.2,-18.417,,,,,70.007,36.725,,184.078,76.103,,-56.672,,27.976,-39.636,,140.45,115.617,12.552,80.933,-22.628,-60.192,32.154,9.431,47.869,,-160.772,-33.158,105.515,,,63.083,22.911,51.384,29.624,,24.618,133.636
1985,49.128,45.824,,,23.953,159.671,131.735,55.73,5.423,28.916,-27.733,163.039,,9.337,6.742,,37.593,18.665,59.225,-56.098,-420.424,52.405,474.625,88.968,-96.403,-14.868,,48.144,132.524,79.243,,4.656,,,180.893,-0.112,-10.583,-10.167,-12.75,,,,,68.949,41.942,,188.555,74.571,,-63.767,,22.959,-50.919,,139.376,117.017,10.778,74.539,-28.938,-65.854,36.229,10.922,47.297,,-165.329,-35.721,112.817,,,55.917,25.208,49.769,41.126,,15.935,133.636
1986,52.574,44.648,,-76.451,27.867,166.2,138.026,54.344,6.477,29.145,-26.948,171.148,,13.353,11.972,,43.118,10.431,58.975,-77.848,-418.389,52.28,486.292,91.637,-113.684,-11.29,,46.496,137.184,80.319,,11.182,,,187.215,-4.196,-7.25,-9.425,3.25,,,,23.889,67.354,47.592,,195.269,75.948,,-62.241,,14.333,-54.475,,140.924,123.983,6.176,80.792,-29.805,-70.72,41.352,11.94,45.343,,-161.292,-37.229,116.619,,,59,27.259,52.392,44.4,,6.774,133.636
1987,59.721,42.313,,-75.477,30.239,173.532,141.393,54.232,7.71,31.508,-24.908,181.781,,16.901,9.781,,45.764,17.477,69.95,-67.064,-425.04,52.487,495.917,93.43,-122.373,-4.818,,35.359,146.484,75.619,,14.314,,,190.126,-4.764,-3,-6.88,4.417,,,,30.125,69.936,54.167,,194.228,77.122,,-62.317,,13.133,-58.017,,135.437,126.883,5.358,87.984,-22.851,-77.657,43.344,11.391,46.079,,-163.646,-41.333,122.2,,,62.667,25.589,53.439,52.206,,3.746,133.636
1988,56.909,45.093,26.03,-49.995,38.017,168.711,146.2,52.315,6.373,30.578,-20.641,184.297,,15.696,6.11,,52.56,12.593,67.225,-70.573,-419.544,55.2,508.958,102.755,-126.625,-4.228,,25.96,146.906,74.826,,24.467,,,189.601,-7.617,-7.417,-3.479,4.167,,,,29.943,66.371,56.125,,201.416,76.749,,-52.571,,21.833,-64.017,,135.348,133.483,2.66,94.159,-10.835,-80.695,43.702,11.969,47.603,,-157.551,-39.375,130.695,,,68.417,27.742,51.034,49.06,,1.433,133.636
1989,55.722,48.181,30.437,-35.195,43.181,165.428,136.752,46.692,10.61,31.096,-16.356,189.664,,7.043,4.522,,63.744,8.251,63.983,-70.239,-407.698,55.909,510.667,114.701,-133.519,-13.409,,17.217,152.681,76.377,,30.629,,,183.134,-7.778,-1.083,1.728,28.417,,,,28.719,69.379,53.542,,203.885,79.469,,-40.939,,12.933,-76.267,,127.679,149.9,-1.485,88.134,8.158,-78.785,37.861,16.192,46.4,,-143.463,-36.604,139.776,,,65.333,30.389,49.245,50.925,,3.688,133.636
1990,55.065,47.494,33.6,-24.978,47.778,162.29,131.175,47.59,17.06,27.739,-10.778,192.168,,0.434,3.856,,75.394,5.105,64.492,-62.398,-390.919,56.016,516.208,130.401,-153.213,-18.281,,9.893,167.614,79.036,,39.467,,,180.073,-6.522,4.333,7.258,46.833,,,,26.143,69.384,50.425,,207.461,78.546,,-30.116,,4.045,-82.025,,127.235,163,-4.094,91.559,22.054,-80.137,31.636,16.884,44.045,,-129.292,-33.963,142.226,,,55.167,29.252,48.963,48.855,,2.342,133.636
1991,51.102,40.547,29.919,-13.685,40.953,154.87,119.274,44.464,23.509,28.378,-5.349,194.866,,-5.579,8.099,,68.081,8.184,62.925,-32.081,-402.401,54.572,525.75,125.229,-150.585,-22.551,,2.951,176.279,74.713,,42.496,,,177.247,-7.093,5.583,12.075,39.917,,,,19.798,69.563,41.067,,197.107,74.973,,-32.768,,3.545,-81.097,,121.562,152.867,-7.427,93.025,30.945,-85.373,26.658,12.573,42.776,,-140.503,-31.471,150.91,,,44.5,22.584,50.634,46.709,,-0.614,133.636
1992,46.71,38.734,32.778,4.175,39.775,143.193,106.695,46.487,23.051,22.982,1.107,201.933,,-10.208,12.488,,67.042,12.652,55.417,2.35,-403.859,53.096,533.917,129.631,-146.795,-26.267,,1.668,166.379,81.219,,35.059,,,175.174,-2.86,11.333,18.278,46.25,,,,11.393,69.563,,,193.509,71.845,,-38.867,,3.227,-82.188,,118.898,158.167,-11.631,93.195,34.021,-80.803,22.875,13.724,39.226,,-139.414,-21.029,151.1,50.256,,36.833,16.836,53.853,42.56,,6.007,133.636
1993,38.815,34.167,35.928,10.037,37.097,135.797,96.505,43.155,23.463,14.955,3.931,,,-13.373,5.852,,59.635,0.308,49.092,20.033,-422.908,49.2,554.375,120.219,-146.795,-29.934,,-9.014,157.079,86.45,,31.823,,,175.69,-2.97,,18.429,36.25,-32.248,,,6.673,69.563,,,186.163,69.94,,-51.35,,11.833,-88.122,,113.837,145.49,-19.391,113.353,35.396,-79.012,20.842,13.794,35.367,,-150.75,-13.475,143.048,,-0.637,32.917,11.295,51.983,39.553,,7.421,133.636
1994,34.987,28.441,39.978,1.67,40.4,139.5,91.714,42.451,23.463,15.329,9.089,,,-16.296,6.768,,62.559,18.672,49.508,35.892,-423.793,48.317,579.833,124.233,-146.795,-29.84,,-3.885,150.292,82.429,,34.915,,,177.205,3.068,,21.944,42.417,-35.803,,,11.096,69.563,,,188.433,68.328,,-51.053,,20.439,-85.355,,103.956,149.213,-33.085,109.712,40.791,-81.88,20.267,8.541,34.687,,-146.608,-3.397,138.399,,5.807,31.167,14.344,50.044,32.28,,8.721,133.636
1995,33.66,25.663,44.563,15.453,46.378,140.842,95.635,42.673,23.463,17.222,10.694,,,-17.446,5.322,,66.257,,44.168,67.467,-418.962,46.96,604.542,130.949,-146.795,-26.603,,-5.44,144.581,90.828,,33.188,,,177.469,8.795,,22.86,51.75,-30.132,,,8.246,69.563,,,191.336,65.828,,-50.488,,29.045,-83.088,,97.628,155.518,-41.343,117.373,46.026,-76.427,19.358,6.698,35.349,,-138.993,7.045,141.701,,9.184,,13.939,53.869,36.575,,10.254,133.636
1996,29.549,27.062,47.238,29.093,47.283,134.325,95.787,43.357,23.463,17.864,13.191,,,-15.342,-1.312,,59.681,,51.251,90.042,-439.466,50.177,619.917,125.614,-146.795,-24.627,,-8.301,137.314,100.055,,32.341,,,181.888,10.158,,23.691,36.917,-24.115,,,7.342,69.563,,,183.301,66.347,,-60.463,,37.651,-95.197,,101.19,145.987,-39.005,121.453,49.011,-73.805,18.133,7.817,39.278,,-150.084,,151.5,,11.71,,12.378,55.021,26.293,,11.915,133.636
1997,26.965,26.279,51.831,29.415,48.833,124.398,,50.838,,23.121,14.378,,,-17.494,3.398,,61.423,,42.401,100.684,-440.316,49.225,672.617,126.494,-146.795,-25.913,,-0.132,133.273,113.847,,30.326,,,182.133,10.88,,25.927,47,-23.974,-4.203,,-1.898,69.563,,,182.761,68.798,,-61.242,,46.257,-95.98,,101.49,149.17,-43.759,123.095,50.911,-68.61,11.108,18.743,40.191,,-148.009,,152.925,,13.557,,12.784,58.995,21.204,,13.776,133.636
1998,37.994,21.919,51.831,25.195,50.539,127.742,,57.002,,24.115,17.051,,,-15.838,9.893,,62.201,,47.726,113.884,-444.945,47.312,721.483,124.685,-146.795,-24.365,,1.014,130.806,115.173,,18.014,,,187.295,16.998,,30.254,60.417,-24.69,-2.161,,-1.588,69.563,,,182.552,72.871,,-69.141,,54.863,-97.013,,104.277,151.053,-47.115,,44.921,-63.212,5.525,18.095,38.524,,-148.285,,152.668,,19.709,,11.344,64.933,33.607,,13.043,133.636
1999,37.862,23.784,51.831,33.989,54.019,123.378,,59.451,,23.029,20.52,,,-6.947,11.895,,57.46,,51.093,114.914,-457.948,46.18,780.142,119.435,-146.795,-11.966,,7.591,124.448,126.783,,8.257,,,197.166,22.111,,32.628,52.583,-19.212,3.132,,-5.338,69.563,,,185.675,73.998,,-79.65,,63.469,-107.327,,112.614,141.987,-48.36,,34.784,-55.279,8.892,21.526,35.917,,-158.773,,155.229,,30.88,,9.351,68.702,32.623,,11.063,133.636
2000,34.569,29.537,51.831,41.909,56.733,119.155,,55.914,,22.451,24.674,,,-0.598,9.702,,51.958,,53.176,112.706,-471.551,47.819,830.925,109.157,-146.795,-8.937,,19.686,114.365,132.488,,-3.206,,,206.871,25.324,,34.346,46.833,-11.155,-1.744,,-7.15,69.563,,,184.814,77.753,,-89.108,,72.075,-113.96,,125.29,,-56.764,,25.428,-43.865,12.675,24.957,37.393,,-166.234,,157.647,,37.316,,10.863,69.65,28.401,,10.282,133.636
2001,34.502,36.175,51.831,60.088,68.283,120.452,,45.175,,25.464,29.93,,,6.781,11.849,,57.953,,58.218,103.001,-477.249,52.004,871.292,111.914,-146.795,-5.634,,29.997,110.667,136.536,,-8.528,,-0.761,192.458,28.091,,33.776,50.167,-3.095,1.581,,-6.45,69.563,,,195.17,81.978,,-90.871,,80.681,-115.147,,140.337,,-62.36,,20.826,-30.664,15.158,28.387,40.584,,-162.562,,162.069,,42.435,,15.422,67.864,26.047,,9.771,133.636
2002,33.406,39.626,51.831,58.591,74.178,124.738,,38.912,,28.129,31.074,,,14.403,20.197,,60.588,,74.635,,-490.961,57.166,919.992,112.393,-146.795,2.184,,32.617,118.392,138.993,,2.842,,1.855,178.28,31.74,,34.628,48.083,-0.961,6.331,,-0.179,69.563,,,199.288,83.575,,-92.425,,89.287,,,148.72,,-55.722,,21.291,-23.358,15.158,31.818,46.116,,-168.241,,169.848,,53.911,,22.383,65.495,32.065,,5.05,133.636
2003,34.246,45.13,51.831,72.126,79.069,124.702,,30.608,,29.038,35.021,,,19.136,33.081,,65.061,,84.301,,-497.928,61.302,869.417,116.882,-146.795,11.082,,39.947,129.458,138.66,,25.229,,12.605,160.393,37.738,,39.331,58.25,1.439,2.867,,7.817,69.563,,,202.112,89.175,,-89.783,,97.893,,,158.313,,-46.635,,27.984,-13.916,15.158,35.249,51.508,,-169.084,,179.225,,53.067,,27.045,65.915,25.988,,3.25,133.636
2004,31.348,48.7,51.831,,82.556,119.84,,27.312,,28.05,34.945,,,22.375,33.546,,70.328,,87.476,,-497.288,63.784,863.458,121.594,-146.795,12.91,,32.084,135.592,140.506,,40.113,,18.133,140.217,43.952,,42.017,74.917,3.779,17.467,,4.625,69.563,,,204.408,94.215,,-88.414,,106.499,,,170.112,,-37.602,,29.226,-4.434,,38.679,54.974,,-166.405,,188.662,,50.329,,27.79,67.156,17.708,,-2.833,133.636
2005,33.593,51.973,51.831,,82.897,113.611,,27.731,,24.728,38.019,,,24.093,35.76,,70.029,,93.3,,-503.288,62.519,863.5,120.398,-146.795,14.474,,31.547,140.825,134.95,,54.936,,22.163,121.332,45.478,,43.665,71.95,1.318,11.017,,13.469,69.563,,,204.341,97.39,,-87.837,,115.105,,,186.114,,-35.446,,38.257,-1.515,,42.11,54.338,,-169.922,,198.099,,51.793,,28.337,67.457,14.086,,-9.733,133.636
2006,35.615,50.617,51.831,,90.653,120.228,,26.424,,22.052,40.828,,,20.099,41.613,,78.919,,93.757,,-493.057,61.59,864.125,129.808,-146.795,15.326,,30.501,147.833,129.845,,74.626,,20.458,97.236,49.656,,48.754,87.133,-0.395,3.592,,20.067,69.563,,,215.544,96.769,,-80.037,,123.711,,,199.805,,-33.671,,44.367,4.256,,45.541,51.542,,-159.889,,207.536,,46.829,,34.19,67.3,29.891,,-12.467,133.636
2007,40.998,58.306,51.831,,99.678,114.766,,15.064,,12.292,45.563,,,19.567,39.922,,87.723,,,,-482.653,61.697,820.8,141.868,-146.795,13.143,,27.538,151.625,121.264,,91.751,,21.95,76.845,53.933,,50.616,98.833,5.446,0.292,,29.575,69.563,,,226.605,96.817,,-76.2,,132.317,,,221.2,,-30.38,,53.689,9.989,,48.971,47.485,,-149.075,,216.973,,42.009,,36.862,62.54,39.671,,-12.333,133.636
2008,40.013,64.907,,,101.897,109.476,,12.764,,12.284,49.864,,,18.709,35.488,,89.442,,,,-483.594,63.057,774.975,143.902,-146.795,9.721,,30.231,155.208,124.172,,118.152,,22.154,54.07,53.446,,48.842,96.6,12.33,-1.833,,36.472,69.563,,,227.491,97.92,,-75.093,,140.923,,,242.673,,-24.506,,59.628,11.856,,52.402,47.211,,-148.222,,226.41,,36.065,,39.328,58.938,28.462,,-9.3,133.636
2009,43.652,66.299,,,101.725,121.066,,18.693,,14.14,51.272,,,20.12,37.472,,88.386,,,,-498.483,65.65,728.358,144.111,-146.795,10.49,,30.338,158.958,113.344,,146.805,,25.063,35.415,53.663,,47.612,91.108,12.876,4.167,,50.476,69.563,,,225.58,96.861,,-78.624,,149.529,,,240.867,,-15.872,,63.135,11.082,,55.833,50.311,,-153.463,,235.848,,32.848,,41.577,57.714,37.087,,-8.833,133.636
2010,47.465,66.922,,,100.797,131.136,,24.27,,12.594,54.65,,,29.33,40.801,,86.54,,,,-517.41,69.52,695.075,145.375,-146.795,19.244,,25.23,159.167,108.972,,,,28.224,21.708,59.194,,47.672,86.635,13.837,19.917,,68.039,69.563,,,223.672,93.026,,-86.751,,158.135,,,239.509,,3.075,,63.385,12.668,,60.694,56.117,,-162.415,,245.285,,,,41.165,60.249,42.546,,-7.933,133.636
2011,53.57,71.544,,,96.344,141.351,,37.42,,10.274,52.701,,,28.136,38.581,,88.896,,,,-513.615,68.136,661.833,146.412,-146.795,19.814,,24.137,167.333,107.762,,,,23.576,23.557,63.867,,47.451,92.502,17.737,21.565,,74.322,69.563,,,223.927,92.308,,-82.247,,166.741,,,226.055,,15.658,,65.15,14.217,,62.209,54.265,,-157.118,,254.722,,,,42.905,61.644,41.431,,-8.267,133.636
2012,61.434,79.474,,,94.103,146.414,,40.847,,7.222,62.163,,,25.422,33.687,,90.55,,,,-507.504,65.816,618.258,148.106,-146.795,20.322,,33.071,163.967,105.835,,,,22.111,24.534,68.843,,49.164,104.668,27.02,18.447,,81.718,69.563,,,224.602,95.684,,-84.511,,175.347,,,222.59,,20.547,,66.716,20.027,,63.345,50.354,,-150.285,,264.159,,,,40.338,64.012,34.45,,-3.3,133.636
2013,68.306,88.777,,,91.5,156.793,,43.986,,2.799,69.039,,,31.752,26.182,,89.38,,,,-513.012,65.892,669.25,147.745,,26.266,,40.308,161.175,106.81,,,,19.809,31.275,73.421,,47.325,102.335,36.373,18.447,,82.518,69.563,,,225.115,102.066,,-87.014,,183.953,,,217.355,,28.535,,58.73,25.698,,64.7,47.41,,-151.449,,273.596,,,,39.561,62.596,28.356,,2.117,133.636
2014,77.339,94.253,,,90.737,163.811,,49.241,,0.099,77.589,,,36.742,29.744,,82.932,,,,-525.533,69.394,672.208,142.755,,28.777,,47.493,164.758,106.808,,,,20.399,38.732,73.899,,47.264,80.752,42.192,18.447,4.328,96.81,,,,226.153,107.651,,-90.955,,192.559,,,199.591,,42.444,,59.56,28.057,,63.981,47.948,,-159.374,,283.033,,,,43.323,64.925,41.54,,,
2015,85.886,95.65,,,92.181,174.619,,47.283,,3.336,83.527,,,38.487,38.409,,85.403,,,,-516.91,71.005,669.208,145.817,,30.107,,49.654,171.983,104.501,,,,19.233,43.594,79.956,,50.228,87.165,46.036,18.447,7.591,99.895,,,,230.937,114.839,,-87.411,,201.165,,,177.252,,50.604,,56.292,27.007,,63.156,47.403,,-151.878,,292.808,,,,49.235,65.569,42.409,,,
2016,96.374,100.77,,,95.256,181.912,,48.388,,3.366,90.83,,,42.497,48.891,,85.504,,,,-517.316,72.702,668.083,147.82,,31.454,,58.927,171.333,106.912,,,,20.808,56.548,89.942,,53.448,85.549,52.06,18.447,9.049,101.789,,,,234.597,123.386,,-89.628,,209.771,,,149.598,,65.202,,57.965,30.891,,65.747,47.54,,-149.74,,300.406,,,,51.211,69.614,44.539,,,
2017,97.764,105.458,,,94.892,,,60.567,,6.233,95.441,,,40.643,50.982,,83.34,,,,-521.215,72.911,667.542,146.082,,33.029,,70.431,174.025,,,,,15.773,63.846,95.214,,52.93,82.182,60.15,18.447,9.532,109.976,,,,236.124,133.943,,-90.818,,,,,127.643,,82.088,,53.887,35.421,,64.63,48.442,,-151.575,,311.814,,,,57.394,74.553,43.768,,,
2018,106.852,106.983,,,91.337,,,63.735,,10.531,99.26,,,47.178,56.124,,,,,,-534.545,74.582,668.208,141.139,,40.429,,71.59,180.392,,,,,17.606,78.942,101.741,,54.606,70.499,58.989,,15.757,121.908,,,,233.288,142.208,,-94.338,,,,,100.371,,99.547,,58.099,36.151,,68.368,50.39,,-161.078,,318.713,,,,60.538,77.823,54.615,,,
2019,112.311,107.393,,,95.659,,,70.25,,9.147,106.446,,,42.297,60.79,,,,,,-528.697,77.375,,138.538,,39.166,,72.855,,,,,,16.581,87.896,108.758,,57.152,,,,11.949,128.683,,,,240.531,149.312,,-95.022,,,,,85.044,,111.429,,62.301,33.981,,,51.214,,-155.007,,326.323,,,,66.596,85.272,,,,
2020,,108.603,,,,,,72.598,,10.777,,,,28.688,70.563,,,,,,,78.383,,,,,,77.937,,,,,,,88.983,114.704,,,,,,8.974,125.738,,,,249.117,157.291,,-81.078,,,,,,,117.576,,79.422,31.411,,,48.505,,-137.516,,335.671,,,,76.292,89.23,,,,`

export default function Levels() {

  return(
    <LazyHighcharts
      options={{
        data: {
          csv: data,
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
          },
        },
        legend: {
          maxHeight: 100,
        },
        chart: {
          zoomType: "x",
          height: "600px",
          boost: {
            seriesThreshold: 30,
          },
        },
        yAxis: {
          title: {
            text: "ความเปลี่ยนแปลง (มม., ค่าเฉลี่ยเคลื่อนที่ 10 ปี)"
          }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                align: "right",
                verticalAlign: "middle",
                layout: "vertical",
                maxHeight: undefined,
              },
              chart: {
                height: "70%",
              },
            },
          }]
        }
      }}
    />
  )

}