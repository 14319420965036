import React from "react"
import TaxWorkforce from "./TaxWorkforce"
import TaxContribution from "./TaxContribution"

import AltTax from "./alt/tax.png"

export default function Tax() {
  return(
    <>
      <img className="printonly" src={AltTax} alt="Tax" />
      <div className="split-2 noprint">
        <TaxWorkforce />
        <TaxContribution />
      </div>
    </>
  )
}