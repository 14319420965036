import React from "react"
import thMap from "@highcharts/map-collection/countries/th/th-all.topo.json";
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"
import data from "./data-hc.json"

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import "./Blog240302.scss"



const Blog240302 = () => {
  
  const [source, setSource] = React.useState("กรุงเทพฯ")
  const handleChange = (e) => {
    setSource(e.target.value);
  };

  return (
    <div className="blog240302-container">
      {/* show htis only if less than screen-mid */}
      <div className="blog240302-phone">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">จังหวัด</InputLabel>
          <Select
            value={source}
            onChange={handleChange}
            label="จังหวัด"
            size="small"
          >
            {Object.keys(data).map((key) => (
              <MenuItem key={key} value={key}>{key}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <LazyHighcharts
        constructorType={"mapChart"}
        options={
          {
            chart: {
              map: thMap,
              height: 600,
              spacingBottom: 20,
              type: "map",
            },
          
            dataLabels: {
              enabled: false,
            },
          
            title: {
              text: "",
            },
          
            xAxis: {
              visible: false,
            },
            yAxis: {
              visible: false,
            },
          
            mapView: {
              projection: {
                name: "Miller",
              },
            },
          
            legend: {
              enabled: true,
            },
          
            plotOptions: {
              map: {
                allAreas: false,
                tooltip: {
                  headerFormat: "",
                  // format to two decimals
                  pointFormat: "{point.name}: <b>{point.value:.2f}</b>",
                },
              },
            },
          
            colorAxis: {
              type: 'logarithmic',
              stops: [
                [0, defaultStyles.colors[1]],
                // [0, '#3060cf'],
                [0.5, '#fffbbc'],
                [1, defaultStyles.colors[0]],
                // [1, '#c4463a'],
              ],
              max: 9.9,
            },
          
            series: [
              {
                name: "No subsidy",
                data: data[source],
                allAreas: true,
              },
            ],
          }
        }
      />
      <div className="blog240302-desktop">
        <FormControl>
          <RadioGroup
            value={source}
            onChange={handleChange}
          >
            {Object.keys(data).map((key) => (
              <FormControlLabel key={key} value={key} control={<Radio size="small" />} label={key} />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      
    </div>
  )
}

export default Blog240302