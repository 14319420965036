import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots"
import Slider from "@mui/material/Slider";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const maxX = 10;
const steps = 1000;
const xArray = Array.from(Array(steps + 1).keys()).map((x) => x / (steps / maxX));
const maxPovertyLevel = 3

export default function Ab2223Transfers({
  initialTransferScheme="all",
  initialPovertyLevel=3,
  initialLowestTaxableLevel=2.55,
  allowChangingInformalWorkers=true,
  allowChangingTransferScheme=false,
}) {

  const [transferScheme, setTransferScheme] = React.useState(initialTransferScheme)
  const [povertyLevel, setPovertyLevel] = React.useState(initialPovertyLevel)
  const [lowestTaxableLevel, setLowestTaxableLevel] = React.useState(initialLowestTaxableLevel)

  const totalTransferAmount = transferScheme === "all"
    ? povertyLevel * maxX
    : 1/2 * povertyLevel * povertyLevel

  const getTransfer = (x) => {
    return transferScheme === "all"
      ? povertyLevel
      : Math.max(0, povertyLevel - x)
  }

  const taxFunction = transferScheme === "all"
    ? (x) => x < lowestTaxableLevel ? 0 : Math.sqrt(x)
    : (x) => Math.sqrt(x)

  // tax revenue = integral of tax function = 2/3 x^(3/2) from lower end to max
  const taxRevenue = transferScheme === "all"
    ? 2/5 * (Math.pow(maxX, 5/2) - Math.pow(lowestTaxableLevel, 5/2))
    : 2/5 * (Math.pow((maxX - povertyLevel), 5/2))

  // find alpha to balance the transfer
  const calculateAlpha = () => totalTransferAmount / taxRevenue

  const incomeAfterTransfer = (x) => x + getTransfer(x)

  const incomeAfterTransferAndTax = (x) => transferScheme === "all"
    ? x + getTransfer(x) - x * (calculateAlpha() * taxFunction(x))
    : x >= povertyLevel
      ? x + getTransfer(x) - (x - povertyLevel) * (calculateAlpha() * taxFunction(x - povertyLevel))
      : x + getTransfer(x)

  return (
    <div>
      <div className="split-2">
        <LazyHighcharts
          options={{
            xAxis: {
              min: 0,
              max: maxX,
            },
            yAxis: {
              min: 0,
              max: maxX,
              title: {
                text: "บาท",
              },
            },
            series: [
              {
                name: "45-degree line",
                type: "line",
                data: [
                  [0, 0],
                  [maxX, maxX]
                ],
                color: "black",
                dashStyle: "Dot",
                lineWidth: 1,
                enableMouseTracking: false,
                marker: {
                  enabled: false
                },
                showInLegend: false,
              },
              {
                name: "รายได้รวมเงินให้เปล่า",
                type: "line",
                data: xArray.map((x) => ([x, incomeAfterTransfer(x)])),
                color: defaultStyles.colors[1],
              },
              {
                name: "รายได้หลังภาษีและเงินให้เปล่า",
                type: "line",
                data: xArray.map((x) => ([x, incomeAfterTransferAndTax(x)])),
                color: defaultStyles.colors[0],
              }
            ],
            chart: {
              animation: false,
              events: {
                render: function() {
                  const xAxis = this.xAxis[0]
                  const yAxis = this.yAxis[0]
                  const xZero = xAxis.toPixels(0)
                  const yZero = yAxis.toPixels(0)
                  const w = xAxis.toPixels(lowestTaxableLevel)
                  const height = yAxis.toPixels(10)
                  const box = [
                    'M', xZero, yZero,
                    'H', w,
                    'V', height,
                    'H', xZero,
                    'z',
                  ]
                  if (this.lines) {
                    this.lines.map(x => x.element.remove())
                  }
                  this.lines = []
                  this.lines.push(this.renderer.path(box).attr({fill: 'red', opacity: 0.3}).add())
                },
              },
            },
            legend: {
              floating: true,
              align: 'right',
              verticalAlign: 'bottom',
              y: -40,
              layout: 'vertical',
            },
            tooltip: {
              formatter: function() {
                console.log(this)
                return `รายได้: ${this.x} บาท<br />${this.series.name}: <strong>${this.y.toFixed(2)}</strong> บาท`
              },
            },
          }}
        />
        <LazyHighcharts
          options={{
            yAxis: {
              min: -5,
              max: maxPovertyLevel,
              plotLines: [{
                color: 'black',
                value: 0,
                width: 1,
                zIndex: 3,
              }],
              title: {
                text: "บาท",
              },
            },
            series: [
              {
                name: "เงินได้รับ/จ่ายสุทธิ",
                type: "area",
                data: xArray.map((x) => ([x, incomeAfterTransferAndTax(x) - x])),
                color: 'green',
                negativeColor: 'red',
              }
            ],
            legend: {
              floating: true,
              align: 'right',
              verticalAlign: 'top',
              symbolPadding: 0,
              symbolWidth: 0,
              symbolHeight: 0,
              squareSymbol: false,
            },
            tooltip: {
              formatter: function() {
                console.log(this)
                return `รายได้: ${this.x} บาท<br />${this.series.name}: <strong>${this.y.toFixed(2)}</strong> บาท`
              },
            },
          }}
        />
      </div>
      <div style={{width: '300px', maxWidth: '100%', margin: '0 auto'}}>
        <div>
          <div style={{display: 'flex'}}>
            เงินให้เปล่า{allowChangingTransferScheme && '/เส้นความยากจน'}:
            <span style={{flex: '1'}} />
            <span>
              <strong>{povertyLevel.toFixed(2)}</strong>{' บาท'}
            </span>
          </div>
          <Slider
            value={povertyLevel}
            min={0}
            max={maxPovertyLevel}
            step={0.01}
            onChange={(e, newValue) => setPovertyLevel(newValue)}
          />
        </div>
        {allowChangingInformalWorkers &&
          <div>
            <div style={{display: 'flex'}}>
              สัดส่วนแรงงานนอกระบบ:
              <span style={{flex: '1'}} />
              <span>
                <strong>{(lowestTaxableLevel * 10).toFixed(1)}</strong>%
              </span>
            </div>
            <Slider
              value={lowestTaxableLevel}
              min={0}
              max={5}
              step={0.01}
              onChange={(e, newValue) => setLowestTaxableLevel(newValue)}
            />
          </div>
        }
        {allowChangingTransferScheme &&
          <FormControlLabel
            control={
              <Switch
                checked = {transferScheme === "targeted"}
                onChange={() => setTransferScheme(transferScheme === "all" ? "targeted" : "all")}
              />
            }
            label="คัดกรอง"
          />
        }
      </div>
    </div>
  )

}