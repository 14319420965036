import React from 'react'
import { LazyChartjs, defaultStyles } from '../../../../src/components/Plots'

export default function PoliticalShift() {

  return(
    <LazyChartjs
      type="line"
      style={{minHeight: "400px"}}
      data={{
        labels: [...Array(10).keys()],
        datasets: [
          {
            data: [0.023483366, 0.021526419, 0.014350946, 0.041748206, 0.259621657, 0.200260926, 0.240704501, 0.127853881, 0.033920417, 0.03652968].map(x => x*100),
            label: "2008",
            tension: 0.4,
            borderColor: defaultStyles.colors[1],
            backgroundColor: defaultStyles.colors[1],
          },
          {
            data: [0.019212296, 0.014409222, 0.030739673, 0.055715658, 0.361191162, 0.194044188, 0.11431316, 0.106628242, 0.037463977, 0.066282421].map(x => x*100),
            label: "2018",
            tension: 0.4,
            borderColor: defaultStyles.colors[0],
            backgroundColor: defaultStyles.colors[0],
          },
        ]
      }}
      options={{
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        radius: 0,
        scales: {
          x: {
            ticks: {
              callback: (value, index, values) => {
                if (index === 1)
                  return ['เสรีนิยม', 'Liberal']
                if (index === 8)
                  return ['อนุรักษ์นิยม', 'Conservative']
                return null
              },
            },
            grid: {
              color: 'rgba(255, 255, 255, 0)',
            },
          },
          y: {
            max: 40,
            title: {
              display: true,
              text: "สัดส่วนผู้ตอบแบบสอบถาม (%)"
            }
          }
        },
        plugins: {
          // title: {
          //   display: true,
          //   text: "ทัศนคติทางการเมืองจาก World Values Survey",
          // },
          legend: {
            labels: {
              pointStyle: 'line',
              usePointStyle: true,
            }
          }
        }
      }}
    />
  )


}