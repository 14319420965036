import React from 'react'
import { LazyChartjs } from '../../../../src/components/Plots'
import chroma from 'chroma-js'

const coefData = [
  {
    title: "ความสุดขั้ว", 
    data: [0, 0, 0, 0, -0.2205991, -0.6915629, -0.4377571, -1.58721, -0.4587052, 0.0399224, -0.0624773, 2.305114]
  },
  {
    title: "ความรู้สึกไม่ดีต่อฝั่งตรงข้าม",
    data: [0, -3.168377, -6.185956, 0, -4.332496, -5.34668, -7.804648, 9.998635, 5.958668, 3.164143, 4.615053, -12.45545]
  },
  {
    title: "ความคิดว่าต่างมากเกินจริง",
    data: [0, 0.3459591, 0.5754136, 0, -0.1405119, -0.3061428, -0.1909025, -0.4560134, 0.0107633, -0.2896076, 0.3828785, 1.081103]
  },
]

function Coefficients({ data, i, highlightArray=[], ...rest }) {

  // function alpharize(color) {
  //   return Array(12).fill(chroma(color).alpha(0.3).hex()).map((x, i) => highlightArray.includes(i) ? color : x)
  // }

  const barColors = 
    (i === 1)
    ? data[i].data.map(x => x > 0 ? 'green' : 'firebrick').map(x => chroma(x).brighten(1).hex())
    : data[i].data.map(x => x < 0 ? 'green' : 'firebrick').map(x => chroma(x).brighten(1).hex())


  return(
    <LazyChartjs
      type="bar"
      style={{width: "100%", minWidth: "0", minHeight: "400px"}}
      data={{
        labels: (i === 0 ? [" ", " ", " "] : ["Stance: Mild", "Stance: Moderate", "Stance: Extreme"]).concat(["Age: ≤ 30", "Age: 30–39", "Age: 40–59", "Age: ≥ 60", "Security index", "Openness: family", "Openness: friends", "Media entropy", "Media echo"]),
        datasets: [
          {
            data: data[i].data,
            backgroundColor: barColors//.map((c, i) => chroma(c).alpha(areaAlpha * (highlightArray.includes(i) ? 1 : 0.3)).hex()),
          },
        ]
      }}
      options={{
        maintainAspectRatio: false,
        // aspectRatio: 1.8,
        layout: {
          padding: 0
        },
        indexAxis: 'y',
        scales: {
          y: {
            // stacked: true,
            ticks: {
              // color: alpharize(defaultStyles.font.color, 1),
            }
          },
          x: {
            // ticks: {
            //   callback: (value, index) => {
            //     if (value % 5e5 === 0)
            //       return Math.abs(value)
            //     return null
            //   }
            // }
            min: [-3, -15, -1.5][i],
            max: [3, 15, 1.5][i],
            title: {
              display: true,
              text: "ค่าสัมประสิทธิ์",
            },
          }
        },
        plugins: {
          title: {
            display: true,
            text: data[i].title,
          },
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              // highlight: {
              //   type: 'box',
              //   yMin: ((highlightArray[0] === 0) && (i === 0)) ? 2.5 : highlightArray[0] - 0.5,
              //   yMax: ((highlightArray[0] === 0) && (i === 0)) ? 2.5 : highlightArray[highlightArray.length-1] + 0.5,
              //   backgroundColor: chroma('black').alpha(0.2).hex(),
              //   borderColor: 'rgba(255, 255, 255, 0)',
              //   drawTime: 'beforeDraw'
              // }
            }
          }
        }
      }}
      {...rest}
    />
  )


}

export default function CoefAll() {

  return(
    <div style={{
      // display: "grid",
      // gridTemplateColumns: "1fr 1fr 1fr",
    }}>
      {
        [...Array(3).keys()].map(i =>
          <Coefficients
            data={coefData}
            i={i}
            key={`coef${i}`}
          />
        )
      }
    </div>
  )

}