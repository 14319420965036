import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

let rawData = [
  [1,1,1,2,3.5],
  [1,1,1,3,6],
  [1,1,2,4,8.5],
  [1,1,2,5,11],
  [1,1,2,5,11],
  [1,1,3,6,13.5],
  [1,1,3,7,16],
  [1,2,3,7,14.5],
  [1,2,4,10,22],
  [1,2,4,9,19.5],
  [1,2,5,10,22],
  [1,3,5,11,23],
  [1,3,6,13,28],
  [1,4,8,15,31.5],
  [1,7,15,29,62],
]

// TODO: Need to update this data

export default function Destinations({ dataSeries }) {

  return(
    <LazyHighcharts
      options={{
        series: [{
          name: "Observations",
          data: rawData,
        }],
        plotOptions: {
        },
        legend: {
          enabled: false,
        },
        chart: {
          height: "400px",
          type: "boxplot",
        },
        xAxis: {
          categories: [...Array(15).keys()].map(x => (x + 1).toFixed(0)),
          title: {
            text: "จำนวนเดือนที่มีการใช้งาน",
          }
        },
        tooltip: {
          formatter: function(tooltip) {
            let tmp = tooltip.defaultFormatter.call(this, tooltip);
            tmp[1] = tmp[1].replace('Maximum', `90th percentile`)
            tmp[1] = tmp[1].replace('Minimum', `10th percentile`)
            return tmp
          },
        },
        yAxis: {
          title: {
            text: "ปริมาณธุรกรรมเฉลี่ยในเดือนที่มีการใช้งาน",
          }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}