import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'
import chroma from 'chroma-js'

const areaAlpha = defaultStyles.areaAlpha

function Age() {

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "400px"}}
      data={{
        labels: ["< 30", "30–34", "35–40", "41–44", "45–50", "51–54", "55–60", "> 60"],
        datasets: [
          {
            data: [0.1635731, 0.1742387, 0.1722372, 0.1623036, 0.1486334, 0.1371109, 0.1249853, 0.1181232].map(x => x * 100),
            label: "ก่อนสิ้นสุดมาตรการช่วยเหลือระยะที่ 1",
            backgroundColor: getAreaColor(defaultStyles.colors[1]),
            borderColor: `none`,
          },
          {
            data: [0.0588485, 0.0624361, 0.0555808, 0.0466168, 0.036707, 0.0287787, 0.0239221, 0.0152312].map(x => x * 100),
            label: "หลังสิ้นสุดมาตรการช่วยเหลือระยะที่ 1",
            backgroundColor: getAreaColor(defaultStyles.colors[0]),
            borderColor: `none`,
          },
        ]
      }}
      options={{
        maintainAspectRatio: false,
        radius: 0,
        scales: {
          y: {
            stacked: true,
            title: {
              display: true,
              text: "สัดส่วนผู้กู้ที่ค้างชำระเกิน 90 วัน (%)"
            },
          },
          x: {
            stacked: true,
          }
        },
        plugins: {
          annotation: {
            annotations: {
              avg: {
                type: 'line',
                yMin: 18.9,
                yMax: 18.9,
                borderColor: defaultStyles.font.color,
                borderDash: [2, 2],
                drawTime: 'beforeDatasetsDraw',
                label: {
                  enabled: true,
                  content: "ค่าเฉลี่ยทุกช่วงอายุ",
                  position: "end",
                  font: defaultStyles.font,
                }
              }
            }
          }
        },
      }}
    />
  )


}

function Time() {

  return(
    <LazyChartjs
      type="line"
      style={{minHeight: "400px"}}
      data={{
        labels: ["2019Q1", "2019Q2", "2019Q3", "2019Q4", "2020Q1", "2020Q2", "2020Q3", "2020Q4", "2021Q1", "2021Q2"],
        datasets: [
          {
            data: [270590, 226722, 230139, 269892, 300600, 148902, 246712, 375870, 485447, 414478],
            label: "เริ่มค้างชำระเกิน 30 วัน",
            tension: 0.4,
            backgroundColor: chroma('yellow').saturate(0).brighten(-0.5).alpha(areaAlpha).hex(),
            borderColor: chroma('yellow').saturate(0).brighten(-0.5).alpha(areaAlpha).hex(),
            fill: true,
          },
          {
            data: [116539, 188692, 159448, 166203, 151044, 95208, 147564, 160150, 224416, 293584],
            label: "เริ่มค้างชำระเกิน 60 วัน",
            tension: 0.4,
            backgroundColor: chroma('orange').saturate(0).brighten(-0.5).alpha(areaAlpha).hex(),
            borderColor: chroma('orange').saturate(0).brighten(-0.5).alpha(areaAlpha).hex(),
            fill: true,
          },
          {
            data: [99443, 286083, 199731, 172685, 128786, 87597, 87027, 127230, 167354, 252879],
            label: "เริ่มค้างชำระเกิน 90 วัน",
            tension: 0.4,
            backgroundColor: chroma('red').saturate(0).brighten(-0.5).alpha(areaAlpha).hex(),
            borderColor: chroma('red').saturate(0).brighten(-0.5).alpha(areaAlpha).hex(),
            fill: true,
          },
          {
            data: [13.0647, 14.0709, 14.33325, 15.0378, 14.75127, 15.98843, 17.57394, 17.0901, 17.33212, 18.90122],
            label: "สัดส่วนลูกหนี้ที่เป็นหนี้เสีย (RHS)",
            tension: 0.4,
            backgroundColor: `rgba(255, 255, 255, 0)`,
            borderColor: defaultStyles.colors[1],
            yAxisID: 'y1',
            pointStyle: 'line',
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        radius: 0,
        scales: {
          y: {
            stacked: true,
            title: {
              display: true,
              text: "จำนวนผู้กู้ (คน)"
            },
            min: 0,
            max: 1e6,
          },
          y1: {
            position: 'right',
            display: true,
            min: 0,
            title: {
              display: true,
              text: "สัดส่วนลูกหนี้ที่เป็นหนี้เสีย (%)"
            },
          },
          x: {
            stacked: true,
          }
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            }
          },
          annotation: {
            annotations: {
              covid: {
                type: 'box',
                xMin: "2020Q2",
                // xMax: "Dec-15",
                drawTime: "beforeDatasetsDraw",
                borderWidth: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              },
              text: {
                type: 'line',
                yMin: 1e5,
                yMax: 1e5,
                borderColor: `rgba(255, 255, 255, 0)`,
                borderDash: [0],
                drawTime: 'afterDatasetsDraw',
                label: {
                  enabled: true,
                  content: "หมดมาตรการฯ ระยะที่ 1",
                  font: {
                    ...defaultStyles.font,
                    style: 'normal',
                  },
                  xAdjust: 140,
                  backgroundColor: 'rgba(255, 255, 255, 0)',
                  color: defaultStyles.font.color,
                }
              }
            }
          },
          // texts: {
          //   texts: {
          //     bombing: {
          //       text: 'หมดมาตรการฯ ระยะแรก',
          //       x: 510,
          //       y: 35,
          //     },
          //   },
          // },
        }
      }}
    />
  )


}

export default { Age, Time }