import React from 'react'
import { LazyChartjs, getAreaColor } from '../../../../src/components/Plots'
import chroma from 'chroma-js'

const politicalColors = chroma.scale(['orange', 'white', 'yellow'])

export default function Extremity() {

  return(
    <LazyChartjs
      type="bar"
      // width="100%"
      style={{minHeight: "400px"}}
      data={{
        labels: ["เสาวรสมากที่สุด", "เสาวรสค่อนข้างมาก", "ค่อนไปทางเสาวรส", "ค่อนไปทางกล้วยหอม", "กล้วยหอมค่อนข้างมาก", "กล้วยหอมมากที่สุด"],
        datasets: [
          {
            data: [545, 511, 370, 301, 184, 105],
            backgroundColor: getAreaColor(politicalColors.colors(6)),
            borderColor: "none",
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        // aspectRatio: 1.5,
        scales: {
          y: {
            max: 600,
            title: {
              display: true,
              text: "จำนวน (ราย)",
            },
          },
        },
        plugins: {
          // title: {
          //   display: true,
          //   text: "จำนวนผู้ตอบแบบสอบถามในแต่ละกลุ่ม",
          // },
          legend: {
            display: false,
          }
        }
      }}
    />
  )


}