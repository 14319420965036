import React from "react"
import { SimpleChart } from "../../../../src/components/Plots"

const groups = ['Digital front-runners', 'Digital adopters', 'Digital followers']

export default function OddRatios({ title, data, categories, insig, altImage }) {

  return(
    <SimpleChart
      type="column"
      data={data.map((x, i) => ({ name: groups[i], data: x }))}
      categories={categories}
      ytitle={'Odd ratio'}
      override={{
        title: {
          text: title,
        },
        chart: {
          events: {
            load: insig && function() {
              insig.forEach(x => {
                this.series[x[1]].points[x[0]].update({
                  color: 'white',
                  borderColor: 'black',
                })
              })
            }
        }
        }
      }}
      altImage={altImage}
    />
  )

}