import React from "react"
import { defaultStyles, LazyHighcharts } from "../../../../../src/components/Plots"

import Alt from "./alt/inequality.png"

const rawData = [33,35,36,36,38,39,39,37,33,28,30,29,30,29,29,31,34,34,33,33,34,34,32,32,32,32,33,35,35,35]

export default function Inequality() {

  return(
    <LazyHighcharts
      altImage={Alt}
      options={{
        series: [
          {
            name: 'Capital share',
            data: rawData,
            color: defaultStyles.colors[1],
          },
          {
            name: 'Labor share',
            data: rawData.map(x => 100 - x),
            color: defaultStyles.colors[0],
          },
        ],
        plotOptions: {
          series: {
            pointStart: 2533,
            marker: {
              enabled: false,
            },
            label: {
              enabled: true,
              style: {
                fontSize: "1rem",
              },
            },
          },
          areaspline: {
            stacking: "normal",
          }
        },
        legend: {
          enabled: false,
        },
        chart: {
          type: "areaspline",
          // zoomType: "x",
          height: "500px",
        },
        xAxis: {
          // type: 'datetime',
        },
        tooltip: {
        //   xDateFormat: '%Y-%m',
        //   valueDecimals: 2,
          valueSuffix: "%",
          shared: true,
          crosshairs: true,
        },
        yAxis: {
          title: {
            text: "% ของ GDP"
          },
          max: 100,
          reversedStacks: true,
          // plotLines: [{
          //   value: 0,
          //   color: defaultStyles.zerolineColor,
          //   width: 2,
          // }],
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "60%",
              },
            },
          }]
        }
      }}
    />
  )

}

