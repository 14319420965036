import React from 'react'
import { LazyChartjs, defaultStyles } from '../../../../src/components/Plots'

const noDebt = [10, 15, 25, 29, 30, 24, 20, 18]
const optimalDebt = [21, 22, 22, 23, 22, 21, 21, 20]
const badDebt = [30, 33, 28, 22, 16, 12, 8, 5]

const step = 4
export default function Ab1024Fig1() {
  return(
    <LazyChartjs
      type="line"
      style={{minWidth: "0", width: "100%", minHeight: "300px"}}
      data={{
        // Age from 0-80, step by 10,
        labels: [
          "< 20",
          "20–30",
          "30–40",
          "40–50",
          "50–60",
          "60–70",
          "70–80",
          "> 80",
        ],
        datasets: [
          {
            label: "Income",
            data: noDebt,
            borderColor: "grey",
            borderDash: [5, 5],
            tension: 0.4,
            order: 3,
          },
          {
            label: "Good",
            data: optimalDebt,
            borderColor: "green",
            tension: 0.4,
            order: 2,
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          annotation: {
            annotations: {
              old: {
                type: 'line',
                borderWidth: 0,
                xMin: 3.5,
                xMax: 3.5,
                label: {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  enabled: true,
                  content: 'รายได้',
                  yAdjust: -135,
                  color: 'grey',
                },
              },
              good: {
                type: 'line',
                borderWidth: 0,
                xMin: 3.5,
                xMax: 3.5,
                label: {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  enabled: true,
                  content: 'รายได้หลังการโยกย้ายผ่านหนี้',
                  yAdjust: -20,
                  color: 'green',
                },
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "ช่วงอายุ",
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "รายได้",
            },
            // don't show numbers
            ticks: {
              display: false,
            },
            // min at 0
            min: 0,
            max: 35,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      }}
    />
  )
}