import React from "react";
import { LazyHighcharts, defaultStyles } from "../../../../src/components/Plots";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const data = {
  "อายุขัย 70 ปี": {
    name: "อายุขัย 70 ปี",
    data: [
      {
        name: "สมทบ 15 ปี",
        data: [126000, -360000, -234000, -23220, -190852],
      },
      {
        name: "สมทบ 20 ปี",
        data: [168000, -495000, -327000, -90454, -242189],
      },
      {
        name: "สมทบ 25 ปี",
        data: [210000, -630000, -420000, -173070, -273629],
      },
      {
        name: "สมทบ 30 ปี",
        data: [252000, -765000, -513000, -268783, -281697],
      },
    ],
  },
  "อายุขัย 80 ปี": {
    name: "อายุขัย 80 ปี",
    data: [
      {
        name: "สมทบ 15 ปี",
        data: [126000, -600000, -474000, -310611.8925, -516171.1309],
      },
      {
        name: "สมทบ 20 ปี",
        data: [168000, -825000, -657000, -507832.579, -705032.7137],
      },
      {
        name: "สมทบ 25 ปี",
        data: [210000, -1050000, -840000, -726274.0936, -849564.6538],
      },
      {
        name: "สมทบ 30 ปี",
        data: [252000, -1275000, -1023000, -962782.8328, -961854.2483],
      },
    ],
  },
  "อายุขัย 90 ปี": {
    name: "อายุขัย 90 ปี",
    data: [
      {
        name: "สมทบ 15 ปี",
        data: [126000, -840000, -714000, -707087.7933, -1005134.159],
      },
      {
        name: "สมทบ 20 ปี",
        data: [168000, -1155000, -987000, -1083633.384, -1343554.554],
      },
      {
        name: "สมทบ 25 ปี",
        data: [210000, -1470000, -1260000, -1489454.46, -1644104.756],
      },
      {
        name: "สมทบ 30 ปี",
        data: [252000, -1785000, -1533000, -1920200.424, -1900174.587],
      },
    ],
  },
};

export default function Fig2() {
  const [toShow, setToShow] = React.useState("อายุขัย 70 ปี");

  return (
    <>
      <LazyHighcharts
        options={{
          plotOptions: {},
          legend: {},
          chart: {
            type: "column",
            height: "400px",
          },
          xAxis: {
            categories: [
              "รายรับรวม",
              "รายจ่ายรวม",
              "ดุล ณ สิ้นอายุขัย",
              "ดุล ณ สิ้นอายุขัย (รวมดอกเบี้ย) <br/> (สมทบตั้งแต่อายุ 25)",
              "ดุล ณ สิ้นอายุขัย (รวมดอกเบี้ย) <br/> (สมทบถึงอายุ 55)",
            ],
          },
          title: {
            text: "ดุลสุทธิของกองทุนฯ ณ สิ้นอายุขัย",
            align: "center",
          },
          yAxis: {
            title: {
              text: "บาท",
            },
            plotLines: [
              {
                value: 0,
                color: defaultStyles.zerolineColor,
                zIndex: 9,
              },
            ],
            min: -2500000,
            max: 500000,
            startOnTick: false,
            endOnTick: false,
          },
          tooltip: {
            formatter: function () {
              let tmp = this.points || [this];
              let s = tmp
                .map((point) => `<b>${point.y.toFixed(2)}</b> [บาท]`)
                .join("<br/>");
              return s;
            },
          },
          series: JSON.parse(JSON.stringify(data[toShow].data)),
          responsive: {
            rules: [
              {
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    maxHeight: undefined,
                  },
                  chart: {
                    height: "50%",
                  },
                },
              },
            ],
          },
        }}
      />
      <div style={{ textAlign: "center" }}>
        <ToggleButtonGroup
          value={toShow}
          size="small"
          exclusive
          onChange={(e, v) => {
            if (v) {
              setToShow(v);
            }
          }}
        >
          {Object.keys(data).map((key) => (
            <ToggleButton value={key} key={key}>
              {key}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </>
  );
}
