import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = [
  {name: "Primary or lower", data: [85.0963, 76.0262, 57.4647, 34.1293]},
  {name: "Lower secondary", data: [5.8416, 9.0922, 14.7008, 18.2462]},
  {name: "Upper secondary", data: [4.3378, 6.5456, 11.6861, 20.9859]},
  {name: "Some college", data: [2.0330, 2.8889, 4.9166, 6.8696]},
  {name: "College", data: [2.6913, 5.4471, 11.2319, 19.7690]},
]

const years = [1985, 1994, 2004, 2022]

export default function Ab2023Education() {

  const colorDefs = [
    "#43a047",
    "#EBDF15",
    "#FFB801",
    "#9F3C49",
    "#882D85",
  ].reverse()
  // const colorDefs = defaultStyles.colors

  return(
    <div>
      <svg style={{height: 0, width: 0, display: 'block'}}>
        <defs>
          {[...Array(4)].map((_, i) => (
            <linearGradient key={i} id={`grad${i}`} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{stopColor: colorDefs[i + 1], stopOpacity: 0.3}} />
              <stop offset="100%" style={{stopColor: colorDefs[i], stopOpacity: 0.3}} />
            </linearGradient>
          ))}
        </defs>
      </svg>
			<div>
        <LazyHighcharts
          options={{
            series: rawData,
            colors: colorDefs,
            plotOptions: {
              column: {
                stacking: "normal",
              },
            },
            legend: {
              // enabled: false,
            },
            chart: {
              type: "column",
              height: "400px",
              events: {
                render: function() {
                  
                  const width = 0.24
                  function getPath(obj, column, layerLeft, layerRight=layerLeft) {
                    const xAxis = obj.xAxis[0]
                    const yAxis = obj.yAxis[0]
                    const left = xAxis.toPixels(column + width)
                    const right = xAxis.toPixels(column + 1 - width)
                    const topLeft = yAxis.toPixels(obj.series[layerLeft].data[column].stackY)
                    const topRight = yAxis.toPixels(obj.series[layerRight].data[column+1].stackY)
                    let bottomLeft = yAxis.toPixels(obj.series[layerLeft - 1]?.data[column].stackY || 0)
                    let bottomRight = yAxis.toPixels(obj.series[layerRight - 1]?.data[column+1].stackY || 0)
                    // let bottomLeft = yAxis.toPixels(stackLeft + 1 < obj.series.length ? (obj.series[stackLeft + 1].data[x].stackY) : 100)
                    // let bottomRight = yAxis.toPixels(stackRight + 1 < obj.series.length ? (obj.series[stackRight + 1].data[x + 1].stackY) : 100)
                    // if (stackRight === stackLeft) {
                    //   bottomLeft = yAxis.toPixels(obj.series[stackLeft + 2].data[x].stackY)
                    // }
                    return [
                      'M', left, topLeft,
                      'C', xAxis.toPixels(column + 0.5), topLeft, xAxis.toPixels(column + 1 - 0.5), topRight, right, topRight,
                      'V', bottomRight,
                      'C', xAxis.toPixels(column + 1 - 0.5), bottomRight, xAxis.toPixels(column + 0.5), bottomLeft, left, bottomLeft,
                      'Z',
                    ]
                  }

                  // const styles = {
                  //   stroke: 'black',
                  //   "stroke-width": 2,
                  //   "stroke-dasharray": "2",
                  //   fill: 'url(#grad3)',
                  //   opacity: 0.3,
                  // }
                  if (this.lines) {
                    this.lines.map(x => x.element.remove())
                  }
                  this.lines = []
                  // mid line
                  // this.lines.push(this.renderer.path(getPath(this, 0, 0)).attr({fill: colorDefs[0], opacity: 0.3}).add())
                  // this.lines.push(this.renderer.path(getPath(this, 1, 0)).attr({fill: colorDefs[0], opacity: 0.3}).add())
                  for (let column = 0; column < 3; column ++) {
                    for (let layer = 0; layer < 5; layer ++) {
                      this.lines.push(this.renderer.path(getPath(this, column, layer)).attr({fill: colorDefs[layer], opacity: 0.3}).add())
                    }
                  }
                },
              },
            },
            xAxis: {
              categories: years
            },
            tooltip: {
              formatter: function(tooltip) {
                let tmp = tooltip.defaultFormatter.call(this, tooltip);
                tmp[1] = tmp[1].replace(/<b>[0-9,\.]+<\/b>/, `<b>${this.y.toFixed(2)}%</b>`)
                return tmp
              }
            },
            yAxis: {
              title: {
                text: "สัดส่วนของกำลังแรงงาน (%)"
              },
              max: 100,
              reversedStacks: false,
              labels: {
                // formatter: !showPercent && function() {
                //   return `${(this.value / 1e6).toFixed(1)}M`
                // },
              }
            },
            responsive: {
              rules: [{
                condition: {
                  minWidth: 700,
                },
                chartOptions: {
                  legend: {
                    maxHeight: undefined,
                  },
                  chart: {
                    height: "50%",
                  },
                },
              }]
            }
          }}
        />
			</div>
		</div>
  )

}