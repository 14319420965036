import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'

export default function Platforms() {

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "400px"}}
      data={{
        labels: ["Shopee", "Lazada", "Facebook", "Instagram", "LINE", "Kaidee", "lnwShop", "WeLoveShopping", "Shopping", "ThailandPostmart"],
        datasets: [
          {
            data: [91.0, 72.9, 55.1, 42.1, 41.6, 6.6, 6.3, 3.3, 2.4, 1.1],
            backgroundColor: getAreaColor([
              ...Array(5).fill(defaultStyles.colors[1]),
              ...Array(5).fill(defaultStyles.colors[0]),
            ]),
            borderColor: `rgba(255, 255, 255, 0)`,
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            title: {
              display: true,
              text: "สัดส่วนผู้ตอบแบบสำรวจ (%)"
            }
          },
        },
        plugins: {
          // title: {
          //   display: true,
          //   text: "ช่องทางออนไลน์ที่คนไทยซื้อสินค้าและบริการ",
          // },
          legend: {
            display: false,
          }
        }
      }}
    />
  )


}