import React from "react"
import { LazyHighcharts } from "../../../../../src/components/Plots"

const rawData = {
  actual: [
    ["2564–65", 27.28],
    ["2565–66", 32.76],
  ],
  limit: [
    ["2564–65", 10],
    ["2565–66", 5],
  ]
}

export default function Sugarcane() {

  return(
    <LazyHighcharts
      options={{
        series: [
          {
            name: "เกิดขึ้นจริง",
            data: rawData.actual,
            pointPlacement: 0.08,
          },
          {
            name: "มติ ครม.",
            data: rawData.limit,
            pointPlacement: -0.08,
          },
        ],
        plotOptions: {
          column: {
            // pointPadding: 0,
            // dataLabels: {
            //   enabled: showPercent,
            //   formatter: function() {
            //     return `${this.percentage.toFixed(0)}%`
            //   },
            // },
          },
        },
        legend: {
          // enabled: false,
        },
        chart: {
          type: "column",
          height: "400px",
        },
        title: {
          text: "สัดส่วนอ้อยไฟไหม้ที่เกิดขึ้นจริง<br />ยังสูงกว่ามติคณะรัฐมนตรีหลายเท่าตัว",
          style: {
            fontSize: '14px',
          },
        },
        xAxis: {
          categories: ["2564–65", "2565–66"],
        },
        tooltip: {
          valueSuffix: "%",
          // formatter: function(tooltip) {
          //   let tmp = tooltip.defaultFormatter.call(this, tooltip);
          //   if (showPercent)
          //     tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.percentage.toFixed(2)}%</b>`)
          //   return tmp
          // }
        },
        yAxis: {
          title: {
            text: ""
          },
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}