import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"
import { educationLabels } from "./Labor"

const rawData = [
  [7.69, 3.91],
  [67.86, 36.33],
  [14.51, 35.16],
  [3.15, 5.84],
  [6.79, 18.76],
]

const years = [2531, 2563]

export default function Education() {

  return(
    <LazyHighcharts
      options={{
        series: rawData.map((data, i) => ({
          name: educationLabels[i],
          data,
        })),
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
              // formatter: function() {
              //   return `${this.y.toFixed(0)}%`
              // },
            },
          },
        },
        legend: {
          // enabled: false,
        },
        chart: {
          type: "column",
          height: "400px",
          events: {
            render: function() {
              
              const width = 0.24
              function getPath(obj, x, stackLeft, stackRight=stackLeft) {
                const xAxis = obj.xAxis[0]
                const yAxis = obj.yAxis[0]
                const left = xAxis.toPixels(x + width)
                const right = xAxis.toPixels(x + 1 - width)
                const topLeft = yAxis.toPixels(obj.series[stackLeft].data[x].stackY)
                const topRight = yAxis.toPixels(obj.series[stackRight].data[x + 1].stackY)
                let bottomLeft = yAxis.toPixels(stackLeft > 0 ? (obj.series[stackLeft - 1].data[x].stackY) : 0)
                let bottomRight = yAxis.toPixels(stackRight > 0 ? (obj.series[stackRight - 1].data[x + 1].stackY) : 0)
                return [
                  'M', left, topLeft,
                  'C', xAxis.toPixels(x + 0.5), topLeft, xAxis.toPixels(x + 1 - 0.5), topRight, right, topRight,
                  'V', bottomRight,
                  'C', xAxis.toPixels(x + 1 - 0.5), bottomRight, xAxis.toPixels(x + 0.5), bottomLeft, left, bottomLeft,
                  'Z',
                ]
              }
              if (this.lines) {
                this.lines.map(x => x.element.remove())
              }
              this.lines = []
              // mid line
              for (let i = 0; i < 5; i ++) {
                this.lines.push(this.renderer.path(getPath(this, 0, i)).attr({fill: `${defaultStyles.colors[i]}`, opacity: 0.3}).add())
              }
            },
          },
        },
        xAxis: {
          categories: years,
        },
        tooltip: {
          valueSuffix: "%",
          // formatter: function(tooltip) {
          //   let tmp = tooltip.defaultFormatter.call(this, tooltip);
          //   if (showPercent)
          //     tmp[1] = tmp[1].replace(/<b>[0-9,]+<\/b>/, `<b>${this.percentage.toFixed(2)}%</b>`)
          //   else
          //     tmp[1] = tmp[1].replace("</b>", ` คัน</b>`)
          //   return tmp
          // }
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            format: "{value}%",
          },
          max: 100,
          reversedStacks: false,
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "50%",
              },
            },
          }]
        }
      }}
    />
  )

}