import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'
import chroma from 'chroma-js'

const orangePerception = [2.183398, 3.346723, 1.57685, 1.735632, 1.54352, 2.136179, 4.2, 3.28, 2.031056]
const yellowPerception = [3.985496, 2.042187, 3.795764, 3.631497, 3.471264, 3.566745, 3.122143, 3.193705, 3.256369]
const orangeReported = [2.102983, 3.7066, 1.58794, 1.767591, 1.388889, 1.85592, 4.701779, 4.016429, 1.441029]
const yellowReported = [3.418564, 2.55536, 2.704626, 2.866432, 2.326279, 2.134276, 4.439153, 3.816254, 1.563604]

const dataPerception = [...Array(9).keys()].map(i => [orangePerception[i], yellowPerception[i]])
const dataReported = [...Array(9).keys()].map(i => [orangeReported[i], yellowReported[i]])

const politicalColors = chroma.scale(['orange', 'white', 'yellow'])

const dots = [...Array(9).keys()].map(i => [
  // yellow image
  {
    type: 'point',
    xValue: yellowPerception[i],
    yValue: i,
    backgroundColor: getAreaColor(politicalColors.colors(6)[5]),
    borderColor: defaultStyles.font.color, //politicalColors.colors(6)[0],
    borderDash: [2, 2],
    borderWidth: 1,
    radius: 5,
  },
  // orange image
  {
    type: 'point',
    xValue: orangePerception[i],
    yValue: i,
    borderColor: defaultStyles.font.color, //politicalColors.colors(6)[0],
    backgroundColor: getAreaColor(politicalColors.colors(6)[0]),
    borderDash: [2, 2],
    borderWidth: 1,
    radius: 5,
  },
  // yellow actual
  {
    type: 'point',
    xValue: yellowReported[i],
    yValue: i,
    borderColor: defaultStyles.font.color, //politicalColors.colors(6)[0],
    backgroundColor: getAreaColor(politicalColors.colors(6)[5]),
    radius: 5,
  },
  // orange actual
  {
    type: 'point',
    xValue: orangeReported[i],
    yValue: i,
    borderColor: defaultStyles.font.color, //politicalColors.colors(6)[0],
    backgroundColor: getAreaColor(politicalColors.colors(6)[0]),
    radius: 5,
  },
]).flat()


export default function Perception({ highlightArray }) {

  return(
    <LazyChartjs
      type="bar"
      style={{minHeight: "800px"}}
      data={{
        labels: [
          ["ในการปกครอง การมีผู้นำที่ดี มีศีลธรรม", "สำคัญกว่าการมีระบบที่สามารถตรวจสอบผู้นำได้"],
          ["ควรทำธุรกิจสีเทา", "(การพนัน การค้าประเวณี) ให้เป็นธุรกิจถูกกฎหมาย"],
          ["รัฐประหารเป็นสิ่งที่ยอมรับได้"],
          ["ท่านยอมรับได้หากรัฐจะสอดแนมประชาชน", "เพื่อรักษาความมั่นคงของประเทศ"],
          ["ท่านยอมรับได้หากรัฐใช้ความรุนแรง", "ต่อชีวิตหรือทรัพย์สินของประชาชน", "เพื่อรักษาความมั่นคงของประเทศ"],
          ["การใช้ความสัมพันธ์ส่วนตัว", "เพื่อสิทธิพิเศษบางอย่างเป็นสิ่งที่ยอมรับได้"],
          ["ในการเลือกสมาชิกสภาผู้แทนราษฎร", "คะแนนเสียงของทุกคนควรมีน้ำหนักเท่ากัน"],
          ["หากไม่มีปัญหาการโกงกิน รัฐควรจัดเก็บภาษี", "ให้มากขึ้นเพื่อพัฒนาบริการขั้นพื้นฐานให้มีคุณภาพสูง", "ตรงความต้องการของคนทุกระดับรายได้"],
          ["แม้ว่าท่านจะเห็นว่าคำสั่งของหัวหน้าผิดศีลธรรม", "ท่านก็ยังจะทำตามคำสั่งนั้นเพราะเป็นหน้าที่ของท่าน"],
        ],
        datasets: [
          {
            label: "ความแตกต่างที่คิด",
            data: dataPerception,
            backgroundColor: getAreaColor(defaultStyles.colors[1]),
            borderColor: 'none',
            categoryPercentage: 0.5,
          },
          {
            label: "ความแตกต่างจริง",
            data: dataReported,
            backgroundColor: getAreaColor(defaultStyles.colors[0]),
            borderColor: 'none',
            categoryPercentage: 0.5,
          }
        ]
      }}
      options={{
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            min: 1,
            max: 5,
            ticks: {
              callback: (value, index, values) => {
                if (value === 1)
                  return ['1', 'ไม่เห็นด้วยเลย']
                if (value === 5)
                  return ['5', 'เห็นด้วยมากที่สุด']
                if (value % 1 === 0)
                  return value
                return null
              },
            },
          },
          y: {
            // ticks: {
            //   color: alpharize(styles.textColor),
            // }
          }
        },
        plugins: {
          // annotation: {
          //   annotations: x
          // }
          annotation: {
            annotations: dots,
          },
        }
      }}
    />
  )


}