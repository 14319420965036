// https://janosh.dev/blog/gatsby-interactive-plots

import React from 'react'
import Loadable from 'react-loadable'
import { cloneDeep } from 'lodash'
import deepmerge from "deepmerge"
import CircularProgress from '@mui/material/CircularProgress'
import classNames from "classnames/bind"
import styles from "./styles.module.scss"

const cx = classNames.bind(styles);

const LoadHighcharts = Loadable.Map({
	loader: {
		HighchartsReact: () => import(`highcharts-react-official`),
		Highcharts: () => import('highcharts'),
		highcharts3d: () => import("highcharts/highcharts-3d"),
		highchartsMore: () => import('highcharts/highcharts-more'),
		highchartsSankey: () => import("highcharts/modules/sankey"),
		highchartsSunburst: () => import("highcharts/modules/sunburst"),
		highchartsData: () => import("highcharts/modules/data"),
		highchartsMap: () => import("highcharts/modules/map"),
		highchartsAccessibility: () => import("highcharts/modules/accessibility"),
		highchartsBoost: () => import("highcharts/modules/boost"),
		highchartsAnnotations: () => import("highcharts/modules/annotations"),
		highchartsSeriesLabel: () => import("highcharts/modules/series-label"),
	},
	render(loaded, props) {
		let HighchartsReact = loaded.HighchartsReact.default
    let Highcharts = loaded.Highcharts.default
    if (typeof Highcharts !== 'object')
      return null
    loaded.highcharts3d.default(Highcharts)
    loaded.highchartsSankey.default(Highcharts)
    loaded.highchartsSunburst.default(Highcharts)
    loaded.highchartsMap.default(Highcharts)
    loaded.highchartsData.default(Highcharts)
    loaded.highchartsAccessibility.default(Highcharts)
    loaded.highchartsBoost.default(Highcharts)
    loaded.highchartsAnnotations.default(Highcharts)
    loaded.highchartsSeriesLabel.default(Highcharts)
    loaded.highchartsMore.default(Highcharts)
    Highcharts.setOptions({
      lang: {
        weekdays: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],
        shortWeekdays: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        shortMonths: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
        months: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
        thousandsSep: ",",
      },
    })
    Highcharts.dateFormats = {
      q: function (timestamp) {
        const date = new Date(timestamp)
        return (Math.floor(date.getUTCMonth() / 3) + 1);
      }
    }
		return <HighchartsReact highcharts={Highcharts} {...props} constructorType={props.constructorType} ref={props.refID || undefined} />
	},
	loading: ({ timedOut }) =>
		<div className={cx("loading")}>
		{timedOut
		? <blockquote>Error: Timed out.</blockquote>
		: <CircularProgress />
		}
		</div>,
	timeout: 30000,
})

export const LazyHighcharts = ({ defaultStyles, constructorType, options, refID, split=1, altImage }) => {

	const refInternal = React.useRef(null)
	const refWrapper = React.useRef(null)

	async function handlePrint() {
		// manually resize to 650 (A4 paper with, subtracting margins, I think)
		// take into account split-2 stuff
		// refInternal.current.chart.setSize((650/split) - (10*(split-1)), null, false)
    const mdxWidth = document?.getElementById("mdx-body").offsetWidth
    // TODO: fix this hard-coded 640
		refInternal.current.chart.setSize(refWrapper.current.offsetWidth * 640 / mdxWidth, null, false)
	}

	function handlePrintDone() {
		refInternal.current.chart.setSize(null, null, false)
	}
	
	React.useEffect(() => {
		window.addEventListener("beforeprint", handlePrint);
		window.addEventListener("afterprint", handlePrintDone);
    return () => {
      window.removeEventListener("beforeprint", handlePrint);
			window.removeEventListener("afterprint", handlePrintDone);
		}
  }, [])

	// const buttonTheme = {
	// 	stroke: `rgba(0, 0, 0, 0)`,
	// 	r: 5,
	// 	fill: styles.gridline,
	// 	style: {
	// 		// color set in main.scss
	// 	},
	// 	states: {
	// 		hover: {
	// 			fill: colors.zeroline,
	// 		}
	// 	}
	// }

  const defAxisOptions = {
    gridLineColor: defaultStyles.gridlineColor,
    lineColor: defaultStyles.zerolineColor,
    tickColor: defaultStyles.zerolineColor,
    labels: {
      style: {
				fontFamily: defaultStyles.tick?.font?.family || defaultStyles.font.family,
				fontSize: `${defaultStyles.tick?.font?.size || defaultStyles.font.size}px`,
				color: defaultStyles.tick?.font?.color || defaultStyles.font.color,
			},
    },
    title: {
      style: {
				fontFamily: defaultStyles.axisTitle?.font?.family || defaultStyles.font.family,
				fontSize: `${defaultStyles.axisTitle?.font?.size || defaultStyles.font.size}px`,
				color: defaultStyles.axisTitle?.font?.color || defaultStyles.font.color,
			},
    },
  }

	const defOptions = {
		chart: {
			backgroundColor: defaultStyles.plotColor,
			type: 'spline',
			resetZoomButton: {
				// theme: buttonTheme
			},
		},
		plotOptions: {
			series: {
				dataLabels: {
					style: {
						fontFamily: "Dindan",
					},
				},
        lineWidth: 2,
				label: {
					enabled: false,
					style: {
						fontFamily: "Dindan",
					},
				},
			},
		},
		mapNavigation: {
			buttonOptions: {
				// theme: buttonTheme,
			},
		},
		colors: defaultStyles.colors,
		xAxis: cloneDeep(defAxisOptions),
		yAxis: cloneDeep(defAxisOptions),
		title: {
			text: '',
			style: {
				fontFamily: defaultStyles.title?.font?.family || defaultStyles.font.family,
				fontSize: `${defaultStyles.title?.font?.size || defaultStyles.font.size}px`,
				color: defaultStyles.title?.font?.color || defaultStyles.font.color,
			}
		},
		subtitle: {
			text: '',
			style: {
				fontFamily: defaultStyles.title?.font?.family || defaultStyles.font.family,
				fontSize: `${(defaultStyles.title?.font?.size || defaultStyles.font.size) - 4}px`,
				color: defaultStyles.title?.font?.color || defaultStyles.font.color,
			}
		},
		legend: {
			itemStyle: {
				fontFamily: defaultStyles.legend?.font?.family || defaultStyles.font.family,
				fontSize: `${defaultStyles.legend?.font?.size || defaultStyles.font.size}px`,
				color: defaultStyles.legend?.font?.color || defaultStyles.font.color,
				// fontWeight: `normal`
			},
			// itemHoverStyle: {
			// 	color: styles.textColor
			// }
		},
		tooltip: {
			backgroundColor: defaultStyles.tooltip?.font?.backgroundColor,
			borderWidth: 0,
			shadow: false,
			borderRadius: 5,
			style: {
				fontFamily: defaultStyles.tooltip?.font?.family || defaultStyles.font.family,
				fontSize: `${defaultStyles.tooltip?.font?.size || defaultStyles.font.size}px`,
				color: defaultStyles.tooltip?.font?.color || defaultStyles.font.color,
			},
			xDateFormat: '%Y-%m-%d',
		},
		credits: {
			enabled: false,
		},
	};

	// Highcharts.dateFormats = {
	// 	q: function (timestamp) {
	// 		const date = new Date(timestamp)
	// 		return (Math.floor(date.getUTCMonth() / 3) + 1);
	// 	}
	// };

	// const defSimpleTimeSeries = {
	// 	chart: {
	// 		zoomType: 'x',
	// 	},
	// 	series: [
	// 		{
	// 			type: 'line',
	// 			data: data,
	// 			name: 'ปริมาณธนบัตรที่หมุนเวียนในระบบเศรษฐกิจ',
	// 			tooltip: {
	// 				pointFormat: '{point.y} ล้านบาท',
	// 				xDateFormat: '%Y.%m',
	// 			},
	// 		},
	// 	],
	// 	plotOptions: {
	// 		series: {
	// 			pointStart: Date.UTC(1997, 0, 1),
	// 			pointIntervalUnit: 'month',
	// 			pointInterval: 1,
	// 		}
	// 	},
	// 	xAxis: {
	// 		type: 'datetime',
	// 	},
	// 	yAxis: {
	// 		title: {
	// 			text: 'ล้านบาท',
	// 		},
	// 	},
	// 	title: {
	// 		text: 'ปริมาณธนบัตรที่หมุนเวียนในระบบเศรษฐกิจ'
	// 	},
	// 	legend: {
	// 		enabled: false,
	// 	},
	// }

  let finalOptions = deepmerge(defOptions, options, { arrayMerge: (destinationArray, sourceArray, options) => sourceArray })
  // loop over plotLines and plotBands labels to set default fonts
  if (finalOptions.xAxis.plotLines) {
    finalOptions.xAxis.plotLines.forEach((v) => {
      if (v.label) {
        v.label.style = {
          ...v.label.style,
          fontFamily: defaultStyles.font.family,
        }
      }
    })
  }
  if (finalOptions.xAxis.plotBands) {
    finalOptions.xAxis.plotBands.forEach((v) => {
      if (v.label) {
        v.label.style = {
          ...v.label.style,
          fontFamily: defaultStyles.font.family,
        }
      }
    })
  }

	return(
    <div className={`highcharts-wrapper`} ref={refWrapper}>
      <LoadHighcharts
        // highcharts={Highcharts}
        constructorType={constructorType}
        // options={options}
        options={finalOptions}
        // containerProps={{ style: { width: "100%" } }}
        refID={refID || refInternal}
      />
    </div>
	)

}