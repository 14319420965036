import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"

const rawData = [
  {
    name: "ไม่โอนเข้า e-Wallet",
    data: [20350642,842351,485055,417832,349369,330809,306371,343365,367371,260355,291274,293064,294051,519464,400564,234515,193108,199467],
    color: defaultStyles.colors[1],
  }, 
  {
    name: "โอนเข้า e-Wallet แต่โอนเข้าบัญชีอื่นเป็นธุรกรรมแรก",
    data: [1383240,8081,5169,42023,49646,29428,45816,24674,17273,4659,5926,12433,72791,43656,24375,42531,34069,26506],
    color: 'grey',
  },
  {
    name: "โอนเข้า e-Wallet เป็นธุรกรรมแรก",
    data: [73108,2593,1698,58972,95938,34007,79167,22971,9746,1354,1418,11603,149468,36639,15337,46300,30840,17053],
    color: defaultStyles.colors[0],
  },
]

export default function WalletComposition() {

  return(
    <LazyHighcharts
      options={{
        series: rawData.map(series => ({
          ...series,
          data: series.data.slice(1),
        })),
        plotOptions: {
          series: {
            pointStart: Date.UTC(2020, 7, 1),
            pointIntervalUnit: 'month',
            marker: {
              enabled: false,
            },
          },
          areaspline: {
            stacking: "normal",
          }
        },
        legend: {
          maxHeight: 100,
        },
        chart: {
          type: "areaspline",
          zoomType: "x",
          height: "500px",
        },
        xAxis: {
          type: 'datetime',
        },
        tooltip: {
          xDateFormat: '%Y-%m',
        },
        yAxis: {
          title: {
            text: "ปริมาณผู้ใช้บริการ"
          },
          plotLines: [{
            value: 0,
            color: defaultStyles.zerolineColor,
            zIndex: 9,
          }]
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 700,
            },
            chartOptions: {
              legend: {
                maxHeight: undefined,
              },
              chart: {
                height: "60%",
              },
            },
          }]
        }
      }}
    />
  )

}