import React from "react"
import { LazyHighcharts, defaultStyles } from "../../../../../src/components/Plots"

const countries = ["DOM","PHL","PAN","MAR","IDN","GEO","BRA","ARG","LBN","COL","PER","BIH","MKD","KAZ","CRI","MEX","JOR","URY","MNE","BRN","MDA","BGR","ROU","ALB","CHL","MYS","SRB","GRC","ISR","MLT","UKR","TUR","HRV","SVK","LUX","HUN","ITA","LTU","ESP","USA","ISL","AUT","RUS","PRT","FRA","AUS","DEU","NZL","NOR","BEL","CZE","SWE","CHE","GBR","LVA","NLD","DNK","IRL","SVN","CAN","KOR","POL","FIN","JPN","HKG","VNM","EST","SGP","MAC","CHN","THA"]
const share_basic = [0.12244,0.205889,0.236862,0.273563,0.33964,0.371822,0.381834,0.386851,0.389029,0.420345,0.424887,0.427321,0.4467,0.451827,0.45977,0.483662,0.500943,0.525966,0.526924,0.531209,0.534473,0.54443,0.546536,0.552076,0.562953,0.608751,0.608813,0.661264,0.663402,0.680506,0.687685,0.690201,0.716673,0.72744,0.729327,0.750495,0.750877,0.760148,0.769496,0.770769,0.771036,0.784897,0.784998,0.785093,0.790754,0.793029,0.796111,0.800437,0.800888,0.80109,0.803766,0.810368,0.814041,0.816166,0.820349,0.82055,0.833328,0.835986,0.844655,0.851039,0.853723,0.856714,0.860233,0.888115,0.895938,0.902029,0.904805,0.91881,0.94469,0.977551,0.513056]
const share_top = [0.000181,0.000683,0.000931,0.000715,0.002772,0.00564,0.008727,0.004158,0.012719,0.004822,0.005425,0.004652,0.009572,0.0117,0.002205,0.003814,0.006717,0.008761,0.010525,0.02658,0.016599,0.028706,0.020778,0.012515,0.011352,0.015283,0.034182,0.025343,0.073364,0.064704,0.042489,0.036195,0.044035,0.072405,0.081466,0.06344,0.061522,0.064583,0.057518,0.087233,0.071223,0.094539,0.055798,0.086337,0.088143,0.100071,0.116821,0.114686,0.095105,0.119155,0.101213,0.104688,0.124043,0.113101,0.061223,0.145404,0.085986,0.070534,0.105058,0.133669,0.166285,0.125566,0.117293,0.15736,0.184233,0.094952,0.138855,0.289057,0.207006,0.379747,0.014997]

const data = countries.map((country, index) => ({
  country,
  share_basic: share_basic[index],
  share_top: share_top[index]
}))

export default function Ab2023Fig7() {
  return(
    <LazyHighcharts
      options={{
        chart: {
          type: "scatter",
        },
        series: [
          {
            // add trend line with equation y = 0.0002e^7.6592x
            data: Array.from(Array(101).keys()).map(x => x / 100).map(x => ({
              x,
              y: 0.0002 * Math.exp(7.6592 * x),
            })),
            type: "line",
            marker: {
              enabled: false,
            },
            color: defaultStyles.colors[0],
            dashStyle: "Dash",
            enableMouseTracking: false,
          },
          {
            data: countries.map((country, index) => ({
              name: country,
              x: share_basic[index],
              y: share_top[index],
              color: country === "THA" ? defaultStyles.colors[6] : defaultStyles.colors[1],
              marker: {
                symbol: country === "THA" ? "square" : "circle",
                radius: country === "THA" ? 6 : 4,
              },
              zIndex: country === "THA" ? 1 : 0,
              dataLabels: {
                enabled: country === "THA",
                align: "right",
                x: -5,
                // style: {
                //   fontFamily: "Dindan",
                // },
                formatter: function() {
                  return this.point.name
                }
              },
            })),
            lineWidth: 0,
          },
        ],
        tooltip: {
          headerFormat: "{point.key}<br />",
          formatter: function(tooltip) {
            const tmp = tooltip.defaultFormatter.call(this, tooltip)
            tmp[1] = `<span style="color: ${this.point.color}">●</span> (${this.x.toFixed(3)}, ${this.y.toFixed(3)})`
            return tmp
          }
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          title: {
            text: "Share of students reaching basic level in 2018"
          },
          gridLineWidth: 1,
        },
        yAxis: {
          title: {
            text: "Share of top performers in 2018"
          },
          max: 0.4,
        },
      }}
    />
  )
}