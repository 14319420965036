import React from 'react'
import { LazyChartjs, defaultStyles, getAreaColor } from '../../../../src/components/Plots'
import chroma from 'chroma-js'

const usColor = '#0A3161'
const cnColor = '#df2407'

function Investments() {

  return(
    <LazyChartjs
      type="line"
      style={{minHeight: "400px"}}
      data={{
        labels: [...Array(15).keys()].map(x => x + 2006),
        datasets: [
          {
            data: [0.461193715, 0.403640965, 0.475220835, 0.50179091, 0.933997959, 1.194895908, 1.448498298, 1.853459414, 1.632765256, 1.689373072, 2.282328423, 2.027178381, 2.283657598, 2.776400004, 2.733742509],
            label: "จีน",
            tension: 0.4,
            backgroundColor: getAreaColor(cnColor),
            borderColor: cnColor,
            fill: true,
          },
          {
            data: [11.37837563, 10.13135187, 9.677620293, 9.519999818, 9.225781636, 9.258475833, 9.587324964, 8.221337325, 7.907904056, 8.142316389, 7.519867543, 6.611558165, 6.772254041, 6.723215518, 6.219724666],
            label: "สหรัฐฯ",
            tension: 0.4,
            backgroundColor: getAreaColor(usColor),
            borderColor: usColor,
            fill: true,
          },
        ]
      }}
      options={{
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        radius: 0,
        scales: {
          y: {
            stacked: true,
            title: {
              display: true,
              text: "สัดส่วนของ FDI รวม (%)"
            }
          }
        },
        plugins: {
          // title: {
          //   display: true,
          //   text: "สัดส่วนการลงทุนทางตรงของจีนและสหรัฐฯ",
          // },
        }
      }}
    />
  )

}

const Tourists = () => (
  <LazyChartjs
    type="line"
    style={{minHeight: "400px"}}
    data={{
      labels: ["Jan-15", "Feb-15", "Mar-15", "Apr-15", "May-15", "Jun-15", "Jul-15", "Aug-15", "Sep-15", "Oct-15", "Nov-15", "Dec-15", "Jan-16", "Feb-16", "Mar-16", "Apr-16", "May-16", "Jun-16", "Jul-16", "Aug-16", "Sep-16", "Oct-16", "Nov-16", "Dec-16", "Jan-17", "Feb-17", "Mar-17", "Apr-17", "May-17", "Jun-17", "Jul-17", "Aug-17", "Sep-17", "Oct-17", "Nov-17", "Dec-17", "Jan-18", "Feb-18", "Mar-18", "Apr-18", "May-18", "Jun-18", "Jul-18", "Aug-18", "Sep-18", "Oct-18", "Nov-18", "Dec-18", "Jan-19", "Feb-19", "Mar-19", "Apr-19", "May-19", "Jun-19", "Jul-19", "Aug-19", "Sep-19", "Oct-19", "Nov-19", "Dec-19", "Jan-20", "Feb-20", "Mar-20", "Apr-20", "May-20", "Jun-20", "Jul-20", "Aug-20", "Sep-20", "Oct-20", "Nov-20", "Dec-20", "Jan-21", "Feb-21", "Mar-21", "Apr-21", "May-21", "Jun-21", "Jul-21"],
      datasets: [
        {
          data: [81118, 71915, 74675, 67450, 63906, 67891, 70255, 56090, 53057, 70841, 88502, 101820, 93149, 82406, 87171, 74761, 72047, 78120, 81007, 61234, 58956, 73760, 98031, 115001, 101785, 87679, 93757, 87128, 76498, 86889, 86394, 67972, 62989, 77854, 105216, 122262, 109577, 90388, 106562, 88698, 81146, 90468, 88351, 71161, 65009, 82986, 118125, 129617, 112815, 92454, 106045, 92922, 82083, 94655, 88356, 70438, 63798, 84767, 118439, 129438, 113847, 72484, 24151, 0, 0, 0, 0, 0, 0, 23, 133, 437, 757, 668, 725, 1172, 1156, 850, 2510].map(x=>x*1e-6),
          label: "สหรัฐฯ",
          tension: 0.4,
          backgroundColor: getAreaColor(usColor),
          borderColor: usColor,
          fill: true,
        },
        {
          data: [560360, 780516, 663571, 701169, 668079, 632889, 775829, 800596, 532696, 566053, 615195, 637838, 814693, 958201, 856683, 816029, 738601, 715411, 865355, 891382, 658895, 474363, 432467, 535566, 859617, 790051, 789417, 748127, 762193, 762481, 937971, 983245, 760999, 805616, 790839, 815704, 969996, 1198971, 1004025, 986703, 869206, 900652, 929738, 867481, 647664, 646141, 675129, 838634, 1076816, 1079300, 993627, 909942, 804453, 844240, 1004061, 1056767, 868292, 832446, 804876, 863838, 1030148, 160564, 56852, 0, 0, 0, 0, 0, 0, 471, 914, 961, 572, 359, 589, 643, 505, 323, 1032].map(x=>x*1e-6),
          label: "จีน",
          tension: 0.4,
          backgroundColor: getAreaColor(cnColor),
          borderColor: cnColor,
          fill: true,
        },
      ]
    }}
    options={{
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: 'index',
      },
      radius: 0,
      scales: {
        y: {
          stacked: true,
          title: {
            display: true,
            text: "จำนวนนักท่องเที่ยว (ล้านคน)"
          }
        }
      },
      plugins: {
        // title: {
        //   display: true,
        //   text: "สัดส่วนนักท่องเที่ยวจีนและสหรัฐฯ",
        // },
        legend: {
          reverse: true,
        },
        annotation: {
          annotations: {
            bombing: {
              type: 'box',
              xMin: "Aug-15",
              xMax: "Dec-15",
              drawTime: "beforeDatasetsDraw",
              borderWidth: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
            bombingLabel: {
              type: 'line',
              xMin: "Oct-15",
              xMax: "Oct-15",
              borderColor: `rgba(255, 255, 255, 0)`,
              drawTime: 'afterDatasetsDraw',
              label: {
                enabled: true,
                content: "เหตุการณ์ระเบิดราชประสงค์",
                font: {
                  ...defaultStyles.font,
                  style: 'normal',
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                color: defaultStyles.font.color,
                rotation: -90,
                position: 'start',
              }
            },
            tour: {
              type: 'box',
              xMin: "Oct-16",
              xMax: "Dec-16",
              drawTime: "beforeDatasetsDraw",
              borderWidth: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
            tourLabel: {
              type: 'line',
              xMin: "Nov-16",
              xMax: "Nov-16",
              borderColor: `rgba(255, 255, 255, 0)`,
              drawTime: 'afterDatasetsDraw',
              label: {
                enabled: true,
                content: "ทัวร์ศูนย์เหรียญ",
                font: {
                  ...defaultStyles.font,
                  style: 'normal',
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                color: defaultStyles.font.color,
                rotation: -90,
                position: 'start',
              }
            },
            boat: {
              type: 'box',
              xMin: "Jul-18",
              xMax: "Nov-18",
              drawTime: "beforeDatasetsDraw",
              borderWidth: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
            boatLabel: {
              type: 'line',
              xMin: "Sep-18",
              xMax: "Sep-18",
              borderColor: `rgba(255, 255, 255, 0)`,
              drawTime: 'afterDatasetsDraw',
              label: {
                enabled: true,
                content: "เหตุการณ์เรือล่ม",
                font: {
                  ...defaultStyles.font,
                  style: 'normal',
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                color: defaultStyles.font.color,
                rotation: -90,
                position: 'start',
              }
            },
            covid: {
              type: 'box',
              xMin: "Feb-20",
              xMax: "Jul-21",
              drawTime: "beforeDatasetsDraw",
              borderWidth: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
            covidLabel: {
              type: 'line',
              xMin: "Nov-20",
              xMax: "Nov-20",
              borderColor: `rgba(255, 255, 255, 0)`,
              drawTime: 'afterDatasetsDraw',
              label: {
                enabled: true,
                content: "วิกฤตโควิด 19",
                font: {
                  ...defaultStyles.font,
                  style: 'normal',
                },
                backgroundColor: 'rgba(255, 255, 255, 0)',
                color: defaultStyles.font.color,
                rotation: -90,
                position: 'start',
              }
            },
          }
        },
        // texts: {
        //   texts: {
        //     bombing: {
        //       text: 'เหตุการณ์ระเบิดราชประสงค์',
        //       x: 160,
        //       y: 35,
        //     },
        //     tour: {
        //       text: 'ทัวร์ศูนย์เหรียญ',
        //       x: 460,
        //       y: 35,
        //     },
        //     boat: {
        //       text: 'เหตุการณ์เรือล่ม',
        //       x: 725,
        //       y: 35,
        //     },
        //     covid: {
        //       text: 'วิกฤตโควิด 19',
        //       x: 1110,
        //       y: 35,
        //     },
        //   }
        // }
      }
    }}
  />
)

function ExportProducts() {

  return(
    <LazyChartjs
      type="bar"
      height={180}
      style={{minHeight: "400px"}}
      data={{
        labels: ["Machinery", "Electrical machinery and equipment", "Vehicles", "Pearls, stones, precious metals", "Rubber products", "Plastics products", "Preparations of meat, fish etc.", "Mineral fuels", "Optical products", "Edible fruit and nuts; peel of citrus fruit or melons"],
        datasets: [
          {
            data: [9.186, 9.109, 7.265, 0.781, 26.258, 27.144, 0.878, 10.032, 24.105, 69.528],
            label: "จีน",
            backgroundColor: getAreaColor(cnColor),
            borderColor: cnColor,
          },
          {
            data: [22.286, 21.944, 9.632, 5.663, 26.865, 6.684, 17.254, 0.054, 15.372, 2.393],
            label: "สหรัฐฯ",
            backgroundColor: getAreaColor(usColor),
            borderColor: usColor,
          },
        ]
      }}
      options={{
        maintainAspectRatio: false,
        indexAxis: 'y',
        radius: 0,
        scales: {
          x: {
            title: {
              display: true,
              text: "สัดส่วนมูลค่าการส่งออกในหมวดสินค้านั้น ๆ (%)"
            }
          }
        },
        plugins: {
          // title: {
          //   display: true,
          //   text: "สัดส่วนการส่งออกของไทยไปจีนและสหรัฐฯ",
          // },
          annotation: {
            annotations: [0, 5, 9].map(x => {
              let offset = 0
              if (x === 0)
                offset = 1
              return(
                {
                  type: 'box',
                  yMin: x - 0.5,
                  yMax: x + 0.5 + offset,
                  backgroundColor: chroma('black').alpha(0.2).hex(),
                  borderColor: 'rgba(255, 255, 255, 0)',
                  drawTime: 'beforeDraw'
                }
              )}
            )
          }
        }
      }}
    />
  )

}

function Exports() {

  return(
    <LazyChartjs
      type="line"
      style={{minHeight: "400px"}}
      data={{
        labels: [...Array(26).keys()].map(x => x + 1995),
        datasets: [
          {
            data: [2.907573869, 3.352511432, 3.043451846, 3.242065949, 3.18176053, 4.065222301, 4.408128539, 5.216009315, 7.107585237, 7.371223277, 8.263696605, 9.040960912, 9.649204673, 9.107349445, 10.57521761, 11.10886366, 11.79390837, 11.72921265, 11.91811991, 11.02768863, 11.07391933, 11.04966889, 12.46901342, 11.98499444, 11.84456171, 12.87073393],
            label: "จีน",
            tension: 0.4,
            backgroundColor: getAreaColor(cnColor),
            borderColor: cnColor,
            fill: true,
          },
          {
            data: [17.82828826, 17.97175979, 19.44514375, 22.330245, 21.63543144, 21.31141409, 20.25002984, 19.82118459, 16.98672979, 16.06464302, 15.32089143, 14.99345676, 12.61859067, 11.40470261, 10.93070977, 10.45045008, 9.786964663, 9.946514876, 10.04517578, 10.50323177, 11.22485985, 11.37467376, 11.22845138, 11.08528019, 12.72935508, 14.84290893],
            label: "สหรัฐฯ",
            tension: 0.4,
            backgroundColor: getAreaColor(usColor),
            borderColor: usColor,
            fill: true,
          },
        ]
      }}
      options={{
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        radius: 0,
        scales: {
          y: {
            stacked: true,
            title: {
              display: true,
              text: "สัดส่วนมูลค่าการส่งออก (%)"
            }
          }
        },
        plugins: {
          // title: {
          //   display: true,
          //   text: "สัดส่วนการส่งออกของไทยไปจีนและสหรัฐฯ",
          // },
        }
      }}
    />
  )

}

export default { Investments, Tourists, ExportProducts, Exports }