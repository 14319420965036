import React from 'react'
import { LazyChartjs } from '../../../../src/components/Plots'
import chroma from 'chroma-js'

const politicalColors = chroma.scale(['orange', 'white', 'yellow'])
const politicalLabels = ["เสาวรสมากที่สุด", "เสาวรสค่อนข้างมาก", "ค่อนไปทางเสาวรส", "ค่อนไปทางกล้วยหอม", "กล้วยหอมค่อนข้างมาก", "กล้วยหอมมากที่สุด"]

// const eduLabel = ["มัธยมศึกษาหรือต่ำกว่า", "ปริญญาตรี/ปวส.", "ปริญญาโท", "ปริญญาเอก"]
// const eduData = [
//   [0.258333, 0.306630, 0.249208, 0.248889],
//   [0.233333, 0.247238, 0.252376, 0.288889],
//   [0.141667, 0.169890, 0.202746, 0.168889],
//   [0.116667, 0.149171, 0.147835, 0.173333],
//   [0.116667, 0.075967, 0.101373, 0.084444],
//   [0.133333, 0.051105, 0.046463, 0.035556],
// ]

const ageLabel = ["< 30", "30–39", "40–59", "≥ 60"]
const ageData = [
  [0.433692, 0.366541, 0.191684, 0.182648],
  [0.390681, 0.308271, 0.194726, 0.210046],
  [0.100358, 0.180451, 0.208925, 0.182648],
  [0.039427, 0.082707, 0.204868, 0.200913],
  [0.014337, 0.043233, 0.129817, 0.132420],
  [0.021505, 0.018797, 0.069980, 0.091324],
]

const occLabel = ["นักเรียน", "รับจ้าง", "พนักงานเอกชน", "ข้าราชการ", "ธุรกิจส่วนตัว", "ทำงานที่บ้าน", "เกษียณ/ว่างงาน"]
const occData = [
  [0.412500, 0.364964, 0.300353, 0.258427, 0.251534, 0.154930, 0.211538],
  [0.400000, 0.204380, 0.263251, 0.267790, 0.233129, 0.253521, 0.201923],
  [0.100000, 0.175182, 0.180212, 0.196629, 0.187117, 0.197183, 0.177885],
  [0.037500, 0.153285, 0.130742, 0.132959, 0.174847, 0.154930, 0.216346],
  [0.025000, 0.051095, 0.081272, 0.089888, 0.113497, 0.154930, 0.120192],
  [0.025000, 0.051095, 0.044170, 0.054307, 0.039877, 0.084507, 0.072115],
]

const areaLabel = ["กรุงเทพฯ", "ในเขตเทศบาล", "นอกเขตเทศบาล"]
const areaData = [
  [0.248536, 0.310062, 0.290625],
  [0.261925, 0.252567, 0.218750],
  [0.186611, 0.170431, 0.190625],
  [0.157322, 0.133470, 0.143750],
  [0.094561, 0.084189, 0.093750],
  [0.051046, 0.049281, 0.062500],
]

const incomeLabel = [...Array(5).keys()].map(x => `กลุ่มรายได้ที่ ${x+1}`)
const incomeData = [
  [0.287305, 0.304245, 0.301837, 0.205656, 0.246649],
  [0.211581, 0.261792, 0.246719, 0.285347, 0.268097],
  [0.195991, 0.165094, 0.165354, 0.210797, 0.179625],
  [0.133630, 0.141509, 0.149606, 0.154242, 0.171582],
  [0.091314, 0.082547, 0.094488, 0.100257, 0.088472],
  [0.080178, 0.044811, 0.041995, 0.043702, 0.045576],
]

export default function All() {

  return(
    <div>
      {/* <ExtremityBreakdown
        data={eduData}
        label={eduLabel}
        title={"ตามกลุ่มการศึกษา"}
      /> */}
      <ExtremityBreakdown
        data={ageData}
        label={ageLabel}
        title={"ตามกลุ่มอายุ"}
      />
      <ExtremityBreakdown
        data={areaData}
        label={areaLabel}
        title={"ตามกลุ่มพื้นที่"}
      />
      <ExtremityBreakdown
        data={occData}
        label={occLabel}
        title={"ตามกลุ่มอาชีพ"}
      />
      <ExtremityBreakdown
        data={incomeData}
        label={incomeLabel}
        title={"ตามกลุ่มรายได้"}
      />
    </div>
  )

}

function ExtremityBreakdown({ data, label, title }) {

  return(
    <LazyChartjs
      type="line"
      style={{minHeight: "300px"}}
      data={{
        labels: label,
        datasets: data.map((d, i) => ({
          label: politicalLabels[i],
          data: d.map(x=>x*100),
          backgroundColor: politicalColors.colors(6)[i],
          fill: true,
          tension: 0.4,
          borderColor: 'rgba(255, 255, 255, 0)',
        }))
      }}
      options={{
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
          axis: 'y',
        },
        radius: 0,
        indexAxis: 'y',
        scales: {
          y: {
            stacked: true,
            reverse: true,
          },
          x: {
            stacked: true,
            max: 100,
            title: {
              display: true,
              text: "สัดส่วนของกลุ่ม (%)"
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: {
            display: false,
          }
        }
      }}
    />
  )


}